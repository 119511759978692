import pick from 'lodash/fp/pick';
import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { NewOrganization, Organization, OrganizationInvitation, OrganizationUser } from 'types/api.types';
import type { OrganizationApi } from '@env0/organization-service/api';
import type { AxiosResponse } from 'axios';
import type { RolesApi } from '@env0/role-service/api';

export default (client: HttpClient) => ({
  deleteOrganizationUser: (organizationId: string, userId: string) =>
    client.delete(`organizations/${organizationId}/users/${encodeURI(userId)}`),

  updateUserRole: (organizationId: string, userId: string, role: RolesApi.RBACPermissionRole) =>
    client.put(`organizations/${organizationId}/users/${encodeURI(userId)}/role`, role),

  validateOrganizationInvitation: (token: string) =>
    client.get<OrganizationInvitation>(`invites/accept/${token}`).then(returnData),

  acceptOrganizationInvitation: (token: string) => client.post(`invites/accept/${token}`),

  resendOrganizationInvitation: (inviteId: string) => client.post(`invites/${inviteId}/resend`),

  revokeOrganizationInvitation: (inviteId: string) => client.delete(`invites/${inviteId}`),

  sendInvite: (request: OrganizationApi.InviteRequest) => client.post('invites', request),

  getOrganizationUserList: (
    organizationId: string,
    { includeApiKeys, query }: { includeApiKeys?: boolean; query?: string }
  ) =>
    client
      .get<OrganizationUser[]>(`organizations/${organizationId}/users`, {
        params: { includeApiKeys, query: query || undefined }
      })
      .then(returnData),

  updateOrganizationPolicies: (organizationId: string, policies: OrganizationApi.UpdatePolicy.Request.Body) =>
    client.post(`organizations/${organizationId}/policies`, policies),

  createOrganization: (organization: NewOrganization) =>
    client.post<NewOrganization, AxiosResponse<Organization>>(`organizations`, organization).then(returnData),

  getOrganization: (organizationId: string) =>
    client.get<Organization>(`organizations/${organizationId}`).then(returnData),

  getOrganizations: () => client.get<Organization[]>(`organizations`).then(returnData),

  updateOrganization: (organization: Organization) =>
    client
      .put<Pick<Organization, 'name' | 'description' | 'photoUrl'>, AxiosResponse<Organization>>(
        `organizations/${organization.id}`,
        pick(['name', 'description', 'photoUrl'], organization),
        {
          hideNotification: true
        }
      )
      .then(returnData),

  getWebhookSecret: (organizationId: string, webhookType: OrganizationApi.GetWebhookSecret.WebhookType) =>
    client
      .get<OrganizationApi.GetWebhookSecret.Response>(`organizations/${organizationId}/webhook-secret`, {
        params: { webhookType }
      })
      .then(returnData)
});
