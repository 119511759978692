// tslint:disable
// this is an auto generated file. This will be overwritten

export const getLastEvent = /* GraphQL */ `
  query GetLastEvent($stream: String) {
    getLastEvent(stream: $stream) {
      id
      stream
      key
      description
      projectId
      projectRole
      organizationId
      organizationRole
      createdAt
    }
  }
`;
