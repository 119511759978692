import type { SchedulingApi } from '@env0/scheduling-service/api';
import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  getScheduledActions: async (environmentId: string): Promise<SchedulingApi.GetScheduleByEnvironmentId.Response> =>
    client
      .get<SchedulingApi.SetEnvironmentSchedule.Response>(`scheduling/environments/${environmentId}`)
      .then(returnData),

  setScheduledActions: async (
    environmentId: string,
    scheduledActions: SchedulingApi.SetEnvironmentSchedule.Request.Body
  ) =>
    client
      .put<
        SchedulingApi.SetEnvironmentSchedule.Request.Body,
        AxiosResponse<SchedulingApi.SetEnvironmentSchedule.Response>
      >(`scheduling/environments/${environmentId}`, scheduledActions)
      .then(returnData),

  getDriftDetection: async (environmentId: string): Promise<SchedulingApi.GetDriftDetectionByEnvironmentId.Response> =>
    client
      .get<SchedulingApi.SetDriftDetection.Response>(`scheduling/drift-detection/environments/${environmentId}`)
      .then(returnData),

  setDriftDetection: async (environmentId: string, driftDetection: SchedulingApi.SetDriftDetection.Request.Body) =>
    client
      .patch<SchedulingApi.SetDriftDetection.Request.Body, AxiosResponse<SchedulingApi.SetDriftDetection.Response>>(
        `scheduling/drift-detection/environments/${environmentId}`,
        driftDetection
      )
      .then(returnData)
});
