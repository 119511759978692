import type { Hub } from '@sentry/types';
import { toPng } from 'html-to-image';
import { serializeError } from 'serialize-error';
import { isError } from '@sentry/utils';
import * as Sentry from '@sentry/react';
import { EventNames, track } from 'utils/analytics.utils';
import { enableManualSentryErrorSending, isProd } from 'constants/config';
import { apiClient } from 'services/api-client';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { AxiosError } from 'axios';

declare global {
  interface Window {
    Sentry: Hub;
    FS?: {
      getCurrentSessionURL?: (now: boolean) => string;
    };
  }
}

const EXTRA_DATA_CONTEXT_NAME = 'Env0 Extra Data Section';
// When setting Sentry context values explicitly, undefineds are ignored
// So use this string instead to represent undefined values
const SENTRY_CONTEXT_UNDEFINED_VALUE = '[undefined]';

// default image if the existing cannot be loaded
// https://github.com/bubkoo/html-to-image/issues/314#issuecomment-1274574340
const BROKEN_IMAGE_PLACEHOLDER =
  'data:image/gif;base64,R0lGODlhGQAZAPQAAISChKWipcbT7+fr/8bf51KuOdbf93vDc87b9/f3997n/2u2Y9bf/5SSlJzHtcbT9////+/z/1KyOdbj94zHjNbb9/f3/2u6Y9bj/5yenLXP1sbX9wAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS41LjAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+ICAgPD94cGFja2V0IGVuZD0idyI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACwAAAAAGQAZAAAF/2DgWGRpmlkTaIkFvWIkzzSdZY42JS8UBIqJcEicKBSZAGSSIfB8AaHBMBlYB5OpMAlJaAhOyy+LKLsgljKCyn1pMruxATGI9CARxZqZ0fgDKg5yCHZ3EAN7KQ2LjA1JVWeGiGVCV1aAjwOGPZN0dTNijlFzaqWlWlQKA5hRCBuvsLGnWgqsEwgCsLmxurAIE7a4rwLEucW7u78EGmO4x8/QAr8uj87PfhrRCmfV0djRmi/dxA4OC+foF34CE4bjAuUFBRL05zkICj0JCd0a5vQAAx4wZEuAPwoLAgYs18NCBFsEHBxQqPDJCyyAfiw7kJCiBAcE9H0BpiJARI8AQyL2IKCOZIAMFCZ6dLDvRYKYEig4SPHywAWUGu4kAHiAwqIQADs=';

export async function getScreenshot() {
  try {
    const screenshotBase64Uri = await toPng(document.body, {
      cacheBust: true,
      imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER
    });
    const { s3Uri } = await apiClient.shared.uploadScreenshot(screenshotBase64Uri);
    const downloadCommand = `aws s3 cp "${s3Uri}" ./my-error.png`;
    return downloadCommand;
  } catch (err) {
    // ignore
    console.warn('could not upload error image', err);
  }
}

const getUserSnapshotContext = async () => {
  const screenshot = (await getScreenshot()) ?? SENTRY_CONTEXT_UNDEFINED_VALUE;
  const fullStorySession = window.FS?.getCurrentSessionURL?.(true) ?? SENTRY_CONTEXT_UNDEFINED_VALUE;

  return { fullStorySession, screenshot };
};

export const reportError = async (errorSource: string, originalError: any, extraInfo?: Record<string, unknown>) => {
  try {
    let errorToReport = originalError;
    if (!isError(originalError)) {
      // error is probably string, so wrap it:
      if (isString(originalError)) {
        errorToReport = new Error(`String error reported: ${originalError}`, { cause: originalError });
      } else {
        // error is object
        errorToReport = new Error('Wrapped object as error, look on inner cause', { cause: originalError });
      }
    }

    const justMetadata: Record<string, unknown> = {
      ...extraInfo,
      env0ErrorSource: errorSource
    };

    Sentry.withScope(scope => {
      scope.setTag('ENV0_ERROR_SOURCE', errorSource);

      let context: Record<string, unknown> = { ...justMetadata, serializedError: serializeError(errorToReport) };
      if (!!errorToReport.cause) {
        // doing so in different field, to manually support "cause" spec, because both `serializeError` and Sentry doesn't support it.
        // Note that `serializeError` lib do support it on v11 (we need to upgrade), but Sentry doesn't support it yet
        // spec: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
        context.errorCause = serializeError(errorToReport.cause);
      }
      scope.setContext(EXTRA_DATA_CONTEXT_NAME, context);

      Sentry.captureException(errorToReport);
    });

    track(EventNames.ERROR_REPORTED, justMetadata);
  } catch (err) {
    console.error('failed to alert sentry', err);
  }
};

export const addUserSnapshotContextToEvent = async (event: Sentry.Event) => {
  const userSnapshotContext = await getUserSnapshotContext();
  if (isEmpty(event.contexts)) {
    event.contexts = { [EXTRA_DATA_CONTEXT_NAME]: userSnapshotContext };
  } else {
    event.contexts[EXTRA_DATA_CONTEXT_NAME] = { ...event.contexts[EXTRA_DATA_CONTEXT_NAME], ...userSnapshotContext };
  }
};

if (enableManualSentryErrorSending && !isProd) {
  (window as any).ourSentry = { reportError };
}

export const parseUrlForFingerprint = (originalUrl?: string) => {
  if (!originalUrl) {
    return '';
  }

  // look for anything after the ".com" before the QS
  const suffixRegex = /(?<=\.com\/)([^?]+)/;

  const suffixMatch = originalUrl.match(suffixRegex);

  let url;
  if (suffixMatch) {
    url = suffixMatch[0];
  } else {
    // might happen for axios errors, where the url we parse is already the part after the .com
    url = originalUrl;
  }
  // make sure that Urls with ids will look the same, as they shouldn't create different issues
  return url.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/g, 'UUID');
};

export const updateFingerprint = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const exception = hint.originalException;

  if (exception instanceof AxiosError) {
    event.fingerprint = createFingerprint(
      exception.response?.status ?? exception.response?.statusText ?? '',
      exception.message ?? '',
      exception.config?.url ?? '',
      exception.response?.data ?? ''
    );
  } else if (event.exception?.values?.[0]?.mechanism?.type === 'http.client') {
    event.fingerprint = createFingerprint(
      event.contexts?.response?.status_code ?? '',
      event.message ?? '',
      event.request?.url ?? ''
    );
  }
};

const createFingerprint = (status: string | number, message: string, url: string, responseData?: string) => {
  return [
    '{{ default }}',
    JSON.stringify(status),
    message,
    parseUrlForFingerprint(url),
    JSON.stringify(responseData)
  ].filter(Boolean);
};
