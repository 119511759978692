import type { DeploymentLog, Environment } from 'types/api.types';
import type { WorkflowPartialDeployOperation } from '@env0/environment-service/api';

export const getEnqueuedDeploymentRedirectUrl = (environmentId: string, projectId: string) => {
  return `/p/${projectId}/environments/${environmentId}?tabname=deployments`;
};

export const getInProgressDeploymentRedirectUrl = (
  deployment: DeploymentLog,
  environmentId: string,
  projectId: string
) => {
  const inProgressTab = deployment.blueprintType === 'workflow' ? 'graph' : 'deployment-logs';
  return `/p/${projectId}/environments/${environmentId}?tabname=${inProgressTab}`;
};

export const getRedeployHref = (environment: Environment, projectId: string) => {
  return `/p/${projectId}/environments/${environment.id}/redeploy`;
};

export const getSubEnvDeploymentHref = (
  workflowEnvironmentId: string,
  projectId: string,
  operation: WorkflowPartialDeployOperation,
  environmentAlias?: string
) => {
  return `/p/${projectId}/environments/${workflowEnvironmentId}/redeploy?node=${encodeURIComponent(
    environmentAlias!
  )}&operation=${operation}`;
};
