import type { HttpClient } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';
import type { RemoteBackendApi } from '@env0/remote-backend-service/api';
import { returnData } from 'services/api-client/client';

export default (client: HttpClient) => ({
  upsert: (data: RemoteBackendApi.PutStateAccessConfiguration.Request.Body, environmentId: string) =>
    client
      .put<
        RemoteBackendApi.PutStateAccessConfiguration.Request.Body,
        AxiosResponse<RemoteBackendApi.PutStateAccessConfiguration.Response>
      >(`remote-backend/states/${environmentId}/access-control`, data)
      .then(returnData),

  find: (environmentId: string) =>
    client
      .get<RemoteBackendApi.GetStateAccessConfiguration.Response>(
        `remote-backend/states/${environmentId}/access-control`
      )
      .then(returnData)
});
