import styled from 'types/theme.types';
import { LOCK_CONTAINER_ID } from 'stores/mobx/auth.store';
import React from 'react';

const StyledDiv = styled.div`
  margin-top: auto;
`;

const LockContainer = () => <StyledDiv data-e2e={LOCK_CONTAINER_ID} id={LOCK_CONTAINER_ID} />;

export default LockContainer;
