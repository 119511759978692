import { useMutation } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import type { WithScopeId } from 'stores/rq/approval-policies-assignments';

export type UpdateApprovalPolicyBody = BlueprintApi.UpdateApprovalPolicy.Request.Body & { id: string };

const KEY = 'approval-policies';

const getApprovalPolicyName = ({ scope, scopeId }: Pick<BlueprintApi.ApprovalPolicyAssignment, 'scope' | 'scopeId'>) =>
  `approval-policy-${scope}-${scopeId}`;

const useCacheKeys = () => {
  const organizationId = useCurrentOrganizationId();
  return {
    single: (name: string) => [organizationId, KEY, name]
  } as const;
};

const useCommonHookSetup = () => {
  return {
    apiClient: useApiClient(),
    queryClient: getQueryClientInstance(),
    organizationId: useCurrentOrganizationId(),
    keys: useCacheKeys()
  };
};

export const useCreateApprovalPolicy = ({ scope }: Pick<BlueprintApi.ApprovalPolicyAssignment, 'scope'>) => {
  const { apiClient, keys } = useCommonHookSetup();

  return useMutation({
    mutationFn: (data: WithScopeId<BlueprintApi.CreateConfigurationTemplate>) => {
      return apiClient.approvalPolicies.create({
        ...data,
        name: data.name ?? getApprovalPolicyName({ scope, scopeId: data.scopeId })
      });
    },
    onSuccess: (data: BlueprintApi.ApprovalPolicyTemplate) => {
      getQueryClientInstance().setQueryData(keys.single(data.name), data);
    }
  });
};

export const useDeleteApprovalPolicy = () => {
  const { apiClient } = useCommonHookSetup();

  return useMutation({
    mutationFn: (id: string) => {
      return apiClient.approvalPolicies.delete(id);
    }
  });
};

export const useUpdateApprovalPolicy = () => {
  const { apiClient, keys } = useCommonHookSetup();

  return useMutation({
    mutationFn: (data: UpdateApprovalPolicyBody) => apiClient.approvalPolicies.update(data),
    onSuccess: (data: BlueprintApi.ApprovalPolicyTemplate) => {
      getQueryClientInstance().setQueryData(keys.single(data.name), data);
    }
  });
};
