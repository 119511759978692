import type { RolesApi } from '@env0/role-service/api';
import castArray from 'lodash/castArray';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useCurrentEnvironmentId, useCurrentProjectId, useCurrentTemplateId } from 'hooks/path-params-extraction.hooks';
import { useCuratedProjects } from 'stores/rq/projects';
import { intl } from 'components/localization';
import isEmpty from 'lodash/isEmpty';
import type { RBACPermissionsAssignments } from 'stores/rq/user-permissions';
import { emptyPermissions, useGetUserPermissions } from 'stores/rq/user-permissions';
import type { ProjectWithoutChildren } from 'types/api.types';
import { useCallback } from 'react';
import { useGetBlueprint } from 'stores/rq/blueprints';

type UseHasPermissionReturnType = {
  isAuthorized: boolean;
  unauthorizedReason?: string;
  isLoading: boolean;
};

export type PermissionOperator = 'AND' | 'OR';
const defaultPermissionsOperator = 'AND';
type AssignmentsScopeName = keyof RBACPermissionsAssignments;

export const isProjectReadable = (
  project: ProjectWithoutChildren,
  userPermissionsByScope: RBACPermissionsAssignments
) => {
  const { isAuthorized } = hasPermission({
    requiredPermissions: ['VIEW_PROJECT', 'VIEW_ENVIRONMENT'],
    operator: 'OR',
    scopes: { organizationId: project.organizationId, projectId: project.id },
    userPermissionsByScope
  });

  return isAuthorized || canViewProjectByEnvironmentPermissions(project.id, userPermissionsByScope);
};

const canViewProjectByEnvironmentPermissions = (
  projectId: string,
  userPermissionsByScope: RBACPermissionsAssignments
) => {
  const hasAccessOnChildEnvironment = Object.values(userPermissionsByScope?.environments || {})
    .filter(env => env.projectId === projectId)
    .some(env => env.permissions.includes('VIEW_ENVIRONMENT'));

  return hasAccessOnChildEnvironment;
};

export const useHasPermissionCallback = (
  opts: { projectId?: string; environmentId?: string; permissionOperator?: PermissionOperator } = {}
) => {
  const operator = opts.permissionOperator ?? defaultPermissionsOperator;
  const organizationId = useCurrentOrganizationId();
  const projectIdFromRoute = useCurrentProjectId();
  const environmentIdFromRoute = useCurrentEnvironmentId();
  const templateIdFromRoute = useCurrentTemplateId();

  const currentProjectId = opts.projectId || projectIdFromRoute;
  const currentEnvironmentId = opts.environmentId || environmentIdFromRoute;

  const { isPlaceholderData: isLoading, data: userPermissionsByScope } = useGetUserPermissions();
  const isProjectOrgMismatch = useProjectOrganizationMismatch(organizationId, currentProjectId);
  const isTemplateOrgMismatch = useTemplateOrganizationMismatch(organizationId, templateIdFromRoute);

  return useCallback(
    (requiredPermission: RolesApi.RBACPermission | RolesApi.RBACPermission[]) => {
      if (isProjectOrgMismatch) {
        return {
          isAuthorized: false,
          unauthorizedReason: intl.formatMessage(
            { id: 'forbidden.project.not.in.org' },
            { projectId: projectIdFromRoute, organizationId }
          ),
          isLoading: false
        };
      }

      if (isTemplateOrgMismatch) {
        return {
          isAuthorized: false,
          unauthorizedReason: intl.formatMessage(
            { id: 'forbidden.template.not.in.org' },
            { templateId: templateIdFromRoute, organizationId }
          ),
          isLoading: false
        };
      }

      if (isLoading) {
        return { isAuthorized: true, isLoading };
      }

      const requiredPermissions = castArray(requiredPermission);

      return hasPermission({
        userPermissionsByScope: userPermissionsByScope ?? emptyPermissions,
        requiredPermissions,
        scopes: { organizationId, projectId: currentProjectId, environmentId: currentEnvironmentId },
        operator
      });
    },
    [
      operator,
      organizationId,
      projectIdFromRoute,
      templateIdFromRoute,
      currentEnvironmentId,
      currentProjectId,
      isLoading,
      isProjectOrgMismatch,
      isTemplateOrgMismatch,
      userPermissionsByScope
    ]
  );
};

export const useHasPermission = (
  requiredPermission: RolesApi.RBACPermission | RolesApi.RBACPermission[],
  opts: { projectId?: string; environmentId?: string; permissionOperator?: PermissionOperator } = {}
): UseHasPermissionReturnType => {
  return useHasPermissionCallback(opts)(requiredPermission);
};

const useProjectOrganizationMismatch = (organizationId: string, projectId?: string) => {
  const { projects, isLoading: areProjectsLoading } = useCuratedProjects();
  const project = projectId ? projects[projectId] : undefined;
  const projectInResponseWithDifferentOrgId = !project || project.organizationId !== organizationId;

  return !areProjectsLoading && Boolean(projectId && projectInResponseWithDifferentOrgId);
};

const useTemplateOrganizationMismatch = (organizationId: string, templateId?: string) => {
  const { data: template, isFetched } = useGetBlueprint(templateId);

  const templateNotInResponse = isFetched && !template;
  const templateInResponseWithDifferentOrgId = template && template.organizationId !== organizationId;

  return templateId && (templateNotInResponse || templateInResponseWithDifferentOrgId);
};

const getUserPermissionsByScopeId = (
  assignmentsScopeName: AssignmentsScopeName,
  scopeId: string,
  userPermissions: RBACPermissionsAssignments
) => {
  const scopedPermissionAssignments = userPermissions[assignmentsScopeName];
  return scopedPermissionAssignments ? scopedPermissionAssignments[scopeId]?.permissions || [] : [];
};

const getUserPermissionsByActionLevel: (
  userPermissions: RBACPermissionsAssignments,
  { organizationId, projectId, environmentId }: { organizationId: string; projectId?: string; environmentId?: string }
) => RolesApi.RBACPermission[] = (userPermissions, { organizationId, projectId, environmentId }) => {
  if (environmentId) {
    const assignedEnvironmentPermissions = getUserPermissionsByScopeId('environments', environmentId, userPermissions);

    // It only returns environment assignments for environment that has an actual assignment.
    // For project level we're returning all projects, even if they don't have any assignment.
    // This response suffice for understanding the users permissions in any scope in FE, without
    // the need to return all-environments permissions, which reduces BE requests.
    // https://env0.slack.com/archives/C04MPS6SLBH/p1682936349075749?thread_ts=1682856428.553269&cid=C04MPS6SLBH
    if (assignedEnvironmentPermissions.length) {
      return assignedEnvironmentPermissions;
    } else {
      return getUserPermissionsByScopeId('projects', projectId!, userPermissions);
    }
  } else if (projectId) {
    // We assume that if under the current project, the user has a viewable environment, then the project can have VIEW_ENVIRONMENT permission
    const canViewEnvironment = canViewProjectByEnvironmentPermissions(projectId, userPermissions);
    const projectPermissions = getUserPermissionsByScopeId('projects', projectId, userPermissions);

    return canViewEnvironment ? projectPermissions.concat('VIEW_ENVIRONMENT') : projectPermissions;
  }

  return getUserPermissionsByScopeId('organizations', organizationId, userPermissions);
};

export const hasPermission = ({
  userPermissionsByScope,
  scopes: { organizationId, projectId, environmentId },
  requiredPermissions,
  operator
}: {
  userPermissionsByScope: NonNullable<ReturnType<typeof useGetUserPermissions>['data']>;
  scopes: { organizationId: string; projectId?: string; environmentId?: string };
  requiredPermissions: RolesApi.RBACPermission[];
  operator: PermissionOperator;
}) => {
  const userPermissions = getUserPermissionsByActionLevel(userPermissionsByScope, {
    organizationId,
    projectId,
    environmentId
  });

  const missingPermissions = getMissingPermissions(requiredPermissions, userPermissions);
  const hasAtLeastOnePermission =
    isEmpty(requiredPermissions) ||
    requiredPermissions.some(requiredPermission => userPermissions.includes(requiredPermission));

  const isAuthorized = operator === 'AND' ? isEmpty(missingPermissions) : hasAtLeastOnePermission;

  return {
    isAuthorized,
    unauthorizedReason: isAuthorized
      ? undefined
      : intl.formatMessage(
          { id: 'forbidden.missing.permissions' },
          {
            missingPermissions: operator === 'AND' ? missingPermissions.join(', ') : requiredPermissions.join(' OR ')
          }
        ),
    isLoading: false
  };
};

const getMissingPermissions = (
  requiredPermissions: RolesApi.RBACPermission[],
  permissions: RolesApi.RBACPermission[]
) => {
  let missingPermissions = requiredPermissions.filter(requiredPermission => !permissions.includes(requiredPermission));
  if (missingPermissions.includes('VIEW_ENVIRONMENT') && permissions.includes('VIEW_PROJECT')) {
    // 'VIEW_ENVIRONMENT' is contained in 'VIEW_PROJECT' permission, so we can ignore it
    missingPermissions = missingPermissions.filter(permission => permission !== 'VIEW_ENVIRONMENT');
  }
  return missingPermissions;
};
