import type React from 'react';
import { useCallback, useState } from 'react';
import isString from 'lodash/isString';

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const useInputState = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  const setFromEvent = useCallback(
    (value: Event | string) => setValue(isString(value) ? value : value.target.value),
    []
  );

  return [value, setFromEvent] as const;
};
