import useForm from 'hooks/use-form.hook';
import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import defaults from 'lodash/defaults';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useUpsertEnvironmentDiscovery } from 'stores/rq/environment-discovery';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import isGlob from 'is-glob';
import type { SupportedDiscoveryTemplatesTypes } from 'components/projects/settings/environment-discovery/project-settings-environment-discovery';

export const DEFAULT_WORKSPACE_NAMING = 'default';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_TG_FILE_FILTER_GLOB_PATTERN = '+((_envcommon/**)|(${env0_template_dir_ancestors}))';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_TF_FILE_FILTER_GLOB_PATTERN = '+((modules/**)|(${env0_template_dir_path}/**))';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_WF_FILE_FILTER_GLOB_PATTERN = '${env0_template_dir_path}/**';

const autoDeployByCustomGlobPerIac: Record<SupportedDiscoveryTemplatesTypes, string> = {
  opentofu: DEFAULT_TF_FILE_FILTER_GLOB_PATTERN,
  terraform: DEFAULT_TF_FILE_FILTER_GLOB_PATTERN,
  terragrunt: DEFAULT_TG_FILE_FILTER_GLOB_PATTERN,
  workflow: DEFAULT_WF_FILE_FILTER_GLOB_PATTERN
};

type CustomGlobSwitch = 'templateFolder' | 'customGlob';

type EnvironmentDiscoveryAutoCreateSettings = Pick<
  BlueprintApi.EnvironmentDiscoveryFields,
  'createNewEnvironmentsFromPullRequests' | 'workspaceNaming'
> & {
  customGlobSwitch: CustomGlobSwitch;
  autoDeployByCustomGlob?: string;
};

type UseEnvironmentDiscoveryAutoCreateSettingsFormProps = EnvironmentDiscoveryAutoCreateSettings & {
  templateType: SupportedDiscoveryTemplatesTypes;
};

const getEnvironmentDiscoverySettingsSchema = (
  templateType: SupportedDiscoveryTemplatesTypes
): SchemaOf<EnvironmentDiscoveryAutoCreateSettings> =>
  Yup.object({
    workspaceNaming: Yup.mixed<BlueprintApi.WorkspaceNaming>()
      .required()
      .oneOf(['default', 'environmentName'])
      .default(DEFAULT_WORKSPACE_NAMING),
    createNewEnvironmentsFromPullRequests: Yup.boolean().required().default(false),
    customGlobSwitch: Yup.mixed<CustomGlobSwitch>()
      .required()
      .oneOf(['templateFolder', 'customGlob'])
      .default('templateFolder'),
    autoDeployByCustomGlob: Yup.string().when('customGlobSwitch', {
      is: 'customGlob',
      then: Yup.string().test('is-glob', 'environment.auto-deploy.settings.redeploy-by-custom-glob.error', text =>
        isGlob(text)
      ),
      otherwise: Yup.string().default(autoDeployByCustomGlobPerIac[templateType])
    })
  });

const useEnvironmentDiscoveryAutoCreateSettingsForm = ({
  workspaceNaming,
  createNewEnvironmentsFromPullRequests,
  customGlobSwitch,
  autoDeployByCustomGlob,
  templateType
}: UseEnvironmentDiscoveryAutoCreateSettingsFormProps) => {
  const currentProjectId = useCurrentProjectId<string>();
  const { mutate: upsertEnvironmentDiscovery } = useUpsertEnvironmentDiscovery(currentProjectId);
  const environmentDiscoverySettingsSchema = useMemo(
    () => getEnvironmentDiscoverySettingsSchema(templateType),
    [templateType]
  );
  const getInitialValues = useCallback<() => EnvironmentDiscoveryAutoCreateSettings>(() => {
    const schemaDefaults = environmentDiscoverySettingsSchema.getDefault() as EnvironmentDiscoveryAutoCreateSettings;
    return defaults<EnvironmentDiscoveryAutoCreateSettings, EnvironmentDiscoveryAutoCreateSettings>(
      { createNewEnvironmentsFromPullRequests, workspaceNaming, customGlobSwitch, autoDeployByCustomGlob },
      { ...schemaDefaults }
    );
  }, [
    environmentDiscoverySettingsSchema,
    createNewEnvironmentsFromPullRequests,
    workspaceNaming,
    customGlobSwitch,
    autoDeployByCustomGlob
  ]);

  return useForm<EnvironmentDiscoveryAutoCreateSettings>({
    initialValues: getInitialValues(),
    schema: environmentDiscoverySettingsSchema,
    shouldUnregister: false,
    onSubmit: data => {
      upsertEnvironmentDiscovery({
        createNewEnvironmentsFromPullRequests: data.createNewEnvironmentsFromPullRequests,
        ...(data.createNewEnvironmentsFromPullRequests
          ? {
              workspaceNaming: data.workspaceNaming,
              autoDeployByCustomGlob: data.customGlobSwitch === 'customGlob' ? data.autoDeployByCustomGlob : null
            }
          : {})
      });
    }
  });
};

export default useEnvironmentDiscoveryAutoCreateSettingsForm;
