import React, { type CSSProperties } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import NavBarDrawer from 'components/layout/navbar/navbar-drawer';
import NavbarResizer from 'components/layout/navbar/navbar-resizer';
import NavbarPanels from 'components/layout/navbar/navbar-panels';
import OrganizationContainer from './navbar-organization-container';
import useStores from 'hooks/use-stores.hooks';
import useIsMobile from 'hooks/use-is-mobile';
import { useSidebarWidth } from 'hooks/use-sidebar-width';
import { DEFAULT_SHADOW, TOP_BAR_HEIGHT } from 'constants/themes.constants';
import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import styled from 'types/theme.types';

const NavBar = () => {
  const { isDesktop } = useIsMobile();
  const {
    organizationsStore: { currentOrganization }
  } = useStores();
  const [desktopSidebarWidth, setDesktopSidebarWidth] = useSidebarWidth(SIDE_BAR_DESKTOP_WIDTH);
  const sidebarWidth = isDesktop ? desktopSidebarWidth : SIDE_BAR_MOBILE_WIDTH;

  return (
    <NavBarDrawer bypass={isDesktop} mobileWidth={sidebarWidth}>
      <NavBarContainer data-e2e="navbar-container" style={{ '--sidebar-width': `${sidebarWidth}px` } as CSSProperties}>
        <LogoContainer>
          <Link to="/">
            <StyledLogo data-e2e="navbar-logo" />
          </Link>
        </LogoContainer>
        {currentOrganization && (
          <>
            <MainContainer>
              <NavbarPanels organization={currentOrganization} />
            </MainContainer>
            <OrganizationContainer organization={currentOrganization} />
          </>
        )}
        {isDesktop && <NavbarResizer width={desktopSidebarWidth} setWidth={setDesktopSidebarWidth} />}
      </NavBarContainer>
    </NavBarDrawer>
  );
};

const SIDE_BAR_MOBILE_WIDTH = 285;
const SIDE_BAR_DESKTOP_WIDTH = 260;

const NavBarContainer = styled.div`
  --item-height: 56px;
  --subtitle-height: 30px;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--sidebar-width);
  box-shadow: ${DEFAULT_SHADOW};
  background-color: ${({ theme }) => theme.sideMenuBackground};
  overflow: hidden;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${TOP_BAR_HEIGHT};
  flex-shrink: 0;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.sidebarBorder};
`;

const StyledLogo = styled(Logo)`
  position: relative;
  top: 4px;
  width: 96px;
`;

const MainContainer = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`;

export default observer(NavBar);
