import React from 'react';
import { SelectOptGroup, SelectOption } from 'components/common/input-components/select';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import { FormattedMessage } from 'react-intl';
import { useGitRevisions } from './use-git-revisions.hook';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { GitProviderTypes } from 'types/api.types';
import type { TooltipProps } from 'components/common/tooltip';

type RevisionsComponentProps = {
  gitProvider: GitProviderTypes;
  vcsForm: VCSForm;
  disabled?: boolean;
  tooltip?: TooltipProps;
};

type Props = RevisionsComponentProps & {
  showDropdown: boolean;
};

const getCommonInputProps = (gitProviderType: GitProviderTypes) => ({
  inline: true,
  width: 50,
  label: {
    id: `templates.add.new.field.revision.${gitProviderType === 'HelmRepository' ? 'helmRepo' : 'git'}`
  },
  info: { id: `templates.add.new.field.revision.${gitProviderType === 'HelmRepository' ? 'helmRepo' : 'git'}.info` },
  name: 'revision'
});

const RevisionsSelectField: React.FunctionComponent<Props> = ({
  gitProvider,
  vcsForm,
  showDropdown,
  disabled,
  tooltip
}) => {
  return showDropdown ? (
    <RevisionDropdown gitProvider={gitProvider} vcsForm={vcsForm} disabled={disabled} tooltip={tooltip} />
  ) : (
    <RevisionTextBox gitProvider={gitProvider} vcsForm={vcsForm} disabled={disabled} tooltip={tooltip} />
  );
};

const RevisionDropdown: React.FunctionComponent<RevisionsComponentProps> = ({
  gitProvider,
  vcsForm,
  disabled,
  tooltip
}) => {
  const { availableRevisions, revisionsLoading } = useGitRevisions({ vcsForm });
  const { repository } = vcsForm.watch();

  const RevisionsOptGroup = (keyName: string, labelName: string, revisions: string[] | undefined) => {
    return (
      <SelectOptGroup key={keyName} label={labelName} data-e2e={`git-vcs-revision-select-group-${labelName}`}>
        {revisions &&
          revisions.map(revision => (
            <SelectOption key={revision} value={revision}>
              {revision}
            </SelectOption>
          ))}
      </SelectOptGroup>
    );
  };

  return (
    <ControlledSelect
      {...getCommonInputProps(gitProvider)}
      form={vcsForm}
      showSearch
      data-e2e="revisions-select"
      loading={revisionsLoading}
      disabled={Boolean(!repository || disabled)}
      tooltip={tooltip}
      customNotFoundContent={<FormattedMessage id="templates.add.new.field.revisions.empty" />}>
      {RevisionsOptGroup('heads', 'branches', availableRevisions?.heads)}
      {RevisionsOptGroup('tags', 'tags', availableRevisions?.tags)}
    </ControlledSelect>
  );
};

const RevisionTextBox: React.FunctionComponent<RevisionsComponentProps> = ({
  vcsForm,
  gitProvider,
  disabled,
  tooltip
}) => {
  return (
    <ControlledTextInput
      {...getCommonInputProps(gitProvider)}
      data-e2e="revision-input"
      form={vcsForm}
      disabled={disabled}
      tooltip={tooltip}
      placeholder={`templates.add.new.field.revision.placeholder.${
        gitProvider === 'HelmRepository' ? 'helmRepo' : 'git'
      }`}
    />
  );
};

export default RevisionsSelectField;
