import SelectedTree from 'components/common/selected-tree';
import React, { useMemo } from 'react';
import { useCuratedProjects } from 'stores/rq/projects';
import { useCurrentOrganization } from 'hooks/use-current-organization-id';
import values from 'lodash/values';
import Loader from 'components/common/loader';
import { mapItemsToAntdTreeNodes } from 'components/common/utils/map-items-to-antd-tree-nodes';

export interface SelectedTreeProjectsProps {
  onChange?: (projectIds: string[]) => void;
  selectedProjects?: string[];
  disabled?: boolean;
  disabledProjects?: string[];
  withCheckAll?: boolean;
  withRoot?: boolean;
  allowOnlySingleCheck?: boolean;
}

const SelectedTreeProjects: React.FC<SelectedTreeProjectsProps> = ({
  onChange,
  selectedProjects,
  disabled,
  disabledProjects,
  withCheckAll,
  withRoot,
  allowOnlySingleCheck
}) => {
  const organization = useCurrentOrganization();
  const { activeRootProjects, isLoading } = useCuratedProjects();
  const treeData = useMemo(
    () =>
      mapItemsToAntdTreeNodes(
        values(activeRootProjects),
        {
          rootTitle: organization.name,
          withRoot,
          disabledItemKeys: disabledProjects,
          disabled
        },
        'project'
      ),
    [organization, withRoot, disabledProjects, disabled, activeRootProjects]
  );

  return !isLoading ? (
    <SelectedTree
      data={treeData}
      onCheck={onChange}
      checkedKeys={selectedProjects}
      disabled={!!disabled}
      withCheckAll={withCheckAll}
      allowOnlySingleCheck={allowOnlySingleCheck}
    />
  ) : (
    <Loader />
  );
};

export default SelectedTreeProjects;
