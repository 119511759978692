import type { TableProps } from 'components/common/table';
import React, { useMemo, useState } from 'react';
import Button from 'components/common/button';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { UserAvatar } from 'components/common/user-avatar';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';
import EnvironmentImportTable from 'components/projects/settings/environment-discovery/environment-import/environment-import-table';
import { formatDate } from 'utils/time.utils';
import { ImportStatusTag } from 'components/projects/settings/environment-discovery/environment-import/import-status-tag';
import {
  multipleMenuFilter,
  sorterByDateProp,
  sorterByStringProp
} from 'components/dashboard/common/table-widget-common';
import Tooltip from 'components/common/tooltip';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { useHasPermission } from 'hooks/use-has-permission';
import type { User } from 'types/api.types';
import { TooltipEllipsisText } from 'components/common/tooltip-ellipsis-text';
import Link from 'components/common/link';

interface Props {
  importedEnvironments: EnvironmentImportApi.DiscoveredImportedEnvironment[];
  onRerunClicked: (selectedImportedEnvironmentIds: string[]) => void;
}

const getColumns = (rows: EnvironmentImportApi.DiscoveredImportedEnvironment[]) => {
  return [
    {
      dataIndex: 'status',
      title: 'projects.settings.environment.discovery.discovered-environments.column.status',
      ...multipleMenuFilter(rows, row => row.status),
      sorter: sorterByStringProp(row => row.status),
      render: (
        status: Exclude<EnvironmentImportApi.ImportStatus, 'discovered'>,
        discoveredEnvironment: EnvironmentImportApi.DiscoveredEnvironment
      ) => <ImportStatusTag status={status} discoveredEnvironment={discoveredEnvironment} />
    },
    {
      dataIndex: 'path',
      title: 'projects.settings.environment.discovery.discovered-environments.column.path',
      ...multipleMenuFilter(rows, row => row.path),
      sorter: sorterByStringProp(row => row.path),
      render: path => <TooltipEllipsisText text={path} />,
      width: 120,
      ellipsis: true
    },
    {
      dataIndex: 'environmentName',
      title: 'projects.settings.environment.discovery.discovered-environments.column.environmentName',
      ...multipleMenuFilter(rows, row => row.environmentName),
      sorter: sorterByStringProp(row => row.environmentName),
      render: (environmentName, row) => (
        <TooltipEllipsisText
          text={
            <Link
              data-e2e={`link-to-imported-env-${row.id}`}
              url={`/p/${row.targetProjectId}/environments/${row.environmentId}`}>
              {environmentName}
            </Link>
          }
        />
      ),
      ellipsis: true
    },
    {
      dataIndex: 'importStartedAt',
      title: 'projects.settings.environment.discovery.discovered-environments.column.importStartedAt',
      sorter: sorterByDateProp(row => row.importStartedAt),
      render: createdAt => <CellWrapper>{formatDate(createdAt)}</CellWrapper>
    },
    {
      dataIndex: 'importStartedByUser',
      title: 'projects.settings.environment.discovery.discovered-environments.column.importStartedBy',
      ...multipleMenuFilter(
        rows,
        row => row.importStartedByUser.name ?? '',
        row => row.importStartedByUser.name ?? ''
      ),
      sorter: sorterByStringProp(row => row.importStartedByUser?.name),
      render: (user: User) => (
        <CellWrapper>
          <UserAvatar user={user} />
        </CellWrapper>
      )
    }
  ] as TableProps<EnvironmentImportApi.DiscoveredImportedEnvironment>['columns'];
};

const ImportedEnvironmentsTable: React.FunctionComponent<Props> = ({ importedEnvironments, onRerunClicked }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const columns = useMemo(() => getColumns(importedEnvironments), [importedEnvironments]);
  const projectId = useCurrentProjectId();
  const { isAuthorized: hasImportEnvironmentsPermission } = useHasPermission('IMPORT_ENVIRONMENTS', { projectId });

  return (
    <EnvironmentImportTable
      data-e2e="imported-environments-table"
      titleId={'projects.settings.environment.discovery.discovered-environments.imported-table.title'}
      actionButton={
        <Tooltip
          titleId={
            !hasImportEnvironmentsPermission
              ? 'projects.settings.environment.discovery.discovered-environments.import.unauthorized-tooltip-text'
              : ''
          }
          placement="bottomLeft">
          <Button
            onClick={() => {
              onRerunClicked(selectedRowKeys.map(String));
            }}
            disabled={!hasImportEnvironmentsPermission || isEmpty(selectedRowKeys)}
            data-e2e="rerun-discovered-environments">
            <FormattedMessage id="projects.settings.environment.discovery.discovered-environments.rerun.button.text" />
          </Button>
        </Tooltip>
      }
      columns={columns}
      dataSource={importedEnvironments}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      disableRow={({ status }) => status !== 'error'}
    />
  );
};

const CellWrapper = styled.div`
  height: 25px;
`;

export default ImportedEnvironmentsTable;
