import React from 'react';
import { iacVersionList } from 'components/templates/templates-wizard/settings/versions-list';
import type { VersionFieldFormProps } from 'components/templates/templates-wizard/settings/version-field';
import { VersionField } from 'components/templates/templates-wizard/settings/version-field';

const terragruntPrefix = 'templates.add.new.field.terragruntVersion';
const terragruntVersionKey = 'terragruntVersion';

export const TerragruntVersionField: React.FC<VersionFieldFormProps> = ({ form }) => {
  return (
    <VersionField
      form={form}
      versions={iacVersionList.terragrunt}
      prefix={terragruntPrefix}
      versionKey={terragruntVersionKey}
      type="terragrunt"
    />
  );
};
