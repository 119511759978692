import { Drawer } from 'antd';
import { DrawerFooter } from 'components/app-notifications/drawer/drawer-footer';
import React, { type ReactNode } from 'react';
import type { DrawerProps } from 'antd/lib/drawer';
import { DrawerHeader } from 'components/app-notifications/drawer/drawer-header';

interface Props {
  onClose: DrawerProps['onClose'];
  open: DrawerProps['open'];
  children?: ReactNode;
}

export const AppNotificationsDrawer: React.FC<Props> = ({ onClose, open, children }) => {
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      title={<DrawerHeader />}
      placement="right"
      onClose={onClose}
      open={open}
      width={640}
      footer={<DrawerFooter />}>
      {children}
    </Drawer>
  );
};
