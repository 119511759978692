import styled from '@emotion/styled';
import { ReactComponent as UnstyledEnvironmentDiscoveredStep } from 'assets/images/environment-discovered-step.svg';
import { ReactComponent as UnstyledPrApprovedStep } from 'assets/images/pr-approved-step.svg';
import { ReactComponent as UnstyledSelectEnvironments } from 'assets/images/select-environments.svg';
import { ReactComponent as UnstyledCurvedArrow } from 'assets/images/bold-curved-arrow.svg';
import { ReactComponent as UnstyledForwardArrow } from 'assets/images/forward-arrow.svg';
import { ReactComponent as UnstyledDiscoveryConfiguration } from 'assets/images/discovery-configuration.svg';
import { ReactComponent as UnstyledEnvironmentImported } from 'assets/images/environment-imported.svg';

import type { ReactElement } from 'react';
import { PurpleText } from 'components/projects/settings/environment-discovery/environment-discovery-typography';
import { FormattedMessage } from 'react-intl';

const getSteps = (): Array<{
  name: string;
  image: JSX.Element;
  stepPosition: 'up' | 'down';
  arrow: ReactElement;
}> => [
  {
    name: 'discovery-configuration',
    image: <DiscoveryConfiguration />,
    stepPosition: 'down',
    arrow: <CurvedUpArrow />
  },
  {
    name: 'pr-generated',
    image: <EnvironmentDiscoveredStep />,
    stepPosition: 'up',
    arrow: <UnstyledForwardArrow />
  },
  {
    name: 'select-environments',
    image: <SelectEnvironments />,
    stepPosition: 'down',
    arrow: <UnstyledForwardArrow />
  },
  {
    name: 'pr-approved',
    image: <PrApprovedStep />,
    stepPosition: 'up',
    arrow: <UnstyledForwardArrow />
  },
  {
    name: 'environment-imported',
    image: <EnvironmentImported />,
    stepPosition: 'down',
    arrow: <CurvedDownArrow />
  }
];

const WelcomeStep: React.FC<{
  stepPosition: 'up' | 'down';
  arrow: ReactElement;
  children: any;
}> = ({ stepPosition, arrow, children }) => {
  return (
    <StepContainer stepPosition={stepPosition}>
      <StepImageAndText>{children}</StepImageAndText>
      {<ArrowContainer stepPosition={stepPosition}>{arrow}</ArrowContainer>}
    </StepContainer>
  );
};

const EnvironmentDiscoveryWelcomeChart: React.FC = () => {
  const steps = getSteps();

  return (
    <ChartContainer>
      {steps.map((step, index) => (
        <WelcomeStep key={index} stepPosition={step.stepPosition} arrow={step.arrow}>
          {step.image}
          <TextContainer>
            <PurpleText>
              <FormattedMessage id={`environment.discovery.welcome.${step.name}.step`} />
            </PurpleText>
          </TextContainer>
        </WelcomeStep>
      ))}
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4em 0;
  height: 250px;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
`;

const ArrowContainer = styled.div<{ stepPosition: 'up' | 'down' }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-bottom: ${({ stepPosition }) => stepPosition === 'up' && '50px'};
  margin-top: ${({ stepPosition }) => stepPosition === 'down' && '20px'};
`;

const StepImageAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const StepContainer = styled.div<{ stepPosition: 'up' | 'down' }>`
  display: flex;
  flex-direction: ${({ stepPosition }) => (stepPosition === 'down' ? 'column-reverse' : 'column')};
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  height: 100%;
  width: 244px;
`;

const stepSvgStyles = `
width: 200px;
height: 70px;
transition: transform 0.2s ease-in;
&:hover {
  transform: scale(1.05);
}
`;

const EnvironmentDiscoveredStep = styled(UnstyledEnvironmentDiscoveredStep)`
  ${stepSvgStyles}
`;

const SelectEnvironments = styled(UnstyledSelectEnvironments)`
  ${stepSvgStyles}
`;

const PrApprovedStep = styled(UnstyledPrApprovedStep)`
  ${stepSvgStyles}
`;

const DiscoveryConfiguration = styled(UnstyledDiscoveryConfiguration)`
  ${stepSvgStyles}
`;

const EnvironmentImported = styled(UnstyledEnvironmentImported)`
  ${stepSvgStyles}
`;

const CurvedUpArrow = styled(UnstyledCurvedArrow)`
  width: 150px;
  height: 60px;
`;

const CurvedDownArrow = styled(UnstyledCurvedArrow)`
  transform: rotate(90deg);
`;

const TextContainer = styled.div`
  width: 200px;
`;

export default EnvironmentDiscoveryWelcomeChart;
