import type { HttpClient } from 'services/api-client/client';
import type { AuditLog } from 'types/api.types';
import { returnData } from 'services/api-client/client';

export default (client: HttpClient) => ({
  findByOrganizationId: (organizationId: string, pageKey?: string) =>
    client
      .get<{ events: AuditLog[]; nextPageKey?: string }>('audit/events', {
        params: {
          organizationId,
          offset: pageKey
        }
      })
      .then(returnData)
});
