import { useNavigate } from 'react-router-dom';
import { useGetModules } from 'stores/rq/modules';
import type { Action } from 'kbar';
import { useMemo } from 'react';
import flatMap from 'lodash/flatMap';
import { generatePerformableActions, generateRootActionForTemplate } from 'hooks/kbar/common/generate-template-actions';
import { useHasPermission } from 'hooks/use-has-permission';

export const rootId = 'MODULES';
export const section = 'Specific Modules';
export const kindName = 'modules';

type useModuleSearchActionsProps = { enabled: boolean };

export default function useModulesSearchActions({ enabled }: useModuleSearchActionsProps) {
  const navigate = useNavigate();
  const { isAuthorized: hasModulesPermissions, isLoading } = useHasPermission('VIEW_MODULES');
  const { data: modules } = useGetModules({ enabled: hasModulesPermissions && !isLoading && enabled });

  const searchSpecificModulesActions: Action[] = useMemo(
    () =>
      modules?.map(module => generateRootActionForTemplate({ ...module, name: module.moduleName }, rootId, section)) ??
      [],
    [modules]
  );
  const performableActions: Action[] = useMemo(
    () =>
      flatMap(modules, module =>
        generatePerformableActions({ ...module, name: module.moduleName }, navigate, kindName)
      ),
    [navigate, modules]
  );

  return useMemo(
    () => [...searchSpecificModulesActions, ...performableActions].filter(Boolean),
    [searchSpecificModulesActions, performableActions]
  );
}
