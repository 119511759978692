import dayjs from 'types/dayjs.types';
import type { DeploymentLog } from 'types/api.types';
import { intl } from 'components/localization';

export const timeFormat = 'HH:mm';
export const usDateTimeFormat = 'MM/DD/YY HH:mm';
export const standartDateTimeFormat = 'DD/MM/YY HH:mm';

export const formatTimeUnit = (timeUnit: number): string =>
  Number.isFinite(timeUnit) ? (timeUnit < 10 ? `0${timeUnit}` : String(timeUnit)) : '00';

const defaultConfig = {
  h: false,
  m: true,
  s: true
};

export const parseTimerElapsedTime = (seconds: number, customConfig?: { h: boolean }) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const config = {
    ...defaultConfig,
    ...customConfig
  };

  return [config.h && h, config.m && m, config.s && s]
    .filter((maybeNumber: false | number): maybeNumber is number => maybeNumber !== false)
    .map(formatTimeUnit)
    .join(':');
};

export const getHumanizedDuration = (firstDate: Date | string, secondDate: Date | string) => {
  return dayjs.duration(dayjs(firstDate).diff(secondDate)).humanize(true);
};

export const getDeploymentDuration = (log: DeploymentLog) => {
  if (!log.finishedAt || !log.startedAt) return '-';
  const durationInSeconds = dayjs.duration(dayjs(log.finishedAt).diff(log.startedAt)).asSeconds();
  return parseTimerElapsedTime(durationInSeconds, { h: true });
};

// Date & Time Formatters
export const formatDate = (value: string | Date) =>
  value && intl.formatDate(value, { year: 'numeric', month: 'short', day: 'numeric' });

export const formatShortDate = (value: string | Date) =>
  value && intl.formatDate(value, { month: 'short', day: 'numeric' });

export const formatShortestDate = (value: string | Date) =>
  value && intl.formatDate(value, { month: 'numeric', day: 'numeric' });

export const formatDatetime = (value: string | Date | undefined) =>
  value &&
  intl.formatDate(value, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });

export const formatShortDateTime = (value: string | Date) =>
  value && intl.formatDate(value, { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });

export const formatHumanizedElapsedTime = (value: string | Date) => value && dayjs(value).fromNow();
