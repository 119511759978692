import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import type { RolesApi } from '@env0/role-service/api';
import type { ScopeId } from 'components/common/role-assignments/types';

type UserAssignInput = { userId: string; role: RolesApi.RBACPermissionRole };
type TeamAssignInput = { teamId: string; role: RolesApi.RBACPermissionRole };

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = { orgId, scope: 'environment-role-assignments' } as const;
  const cacheKeys = {
    userRoles: (environmentId: string) =>
      [{ ...baseKey, subscope: 'userRoles', scope: 'environment', environmentId }] as const, // currently supports only environment scope
    teamRoles: (scopeId: ScopeId) => [{ ...baseKey, subscope: 'teamRoles', ...scopeId }] as const
  } as const;

  return cacheKeys;
};

// User role assignments:

export const useGetEnvironmentUserRoleAssignments = (environmentId: string) => {
  const apiClient = useApiClient();
  const { userRoles } = useCacheKeys();

  return useQuery({
    queryKey: userRoles(environmentId),
    queryFn: ({ queryKey: [{ environmentId }] }) => apiClient.environments.getUserRoleAssignments(environmentId)
  });
};

export const useUpsertEnvironmentUserRoleAssignment = (environmentId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { userRoles } = useCacheKeys();

  return useMutation({
    mutationFn: ({ userId, role }: UserAssignInput) =>
      apiClient.environments.assignRoleToUser(environmentId, userId, role),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: userRoles(environmentId) });
    }
  });
};

export const useRemoveEnvironmentUserRoleAssignment = (environmentId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { userRoles } = useCacheKeys();

  return useMutation({
    mutationFn: (userId: string) => apiClient.environments.removeUserRoleAssignment(environmentId, userId),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: userRoles(environmentId) });
    }
  });
};

// Team role assignments:

export const useGetTeamRoleAssignments = (scopeId: ScopeId) => {
  const apiClient = useApiClient();
  const { teamRoles } = useCacheKeys();

  return useQuery({
    queryKey: teamRoles(scopeId),
    queryFn: () => apiClient.roles.getTeamRoleAssignments(scopeId)
  });
};

export const useUpsertTeamRoleAssignment = (scopeId: ScopeId) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { teamRoles } = useCacheKeys();

  return useMutation({
    mutationFn: ({ teamId, role }: TeamAssignInput) => apiClient.roles.assignRoleToTeam(scopeId, teamId, role),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: teamRoles(scopeId) });
    }
  });
};

export const useRemoveTeamRoleAssignment = (scopeId: ScopeId) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { teamRoles } = useCacheKeys();

  return useMutation({
    mutationFn: (teamId: string) => apiClient.roles.removeTeamRoleAssignment(scopeId, teamId),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: teamRoles(scopeId) });
    }
  });
};
