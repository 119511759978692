import uniqBy from 'lodash/fp/uniqBy';
import isNaN from 'lodash/fp/isNaN';
import map from 'lodash/map';
import { action, observable } from 'mobx';
import type { Blueprint, BlueprintRevisions, VariablesFromRepositoryParams, VcsRevisionParams } from 'types/api.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { isGitProviderIntegrated } from 'utils/blueprint.utils';
import { Forbidden, NotFound } from 'http-errors';
import BaseService from 'services/base-service';
import { validateGitUrl } from 'components/templates/templates-wizard/url-utils';

type Repositories = BlueprintApi.GetAvailableRepositories.Response.Body;

export const GITHUB_INSTALLATION_ID_KEY = 'installationId';
export const GITLAB_AUTH_CODE_KEY = 'env0GitlabAuthorizationCode';
export const BITBUCKET_CLIENT_KEY = 'env0BitbucketClientKey';
export const AZURE_DEVOPS_CLIENT_KEY = 'env0AzureDevopsClientKey';

// When GitHub installation process is finished without actually installing the App,
// GitHub returns the string 'undefined' in the installationId param
export const GITHUB_ADMIN_REQUIRED_INSTALLATION_ID = 'undefined';

export class BlueprintsStore extends BaseService {
  @observable revisionsByBlueprint: { [blueprintId: string]: BlueprintRevisions } = {};
  @observable repositoriesByOrganizationId: { [organizationId: string]: Repositories } = {};

  @action setBlueprintRevisions(blueprintId: string, revisions: BlueprintRevisions) {
    this.revisionsByBlueprint[blueprintId] = revisions;
  }

  @action setRepositories(repositories: Repositories, organizationId: string) {
    this.repositoriesByOrganizationId[organizationId] = repositories;
    return this.repositoriesByOrganizationId[organizationId];
  }

  appendRepositories(repositories: Repositories, organizationId: string) {
    const currentRepositories = this.repositoriesByOrganizationId[organizationId] || [];
    const newRepositories = uniqBy('httpsUrl', [...currentRepositories, ...repositories]);
    return this.setRepositories(newRepositories, organizationId);
  }

  getGithubNewInstallationId() {
    const installationId = localStorage.getItem(GITHUB_INSTALLATION_ID_KEY);
    localStorage.removeItem(GITHUB_INSTALLATION_ID_KEY);

    if (installationId === GITHUB_ADMIN_REQUIRED_INSTALLATION_ID) {
      throw new Forbidden('Admin permissions are required to install the GitHub App');
    }

    if (!installationId || isNaN(+installationId)) {
      throw new NotFound('GitHub installation ID was not found');
    }

    return +installationId;
  }

  getGitlabAuthorizationCode() {
    const authCode = localStorage.getItem(GITLAB_AUTH_CODE_KEY);
    localStorage.removeItem(GITLAB_AUTH_CODE_KEY);

    if (!authCode) {
      throw new NotFound('GitLab authorization code was not found');
    }

    return authCode;
  }

  getAzureDevOpsAuthorizationCode() {
    const authCode = localStorage.getItem(AZURE_DEVOPS_CLIENT_KEY);
    localStorage.removeItem(AZURE_DEVOPS_CLIENT_KEY);

    if (!authCode) {
      throw new NotFound('Azure DevOps authorization code was not found');
    }

    return authCode;
  }

  getBitbucketClientKey() {
    const clientKey = localStorage.getItem(BITBUCKET_CLIENT_KEY);
    localStorage.removeItem(BITBUCKET_CLIENT_KEY);

    if (!clientKey) throw new NotFound('Bitbucket client key was not found');

    return clientKey;
  }

  async loadBlueprintRevisions(blueprintId: string) {
    const revisions = await this.service.apiClient.blueprints.getRevisions(blueprintId);
    this.setBlueprintRevisions(blueprintId, revisions);
    return revisions;
  }

  async loadVcsRevisions({
    repository,
    tokenId,
    githubInstallationId,
    bitbucketClientKey,
    sshKeyIds
  }: VcsRevisionParams) {
    if (!validateGitUrl(repository)) return undefined;

    const params = {
      repository,
      githubInstallationId: githubInstallationId?.toString(),
      tokenId,
      bitbucketClientKey,
      sshKeyIds: sshKeyIds?.length ? JSON.stringify(map(sshKeyIds, 'id')) : undefined
    };
    return await this.service.apiClient.blueprints.getVcsRepositoryRevisions(params);
  }

  async getGithubRepositories(installationId?: number) {
    const organizationId = this.service.organizationsStore.currentOrganizationId!;
    const params = installationId ? { githubInstallationId: installationId.toString() } : { organizationId };

    const repositories = await this.service.apiClient.blueprints.getRepositories('GitHub', params);
    return installationId
      ? this.appendRepositories(repositories, organizationId)
      : this.setRepositories(repositories, organizationId);
  }

  async getBitbucketRepositories(bitbucketClientKey?: string) {
    const organizationId = this.service.organizationsStore.currentOrganizationId!;
    const params = bitbucketClientKey ? { bitbucketClientKey } : { organizationId };

    const repositories = await this.service.apiClient.blueprints.getRepositories('BitBucket', params);
    return bitbucketClientKey
      ? this.appendRepositories(repositories, organizationId)
      : this.setRepositories(repositories, organizationId);
  }

  async getGitlabRepositories(gitlabTokenId: string) {
    let { data: allRepositories, maxPages } = await this.service.apiClient.blueprints.getPagedRepositories('GitLab', {
      gitlabTokenId,
      startPage: '1',
      endPage: '1'
    });

    if (maxPages && maxPages > 1) {
      const { data: additionalRepositories } = await this.service.apiClient.blueprints.getPagedRepositories('GitLab', {
        gitlabTokenId,
        startPage: '2',
        endPage: maxPages.toString()
      });

      allRepositories = [...allRepositories, ...additionalRepositories];
    }
    return this.setRepositories(allRepositories, this.service.organizationsStore.currentOrganizationId as string);
  }

  async getAzureDevOpsRepositories(azureDevOpsTokenId: string) {
    const repositories = await this.service.apiClient.blueprints.getRepositories('AzureDevOps', { azureDevOpsTokenId });
    return this.setRepositories(repositories, this.service.organizationsStore.currentOrganizationId as string);
  }

  async getWorkflowFile(blueprintId: string, revision?: string) {
    return this.service.apiClient.blueprints.getWorkflowFile(blueprintId, revision);
  }

  isBlueprintGitIntegrated(blueprint: Blueprint | undefined) {
    if (!blueprint) return false;
    return isGitProviderIntegrated(blueprint);
  }

  async getVariablesFromRepository({
    repository,
    path,
    revision,
    tokenId,
    githubInstallationId,
    bitbucketClientKey,
    sshKeys
  }: VariablesFromRepositoryParams) {
    const params = {
      repository,
      path,
      revision,
      githubInstallationId: githubInstallationId?.toString(),
      tokenId,
      bitbucketClientKey: bitbucketClientKey as string | undefined,
      sshKeyIds: JSON.stringify(sshKeys?.map(sshKey => sshKey.id))
    };
    return await this.service.apiClient.blueprints.getVariablesFromRepository(params);
  }
}
