import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useOidcJwtSub } from 'stores/rq/oidc-jwt-subs';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import CopyableId from 'components/common/copyable-id';
import styled from 'types/theme.types';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';

interface JwtOidcSubProps {
  form: CredentialsInputProps['form'];
}

const JwtOidcSub: React.FunctionComponent<JwtOidcSubProps> = ({ form }) => {
  const { data: sub, isPending, error } = useOidcJwtSub(true, true);

  useEffect(() => {
    if (sub && (form.getValues() as any).value.jwtOidcSub !== sub) {
      form.setValue('value.jwtOidcSub' as never, sub);
      form.clearErrors('value.jwtOidcSub' as never);
    }
    if (error && !(form.errors as any).value?.jwtOidcSub) {
      form.setError('value.jwtOidcSub' as never, { message: 'settings.credentials.error.missingJwtOidcSub' });
    }
  }, [form, sub, error]);

  return (
    <StyledControlledTextInput
      label={{ id: 'settings.credentials.jwt-oidc-sub' }}
      name="value.jwtOidcSub"
      form={form}
      disabled={true}
      value={sub}
      prefix={isPending && <Spin size="small" />}
      suffix={
        !isPending && (
          <StyledCopyableId text={sub || ''} showText={false} entityName={'sub'} copyIcon={<StyledCopyIcon />} />
        )
      }
    />
  );
};

const StyledControlledTextInput = styled(ControlledTextInput)`
  // Specific override for antd disabled state
  border-color: ${({ theme }) => theme.secondaryBlack} !important;
  .ant-input-disabled {
    background-color: #ebeff2 !important;
    color: ${({ theme }) => theme.secondaryDisabledGray};
  }
`;

const StyledCopyableId = styled(CopyableId)`
  display: flex;
  justify-content: center;
`;

const StyledCopyIcon = styled(CopyOutlined)`
  color: ${({ theme }) => theme.primaryGreen};
  font-size: 1.3em;
  display: flex;
`;

export default JwtOidcSub;
