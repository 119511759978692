// This file is meant to be exporting enums and consts only
// Please refrain from importing anything to it and from introducing anything here other than enums
// Doing so will make downstream services include runtime code from this service
export enum ConfigurationScope {
  SET = 'SET',
  GLOBAL = 'GLOBAL',
  BLUEPRINT = 'BLUEPRINT',
  PROJECT = 'PROJECT',
  WORKFLOW = 'WORKFLOW',
  ENVIRONMENT = 'ENVIRONMENT',
  DEPLOYMENT = 'DEPLOYMENT'
}

export enum ConfigurationType {
  ENVIRONMENT_VARIABLE,
  TERRAFORM_VARIABLE
}

export enum CredentialType {
  AWS_ASSUMED_ROLE = 'AWS_ASSUMED_ROLE', // FOR COSTS
  AWS_ASSUMED_ROLE_FOR_DEPLOYMENT = 'AWS_ASSUMED_ROLE_FOR_DEPLOYMENT',
  AWS_ACCESS_KEYS_FOR_DEPLOYMENT = 'AWS_ACCESS_KEYS_FOR_DEPLOYMENT',
  AWS_OIDC = 'AWS_OIDC',
  GCP_CREDENTIALS = 'GCP_CREDENTIALS', // FOR COSTS
  GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT = 'GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT',
  GCP_OIDC = 'GCP_OIDC',
  AZURE_CREDENTIALS = 'AZURE_CREDENTIALS', // FOR COSTS
  AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT = 'AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT',
  AZURE_OIDC = 'AZURE_OIDC',
  K8S_KUBECONFIG_FILE = 'K8S_KUBECONFIG_FILE',
  K8S_EKS_AUTH = 'K8S_EKS_AUTH',
  K8S_AZURE_AKS_AUTH = 'K8S_AZURE_AKS_AUTH',
  K8S_GCP_GKE_AUTH = 'K8S_GCP_GKE_AUTH',
  VAULT_OIDC = 'VAULT_OIDC'
}

export enum CloudProvidersType {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure'
}

export const K8S = 'k8s' as const;
export const VAULT = 'vault' as const;

export const CloudProviders = [CloudProvidersType.AWS, CloudProvidersType.GCP, CloudProvidersType.AZURE] as const;
export const CredentialProviders = [...CloudProviders, K8S, VAULT] as const;

export type CredentialProviderType = CloudProvidersType | typeof K8S | typeof VAULT;

export enum TokenTypes {
  GIT = 'GIT',
  GitLab = 'GitLab',
  AzureDevOps = 'AzureDevOps'
}

export const OVERWRITABLE_FIELDS = [
  'scope',
  'scopeId',
  'value',
  'schema',
  'isReadonly',
  'isRequired',
  'isSensitive',
  'regex'
] as const;

export type OverwritableFields = (typeof OVERWRITABLE_FIELDS)[number];

export const CREDENTIALS_TYPE_MAPPING: Record<CredentialType, CredentialProviderType> = {
  AWS_ASSUMED_ROLE: CloudProvidersType.AWS,
  AWS_ASSUMED_ROLE_FOR_DEPLOYMENT: CloudProvidersType.AWS,
  AWS_ACCESS_KEYS_FOR_DEPLOYMENT: CloudProvidersType.AWS,
  AWS_OIDC: CloudProvidersType.AWS,
  GCP_CREDENTIALS: CloudProvidersType.GCP,
  GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT: CloudProvidersType.GCP,
  GCP_OIDC: CloudProvidersType.GCP,
  AZURE_CREDENTIALS: CloudProvidersType.AZURE,
  AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT: CloudProvidersType.AZURE,
  AZURE_OIDC: CloudProvidersType.AZURE,
  K8S_KUBECONFIG_FILE: K8S,
  K8S_EKS_AUTH: K8S,
  K8S_AZURE_AKS_AUTH: K8S,
  K8S_GCP_GKE_AUTH: K8S,
  VAULT_OIDC: VAULT
} as const;

export const CREDENTIALS_USAGE_TYPE_MAPPING = {
  COSTS: [CredentialType.AWS_ASSUMED_ROLE, CredentialType.GCP_CREDENTIALS, CredentialType.AZURE_CREDENTIALS],
  DEPLOYMENT: [
    CredentialType.AWS_ASSUMED_ROLE_FOR_DEPLOYMENT,
    CredentialType.AWS_ACCESS_KEYS_FOR_DEPLOYMENT,
    CredentialType.AWS_OIDC,
    CredentialType.GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT,
    CredentialType.GCP_OIDC,
    CredentialType.AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT,
    CredentialType.AZURE_OIDC,
    CredentialType.K8S_KUBECONFIG_FILE,
    CredentialType.K8S_EKS_AUTH,
    CredentialType.K8S_AZURE_AKS_AUTH,
    CredentialType.K8S_GCP_GKE_AUTH,
    CredentialType.VAULT_OIDC
  ]
} as const;

export const DEPLOYMENT_CREDENTIAL_TYPES = [...CREDENTIALS_USAGE_TYPE_MAPPING.DEPLOYMENT] as CredentialType[];

export type CostCredentialType = (typeof CREDENTIALS_USAGE_TYPE_MAPPING.COSTS)[number];
