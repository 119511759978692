import React from 'react';
import InputNumber from 'antd/lib/input-number';
import type { InputNumberProps } from 'antd/lib/input-number';
import styled from 'types/theme.types';
import { getCommonInputCssFromProps } from 'components/common/input-components/common-styles';
import omit from 'lodash/omit';

export interface NumberInputProps extends InputNumberProps {
  invalid?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({ ...props }) => <StyledNumberInput {...omit(props, ['hideError'])} />;

const StyledNumberInput = styled(InputNumber)<NumberInputProps>`
  height: 28px !important;
  ${getCommonInputCssFromProps}

  .ant-input-number-input {
    height: 28px !important;
  }

  .ant-input-number-handler-down-inner {
    margin-top: 3px;
  }

  .ant-input-number-handler-up-inner {
    margin-top: -4px;
  }
`;

export default NumberInput;
