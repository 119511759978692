import type { Action, ActionSection } from 'kbar';
import keyBy from 'lodash/keyBy';
import isString from 'lodash/isString';

export type ActionAnalyticsData = {
  actionParentType: string;
  actionType: string;
  name?: string;
  id?: string;
};

export const createActionToDataMap = (actions: Action[]) => {
  const getSectionString = (action: ActionSection) => {
    if (isString(action)) return action;
    return action.name;
  };

  const idToActionMap = keyBy(actions, 'id');

  return actions.reduce((previousValue, currentAction) => {
    previousValue[currentAction.id] = {
      actionParentType: currentAction.parent
        ? getSectionString(idToActionMap[currentAction.parent].section!)
        : getSectionString(currentAction.section!),
      actionType: currentAction.name
    };
    return previousValue;
  }, {} as Record<string, ActionAnalyticsData>);
};
