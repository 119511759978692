import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import isString from 'lodash/isString';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import type { Module } from 'types/api.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { filterOutById } from 'stores/rq/common/react-query-utils';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'modules'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all'],
    single: (id: string) => [...baseKey, 'single', { id }] as const
  } as const;

  return cacheKeys;
};

export interface UseGetModulesOptions {
  enabled?: boolean;
}

export const useGetModules = ({ enabled = true }: UseGetModulesOptions = {}) => {
  const apiClient = useApiClient();
  const orgId = useCurrentOrganizationId();
  const { all } = useCacheKeys();

  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.modules.getModules(orgId),
    enabled: isString(orgId) && enabled
  });
};

export const useGetModule = (id: string | undefined) => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();

  return useQuery({
    queryKey: single(id!),
    queryFn: () => apiClient.modules.getModule(id!),
    enabled: isString(id),
    placeholderData: () => {
      const modules = queryClient.getQueryData<Module[]>(all);
      return modules?.find(module => module.id === id);
    }
  });
};

export const useRemoveModule = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (id: string) => apiClient.modules.deleteModule(id),
    onMutate: (id: string) => {
      const previousData: Module[] | undefined = queryClient.getQueryData(all);
      queryClient.setQueryData(all, filterOutById<Module>(id));

      return previousData;
    },
    onError: (_err, _id: string, previousData: Module[] | undefined) => queryClient.setQueryData(all, previousData)
  });
};

export const useCreateModule = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();

  return useMutation({
    mutationFn: (data: BlueprintApi.CreateModule.Request.Body) => apiClient.modules.createModule(data),
    onSuccess: (newModule: Module) => {
      queryClient.setQueryData(single(newModule.id), newModule);
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useUpdateModule = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: BlueprintApi.UpdateModule.Request.Body }) =>
      apiClient.modules.updateModule(id, data),
    onSuccess: newModule => queryClient.setQueryData(single(newModule.id), newModule),
    onSettled: () => queryClient.refetchQueries({ queryKey: all })
  });
};
