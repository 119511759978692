import { Row } from 'components/common/grid';
import { Text } from 'components/common/form-components';
import React, { useState, useEffect } from 'react';
import type { useNotificationEndpointForm } from 'components/organizations/notifications/notification-endpoint-form.hook';
import { useController } from 'react-hook-form';
import { EmailRecipientPlaceholder } from '@env0/notification-service/api.enum';
import Checkbox from 'components/common/input-components/checkbox';
import { TextInputWithLabel } from 'components/common/input-components/text-input';
import { intlPrefix } from 'components/organizations/notifications/modal-utils';

export const EmailList: React.FC<{ form: ReturnType<typeof useNotificationEndpointForm> }> = ({ form }) => {
  const {
    field: { value, onChange, onBlur },
    meta: { isDirty, isTouched }
  } = useController<{ value: string }>({ name: 'value', control: form.control });
  const error = isDirty || isTouched ? form.errors?.value?.message : undefined;

  const parts = value ? value.split(/[\s,]+/) : [];

  const [emailList, setEmailList] = useState(
    parts
      .filter((v: string) => !Object.values(EmailRecipientPlaceholder).includes(v as EmailRecipientPlaceholder))
      .join(',')
  );
  const [deployerChecked, setDeployerChecked] = useState(parts.includes(EmailRecipientPlaceholder.$DEPLOYER$));
  const [environmentCreatorChecked, setEnvironmentCreatorChecked] = useState(
    parts.includes(EmailRecipientPlaceholder.$ENVIRONMENT_CREATOR$)
  );

  useEffect(() => {
    onChange(
      [
        deployerChecked ? [EmailRecipientPlaceholder.$DEPLOYER$] : null,
        environmentCreatorChecked ? [EmailRecipientPlaceholder.$ENVIRONMENT_CREATOR$] : null,
        emailList
      ]
        .filter(Boolean)
        .join(',')
    );
  }, [emailList, deployerChecked, environmentCreatorChecked, onChange]);

  return (
    <>
      <Row>
        <TextInputWithLabel
          label={{ id: `${intlPrefix}.emailAddress` }}
          info={{ id: `${intlPrefix}.emailAddress.info` }}
          name="value"
          value={emailList}
          onBlur={onBlur}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailList(e.target.value)}
          invalid={!!error}
          error={error}
          data-e2e="emails-list-input"
        />
      </Row>
      <Row>
        <Checkbox
          name={EmailRecipientPlaceholder.$ENVIRONMENT_CREATOR$}
          checked={environmentCreatorChecked}
          onChange={e => setEnvironmentCreatorChecked(e.target.checked)}>
          <Text id={`${intlPrefix}.environmentCreator`} />
        </Checkbox>
      </Row>
      <Row>
        <Checkbox
          name={EmailRecipientPlaceholder.$DEPLOYER$}
          checked={deployerChecked}
          onChange={e => setDeployerChecked(e.target.checked)}>
          <Text id={`${intlPrefix}.deployer`} />
        </Checkbox>
      </Row>
    </>
  );
};
