import { useCallback } from 'react';
import useStores from 'hooks/use-stores.hooks';
import useIsMobile from 'hooks/use-is-mobile';

export const useCloseOnMobile = () => {
  const { navbarStore } = useStores();
  const { isMobile } = useIsMobile();

  return useCallback(() => {
    isMobile && navbarStore.setOpen(false);
  }, [navbarStore, isMobile]);
};
