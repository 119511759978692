import type { CredentialProviderType, CredentialUsageType } from 'types/api.types';
import type { CredentialType } from '@env0/configuration-service/api.enum';
import { CREDENTIALS_TYPE_MAPPING, CREDENTIALS_USAGE_TYPE_MAPPING } from '@env0/configuration-service/api.enum';

export const getCredentialTypesByUsageTypeAndCredentialProvider = (
  usageType: CredentialUsageType,
  credentialProvider: CredentialProviderType
): readonly CredentialType[] => {
  const credentialTypes = new Set(
    Object.entries(CREDENTIALS_TYPE_MAPPING)
      .filter(([_, cloud]) => credentialProvider === cloud)
      .map(([credentialType]) => credentialType)
  );

  const clone = [...CREDENTIALS_USAGE_TYPE_MAPPING[usageType]];
  return clone.filter((credentialType: CredentialType) => credentialTypes.has(credentialType));
};

export const getCredentialsTypeByUsageType = (usageType: CredentialUsageType) => [
  ...CREDENTIALS_USAGE_TYPE_MAPPING[usageType]
];
