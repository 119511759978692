import { observer } from 'mobx-react';
import { useCurrentOrganization } from 'hooks/use-current-organization-id';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { appVersion, appVersionHash } from 'constants/config';

const SentryEventProcessors: React.FC = () => {
  const organization = useCurrentOrganization();

  useEffect(() => {
    Sentry.addEventProcessor(event => {
      Sentry.setTags({
        'organization.name': organization?.name,
        'organization.id': organization?.id,
        'version.number': appVersion,
        'version.hash': appVersionHash
      });

      return event;
    });
  }, [organization]);

  return null;
};

export default observer(SentryEventProcessors);
