import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import differenceBy from 'lodash/differenceBy';
import { action, observable } from 'mobx';
import BaseService from 'services/base-service';
import type { RolesApi } from '@env0/role-service/api';

type InputUser = { userId: string; role: RolesApi.RBACPermissionRole };

export class ProjectsStore extends BaseService {
  @observable projectsUsers: { [projectId: string]: RolesApi.UserRoleAssignment[] } = {};

  // ProjectUsers:

  @action setProjectUsers(projectId: string, users: any[]) {
    this.projectsUsers = {
      ...this.projectsUsers,
      [projectId]: users
    };
  }

  getProjectUsers(projectId: string): RolesApi.UserRoleAssignment[] {
    return this.projectsUsers[projectId] || [];
  }

  async fetchUsers(projectId: string) {
    const { data: users } = await this.service.apiClient.projects.getUsers(projectId);

    this.setProjectUsers(projectId, users);

    return users;
  }

  async removeUsers(projectId: string, users: InputUser[]) {
    if (users.length === 0) {
      return;
    }

    await Promise.all(users.map(user => this.service.apiClient.projects.removeUser(projectId, user.userId)));

    this.setProjectUsers(projectId, differenceBy(this.getProjectUsers(projectId), users, 'userId'));
  }

  async assignUsers(projectId: string, users: InputUser[]) {
    if (users.length === 0) {
      return;
    }

    const results = await Promise.all(
      users.map(user => this.service.apiClient.projects.assignUser(projectId, user.userId, user.role))
    );

    this.setProjectUsers(projectId, [...this.getProjectUsers(projectId), ...map(results, 'data')]);
  }

  async updateUsers(projectId: string, users: InputUser[]) {
    if (users.length === 0) {
      return;
    }

    const results = await Promise.all(
      users.map(user => this.service.apiClient.projects.updateUser(projectId, user.userId, user.role))
    );

    const newUsersMap = keyBy(map(results, 'data'), 'userId');
    this.setProjectUsers(
      projectId,
      this.getProjectUsers(projectId).map(user => ({
        ...user,
        ...newUsersMap[user.userId]
      }))
    );
  }
}
