import React, { useEffect, useMemo } from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { INFINITE_VALUE } from 'constants/ttl.constants';
import styled from 'types/theme.types';
import { convertTTLToDuration } from 'utils/ttl.utils';
import { TtlInputNumber } from 'components/policies/ttl-input-number';
import { TtlRadioButtons } from 'components/policies/ttl-radio-buttons';
import type { TtlPolicy, TtlTimeUnit } from 'components/policies/common-ttl';
import { validTimeUnits, getTtlTimeUnit } from 'components/policies/common-ttl';

export interface DefaultTtlSectionProps {
  form: UseFormReturnType<TtlPolicy>;
}

const shouldAdjustDefaultTtl = (maxTtl: string | null, defaultTtl: string | null): boolean =>
  //max TTL is finite and default TTL is infinite or max TTL is < Default TTL, we need to adjust Default TTL
  !!maxTtl &&
  (defaultTtl === INFINITE_VALUE ||
    convertTTLToDuration(maxTtl) < convertTTLToDuration(defaultTtl) ||
    convertTTLToDuration(`1-${getTtlTimeUnit(maxTtl)}`) < convertTTLToDuration(`1-${getTtlTimeUnit(defaultTtl)}`));

export const DefaultTtlSection: React.FC<DefaultTtlSectionProps> = ({ form }) => {
  const shouldAdjustDefaultTtlByMax: boolean = useMemo<boolean>(
    () => shouldAdjustDefaultTtl(form.getValues('maxTtl'), form.getValues('defaultTtl')),
    [form]
  );

  // because Default TTL is limited by Max TTL (cannot be greater than MAX TTL),
  // we need to catch changes made to Max TTL and act accordingly.
  useEffect(() => {
    if (shouldAdjustDefaultTtlByMax) {
      form.setValue('defaultTtl', form.getValues('maxTtl'));
    }
  }, [form, shouldAdjustDefaultTtlByMax]);

  const defaultTtlUnitsSmallerThanMaxTtl = (): TtlTimeUnit[] => {
    const maxTtlUnit: string | null | undefined = getTtlTimeUnit(form.getValues('maxTtl'));
    const res = validTimeUnits.filter(
      ttlUnit =>
        form.getValues('maxTtl') === INFINITE_VALUE ||
        convertTTLToDuration(`1-${ttlUnit}`) <= convertTTLToDuration(`1-${maxTtlUnit}`)
    );
    return res;
  };

  return (
    <Row>
      <MaxEnvironmentTTLContainer>
        <TtlRadioButtons form={form} name={'defaultTtl'} />
        <TtlInputNumber form={form} name={'defaultTtl'} validTimeUnits={defaultTtlUnitsSmallerThanMaxTtl()} />
      </MaxEnvironmentTTLContainer>
    </Row>
  );
};

const MaxEnvironmentTTLContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
