import Modal from 'components/common/modal';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'types/theme.types';
import { useHasPermission } from 'hooks/use-has-permission';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

const PayGate = () => {
  const { payGateStore } = useStores();
  const organizationId = useCurrentOrganizationId();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthorized: canManageBilling } = useHasPermission('MANAGE_BILLING');
  const route = location.pathname + location.search;

  useEffect(() => {
    payGateStore.checkCachedRoute(route);
  }, [payGateStore, route]);

  const onClose = useCallback(() => {
    payGateStore.clearActivePayGateError();
  }, [payGateStore]);

  const onContactUsClick = useCallback(() => {
    onClose();
    navigate(`/organizations/${organizationId}/billing`);
  }, [navigate, onClose, organizationId]);

  return (
    <ModalWithLineBreaks
      open={!!payGateStore.activePayGateErrorText}
      width={600}
      onOk={onContactUsClick}
      onCancel={onClose}
      titleId="pay-gate.title"
      destroyOnClose
      maskClosable
      maskStyle={{ backdropFilter: 'blur(4px)' }}
      okButtonProps={canManageBilling ? {} : { style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={<FormattedMessage id="paygate.explore-tier-upgrade" />}
      centered>
      <div>{payGateStore.activePayGateErrorText}</div>
      {!canManageBilling && <FormattedMessage id="paygate.contact-admin" />}
    </ModalWithLineBreaks>
  );
};

const ModalWithLineBreaks = styled(Modal)`
  white-space: pre-line;
`;

export default observer(PayGate);
