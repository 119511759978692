import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import useForm, { type UseFormProps } from 'hooks/use-form.hook';
import { useCallback } from 'react';
import type { Project } from 'types/api.types';
import { useUpdateProject } from 'stores/rq/projects';

export type ProjectSettingsSchema = {
  name: string;
  description: string;
};

const schema: SchemaOf<ProjectSettingsSchema> = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().default('')
});

export const useProjectGeneralSettingsForm = (project: Project) => {
  const { mutateAsync: updateProject } = useUpdateProject();
  const { id, name, description } = project;

  const onSubmit = useCallback<UseFormProps<ProjectSettingsSchema>['onSubmit']>(
    ({ name, description }) => updateProject({ id, data: { name, description } }),
    [id, updateProject]
  );
  return useForm<ProjectSettingsSchema>({
    schema,
    onSubmit,
    initialValues: {
      name,
      description
    }
  });
};
