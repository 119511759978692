import { EventNames } from 'utils/analytics.utils';
import type { TemplateWizardStep } from 'components/templates/templates-wizard/common/template-wizard.types';

export const stepToEventMapper: Record<TemplateWizardStep, EventNames> = {
  settings: EventNames.TEMPLATE_WIZARD_SETTINGS_STEP,
  vcs: EventNames.TEMPLATE_WIZARD_VCS_STEP,
  credentials: EventNames.TEMPLATE_WIZARD_CREDENTIALS_STEP,
  variables: EventNames.TEMPLATE_WIZARD_VARIABLES_STEP,
  projects: EventNames.TEMPLATE_WIZARD_PROJECTS_STEP,
  'environment-details': EventNames.TEMPLATE_WIZARD_ENVIRONMENT_DETAILS_STEP,
  mapping: EventNames.TEMPLATE_WIZARD_MAPPING_STEP,
  done: EventNames.TEMPLATE_WIZARD_DONE_STEP
};
