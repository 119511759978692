import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CardDescription } from 'components/common/card';
import type { CredentialUsageType } from 'types/api.types';

type ProjectCredentialsCardHeaderProps = {
  docsHref: string;
  usageType: CredentialUsageType;
};

const ProjectCredentialsCardHeader: React.FunctionComponent<ProjectCredentialsCardHeaderProps> = ({
  docsHref,
  usageType
}) => {
  const docsLink = (
    <a href={docsHref} target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="link.explanation.more" />
    </a>
  );

  const intlPrefix = `projects.settings.menu.` + usageType.toLowerCase();

  return (
    <>
      <FormattedMessage data-e2e="project-credentials-title" id={intlPrefix + '.title'} />
      <CardDescription>
        <FormattedMessage
          data-e2e="project-credentials-description"
          id={intlPrefix + '.description'}
          values={{ docsLink }}
        />
      </CardDescription>
    </>
  );
};

export default ProjectCredentialsCardHeader;
