import type { MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import theme from 'constants/themes.constants';
import styled from 'types/theme.types';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

export interface LinkProps {
  messageId?: string | ReactNode;
  url?: string;
  plainText?: boolean;
  color?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  download?: string;
  children?: ReactNode;
}

const Link: React.FC<LinkProps> = ({
  messageId,
  url,
  plainText = false,
  color = theme.primaryBlue,
  onClick,
  className,
  children,
  ...props
}) => {
  const LinkText = children ? children : plainText ? messageId : <FormattedMessage id={messageId as string} />;

  if (url?.startsWith('/')) {
    return (
      <StyledRRDLink to={url} className={className} {...props} onClick={onClick}>
        {LinkText}
      </StyledRRDLink>
    );
  }
  return (
    <StyledLink
      {...props}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      onClick={e => {
        e.stopPropagation();
        onClick?.(e);
      }}
      color={color}
      className={className}>
      {LinkText}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primaryBlue} !important;
`;

const StyledRRDLink = styled(RRDLink)`
  color: ${({ theme }) => theme.primaryBlue} !important;
`;

export const setLink = (link: string) => ({
  a: (text: string | ReactNode) => <Link plainText messageId={text} url={link} />
});

export const setLinksByTag = (tagToLinkMap: Record<string, string>) => {
  const tagToLinkCallback: Record<string, (text: string | ReactNode) => EmotionJSX.Element> = {};

  Object.keys(tagToLinkMap).forEach(tag => {
    tagToLinkCallback[tag] = text => <Link messageId={text} url={tagToLinkMap[tag] ?? ''} plainText />;
  });

  return tagToLinkCallback;
};

export default Link;
