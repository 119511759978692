import isNil from 'lodash/isNil';
import { observable } from 'mobx';
import type { Environment, Project } from 'types/api.types';
import LazyObservableMap from 'stores/mobx/common/lazy-observable-map';
import type { CostApi } from '@env0/cost-service/api';
import dayjs from 'types/dayjs.types';
import BaseService from 'services/base-service';
import { apiBaseUrl } from 'constants/config';
import type { DeployableType } from '@env0/blueprint-service/schema';

const INITIAL_DEPLOYMENT_DIFF_SECONDS = 60;
const MAX_REST_CALL_LENGTH = 1024 - apiBaseUrl.length - 10;

export enum CostUnavailableStatus {
  DISABLED = 'DISABLED',
  ARCHIVED = 'ARCHIVED',
  CREATED = 'CREATED',
  NEVER_DEPLOYED = 'NEVER_DEPLOYED',
  INITIAL_DEPLOYMENT = 'INITIAL_DEPLOYMENT',
  UNAVAILABLE_FOR_TYPE = 'UNAVAILABLE_FOR_TYPE'
}

export const isCostsNotSupported = (blueprintType: DeployableType) => ['pulumi', 'ansible'].includes(blueprintType);

const onInitialDeployment = (environment: Environment) => {
  const inProgressStatuses = ['DEPLOY_IN_PROGRESS', 'WAITING_FOR_USER'];

  return (
    inProgressStatuses.includes(environment.status) &&
    environment.latestDeploymentLog &&
    Math.abs(dayjs(environment.latestDeploymentLog.startedAt).diff(environment.createdAt, 'seconds')) <
      INITIAL_DEPLOYMENT_DIFF_SECONDS
  );
};

const getWeeklyCostForSingleEntityMapper = (id: string) => {
  return async (bulkResponse: CostApi.GetWeeklyCosts.Response) => {
    const singleEntityResponse = bulkResponse[id];
    if (!singleEntityResponse) return CostUnavailableStatus.DISABLED;
    if (singleEntityResponse.error) return Promise.reject(singleEntityResponse.error);
    return singleEntityResponse.cost;
  };
};

export class CostsStore extends BaseService {
  @observable private weeklyCostsByEnvironmentId = new LazyObservableMap(environmentId =>
    this.service.apiClient.costs
      .getWeeklyCost({ environmentIds: [environmentId] })
      .then(getWeeklyCostForSingleEntityMapper(environmentId))
  );
  @observable private weeklyCostsByProjectId = new LazyObservableMap(projectId =>
    this.service.apiClient.costs
      .getWeeklyCost({ projectIds: [projectId] })
      .then(getWeeklyCostForSingleEntityMapper(projectId))
  );

  @observable private isCostEnabledByProjectId = new LazyObservableMap(this.service.apiClient.costs.isCostEnabled);

  private static getEnvironmentCostUnavailableStatus(environment: Environment) {
    if (environment.isArchived) return CostUnavailableStatus.ARCHIVED;
    if (environment.status === 'CREATED') return CostUnavailableStatus.CREATED;
    if (environment.status === 'NEVER_DEPLOYED') return CostUnavailableStatus.NEVER_DEPLOYED;
    if (onInitialDeployment(environment)) return CostUnavailableStatus.INITIAL_DEPLOYMENT;
    const blueprintType = environment.latestDeploymentLog?.blueprintType;
    if (isCostsNotSupported(blueprintType)) return CostUnavailableStatus.UNAVAILABLE_FOR_TYPE;
  }

  private async shouldCheckEnvironmentCost(environment: Environment) {
    const environmentCostUnavailableStatus = CostsStore.getEnvironmentCostUnavailableStatus(environment);
    if (environmentCostUnavailableStatus) return environmentCostUnavailableStatus;

    const { enabled } = await this.isCostEnabled(environment.projectId);
    if (!enabled) return CostUnavailableStatus.DISABLED;
  }

  private filterEnvironmentsFromBatch(environments: Environment[]) {
    // We don't use shouldCheckEnvironmentCost because it is async.
    // Currently, our batch requests rely on rendering. If the weekly cost component is rendered before bulk API call is done,
    // then we'll also fetch cost for each environment one-by-one.
    // https://env0.slack.com/archives/CH9ND4Z6C/p1664351441921779?thread_ts=1663859496.833819&cid=CH9ND4Z6C
    const unavailableStatuses = environments.map(env => CostsStore.getEnvironmentCostUnavailableStatus(env));
    return environments.filter((_env, index) => isNil(unavailableStatuses[index]));
  }

  isCostEnabled(projectId: string) {
    return this.isCostEnabledByProjectId.get(projectId);
  }

  async getEnvironmentWeeklyCost(environment: Environment): Promise<number | CostUnavailableStatus> {
    const unavailableStatus = await this.shouldCheckEnvironmentCost(environment);
    return isNil(unavailableStatus) ? this.weeklyCostsByEnvironmentId.get(environment.id) : unavailableStatus;
  }

  async getProjectWeeklyCost(project: Project): Promise<number | CostUnavailableStatus> {
    return this.weeklyCostsByProjectId.get(project.id);
  }

  private async batchLoadWeeklyCosts(
    objectIds: string[],
    cache: LazyObservableMap<number | CostUnavailableStatus.DISABLED>,
    queryStringParamsName: 'projectIds' | 'environmentIds' | 'organizationId'
  ) {
    // Only fetch what isn't in cache
    const unCachedObjectIds = objectIds.filter(id => !cache.has(id));
    if (unCachedObjectIds.length === 0) return;

    const promiseForWeeklyCostPerObject = this.service.apiClient.costs.getWeeklyCost({
      [queryStringParamsName]: unCachedObjectIds
    });
    unCachedObjectIds.forEach(id => {
      cache.set(id, promiseForWeeklyCostPerObject.then(getWeeklyCostForSingleEntityMapper(id)));
    });

    return promiseForWeeklyCostPerObject;
  }

  private async batchLoadWeeklyCostByOrganization(
    unCachedProjectIds: string[],
    organizationId: string,
    cache: LazyObservableMap<number | CostUnavailableStatus.DISABLED>
  ) {
    const promiseForWeeklyCostPerObject = await this.service.apiClient.costs.getWeeklyCost({
      organizationId: [organizationId]
    });
    unCachedProjectIds.forEach(id => {
      cache.set(id, Promise.resolve(promiseForWeeklyCostPerObject).then(getWeeklyCostForSingleEntityMapper(id)));
    });

    return promiseForWeeklyCostPerObject;
  }

  async batchLoadWeeklyCostsForEnvironments(environments: Environment[]) {
    const environmentsToFetch = this.filterEnvironmentsFromBatch(environments);
    const environmentIds = environmentsToFetch.map(env => env.id);
    return this.batchLoadWeeklyCosts(environmentIds, this.weeklyCostsByEnvironmentId, 'environmentIds');
  }

  async batchLoadWeeklyCostsForProjects(projects: Project[], organizationId: string) {
    const projectIds = projects.map(proj => proj.id);
    // Only fetch what isn't in cache
    const unCachedProjectIds = projectIds.filter(id => !this.weeklyCostsByProjectId.has(id));

    if (unCachedProjectIds.length === 0) return;
    else if (unCachedProjectIds.join(',').length <= MAX_REST_CALL_LENGTH) {
      return this.batchLoadWeeklyCosts(projectIds, this.weeklyCostsByProjectId, 'projectIds');
    } else {
      return this.batchLoadWeeklyCostByOrganization(unCachedProjectIds, organizationId, this.weeklyCostsByProjectId);
    }
  }
}
