import type { CheckboxProps } from 'antd';
import withController from 'components/common/form-controlled/with-controller.hoc';
import withLabel from 'components/common/form-controlled/with-label.hoc';
import Checkbox from 'components/common/input-components/checkbox';

export default withLabel(
  withController<CheckboxProps>(Checkbox, {
    withoutRef: true,
    mapValueTo: 'checked',
    onChangeTransformer: e => e.target.checked
  })
);
