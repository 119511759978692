import React from 'react';
import { Tag as AntdTag } from 'antd';
import styled from 'types/theme.types';
import type { TagProps } from 'antd/lib/tag';

export const Tag: React.FunctionComponent<TagProps> = ({ children, ...props }) => (
  <StyledTag {...props}>{children}</StyledTag>
);

const StyledTag = styled(AntdTag)`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
