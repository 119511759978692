import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type {
  ApiKey,
  ConfigurationProperty,
  CreateSshKeyInput,
  UpdateSshKeyInput,
  SshKey,
  Token,
  TokenTypes,
  GitProviderTypes,
  PersonalApiKey
} from 'types/api.types';
import type { ConfigurationApi, ConfigurationSet } from '@env0/configuration-service/api';
import type { AxiosResponse } from 'axios';
import type { OrganizationApi } from '@env0/organization-service/api';
import type { ConfigurationSetAssignmentScope } from '@env0/configuration-service/api';

const castEmptyStringValues = (property: ConfigurationProperty) => ({
  ...property,
  value: property.value ?? ''
});

export type GetConfigurationPropertiesByScopeRequest = ConfigurationApi.FindVariablesByScope.Request.QueryParams;

export default (client: HttpClient) => ({
  getSshKeys: (organizationId: string) =>
    client.get<SshKey[]>('ssh-keys', { params: { organizationId } }).then(returnData),

  createSshKey: (data: CreateSshKeyInput, organizationId: string): Promise<SshKey> =>
    client
      .post<ConfigurationApi.CreateSshKey.Request.Body, AxiosResponse<ConfigurationApi.CreateSshKey.Response>>(
        'ssh-keys',
        {
          ...data,
          organizationId
        }
      )
      .then(returnData),

  deleteSshKey: (sshKeyId: string): Promise<void> => client.delete(`ssh-keys/${sshKeyId}`),

  updateSshKey: (sshKeyId: string, data: UpdateSshKeyInput): Promise<SshKey> =>
    client.put(`ssh-keys/${sshKeyId}`, data).then(returnData),

  getOrganizationApiKeys: (organizationId: string) =>
    client.get<ApiKey[]>('api-keys', { params: { organizationId } }).then(returnData),

  addOrganizationApiKey: (organizationId: string, name: string, permissions: OrganizationApi.Permissions) =>
    client
      .post<{ name: string; organizationId: string; permissions: OrganizationApi.Permissions }, AxiosResponse<ApiKey>>(
        'api-keys',
        {
          name,
          organizationId,
          permissions
        }
      )
      .then(returnData),

  deleteOrganizationApiKey: (id: string): Promise<undefined> => client.delete(`api-keys/${id}`),

  getPersonalApiKeys: () => client.get<PersonalApiKey[]>('personal-api-keys').then(returnData),

  addPersonalApiKey: (name: string) =>
    client
      .post<{ name: string }, AxiosResponse<PersonalApiKey>>('personal-api-keys', {
        name
      })
      .then(returnData),

  deletePersonalApiKey: (id: string): Promise<undefined> => client.delete(`personal-api-keys/${id}`),

  deleteToken: (tokenId: string) => client.delete(`tokens/${tokenId}`),

  getTokens: (organizationId: string, type: TokenTypes) => {
    return client.get<Token[]>('tokens', { params: { organizationId, type } }).then(returnData);
  },

  createToken: (token: Token) => client.post<Token, AxiosResponse<Token>>('tokens', token).then(returnData),

  getTokenById: (tokenId: string) => client.get<Token>(`tokens/${tokenId}`).then(returnData),

  generateGitAuthToken: (organizationId: string, gitProvider: GitProviderTypes, authCode: string) =>
    client
      .post<{ organizationId: string; gitProvider: GitProviderTypes; authCode: string }, AxiosResponse<Token>>(
        'tokens/git',
        { organizationId, gitProvider, authCode },
        { hideNotification: true }
      )
      .then(returnData),

  getConfigurationPropertiesByScope: (params: GetConfigurationPropertiesByScopeRequest) =>
    client
      .get<ConfigurationProperty[]>('configuration', {
        params
      })
      .then(returnData),

  createOrUpdateConfigurationProperty: (configs: ConfigurationProperty[], organizationId: string) => {
    const configurationsWithOrganization = configs
      .map(castEmptyStringValues)
      .map(config => ({ ...config, organizationId }));

    return client
      .post<typeof configurationsWithOrganization, AxiosResponse<ConfigurationProperty[]>>(
        'configuration',
        configurationsWithOrganization
      )
      .then(returnData);
  },

  deleteConfigurationProperty: (configurationPropertyId: string) =>
    client.delete(`configuration/${configurationPropertyId}`),

  getConfigurationSetsByCreationScope: (params: ConfigurationApi.FindSetsByCreationScope.Request.QueryParams) =>
    client.get<ConfigurationSet[]>(`configuration-sets`, { params }).then(returnData),

  getConfigurationSetById: (id: string) => client.get<ConfigurationSet>(`configuration-sets/${id}`).then(returnData),

  getConfigurationSetsByAssignmentScope: (scope: ConfigurationSetAssignmentScope, scopeId: string) =>
    client.get<ConfigurationSet[]>(`configuration-sets/assignments/${scope}/${scopeId}`).then(returnData),

  createSet: (createSetBody: ConfigurationApi.CreateConfigurationSet.Request.Body) => {
    return client
      .post<
        ConfigurationApi.CreateConfigurationSet.Request.Body,
        AxiosResponse<ConfigurationApi.CreateConfigurationSet.Response>
      >('configuration-sets', createSetBody)
      .then(returnData);
  },

  deleteSet: (setId: string): Promise<void> => client.delete(`configuration-sets/${setId}`),
  updateSet: (setId: string, updateSetBody: ConfigurationApi.UpdateConfigurationSet.Request.Body) => {
    return client
      .put<
        ConfigurationApi.UpdateConfigurationSet.Request.Body,
        AxiosResponse<ConfigurationApi.UpdateConfigurationSet.Response>
      >(`configuration-sets/${setId}`, updateSetBody)
      .then(returnData);
  },
  assignSets: (scope: ConfigurationSetAssignmentScope, scopeId: string, setIds: string[]) => {
    return client.post<
      ConfigurationApi.AssignConfigurationSetsToScope.Request.Body,
      ConfigurationApi.AssignConfigurationSetsToScope.Response
    >(`configuration-sets/assignments/${scope}/${scopeId}`, {}, { params: { setIds: setIds.join(',') } });
  },

  unassignSets: (scope: ConfigurationSetAssignmentScope, scopeId: string, setIds: string[]) => {
    return client.delete(`configuration-sets/assignments/${scope}/${scopeId}`, {
      params: { setIds: setIds.join(',') }
    });
  }
});
