import type { TeamApi } from '@env0/team-service/api';
type Team = TeamApi.Team;

export const compareTeamsByName = (team1: Pick<Team, 'name'>, team2: Pick<Team, 'name'>) => {
  return team1.name.localeCompare(team2.name);
};

export const compareTeamsByDescription = (team1: Pick<Team, 'description'>, team2: Pick<Team, 'description'>) => {
  if (team1.description && team2.description) return team1.description.localeCompare(team2.description);
  else if (team1.description) return -1;
  else return 1;
};

export const compareTeamsByUsersNumber = (team1: Pick<Team, 'users'>, team2: Pick<Team, 'users'>) => {
  if (team1.users && team2.users) return team2.users.length - team1.users.length;
  else if (team1.users) return -1;
  else return 1;
};
