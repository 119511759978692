import React, { useCallback, useState } from 'react';
import styled from 'types/theme.types';

import CommonModal from 'components/common/modal';
import Button, { type ButtonType } from 'components/common/button';
import Trash from 'components/common/trash';
import Tooltip from 'components/common/tooltip';
import isFunction from 'lodash/isFunction';

import { useModal } from 'hooks/modal.hooks';
import { FormattedMessage } from 'react-intl';

import type { KeyToObjectLiteral } from 'types/common.types';
import { css } from '@emotion/css';

export interface PopupEntityProps {
  id?: string;
  shouldRender?: boolean;
  onApprove: (event?: React.MouseEvent) => void;
  onCancel?: () => void;
  popupTitleId: string;
  popupTitleVariables?: KeyToObjectLiteral<string | React.ReactElement>;
  popupOkTextId: string;
  popupCancelTextId?: string;
  popupContentId?: string;
  iconComponent?: React.ReactElement;
  popupContent?: React.ReactElement;
  tooltipTextId?: string;
  beforeModalOpen?: () => void;
  allowEventPropagation?: boolean;
  preventDefault?: boolean;
  disabled?: boolean;
  approveButtonType?: ButtonType;
  approveDisabled?: boolean;
  children?: React.ReactNode;
  dataE2e?: string;
}

const PopupEntity = ({
  id,
  shouldRender = true,
  onApprove,
  onCancel,
  popupTitleId,
  popupTitleVariables,
  popupOkTextId,
  popupCancelTextId,
  iconComponent,
  popupContent,
  popupContentId,
  tooltipTextId,
  beforeModalOpen,
  allowEventPropagation,
  preventDefault,
  disabled,
  approveButtonType = 'danger',
  approveDisabled = false,
  dataE2e
}: PopupEntityProps) => {
  const { isModalOpen, hideModal, showModal } = useModal();
  const [loading, setLoading] = useState(false);

  const approveClickHandler = useCallback(
    (event: React.MouseEvent<any>) => {
      event.stopPropagation();

      setLoading(true);
      Promise.resolve(onApprove(event))
        .then(() => hideModal())
        .finally(() => setLoading(false));
    },
    [hideModal, onApprove]
  );

  if (!shouldRender) {
    return null;
  }

  const onIconClick = (event: React.MouseEvent) => {
    if (!allowEventPropagation) event.stopPropagation();
    if (preventDefault) event.preventDefault();
    beforeModalOpen?.();
    showModal();
  };

  const onCancelModal = (event: any) => {
    event.stopPropagation();
    if (isFunction(onCancel)) {
      onCancel();
    }
    hideModal();
  };

  const onPressX = (event: any) => {
    event.stopPropagation();
    hideModal();
  };

  const icon = iconComponent || <StyledTrash />;
  const wrappedIcon = (
    <IconContainer
      disabled={disabled}
      data-e2e={dataE2e ?? 'show-popup-entity-button'}
      id={id}
      onClick={e => !disabled && onIconClick(e)}>
      {icon}
    </IconContainer>
  );

  const footer = (
    <ModalFooterContainer>
      <Button onClick={onCancelModal} id="popup-cancel" disabled={loading} data-e2e={'popup-cancel'}>
        <FormattedMessage id={popupCancelTextId} />
      </Button>
      <Button
        data-e2e="popup-confirm"
        isLoading={loading}
        type={approveButtonType}
        onClick={approveClickHandler}
        id="popup-confirm"
        disabled={loading || approveDisabled}>
        <FormattedMessage id={popupOkTextId} values={popupTitleVariables} />
      </Button>
    </ModalFooterContainer>
  );

  return (
    <>
      {tooltipTextId ? (
        <Tooltip titleId={tooltipTextId} placement="top">
          {wrappedIcon}
        </Tooltip>
      ) : (
        wrappedIcon
      )}
      <CommonModal
        data-e2e="popup-entity-common-modal"
        centered
        width={690}
        footer={footer}
        onCancel={onPressX}
        wrapClassName={ModalWrapper}
        open={isModalOpen}>
        <ModalTitleContainer>
          <FormattedMessage values={popupTitleVariables} id={popupTitleId} />
        </ModalTitleContainer>
        {popupContentId && <ModalContent>{popupContentId && <FormattedMessage id={popupContentId} />}</ModalContent>}
        {popupContent}
      </CommonModal>
    </>
  );
};

const IconContainer = styled.div<{ disabled: boolean | undefined }>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
`;

const StyledTrash = styled(Trash)`
  opacity: 1;
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.7;
  }
`;

const ModalFooterContainer = styled.div`
  padding: 10px 9px;
  font-size: 14px;
`;

const ModalTitleContainer = styled.div`
  height: 100%;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryBlue};
  display: block;
  margin-bottom: 1.5em;
`;

const ModalContent = styled.p`
  font-size: 16px;
  white-space: pre-line;
`;

const ModalWrapper = css`
  .ant-modal-body {
    padding: 3em;
  }
`;

export default PopupEntity;
