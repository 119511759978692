import { useMutation, useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';

export type AssignApprovalPolicyBody = BlueprintApi.AssignApprovalPolicy.Request.Body;
export type UnassignApprovalPolicyBody = BlueprintApi.UnassignApprovalPolicy.Request.PathParams;
export type UnassignApprovalPolicyByIdBody = BlueprintApi.UnassignApprovalPolicyById.Request.QueryParams;

export type WithScopeId<T> = T & { scopeId: string };

const KEY = 'approval-policies-assignments';
export type ApprovalPolicyAssignmentCacheKeyProps = {
  scope: string;
  scopeId: string;
};

const useCacheKeys = () => ({
  // todo: single will be removed when we will only use the table
  single: ({ scopeId, scope }: ApprovalPolicyAssignmentCacheKeyProps) => [KEY, { scopeId, scope }] as const,
  all: ({ scopeId, scope }: ApprovalPolicyAssignmentCacheKeyProps) => [KEY + 'all', { scopeId, scope }] as const
});

const useCommonHookSetup = () => {
  return {
    apiClient: useApiClient(),
    keys: useCacheKeys()
  };
};

export const useGetAllApprovalPolicyAssignments = ({
  scope,
  scopeId
}: {
  scope: BlueprintApi.ApprovalPolicyScope;
  scopeId?: string;
}) => {
  const { apiClient, keys } = useCommonHookSetup();

  return useQuery({
    queryFn: async (): Promise<BlueprintApi.ApprovalPolicyTemplateWithScope[]> =>
      apiClient.approvalPolicies.getApprovalPoliciesAssignmentsByScope({ scope, scopeId: scopeId! }),
    queryKey: keys.all({ scopeId: scopeId!, scope }),
    enabled: !!scopeId
  });
};

export const useAssignApprovalPolicy = ({ scope }: { scope: BlueprintApi.ApprovalPolicyScope }) => {
  const { apiClient, keys } = useCommonHookSetup();

  return useMutation({
    mutationFn: (data: AssignApprovalPolicyBody) => {
      return apiClient.approvalPolicies.assignApprovalPolicy(data);
    },
    onSuccess: (data: BlueprintApi.AssignApprovalPolicy.Response) => {
      getQueryClientInstance().setQueryData(keys.single({ scopeId: data.scopeId, scope }), data);
      getQueryClientInstance().invalidateQueries({ queryKey: keys.all({ scopeId: data.scopeId, scope }) });
    }
  });
};

export const useUnassignApprovalPolicy = ({ scope }: { scope: BlueprintApi.ApprovalPolicyScope }) => {
  const { apiClient, keys } = useCommonHookSetup();

  return useMutation({
    mutationFn: async (
      data: UnassignApprovalPolicyBody
    ): Promise<{ scope: BlueprintApi.ApprovalPolicyScope; scopeId: string }> => {
      await apiClient.approvalPolicies.unassignApprovalPolicy(data);

      return { scope, scopeId: data.scopeId };
    },
    onSuccess: ({ scope, scopeId }) => {
      getQueryClientInstance().setQueryData(keys.single({ scopeId, scope }), null);
      getQueryClientInstance().invalidateQueries({ queryKey: keys.all({ scopeId, scope }) });
    }
  });
};

export const useUnassignApprovalPolicyById = ({ scope }: { scope: BlueprintApi.ApprovalPolicyScope }) => {
  const { apiClient, keys } = useCommonHookSetup();

  return useMutation({
    mutationFn: async (
      data: WithScopeId<UnassignApprovalPolicyByIdBody>
    ): Promise<WithScopeId<UnassignApprovalPolicyByIdBody>> => {
      await apiClient.approvalPolicies.unassignApprovalPolicyById(data);
      return data;
    },
    onSuccess: (data: WithScopeId<UnassignApprovalPolicyByIdBody>) => {
      getQueryClientInstance().invalidateQueries({ queryKey: keys.all({ scopeId: data.scopeId, scope }) });
    }
  });
};

export const useInvalidateApprovalPolicyAssignmentsCache = () => {
  const { all } = useCacheKeys();

  return ({ scope, scopeId }: ApprovalPolicyAssignmentCacheKeyProps) => {
    getQueryClientInstance().invalidateQueries({ queryKey: all({ scopeId, scope }) });
  };
};
