import styled from 'types/theme.types';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useRef } from 'react';
import AzureDevopsFaceBot from 'assets/images/animations/env0-robot-face-azure-devops.json';
import GitHubFaceBot from 'assets/images/animations/env0-robot-face-github.json';
import GitLabFaceBot from 'assets/images/animations/env0-robot-face-gitlab.json';
import BitbucketFaceBot from 'assets/images/animations/env0-robot-face-bitbucket.json';
import VcsFaceBot from 'assets/images/animations/env0-robot-face-vcs.json';
import AwsFaceRobot from 'assets/images/animations/env0-robot-face-aws.json';
import AzureFaceRobot from 'assets/images/animations/env0-robot-face-azure.json';
import GcpFaceRobot from 'assets/images/animations/env0-robot-face-gcp.json';
import CloudFaceRobot from 'assets/images/animations/env0-robot-face-cloud.json';
import HelmFaceRobot from 'assets/images/animations/env0-robot-face-helm.json';
import K8sFaceRobot from 'assets/images/animations/env0-robot-face-k8s.json';
import VaultFaceRobot from 'assets/images/animations/env0-robot-face-vault.json';
import type { LottieRef } from 'lottie-react';
import type { TemplateWizardStep } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { CredentialProviderType, GitProviderTypes } from 'types/api.types';

const vcsToFaceRobot: Record<GitProviderTypes, unknown> = {
  GitHub: GitHubFaceBot,
  GitHubEnterprise: GitHubFaceBot,
  GitLab: GitLabFaceBot,
  AzureDevOps: AzureDevopsFaceBot,
  HelmRepository: HelmFaceRobot,
  GitLabEnterprise: GitLabFaceBot,
  BitBucket: BitbucketFaceBot,
  BitBucketServer: BitbucketFaceBot,
  Other: VcsFaceBot
};

const providerToFaceRobot: Record<CredentialProviderType | 'default', unknown> = {
  aws: AwsFaceRobot,
  gcp: GcpFaceRobot,
  azure: AzureFaceRobot,
  k8s: K8sFaceRobot,
  vault: VaultFaceRobot,
  default: CloudFaceRobot
};

type FaceRobotProps = {
  wizardStep: TemplateWizardStep;
  vcs?: GitProviderTypes;
  provider?: CredentialProviderType;
};

export const FaceRobot: React.FC<FaceRobotProps> = React.memo(function FaceRobot({ wizardStep, vcs, provider }) {
  const lottieRef = useRef() as LottieRef;

  useEffect(() => {
    lottieRef.current?.setSpeed(0.4);
    lottieRef.current?.play();
  }, [lottieRef, vcs, provider]);

  const vcsAnimationData = vcs ? vcsToFaceRobot[vcs] : VcsFaceBot;
  const providerAnimationData = providerToFaceRobot[provider || 'default'];
  const replayOnHover = useCallback(() => {
    lottieRef.current?.goToAndPlay(0, true);
  }, [lottieRef]);

  return (
    <StandingRobot
      animationData={wizardStep === 'vcs' ? vcsAnimationData : providerAnimationData}
      lottieRef={lottieRef}
      onMouseEnter={replayOnHover}
      loop={false}
    />
  );
});

const StandingRobot = styled(Lottie)`
  height: 180px;
`;
