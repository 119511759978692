import React from 'react';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import JwtOidcSub from 'components/organizations/credentials/jwt-oidc-sub';

const prefix = 'settings.credentials.azure';

const AzureOidcInputs = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.AZURE_OIDC_DOCS;

  return (
    <>
      <Link messageId={`${prefix}.oidc-explanation`} url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.subscriptionId` }} name="value.subscriptionId" form={form} />
      <ControlledTextInput label={{ id: `${prefix}.tenantId` }} name="value.tenantId" form={form} />
      <ControlledTextInput label={{ id: `${prefix}.clientId` }} name="value.clientId" form={form} />
      <JwtOidcSub form={form} />
    </>
  );
};

export default AzureOidcInputs;
