import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useStores from 'hooks/use-stores.hooks';
import Button from 'components/common/button';
import { Label } from 'components/common/form-components';
import TextInput from 'components/common/input-components/text-input';
import Modal from 'components/common/modal';
import { useModal } from 'hooks/modal.hooks';

export const messagePrefix = 'organization.settings.teams';

interface AddTeamModalProps {
  marginTop?: string;
}

const AddTeamModal: React.FC<AddTeamModalProps> = ({ marginTop, ...restProps }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isModalOpen, hideModal, showModal } = useModal();

  const { teamsStore, organizationsStore } = useStores();
  const { teams } = teamsStore;
  const { currentOrganizationId } = organizationsStore;

  const resetToDefaultState = useCallback(() => {
    setName('');
    setDescription('');
  }, []);

  const closeModal = useCallback(() => {
    resetToDefaultState();
    hideModal();
  }, [resetToDefaultState, hideModal]);

  const onNameChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setName(value);
  };

  const onDescriptionChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(value);
  };

  const onCreateClick = async () => {
    setLoading(true);

    try {
      const newTeam = await teamsStore.createTeam({ name, description, organizationId: currentOrganizationId! });
      navigate(`/organizations/${currentOrganizationId}/teams/${newTeam.id}`);
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const getDisabledStatus = () => !name || loading;

  const modalFooter = (
    <>
      <Button data-e2e="cancel-team-button" onClick={closeModal} disabled={loading}>
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        data-e2e="add-team-button"
        isLoading={loading}
        type="primary"
        onClick={onCreateClick}
        disabled={getDisabledStatus()}>
        <FormattedMessage id="addTeam" />
      </Button>
    </>
  );

  const modalContent = (
    <>
      <Label id="name" />
      <TextInput data-e2e="team-name-input" value={name} onChange={onNameChange} />
      <Label id="description" />
      <TextInput data-e2e="team-desc-input" value={description} onChange={onDescriptionChange} />
    </>
  );

  return (
    <>
      <Button
        data-e2e="add-team-btn"
        id="add-team-btn"
        addPlus
        type="primary"
        onClick={showModal}
        style={{ marginTop }}>
        <FormattedMessage id="addTeam" />
      </Button>
      <Modal
        {...restProps}
        open={isModalOpen}
        width={600}
        onCancel={closeModal}
        titleId="addTeam"
        footer={modalFooter}
        destroyOnClose
        centered>
        {modalContent}
      </Modal>
    </>
  );
};

export default observer(AddTeamModal);
