import React, { useState } from 'react';
import styled from 'types/theme.types';
import { SyncOutlined } from '@ant-design/icons';
import Tooltip from 'components/common/tooltip';
import { Button } from 'antd';

const DEFAULT_ICON_SIZE = 32;

export interface RefreshEntitiesProps {
  toolTipMsgId: string;
  onRefresh: () => Promise<any>;
  iconSize?: number;
  spin?: boolean;
  disabled?: boolean;
}

const RefreshEntities: React.FunctionComponent<RefreshEntitiesProps> = ({
  toolTipMsgId,
  onRefresh,
  iconSize = DEFAULT_ICON_SIZE,
  spin,
  disabled
}) => {
  const [updating, setUpdating] = useState(false);

  const onClickHandler = async () => {
    setUpdating(true);
    try {
      await onRefresh();
    } finally {
      setUpdating(false);
    }
  };

  return (
    <StyledButton type="link" onClick={onClickHandler} disabled={updating || disabled} data-e2e="refresh-button">
      <Tooltip titleId={toolTipMsgId} placement="topRight">
        <RefreshIcon spin={updating || spin} size={iconSize} />
      </Tooltip>
    </StyledButton>
  );
};

const RefreshIcon = styled(SyncOutlined)<{ size: number }>`
  font-size: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  color: ${({ theme }) => theme.primaryBlue};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &[disabled] {
    pointer-events: none;
  }
`;
export const StyledButton = styled(Button)`
  border: 1px;
  display: flex;
  align-items: center;

  &[disabled] {
    cursor: auto;
  }
`;
export default RefreshEntities;
