import React, { type Key, type ReactNode, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tree, type TreeProps } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import type { StyledComponent } from '@emotion/styled';
import values from 'lodash/values';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { ParentHeight } from 'components/layout/navbar/navbar-panel.utils';
import { useCloseOnMobile } from 'components/layout/navbar/navbar-panel.hooks';
import {
  type AddProjectNode,
  addProjectTreeNode,
  buildProjectsTreeData,
  getKeyPath,
  getSearchExpandedKeys,
  NODE_HEIGHT,
  NodeType,
  type ProjectNode
} from 'components/layout/navbar/navbar-projects-tree.utils';
import { useCuratedProjects } from 'stores/rq/projects';
import useIsMobile from 'hooks/use-is-mobile';
import { getRedirectUrlForProject } from 'utils/sub-project.utils';
import styled from 'types/theme.types';

interface Props {
  activeProjectId?: string;
  search: string | null;
  canCreateProject: boolean;
  onCreateProject: (parentProjectId?: string) => void;
}

const ProjectsTree = ({ activeProjectId, search, canCreateProject, onCreateProject }: Props) => {
  const { activeRootProjects, activeProjects, isFetched } = useCuratedProjects();
  const deferredSearch = useDeferredValue(search?.trim());
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const closeOnMobile = useCloseOnMobile();
  const { isMobile } = useIsMobile();

  const treeData = useMemo(
    () => buildProjectsTreeData(values(activeRootProjects), deferredSearch),
    [activeRootProjects, deferredSearch]
  );

  const treeDataWithAddButton = useMemo(
    () => compact([...treeData, canCreateProject && addProjectTreeNode]),
    [treeData, canCreateProject]
  );

  useEffect(() => {
    deferredSearch && setExpandedKeys(getSearchExpandedKeys(treeData));
  }, [treeData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFetched && activeProjectId) {
      const newKeys = getKeyPath(activeProjects, activeProjectId);
      setExpandedKeys(keys => uniq([...keys, ...newKeys]));
    }
  }, [isFetched, activeProjectId]); // eslint-disable-line react-hooks/exhaustive-deps

  return !isFetched ? null : (
    <ParentHeight bypass={isMobile} fallbackHeight={NODE_HEIGHT * 3}>
      {parentHeight => (
        <StyledTree
          data-e2e="navbar-projects-tree"
          blockNode
          height={parentHeight}
          treeData={treeDataWithAddButton}
          expandAction="click"
          switcherIcon={<DownOutlined data-e2e="navbar-projects-tree-switcher" />}
          expandedKeys={expandedKeys}
          onExpand={setExpandedKeys}
          selectedKeys={compact([activeProjectId])}
          titleRender={node => {
            if (node.type === NodeType.AddProject) {
              return (
                <TitleContainer data-e2e="navbar-projects-add" onClick={() => onCreateProject()}>
                  <StyledProjectPlusIcon />
                  <span>{node.title}</span>
                </TitleContainer>
              );
            }
            const { project, disabled, renderedTitle } = node;
            const wrapLink = (node: ReactNode) =>
              disabled ? (
                node
              ) : (
                <StyledLink
                  to={getRedirectUrlForProject(project)}
                  onClick={e => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(getRedirectUrlForProject(project), '_blank');
                    }
                    closeOnMobile();
                  }}>
                  {node}
                </StyledLink>
              );
            return wrapLink(
              <TitleContainer data-e2e="navbar-projects-tree-item-title">
                <Title>{renderedTitle}</Title>
                {canCreateProject && !disabled && (
                  <StyledSubProjectPlusIcon
                    data-e2e="navbar-projects-tree-item-add"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      onCreateProject(project.id);
                    }}
                  />
                )}
              </TitleContainer>
            );
          }}
        />
      )}
    </ParentHeight>
  );
};

export default ProjectsTree;

const StyledTree = styled(Tree)`
  --node-height: ${NODE_HEIGHT}px;
  background: none;
  color: rgba(255, 255, 255, 0.8);

  .ant-tree-list-scrollbar {
    width: 9px !important;

    &.ant-tree-list-scrollbar-show {
      // force scrollbar to show
      display: block !important;
    }

    .ant-tree-list-scrollbar-thumb {
      background-color: ${({ theme }) => theme.sideMenuText} !important;
      border-radius: 10px !important;
    }
  }

  .ant-tree-treenode {
    height: var(--node-height);
    padding: 0;
    align-items: center;
    transition: background 120ms ease-in-out;

    &:not(.navbar-projects-tree-item-add):hover {
      background: rgba(255, 255, 255, 0.02);
    }

    &.ant-tree-treenode-selected {
      background: ${({ theme }) => theme.menuItemBlue};
    }

    .ant-tree-indent {
      margin-left: 8px;

      .ant-tree-indent-unit {
        width: 20px;
      }
    }

    .ant-tree-switcher {
      align-self: center;

      .ant-tree-switcher-icon {
        font-size: 0.7rem;
        vertical-align: middle;
      }
    }

    .ant-tree-node-content-wrapper {
      min-width: 0;
      background: none !important;
      padding-right: 0;
    }

    &.navbar-projects-tree-item-add {
      color: ${({ theme }) => theme.textMetalBlue};
      opacity: 0.8;
      transition: opacity 0.1s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }
  }
` as StyledComponent<TreeProps<ProjectNode | AddProjectNode>>;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--node-height);
  gap: 8px;
  padding-right: 20px;
`;

const Title = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.8);
  transition: font-weight 0.1s ease-in-out, color 0.1s ease-in-out;

  > b {
    /* Used for search highlighting */
    color: ${({ theme }) => theme.cyan};
    background-color: ${({ theme }) => theme.menuItemBlueActive};
  }

  .ant-tree-treenode-switcher-open & {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
  }

  .ant-tree-treenode-selected & {
    color: ${({ theme }) => theme.cyan};
    font-weight: 600;
  }
`;

const StyledLink = styled(Link)`
  color: inherit !important;
`;

const StyledProjectPlusIcon = styled(PlusOutlined)`
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSubProjectPlusIcon = styled(PlusOutlined)`
  flex-shrink: 0;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, color 0.1s ease-in-out;

  .ant-tree-treenode:hover & {
    opacity: 0.8;

    &:hover {
      color: ${({ theme }) => theme.menuItemGreen};
      opacity: 1;
    }
  }
`;
