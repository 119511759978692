import React, { type PropsWithChildren } from 'react';
import Tooltip, { type TooltipProps } from 'components/common/tooltip';

type TooltipWrapperProps<T> = T & {
  tooltip?: TooltipProps;
};

const withTooltip = function <T>(Component: React.FC<T>) {
  const TooltippedComponent: React.ForwardRefRenderFunction<any, TooltipWrapperProps<T>> = (
    { tooltip, ...props },
    ref
  ) => {
    const element = <Component {...(props as PropsWithChildren<T>)} ref={ref} />;
    if (!tooltip) return element;
    return (
      <Tooltip {...tooltip}>
        <span>{element}</span>
      </Tooltip>
    );
  };

  // Forward the ref to the wrapped component
  return React.forwardRef(TooltippedComponent);
};
export default withTooltip;
