import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RightOutlined } from '@ant-design/icons';
import styled from 'types/theme.types';
import type { BreadcrumbSelector } from 'components/breadcrumbs/use-breadcrumbs';
import useBreadcrumbs from 'components/breadcrumbs/use-breadcrumbs';
import { BREADCRUMB_BAR_HEIGHT } from 'constants/themes.constants';

const Crumb: React.FunctionComponent<{ selector?: BreadcrumbSelector }> = ({ selector }) => {
  const breadcrumbs = useBreadcrumbs(selector);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Wrapper data-e2e="breadcrumbs">
      {breadcrumbs.map((crumb, index) => {
        const isNotLast = index < breadcrumbs.length - 1;
        const content = <BreadcrumbText>{crumb.name}</BreadcrumbText>;
        return (
          <Fragment key={index}>
            {!crumb.to ? (
              <Breadcrumb as={InaccessibleLink}>{content}</Breadcrumb>
            ) : (
              <Breadcrumb as={BreadcrumbLink} {...{ to: crumb.to }}>
                {content}
              </Breadcrumb>
            )}
            {isNotLast && (
              <Breadcrumb>
                <Divider />
              </Breadcrumb>
            )}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 0 0.8rem;
  flex-flow: row wrap;
  min-width: 0;
`;

export const Breadcrumb = styled.div`
  height: ${BREADCRUMB_BAR_HEIGHT};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textGray};
  min-width: 0;
`;

export const BreadcrumbText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

const InaccessibleLink = styled.div`
  cursor: not-allowed;
`;

const BreadcrumbLink = styled(Link)`
  color: ${({ theme }) => theme.textBaseGray} !important;
`;

const Divider = styled(RightOutlined)`
  color: ${({ theme }) => theme.lightGray};
  font-size: 0.8rem;
`;

export default observer(Crumb);
