import { type ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { GroupBySettings, GroupedRowType } from 'utils/table-group-by.utils';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import type { ConfigurationProperty } from 'types/api.types';
import { SetVariablesGroupHeader } from 'components/settings/set-variables-group-header';
import { SensitiveAndReadonlyVariablesGroupHeader } from 'components/settings/sensitive-and-readonly-variables-group-header';

const variableGroups = ['sensitiveOrReadonly', 'set'] as const;
export type VariableGroup = (typeof variableGroups)[number];

const sensitiveOrReadonlyGroupId = 'sensitiveOrReadonly';

const isSensitiveOrReadonly = (configurationProperty: ConfigurationProperty) =>
  configurationProperty.isSensitive || configurationProperty.isReadonly;
const isSet = (configurationProperty: ConfigurationProperty): boolean =>
  configurationProperty.scope === ConfigurationScope.SET ||
  (!!configurationProperty.overwrites && isSet(configurationProperty.overwrites as ConfigurationProperty));

const getSetIdFromConfiguration = (configurationProperty?: ConfigurationProperty): string | undefined =>
  configurationProperty
    ? configurationProperty.scope === ConfigurationScope.SET
      ? configurationProperty.scopeId
      : getSetIdFromConfiguration(configurationProperty?.overwrites as ConfigurationProperty)
    : undefined;

const getRowGroup = (row: ConfigurationPropertyStore, allowedGroups: VariableGroup[]) =>
  allowedGroups.includes('set') && isSet(row.data)
    ? `set-${getSetIdFromConfiguration(row.data)}`
    : allowedGroups.includes('sensitiveOrReadonly') && isSensitiveOrReadonly(row.data) && !row.isNew
    ? sensitiveOrReadonlyGroupId
    : '';

const groupToParentRowRenderer: GroupBySettings<ConfigurationPropertyStore>['getParentRowRenderer'] = record => {
  if (record?.id?.startsWith('set')) {
    return <SetVariablesGroupHeader record={record} />;
  } else if (record?.id === sensitiveOrReadonlyGroupId) {
    return <SensitiveAndReadonlyVariablesGroupHeader record={record} />;
  }
};

export const getVariablesGroupBySettings = (
  // @ts-ignore
  groups: VariableGroup[] = variableGroups
): GroupBySettings<ConfigurationPropertyStore> => ({
  groupBy: (row: ConfigurationPropertyStore) => getRowGroup(row, groups),
  createParentRow: (group: string) => ({
    id: group
  }),
  getParentRowRenderer: record => groupToParentRowRenderer(record)
});

export function sortGroups<RowType>(a: GroupedRowType<RowType>, b: GroupedRowType<RowType>): number {
  if (a.id === sensitiveOrReadonlyGroupId) {
    return -1;
  }
  if (b.id === sensitiveOrReadonlyGroupId) {
    return 1;
  }
  return a.id.localeCompare(b.id);
}
