import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'types/theme.types';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
interface InfoProps {
  style?: Omit<CSSProperties, 'textDecorationSkipInk' | 'transformBox'>;
  tooltip?: string | React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Info: React.FunctionComponent<InfoProps> = ({ tooltip, style, className, onClick }) => (
  <Tooltip title={tooltip} className={className}>
    <StyledIcon data-e2e="info-icon" onClick={onClick} style={style} />
  </Tooltip>
);

const StyledIcon = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.secondaryBlue};
`;

export default Info;
