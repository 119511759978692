import { getSchedule, stringToArray } from 'cron-converter';
import type { TestContext } from 'yup';
import dayjs from 'dayjs';

export const CRON_MISSING_ERROR = 'environment.scheduling.error.cron.missing';
export const ZERO_SLASH_ERROR = 'environment.scheduling.error.cron.syntax.zero-slash';
export const CRON_PARSE_ERROR = 'environment.scheduling.error.cron.syntax';
export const CRON_INTERVAL_ERROR = 'environment.scheduling.error.cron.interval';

export const getCronError = (value?: string, checkInterval = false) => {
  value = value || '';
  if (!value) {
    return null;
  }
  const cronParts = value.split(' ');
  if (cronParts.some(part => part.startsWith('0/'))) {
    return ZERO_SLASH_ERROR;
  }
  let cron;
  try {
    cron = stringToArray(value);
  } catch {
    return CRON_PARSE_ERROR;
  }
  if (checkInterval) {
    const schedule = getSchedule(cron, new Date());
    const firstRun = dayjs(schedule.next().toJSDate());
    const secondRun = dayjs(schedule.next().toJSDate());
    if (secondRun.diff(firstRun, 'hours') < 1) {
      return CRON_INTERVAL_ERROR;
    }
  }
  return null;
};

export const validateCron =
  (checkInterval = false) =>
  (value?: string, context?: TestContext) => {
    const error = getCronError(value, checkInterval);
    return !error ? true : context?.createError({ message: error }) ?? false;
  };
