import { Bold } from 'components/common/modal';
import PopupEntity from 'components/common/popup-entity';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/button';
import { useMoveProject } from 'stores/rq/projects';
import SelectedTreeProjects from 'components/common/selected-tree-projects';
import { ROOT_KEY } from 'components/common/utils/map-items-to-antd-tree-nodes';

export const MoveProjectButton = ({ id, name }: { id: string; name: string }) => {
  const [selectedTargetProject, setSelectedTargetProject] = useState<string | null>(null);

  const moveProjectButton = (
    <Button type="primary" data-e2e="move-project-button">
      <FormattedMessage id="move.button" />
    </Button>
  );

  const projectSelect = (
    <SelectedTreeProjects
      withRoot
      selectedProjects={selectedTargetProject ? [selectedTargetProject] : []}
      onChange={checkedProjectIds =>
        setSelectedTargetProject(
          checkedProjectIds.filter((checkedValue: any) => checkedValue !== selectedTargetProject)[0] ?? null
        )
      }
      withCheckAll={false}
      disabledProjects={[id]}
      data-e2e="project-select-popup-tree"
    />
  );

  const { mutateAsync: handleMove } = useMoveProject();

  return (
    <PopupEntity
      iconComponent={moveProjectButton}
      approveDisabled={!selectedTargetProject}
      onApprove={async () => {
        await handleMove({ id, targetProjectId: selectedTargetProject === ROOT_KEY ? null : selectedTargetProject });
      }}
      popupTitleId="projects.settings.move.title"
      popupContent={projectSelect}
      popupTitleVariables={{ name: <Bold>{name}</Bold> }}
      popupOkTextId="move"
      approveButtonType="primary"
      popupCancelTextId="cancel"
    />
  );
};
