import React, { type MouseEventHandler, useCallback } from 'react';
import type { CustomButtonProps } from 'components/common/button';
import Button from 'components/common/button';
import type { TrackingData } from 'utils/analytics.utils';
import { track } from 'utils/analytics.utils';

export interface ButtonTrackingProps extends CustomButtonProps {
  trackingData: TrackingData;
}

const TrackbleButton: React.FunctionComponent<ButtonTrackingProps> = ({ trackingData, onClick, ...properties }) => {
  const onClickHandler = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      track(trackingData.eventName, trackingData.eventProperties || {});
      onClick?.(event);
    },
    [trackingData.eventName, trackingData.eventProperties, onClick]
  );

  return <Button onClick={onClickHandler} {...properties} />;
};

export default TrackbleButton;
