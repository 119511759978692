import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import { validateCron } from 'components/environments/helpers/cronValidationHelper';
import type { RunEnvironmentArgs } from 'utils/environment.utils';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { TargetsSelection } from 'components/environments/targeted-deployments/common';
import { targetsSelectionValidationError } from 'components/environments/targeted-deployments/common';
import type { EnvironmentApi, WorkflowOperations } from '@env0/environment-service/api';

export type DeployValues = Pick<
  RunEnvironmentArgs,
  | 'environmentName'
  | 'workspaceName'
  | 'terragruntWorkingDirectory'
  | 'blueprintRevision'
  | 'continuousDeployment'
  | 'comment'
  | 'pullRequestPlanDeployments'
  | 'approveAutomatically'
  | 'driftDetectionRequest'
  | 'isRemoteBackend'
  | 'subEnvironments'
  | 'isWorkflowFileValid'
  | 'k8sNamespace'
  | 'workflowDeploymentOptions'
> & {
  workflowEnvironment?: string;
  targetsSelection?: TargetsSelection;
  templateId: string;
};

const validateTargetsSelection = (targetsSelection?: TargetsSelection): boolean => {
  if (!targetsSelection) {
    return true;
  }
  return !targetsSelectionValidationError(targetsSelection);
};

const allWorkflowOperations: WorkflowOperations = ['run-from-here', 'single-node-deploy', 'single-node-destroy'];

export const workspaceNameRegexValidator = /^[0-9a-zA-Z_-]*$/;
export const workspaceNameRegexError = 'environment.errors.workspace-name-invalid';
export const workspaceRequiredError = 'environment.errors.workspace-required';

type FormDeploySchema = DeployValues & { showCDCheckboxes: boolean };

function createDeploySchema(isHelm: boolean): SchemaOf<FormDeploySchema> {
  const workspaceName = isHelm
    ? { regex: /^[0-9a-zA-Z-]*$/, errorMessage: 'environment.errors.helm-release-name-invalid' }
    : { regex: workspaceNameRegexValidator, errorMessage: workspaceNameRegexError };

  return Yup.object({
    environmentName: Yup.string().default('').required(),
    blueprintRevision: Yup.string().optional(),
    comment: Yup.string().optional(),
    continuousDeployment: Yup.boolean().default(false),
    pullRequestPlanDeployments: Yup.boolean().default(false),
    approveAutomatically: Yup.boolean().default(false).required(),
    driftDetectionRequest: Yup.object({
      enabled: Yup.boolean().default(false),
      cron: Yup.string().when('enabled', {
        is: true,
        then: Yup.string().default('').required('validation.required').test(validateCron()),
        otherwise: Yup.string()
      })
    }),
    isRemoteBackend: Yup.boolean(),
    isHelm: Yup.boolean().default(isHelm),
    workspaceName: Yup.string()
      .optional()
      .max(90)
      .matches(workspaceName.regex, workspaceName.errorMessage)
      .when('isRemoteBackend', {
        is: true,
        then: Yup.string().required('remote-state.workspace-name.validation')
      }),
    terragruntWorkingDirectory: Yup.string().optional(),
    workflowEnvironment: Yup.string().optional(),
    subEnvironments: Yup.object().when('isRemoteBackend', isRemoteBackend => {
      return Yup.lazy(subEnvironments => {
        const workspaceNameSchema = Yup.string().max(90).matches(workspaceName.regex, workspaceName.errorMessage);

        return Yup.object(
          Object.keys(subEnvironments ?? {}).reduce((subEnvironmentsSchema: Record<string, Yup.AnySchema>, alias) => {
            subEnvironmentsSchema[alias] = Yup.object({
              workspaceName: workspaceNameSchema
            });

            return subEnvironmentsSchema;
          }, {})
        );
      });
    }),
    isWorkflowFileValid: Yup.boolean()
      .default(true)
      .test({ test: val => val }),
    k8sNamespace: Yup.string().optional(),
    targetsSelection: Yup.object()
      .shape<Record<keyof TargetsSelection, Yup.AnySchema>>({
        selectionMode: Yup.string().default('all').required(),
        selectedTargets: Yup.array().of(Yup.string()).optional().default(undefined)
      })
      .optional()
      .test(validateTargetsSelection),
    workflowDeploymentOptions: Yup.object()
      .shape<Record<keyof EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'], Yup.AnySchema>>({
        node: Yup.string().required(),
        operation: Yup.string()
          .oneOf([...allWorkflowOperations])
          .required()
      })
      .optional()
      .default(undefined),
    templateId: Yup.string().required(),
    showCDCheckboxes: Yup.boolean().required()
  }).defined();
}

const helmDeploySchema = createDeploySchema(true);
const nonHelmDeploySchema = createDeploySchema(false);

export function getDeploySchema(blueprintType?: BlueprintApi.BlueprintType): SchemaOf<FormDeploySchema> {
  const isHelm = blueprintType === 'helm';
  return isHelm ? helmDeploySchema : nonHelmDeploySchema;
}
