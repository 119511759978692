import { useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = { orgId, entity: 'agent-assignment' } as const;

  return {
    byProjectId: (projectId: string) => [{ ...baseKey, projectId }]
  };
};

export const useGetProjectAgent = ({ projectId, enabled }: { projectId: string; enabled: boolean }) => {
  const apiClient = useApiClient();
  const cacheKeys = useCacheKeys();

  return useQuery({
    enabled,
    queryKey: cacheKeys.byProjectId(projectId),
    queryFn: ({ queryKey: [{ projectId }] }) => {
      return apiClient.agents.getProjectAgentById(projectId);
    },
    staleTime: Infinity
  });
};
