import BaseService from 'services/base-service';
import { action, computed, observable } from 'mobx';
import type { ConfigurationProperty, ConfigurationValueType, VariablesPolicies } from 'types/api.types';
import { ConfigurationScope, ConfigurationType } from 'types/api.types';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';
import forEach from 'lodash/fp/forEach';
import { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { GetConfigurationPropertiesByScopeRequest } from 'services/api-client/configurations';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { EnvironmentApi } from '@env0/environment-service/api';
import isEmpty from 'lodash/isEmpty';
import type { ConfigurationSetAssignmentScope } from '@env0/configuration-service/api';

interface ConfigurationsScope {
  scope?: ConfigurationScope;
  scopeId?: string;
  organizationId?: string;
}

export type QueryScope = Partial<GetConfigurationPropertiesByScopeRequest> & {
  organizationId?: string;
};

export const configurationTypeToSupportedBlueprintTypes: Partial<
  Record<ConfigurationType, BlueprintApi.BlueprintType[]>
> = {
  [ConfigurationType.TERRAFORM_VARIABLE]: ['opentofu', 'terraform', 'terragrunt', 'module']
};

export class ConfigurationPropertiesStore extends BaseService {
  private configurations: ConfigurationProperty[] = [];
  configurationsScope: ConfigurationsScope = {};
  private scopes: QueryScope = {};
  variablesPolicies: VariablesPolicies = {
    allowSaveSensitive: true,
    allowSsmReference: { allowed: true }
  };

  @observable
  configurationSetChanges: EnvironmentApi.ConfigurationSetChanges = { assign: [], unassign: [] };

  @action
  resetConfigurationSetChanges() {
    this.configurationSetChanges = { assign: [], unassign: [] };
  }

  @action
  updateConfigurationSetChanges(changes: EnvironmentApi.ConfigurationSetChanges) {
    this.configurationSetChanges = changes;
  }

  @computed get configurationSetIsDirty() {
    return !isEmpty(this.configurationSetChanges.assign) || !isEmpty(this.configurationSetChanges.unassign);
  }

  @action
  async saveConfigurationSetChanges(scopeId: string, scope: ConfigurationSetAssignmentScope) {
    if (!isEmpty(this.configurationSetChanges.unassign)) {
      await this.service.apiClient.configurations.unassignSets(scope, scopeId, this.configurationSetChanges.unassign!);
    }
    if (!isEmpty(this.configurationSetChanges.assign)) {
      await this.service.apiClient.configurations.assignSets(scope, scopeId, this.configurationSetChanges.assign!);
    }
    this.resetConfigurationSetChanges();
  }

  @observable
  private configurationByType: Record<string, ConfigurationPropertyStore[]> = {};

  @action
  async getDeploymentScopeVariables(deploymentLogId: string): Promise<ConfigurationProperty[]> {
    const organizationId = this.service.organizationsStore.currentOrganizationId as string;
    const variables = await this.service.apiClient.configurations.getConfigurationPropertiesByScope({
      deploymentLogId,
      organizationId
    });
    return variables.filter(variable => variable.scope === ConfigurationScope.DEPLOYMENT);
  }

  @action
  async loadProperties(
    { scopeId, scope, ...scopes }: QueryScope & ConfigurationsScope,
    runValidate = false,
    blueprintType?: BlueprintApi.BlueprintType,
    variablesPolicies?: Partial<VariablesPolicies>
  ) {
    const organizationId = this.service.organizationsStore.currentOrganizationId as string;
    this.configurationsScope = {
      scope,
      scopeId,
      organizationId
    };
    this.scopes = {
      ...scopes,
      organizationId
    };
    this.variablesPolicies = {
      ...this.variablesPolicies,
      ...variablesPolicies
    };

    await this.loadConfigurationFromServer(blueprintType);

    this.resetChanges(ConfigurationType.TERRAFORM_VARIABLE);
    this.resetChanges(ConfigurationType.ENVIRONMENT_VARIABLE);

    [
      ...this.configurationByType[ConfigurationType.ENVIRONMENT_VARIABLE],
      ...this.configurationByType[ConfigurationType.TERRAFORM_VARIABLE]
    ]
      .filter(store => store.initialScope === ConfigurationScope.DEPLOYMENT)
      .forEach(store => (store.isUpdated = true));

    if (runValidate) this.validateAll();
  }

  private async loadConfigurationFromServer(blueprintType?: BlueprintApi.BlueprintType) {
    if (this.configurationsScope.scope === ConfigurationScope.SET && !this.configurationsScope.scopeId) {
      this.configurations = [];
      return;
    }

    const allConfigurations = await this.service.apiClient.configurations.getConfigurationPropertiesByScope(
      this.scopes as GetConfigurationPropertiesByScopeRequest
    );

    if (!blueprintType) {
      this.configurations = allConfigurations;
    } else {
      this.configurations = allConfigurations.filter(({ type }) => {
        const supportedBlueprintTypes = configurationTypeToSupportedBlueprintTypes[type as ConfigurationType];
        return !supportedBlueprintTypes || supportedBlueprintTypes.includes(blueprintType);
      });
    }
  }

  @action
  resetChanges(type: ConfigurationType) {
    this.configurationByType[type] = map(
      (data: ConfigurationProperty) => new ConfigurationPropertyStore(this, data),
      filter(['type', type], this.configurations)
    );
  }

  @action
  createConfigurationProperty(type: ConfigurationType, valueType: ConfigurationValueType) {
    const property = ConfigurationPropertyStore.createEmptyNew(this, type, valueType);
    this.addConfigurationProperty(property);
  }

  @action
  createConfigurationPropertyIfNotExists(props: Partial<ConfigurationProperty>) {
    const property = ConfigurationPropertyStore.createNew(this, { ...props, scope: this.configurationsScope.scope });

    if (this.validateNameNotUnique(property)) {
      return;
    }

    this.addConfigurationProperty(property);
  }

  validateNameNotUnique(config: ConfigurationPropertyStore) {
    const nameEqual = (row: ConfigurationPropertyStore) => row.id !== config.id && row.name === config.name;
    return some(nameEqual, filter('isShow', this.configurationByType[config.type!]));
  }

  @action
  addConfigurationProperty(row: ConfigurationPropertyStore) {
    this.configurationByType[row.type]?.push(row);
  }

  @action
  async save(type: ConfigurationType) {
    await this.deleteConfigurationPropertiesInServer(type);
    await this.updateConfigurationPropertiesInServer(type);

    await this.loadConfigurationFromServer();
    this.resetChanges(type);
  }

  @action
  async saveAll() {
    await Promise.all([
      this.deleteConfigurationPropertiesInServer(ConfigurationType.ENVIRONMENT_VARIABLE),
      this.deleteConfigurationPropertiesInServer(ConfigurationType.TERRAFORM_VARIABLE)
    ]);
    await Promise.all([
      this.updateConfigurationPropertiesInServer(ConfigurationType.ENVIRONMENT_VARIABLE),
      this.updateConfigurationPropertiesInServer(ConfigurationType.TERRAFORM_VARIABLE)
    ]);
  }

  @action
  validateAll() {
    forEach(row => row.runValidation(), this.configurationByType[ConfigurationType.TERRAFORM_VARIABLE]);
    forEach(row => row.runValidation(), this.configurationByType[ConfigurationType.ENVIRONMENT_VARIABLE]);
  }

  updateConfigurationScope(scope: ConfigurationsScope) {
    this.configurationsScope = { ...this.configurationsScope, ...scope };
  }

  private async updateConfigurationPropertiesInServer(type: ConfigurationType) {
    const propertyStores = this.findConfigurationPropertiesForUpdate(type);
    if (!propertyStores.length) return;
    await this.service.apiClient.configurations.createOrUpdateConfigurationProperty(
      propertyStores.map(this.populateConfigurationProperty.bind(this)),
      this.service.organizationsStore.currentOrganizationId!
    );
  }

  private findConfigurationPropertiesForUpdate(type: ConfigurationType) {
    return this.configurationByType[type]?.filter(data => !data.markToDelete && data.isUpdated) ?? [];
  }

  private populateConfigurationProperty(propertyStore: ConfigurationPropertyStore) {
    const property = propertyStore.data;
    return {
      ...property,
      name: property.name.trim(),
      ...this.configurationsScope,
      overwrites: undefined,
      value: propertyStore.isFreeText() ? property.value.trim() : property.value
    };
  }

  private async deleteConfigurationPropertiesInServer(type: ConfigurationType) {
    const idsToDelete = map('id', this.findConfigurationPropertiesForDelete(type));
    if (!idsToDelete.length) return;
    await Promise.all(idsToDelete.map(id => this.service.apiClient.configurations.deleteConfigurationProperty(id)));
  }

  private findConfigurationPropertiesForDelete(type: ConfigurationType) {
    return filter(data => data.markToDelete, this.configurationByType[type] as ConfigurationPropertyStore[]);
  }

  @computed get terraformVariables() {
    return this.findConfigurationsByType(ConfigurationType.TERRAFORM_VARIABLE);
  }

  @computed get environmentVariables() {
    return this.findConfigurationsByType(ConfigurationType.ENVIRONMENT_VARIABLE);
  }
  @computed get allVariablesIncludingDeleted() {
    return [
      ...this.configurationByType[ConfigurationType.ENVIRONMENT_VARIABLE],
      ...this.configurationByType[ConfigurationType.TERRAFORM_VARIABLE]
    ];
  }

  @computed get terraformVariablesIsDirty() {
    return some(row => !!row.isUpdated, this.configurationByType[ConfigurationType.TERRAFORM_VARIABLE]);
  }

  @computed get environmentVariablesIsDirty() {
    return some(row => !!row.isUpdated, this.configurationByType[ConfigurationType.ENVIRONMENT_VARIABLE]);
  }

  @computed get terraformVariablesHasError() {
    return some(row => !!row.error, this.findConfigurationsByType(ConfigurationType.TERRAFORM_VARIABLE));
  }

  @computed get environmentVariablesHasError() {
    return some(row => !!row.error, this.findConfigurationsByType(ConfigurationType.ENVIRONMENT_VARIABLE));
  }

  @computed get hasError() {
    return some(
      row => !!row.error,
      [
        ...this.findConfigurationsByType(ConfigurationType.TERRAFORM_VARIABLE),
        ...this.findConfigurationsByType(ConfigurationType.ENVIRONMENT_VARIABLE)
      ]
    );
  }

  getConfigurationPropertiesChangesForDeployment(): ConfigurationProperty[] {
    const updatedConfiguration = map(
      (row: ConfigurationPropertyStore) => {
        const newData = { ...row.data };

        if (row.isNew) {
          delete newData.id;
        }
        return newData;
      },
      [
        ...this.findConfigurationPropertiesForUpdate(ConfigurationType.TERRAFORM_VARIABLE),
        ...this.findConfigurationPropertiesForUpdate(ConfigurationType.ENVIRONMENT_VARIABLE)
      ]
    );

    const deletedConfiguration: ConfigurationProperty[] = map(
      (row: ConfigurationPropertyStore) => ({ ...row.data, toDelete: true }),
      [
        ...this.findConfigurationPropertiesForDelete(ConfigurationType.TERRAFORM_VARIABLE),
        ...this.findConfigurationPropertiesForDelete(ConfigurationType.ENVIRONMENT_VARIABLE)
      ] as ConfigurationPropertyStore[]
    );

    // Update takes precedence over delete
    // So, we filter out configurations deletions when they have another active change under the same name & scope
    // It happens when deleting an existing configuration and then recreating it with the same name
    const deletedConfigurationWithNoUpdate = deletedConfiguration.filter(
      deletedConfig =>
        !updatedConfiguration.some(
          updatedConfig => updatedConfig.name === deletedConfig.name && updatedConfig.scope === deletedConfig.scope
        )
    );

    return [...deletedConfigurationWithNoUpdate, ...updatedConfiguration];
  }

  private findConfigurationsByType(type: ConfigurationType): ConfigurationPropertyStore[] {
    return filter('isShow', this.configurationByType[type] as any);
  }

  // used for workflow variables
  @computed get environmentTypeAndScopeConfigurationProperties() {
    const configurationPropertyStores = this.configurationByType[ConfigurationType.ENVIRONMENT_VARIABLE];
    return configurationPropertyStores?.filter(variable => variable.scope === ConfigurationScope.WORKFLOW) || [];
  }
}
