import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { SharedApi } from '@env0/shared/api';

export default (client: HttpClient) => ({
  getUserPermissions: (organizationId: string) =>
    client
      .get<SharedApi.Authorization.GetUserPermissions.Response>('permissions/user', {
        params: { organizationId }
      })
      .then(returnData)
});
