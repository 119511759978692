import type { Organization } from 'types/api.types';
import OrganizationIcon from 'assets/images/organization-icon.svg';
import OrganizationIconActive from 'assets/images/organization-icon-active.svg';

export const getOrganizationName = (organization?: Organization | null): string => {
  if (!organization) {
    return '';
  }

  return organization.name;
};

export const getOrganizationAvatar = (organization: Organization, active?: boolean) =>
  organization.photoUrl || (active ? OrganizationIconActive : OrganizationIcon);
