import React from 'react';
import Select, { SelectOption } from 'components/common/input-components/select';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/common/input-components/text-input';
import withLabel from 'components/common/form-controlled/with-label.hoc';
import { Col, Row } from 'antd';
import range from 'lodash/range';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import {
  RetryOptions,
  TIMES_DEFAULT_OPTION,
  TIMES_MAX_OPTION,
  useRetryField
} from 'components/templates/templates-wizard/settings/retry-field.hook';
import type { SettingsForm } from 'components/templates/templates-wizard/common/template-wizard.types';

type RetryFieldProps = SubFormProps<SettingsForm>;

const retrySelectOptions = Object.keys(RetryOptions).map(key => (
  <SelectOption key={key} value={key} data-e2e={`retry-type-select-${key}`}>
    <FormattedMessage id={`retry.options.${key}`} />
  </SelectOption>
));

const timesSelectOptions = range(1, TIMES_MAX_OPTION + 1).map(key => (
  <SelectOption key={key} value={key} data-e2e={`retry-times-select-${key}`}>
    {key}
  </SelectOption>
));

const RetryField: React.FC<RetryFieldProps> = ({ form }) => {
  const { retry, retryType, timesOption, errorRegex, onRetryTypeChange, onTimesOptionChange, onErrorRegexChange } =
    useRetryField(form);
  const { readonly } = form;

  return (
    <Row align="middle" justify="space-between">
      <Col span={8}>
        <Select
          data-e2e="retry-type-select"
          value={retryType}
          onChange={onRetryTypeChange}
          showArrow
          disabled={readonly}>
          {retrySelectOptions}
        </Select>
      </Col>

      {(retry?.onDeploy || retry?.onDestroy) && (
        <>
          <Col span={1} style={{ textAlign: 'center' }}>
            <FormattedMessage id="retry.retry" />
          </Col>

          <Col span={2}>
            <Select
              data-e2e="retry-times-select"
              defaultValue={TIMES_DEFAULT_OPTION}
              value={timesOption}
              disabled={readonly}
              onChange={onTimesOptionChange}>
              {timesSelectOptions}
            </Select>
          </Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            <FormattedMessage id="retry.times.match" />
          </Col>

          <Col span={9}>
            <TextInput
              data-e2e="retry-regex-input"
              hideError
              value={errorRegex as string}
              onChange={onErrorRegexChange}
              disabled={readonly}
              placeholder="retry.regex.placeholder"
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default withLabel(RetryField);
