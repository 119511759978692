import withController from 'components/common/form-controlled/with-controller.hoc';
import withLabel from 'components/common/form-controlled/with-label.hoc';
import withTooltip from 'components/common/form-controlled/with-tooltip.hoc';
import type { VcsRepositorySelectProps } from 'components/templates/templates-wizard/vcs/vcs-repository-selector';
import { VcsRepositorySelector } from 'components/templates/templates-wizard/vcs/vcs-repository-selector';

const ControlledVcsRepositorySelector = withLabel(
  withController<VcsRepositorySelectProps>(withTooltip(VcsRepositorySelector))
);

export default ControlledVcsRepositorySelector;
