import React from 'react';
import Table from 'components/common/table';
import styled from '@emotion/styled';
import EditIcon from 'components/common/edit-icon';
import Trash from 'components/common/trash';
import Button from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export type ConfigurationTemplateAssignment =
  | BlueprintApi.RichCustomFlowAssigment
  | BlueprintApi.ApprovalPolicyTemplateWithScope;

export type InheritedAssignmentsWithScope = ConfigurationTemplateAssignment & {
  inheritedFrom?: string | null;
};

export type TableRow = InheritedAssignmentsWithScope & {
  index: number;
  order?: number;
};

const ActionsColumn = ({
  onUpdate,
  onDelete,
  rowData,
  dataE2ePrefix
}: {
  onUpdate: (rowData: TableRow) => void;
  onDelete: (rowData: TableRow) => void;
  rowData: TableRow;
  dataE2ePrefix: BlueprintApi.ConfigurationTemplateType;
}) => {
  return (
    <ButtonsContainer>
      <StyledEditIcon onClick={() => onUpdate(rowData)} data-e2e={`modify-${dataE2ePrefix}-${rowData.index}`} />
      <StyledTrash onClick={() => onDelete(rowData)} data-e2e={`unassign-${dataE2ePrefix}-${rowData.index}`} />
    </ButtonsContainer>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

// https://ant.design/components/table#components-table-demo-drag-sorting-handler
const Row = ({ children, ...props }: RowProps) => {
  const convertedChildren = (children ?? []) as React.ReactNode[];
  const lastColumn = convertedChildren[convertedChildren.length - 1] as any;
  const isInheritedFrom = !!lastColumn?.props?.record?.inheritedFrom;

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, isDragging } = useSortable({
    id: props['data-row-key'],
    disabled: isInheritedFrom
  });
  const style: React.CSSProperties = {
    ...props.style,
    ...(!isInheritedFrom ? { transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }) } : {}),
    ...(isInheritedFrom && isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort' && !isInheritedFrom) {
          return React.cloneElement(child as React.ReactElement, {
            children: <MenuOutlined ref={setActivatorNodeRef} style={{ cursor: 'move' }} {...listeners} />
          });
        }
        return child;
      })}
    </tr>
  );
};

type ConfigurationTemplatesTableProps = {
  dataE2ePrefix: BlueprintApi.ConfigurationTemplateType;
  assignments: ConfigurationTemplateAssignment[];
  inheritedAssignments?: InheritedAssignmentsWithScope[];
  onCreate: () => void;
  onUpdate: (row: TableRow) => void;
  onDelete: (row: TableRow) => void;
  onReorder?: (newRows: TableRow[]) => void;
  blueprintFieldName: BlueprintApi.ConfigurationTemplateBlueprintField;
};

const ConfigurationTemplatesTable: React.FC<ConfigurationTemplatesTableProps> = ({
  dataE2ePrefix,
  assignments,
  inheritedAssignments,
  onCreate,
  onUpdate,
  onDelete,
  onReorder,
  blueprintFieldName
}) => {
  const inheritedAssignmentsAmount = inheritedAssignments?.length ?? 0;

  const columns = [
    ...(onReorder
      ? [
          {
            key: 'sort',
            width: 50
          }
        ]
      : []),
    ...[
      {
        title: 'file.path',
        dataIndex: [blueprintFieldName, 'path']
      },
      {
        title: 'branch',
        render: (tableRow: TableRow) => (tableRow as any)?.[blueprintFieldName]?.revision || '<default-branch>'
      },
      {
        title: 'repository',
        dataIndex: [blueprintFieldName, 'repository']
      },
      {
        render: (assignment: TableRow) =>
          assignment?.inheritedFrom ? (
            <TagWrapper>
              <Tag className="policy-source-tag">
                <FormattedMessage id={`project.settings.policies.from`} />
                &nbsp;
                {assignment.inheritedFrom}
              </Tag>
            </TagWrapper>
          ) : (
            <ActionsColumn onUpdate={onUpdate} onDelete={onDelete} rowData={assignment} dataE2ePrefix={dataE2ePrefix} />
          )
      }
    ]
  ];

  const convertToTableRows = (
    assignments: (ConfigurationTemplateAssignment | InheritedAssignmentsWithScope)[],
    showInheritedFrom: boolean = false
  ): TableRow[] =>
    assignments.map((assignment, index) => ({
      ...assignment,
      index,
      inheritedFrom: showInheritedFrom ? (assignment as InheritedAssignmentsWithScope).inheritedFrom : null,
      order: onReorder ? (showInheritedFrom ? index + 1 : index + inheritedAssignmentsAmount + 1) : undefined
    }));

  const inheritedAssignemntsRows = convertToTableRows(inheritedAssignments ?? [], true);
  const directAssignmentsRows = convertToTableRows(assignments);

  const dataSource = [...inheritedAssignemntsRows, ...directAssignmentsRows];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (onReorder && active.id !== over?.id) {
      const activeIndex = dataSource.findIndex(tableRow => tableRow.order === active.id);
      const overIndex = dataSource.findIndex(tableRow => tableRow.order === over?.id);

      onReorder(
        arrayMove(
          directAssignmentsRows,
          activeIndex - inheritedAssignmentsAmount,
          overIndex - inheritedAssignmentsAmount
        )
      );
    }
  };

  const tableBasicProps = {
    scroll: { y: '250px' },
    dataSource,
    columns,
    footer: () => (
      <Button addPlus type="primary" onClick={onCreate} data-e2e={`add-${dataE2ePrefix}`}>
        <FormattedMessage id={`projects.settings.policies.${dataE2ePrefix}.add`} />
      </Button>
    ),
    emptyMessageId: `projects.settings.policies.no.${dataE2ePrefix}`,
    'data-e2e': `${dataE2ePrefix}-table`
  };

  if (onReorder) {
    return (
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map(tableRow => tableRow.order!)} strategy={verticalListSortingStrategy}>
          <Table components={{ body: { row: Row } }} rowKey="order" {...tableBasicProps} />
        </SortableContext>
      </DndContext>
    );
  } else {
    return <Table {...tableBasicProps} />;
  }
};

const TagWrapper = styled.div`
  display: flex;
  justify-content: end;

  .policy-source-tag {
    margin: 0;
    background-color: ${({ theme }) => theme.lightGray};
    color: black;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 19px;
  color: ${({ theme }) => theme.primaryGreen};
  opacity: 1;
  transition: opacity 200ms ease-in;
  margin: 0 16px;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledTrash = styled(Trash)`
  opacity: 1;
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.7;
  }
`;

export default ConfigurationTemplatesTable;
