import React from 'react';
import styled from 'types/theme.types';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Info, Text } from 'components/common/form-components';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { FormattedMessage } from 'react-intl';
import type { UseFormReturnType } from 'hooks/use-form.hook';

type Props = {
  form: UseFormReturnType;
  fieldName: 'vcsPrCommentsEnabled' | 'vcsPrCommentsEnabledDefault';
  text: React.ReactNode;
};

export const ProjectVcsPrCommentsCheckbox = ({ form }: { form: UseFormReturnType }) => (
  <VcsPrCommentsCheckbox
    form={form}
    fieldName={'vcsPrCommentsEnabledDefault'}
    text={
      <Text
        id="projects.settings.policies.vcs-pr-comments"
        info={{
          id: 'projects.settings.policies.vcs-pr-comments.tooltip',
          values: setLink(links.docs.POLICIES.RUN_PR_COMMENTS_COMMANDS_USING_ALIAS)
        }}
      />
    }
  />
);

export const EnvironmentVcsPrCommentsCheckbox = ({ form }: { form: UseFormReturnType }) => {
  const vcsPrCommentsEnabled = form.watch('vcsPrCommentsEnabled');
  const aliasInput = (
    <SizeLimiter>
      <StyledInput
        disabled={!vcsPrCommentsEnabled}
        data-e2e="vcs-commands-alias-input"
        name="vcsCommandsAlias"
        form={form}
        errorSide="bottom"
        suffix={
          <Info
            alignTop={false}
            information={
              <FormattedMessage
                id="environment.auto-deploy.settings.vcs-pr-comments.alias.info"
                values={setLink(links.docs.POLICIES.RUN_PR_COMMENTS_COMMANDS_USING_ALIAS)}
              />
            }
          />
        }
        placeholder="environment.auto-deploy.settings.vcs-pr-comments-enabled.input.placeholder"
      />
    </SizeLimiter>
  );

  return (
    <VcsPrCommentsCheckbox
      form={form}
      fieldName={'vcsPrCommentsEnabled'}
      text={
        <>
          <Text
            id="environment.auto-deploy.settings.vcs-pr-comments-enabled"
            info={{
              id: 'environment.auto-deploy.settings.vcs-pr-comments-enabled.info',
              values: setLink(links.docs.POLICIES.RUN_PR_COMMENTS_COMMANDS_USING_ALIAS)
            }}
          />
          {aliasInput}
        </>
      }
    />
  );
};

const VcsPrCommentsCheckbox: React.FC<Props> = ({ form, fieldName, text }) => {
  return (
    <ControlledBigCheckbox
      data-e2e="vcs-pr-comments-checkbox"
      name={fieldName}
      form={form}
      onChange={() => form.clearErrors(fieldName as any)}>
      <VcsPrCommentsContainer>{text}</VcsPrCommentsContainer>
    </ControlledBigCheckbox>
  );
};

const StyledInput = styled(ControlledTextInput)`
  width: 150px;
  max-width: 150px;
`;
const VcsPrCommentsContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0 1em;
  flex-wrap: wrap;
`;
const SizeLimiter = styled.div`
  max-width: 150px;
`;
