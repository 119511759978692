import React, { type HTMLAttributes } from 'react';
import { getOrganizationAvatar, getOrganizationName } from 'utils/organizations.utils';
import { HoverItem, type ItemProps } from 'components/layout/navbar/navbar-panel.utils';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

type Props = ItemProps & {
  organization: Organization;
  nameProps?: HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, string>;
  appendedElement?: (hovered: boolean) => React.ReactNode;
};

const OrganizationItem = React.forwardRef<HTMLDivElement, Props>(
  ({ organization, nameProps, appendedElement, ...props }, ref) => {
    return (
      <HoverItem {...props} ref={ref}>
        {hovered => (
          <>
            <Image src={getOrganizationAvatar(organization, hovered)} />
            <Name {...nameProps}>{getOrganizationName(organization)}</Name>
            {appendedElement?.(hovered)}
          </>
        )}
      </HoverItem>
    );
  }
);
OrganizationItem.displayName = 'OrganizationItem';

export default OrganizationItem;

const Image = styled.img`
  height: 26px;
  width: 26px;
  border-radius: 50%;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.08px;
  line-height: 22px;
  flex-grow: 1;
`;
