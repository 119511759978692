import type { SharedApi } from '@env0/shared/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'user-permissions'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all']
  } as const;

  return cacheKeys;
};

export type RBACPermissionsAssignments = SharedApi.Authorization.GetUserPermissions.Response;

export const emptyPermissions: RBACPermissionsAssignments = {
  organizations: {},
  environments: {},
  projects: {}
};

export const useGetUserPermissions = () => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();
  const [orgId] = all;

  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.permissions.getUserPermissions(orgId),
    enabled: Boolean(orgId),
    placeholderData: emptyPermissions,
    staleTime: 1000 * 60 * 2
  });
};
