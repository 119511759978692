import React, { useState } from 'react';
import { Progress } from 'antd';
import DiscoveredEnvironmentsTable from 'components/projects/settings/environment-discovery/environment-import/discovered-environments-table';
import ImportedEnvironmentsTable from 'components/projects/settings/environment-discovery/environment-import/imported-environments-table';
import styled from '@emotion/styled';
import { useCuratedDiscoveredEnvironments, useDiscoverEnvironments } from 'stores/rq/environment-import';
import { useNavigate } from 'react-router-dom';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import useSubscription from 'hooks/use-subscription';

interface Props {
  environmentDiscoveryConfigId: string;
}

const DiscoveredEnvironments: React.FunctionComponent<Props> = ({ environmentDiscoveryConfigId }) => {
  const navigate = useNavigate();
  const currentProjectId = useCurrentProjectId();

  const { discoveredEnvironments, importedEnvironments, isFetching, refetch } =
    useCuratedDiscoveredEnvironments(environmentDiscoveryConfigId);

  useSubscription({
    stream: 'importedEnvironmentUpdated',
    key: environmentDiscoveryConfigId,
    onEvent: () => refetch()
  });

  const { mutateAsync: discoverEnvironments } = useDiscoverEnvironments(environmentDiscoveryConfigId);
  const [isRunningDiscoverEnvironments, setIsRunningDiscoverEnvironments] = useState(false);

  const importedPercentage = Math.round(
    (importedEnvironments.filter(({ status }) => status === 'imported').length /
      (importedEnvironments.length + discoveredEnvironments.length)) *
      100
  );

  const baseImportUrl = `/p/${currentProjectId}/environment-discovery/import`;

  const navigateToNextPage = (isRerun: boolean) => {
    return (selectedDiscoveredEnvironmentIds: string[]) => {
      navigate(
        `${baseImportUrl}${
          isRerun ? '/rerun' : ''
        }?environmentDiscoveryConfigId=${environmentDiscoveryConfigId}&discoveredEnvironmentIds=${JSON.stringify(
          selectedDiscoveredEnvironmentIds
        )}`
      );
    };
  };

  const onImportClick = navigateToNextPage(false);

  const onRerunClick = navigateToNextPage(true);

  const onRefreshClick = async () => {
    try {
      setIsRunningDiscoverEnvironments(true);
      await discoverEnvironments();
    } finally {
      setIsRunningDiscoverEnvironments(false);
    }
  };

  return (
    <>
      <Progress percent={importedPercentage} />
      <TablesWrapper>
        <DiscoveredEnvironmentsTableWrapper>
          <DiscoveredEnvironmentsTable
            discoveredEnvironments={discoveredEnvironments}
            onImportClick={onImportClick}
            onRefreshClick={onRefreshClick}
            isLoading={isFetching || isRunningDiscoverEnvironments}
          />
        </DiscoveredEnvironmentsTableWrapper>
        <ImportedEnvironmentsTableWrapper>
          <ImportedEnvironmentsTable importedEnvironments={importedEnvironments} onRerunClicked={onRerunClick} />
        </ImportedEnvironmentsTableWrapper>
      </TablesWrapper>
    </>
  );
};

const TablesWrapper = styled.div`
  display: flex;
`;

const DiscoveredEnvironmentsTableWrapper = styled.div`
  margin-right: 8px;
  width: calc(50% - 8px);
`;

const ImportedEnvironmentsTableWrapper = styled.div`
  margin-left: 8px;
  width: calc(50% - 8px);
`;

export default DiscoveredEnvironments;
