import React from 'react';
import type { ColumnProps } from 'components/common/table';
import { compareTeamsByDescription, compareTeamsByName, compareTeamsByUsersNumber } from 'utils/teams.utils';
import { UserAvatarGroup } from 'components/common/user-avatar';
import type { Role, User } from 'types/api.types';
import type { TeamRoleAssignmentRow } from 'components/common/role-assignments/types';
import RoleAssignmentCardRoleSelect from 'components/common/role-assignments/role-assignment-card-role-select';
import RoleAssignmentCardAssignCheckbox from 'components/common/role-assignments/role-assignment-card-assign-checkbox';
import { ProjectRoleAssignmentHeader } from 'components/common/role-assignments/project-role-assignment-header';

export const buildTeamRoleAssignmentsColumns = ({
  scope,
  onChangeIsAssigned,
  onChangeAssignmentRole,
  defaultRoles
}: {
  scope: 'organization' | 'project' | 'environment';
  onChangeAssignmentRole: (teamId: string, role: string) => void;
  onChangeIsAssigned: (teamId: string, assigned: boolean) => void;
  defaultRoles: Role[];
}): ColumnProps[] => [
  {
    title: 'assign',
    dataIndex: 'assigned',
    width: '7%',
    render: (_, record: TeamRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardAssignCheckbox scopeName={scope} record={record} onChangeIsAssigned={onChangeIsAssigned} />
      );
    },
    defaultSortOrder: 'ascend',
    sorter: (team1: TeamRoleAssignmentRow, team2: TeamRoleAssignmentRow) => {
      const team1assigned = team1?.initialData.assigned ? 1 : 0;
      const team2assigned = team2?.initialData.assigned ? 1 : 0;
      return team2assigned - team1assigned;
    }
  },
  {
    title: 'teams.name',
    dataIndex: ['team', 'name'],
    width: '18%',
    ellipsis: true,
    sorter: (team1, team2) => compareTeamsByName(team1.team, team2.team)
  },
  {
    title: 'description',
    dataIndex: ['team', 'description'],
    width: '36%',
    ellipsis: {
      showTitle: false
    },
    sorter: (team1, team2) => compareTeamsByDescription(team1.team, team2.team)
  },
  {
    title: 'members',
    dataIndex: ['team', 'users'],
    render: (users: User[]) => (
      <div data-e2e="avatar-group">
        <UserAvatarGroup users={users} maxCount={3} />
      </div>
    ),
    sorter: (team1, team2) => compareTeamsByUsersNumber(team1.team, team2.team)
  },
  {
    title: scope === 'project' ? <ProjectRoleAssignmentHeader /> : 'role',
    dataIndex: 'role',
    render: (_, record: TeamRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardRoleSelect
          scopeName={scope}
          record={record}
          onChangeAssignmentRole={onChangeAssignmentRole}
          defaultRoles={defaultRoles}
          tab={'teams'}
        />
      );
    }
  }
];
