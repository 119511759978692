import styled from 'types/theme.types';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { INFINITE_VALUE, INHERIT_FROM_ORG } from 'constants/ttl.constants';
import { FormattedMessage } from 'react-intl';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import type { TtlPolicy } from 'components/policies/common-ttl';
import { INIT_TTL_VALUE } from 'components/policies/common-ttl';

export type TtlType = 'max' | 'default';
export interface TtlDurationRadioButtonProps {
  value: string | null;
  formatMessageId: string;
  name: string;
}

const TtlDurationRadioButton: React.FC<TtlDurationRadioButtonProps> = ({ ...props }) => (
  <Radio value={`${props.value}`} data-e2e={`${props.name}-number-radio-button`}>
    <FormattedMessage id={props.formatMessageId} />
  </Radio>
);

export interface TtlRadioButtonsProps {
  form: UseFormReturnType<TtlPolicy>;
  name: 'maxTtl' | 'defaultTtl';
}

export const TtlRadioButtons: React.FC<TtlRadioButtonsProps> = ({ form, name }) => {
  const canSetDefaultToInfinite: boolean = !(name === 'defaultTtl' && form.getValues('maxTtl') !== INFINITE_VALUE);
  const onChange = (e: RadioChangeEvent): void => {
    if (e.target.value === 'infinite') {
      // in case max ttl is not infinite just ignore when trying to switch default TTL to infinite (DefaultTTl <= MaxTTL)
      if (canSetDefaultToInfinite) form.setValue(name, INFINITE_VALUE);
    } else {
      form.setValue(name, INIT_TTL_VALUE);
    }
  };
  const ttlValue = form.getValues(name);

  const infiniteButton = (
    <StyledRadioButton
      value={'infinite'}
      checked={ttlValue !== INFINITE_VALUE}
      data-e2e={`${name}-infinite-radio-button`}>
      <FormattedMessage id={`project.settings.policies.ttl.radio.buttons.${name}.value`} />
    </StyledRadioButton>
  );

  const durationButton = (
    <TtlDurationRadioButton
      value={'custom'}
      formatMessageId={'project.settings.policies.custom.ttl'}
      name={name}></TtlDurationRadioButton>
  );

  return (
    <>
      <RadioButtonTitle>
        <FormattedMessage id={`project.settings.policies.ttl.radio.buttons.${name}.title`} />
      </RadioButtonTitle>
      <StyledRadioGroup
        value={ttlValue === INFINITE_VALUE ? 'infinite' : 'custom'}
        onChange={onChange}
        disabled={form.isSubmitting || ttlValue === INHERIT_FROM_ORG}>
        {infiniteButton}
        {durationButton}
      </StyledRadioGroup>
    </>
  );
};

const StyledRadioButton = styled(Radio)`
  width: 11em;
`;

const RadioButtonTitle = styled.div`
  width: 7em;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
`;
