import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import ErrorContainer from 'components/common/error-container';
import useStores from 'hooks/use-stores.hooks';
import type { Project } from 'types/api.types';
import { CardHeader } from 'components/common/card';
import { setLinksByTag } from 'components/common/link';
import { useHasPermission } from 'hooks/use-has-permission';
import { useGetTeams } from 'stores/rq/teams';
import { buildTeamRoleAssignmentsColumns } from 'components/common/role-assignments/build-team-role-assignments-columns';
import { links } from 'constants/external-links';
import RoleAssignmentsCard from 'components/common/role-assignments/role-assignments-card';
import { convertToTeamRoleAssignmentsRows } from 'components/common/role-assignments/convert-to-team-role-assignments-rows';
import { useTeamRoleAssignmentRows } from 'hooks/use-team-role-assignment-rows';
import AddTeamModal from 'components/organizations/teams/add-team-modal';
import { useCuratedRoles } from 'stores/rq/roles';
import { useGetTeamRoleAssignments } from 'components/common/role-assignments/use-role-assignments';
import type { ScopeId } from 'components/common/role-assignments/types';
import { useSaveTeamRoleAssignments } from 'components/common/role-assignments/use-save-team-role-assignments';

const intlPrefix = 'card-headers.team-role-assignments';
const scopeName = 'project';

const ProjectTeamRoleAssignmentsCard: React.FunctionComponent<{ project: Project }> = ({ project }) => {
  const { isAuthorized: canAddTeam } = useHasPermission('EDIT_PROJECT_SETTINGS');
  const addTeamButton = canAddTeam ? <AddTeamModal data-e2e="add-team-modal" marginTop="40px" /> : <></>;
  const { defaultProjectRoles: defaultRoles } = useCuratedRoles();

  const scope = { projectId: project.id } as ScopeId;

  const {
    organizationsStore: { currentOrganizationId }
  } = useStores();

  const { onSave } = useSaveTeamRoleAssignments(scope);

  const {
    onChangeIsAssigned,
    onChangeAssignmentRole,
    hasChanges,
    rows,
    setRows,
    rowsToAssign,
    rowsToRemove,
    rowsToUpdate
  } = useTeamRoleAssignmentRows(defaultRoles);

  const columns = buildTeamRoleAssignmentsColumns({
    scope: scopeName,
    onChangeAssignmentRole,
    onChangeIsAssigned,
    defaultRoles
  });

  const [teamNameSearch, setTeamNameSearch] = useState<string>();

  const {
    data: teams = [],
    isPending: isLoadingTeams,
    error: teamsError,
    hasNextPage,
    fetchNextPage
  } = useGetTeams({ teamName: teamNameSearch });

  const {
    data: teamRoleAssignments = [],
    isLoading: isLoadingAssignments,
    error: errorFetchingAssignments
  } = useGetTeamRoleAssignments(scope);

  const initTeamsWithPermissions = useCallback(async () => {
    if (!isLoadingTeams) {
      setRows(convertToTeamRoleAssignmentsRows(teams, teamRoleAssignments));
    }
  }, [setRows, teams, teamRoleAssignments, isLoadingTeams]);

  const cardHeader = (
    <CardHeader
      titleId={`${intlPrefix}.title`}
      descriptionId={`${intlPrefix}.description`}
      descriptionValues={{
        scope: scopeName,
        ...setLinksByTag({
          manage: links.docs.TEAMS.ROOT,
          customRole: links.docs.USER.CUSTOM_ROLE_PERMISSIONS,
          orgPage: `/organizations/${currentOrganizationId}/teams`
        })
      }}
    />
  );

  const loading = isLoadingTeams || isLoadingAssignments;
  const error = teamsError || errorFetchingAssignments;
  if (error) {
    return <ErrorContainer errorToReport={error} />;
  }

  return (
    <RoleAssignmentsCard
      cardHeader={cardHeader}
      columns={columns}
      rows={rows}
      loadRows={initTeamsWithPermissions}
      getRowKey={(row: any) => row.id}
      hasChanges={hasChanges}
      filterPlaceholderId={`${intlPrefix}.filter`}
      onDiscard={initTeamsWithPermissions}
      onSave={() => onSave(rowsToAssign, rowsToUpdate, rowsToRemove)}
      onFilter={setTeamNameSearch}
      isLoading={loading}
      showMore={{ hasNextPage, fetchNextPage }}
      additionalFooter={addTeamButton}
    />
  );
};

export default observer(ProjectTeamRoleAssignmentsCard);
