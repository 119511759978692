import React from 'react';
import { Divider as AntdDivider, type DividerProps } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'types/theme.types';

interface Props {
  titleId?: string;
  margin?: string;
  titleValues?: Record<string, any>;
  color?: string;
  orientation?: DividerProps['orientation'];
  orientationMargin?: string;
  title?: React.ReactNode;
}

const Divider: React.FC<Props> = ({
  titleId,
  titleValues,
  margin = '5px 0px',
  color,
  orientation = 'left',
  orientationMargin = '2%',
  title
}) => {
  return (
    <StyledDivider style={{ margin }} color={color} orientation={orientation} orientationMargin={orientationMargin}>
      {titleId && <FormattedMessage id={titleId} values={titleValues} />}
      {title}
    </StyledDivider>
  );
};

const StyledDivider = styled(AntdDivider)<{ color?: string }>`
  &.ant-divider-with-text-left .ant-divider-inner-text {
    padding-right: 5px;
    padding-left: 0;
  }

  .ant-divider-inner-text {
    font-size: 14px;
    color: ${({ theme, color }) => color ?? theme.primaryDisabledGray};
  }
`;

export default Divider;
