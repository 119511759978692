import { action, ObservableMap } from 'mobx';

export default class LazyObservableMap<T> extends ObservableMap<string, Promise<T>> {
  private fetcher: (key: string) => Promise<T>;

  constructor(fetcher: (key: string) => Promise<T>) {
    super();
    this.fetcher = fetcher;
  }

  @action async get(key: string): Promise<T> {
    const currentValue = super.get(key);
    if (currentValue) {
      return currentValue;
    }
    const newValue = this.fetcher(key);
    this.set(key, newValue);
    return newValue;
  }

  @action updateSync(key: string, value: T) {
    this.set(key, Promise.resolve(value));
  }
}
