import { usePermissionChangesSubscriber } from 'hooks/use-permission-changes-subscriber.hook';
import React, { type ReactNode } from 'react';
import { observer } from 'mobx-react';

const GlobalSubscriptions: React.FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  usePermissionChangesSubscriber();

  return <>{children}</>;
};

export default observer(GlobalSubscriptions);
