import { Collapse } from 'antd';
import React from 'react';
import type { CollapseProps } from 'antd/lib/collapse';
import ExpandIcon from 'components/common/expand-icon';
import styled from 'types/theme.types';

export type CustomCollapseProps = {
  'data-e2e'?: string;
  headerless?: boolean;
  hideDivider?: boolean;
} & CollapseProps;

const CustomCollapse: React.FC<CustomCollapseProps> = ({
  children,
  activeKey,
  onChange,
  destroyInactivePanel,
  'data-e2e': dataE2e,
  headerless,
  collapsible,
  ghost,
  expandIconPosition = 'end',
  expandIcon,
  hideDivider,
  ...props
}) => (
  <StyledCollapse
    headerless={headerless}
    collapsible={collapsible}
    activeKey={activeKey}
    expandIcon={props => (
      <div data-e2e={dataE2e}>
        {expandIcon ? expandIcon(props) : <ExpandIcon {...props} isOpen={props.isActive || false} />}
      </div>
    )}
    expandIconPosition={expandIconPosition}
    bordered={false}
    onChange={onChange}
    destroyInactivePanel={destroyInactivePanel}
    ghost={ghost}
    {...props}>
    {children}
    {!hideDivider && <div className="ant-collapse-item" id="add-collapse-bottom-divider" />}
  </StyledCollapse>
);

const StyledCollapse = styled(Collapse, {
  shouldForwardProp: prop => prop !== 'headerless'
})<CustomCollapseProps>`
  && {
    .ant-collapse-arrow {
      margin-top: 0;
    }
  }

  .ant-collapse-header {
    ${({ headerless }) => headerless && 'display: none !important;'}
  }
`;

export default CustomCollapse;
