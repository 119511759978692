export enum BulkOperationType {
  Deploy = 'deploy',
  Destroy = 'destroy',
  Approve = 'approve',
  Cancel = 'cancel',
  RunTask = 'run-task',
  Archive = 'archive',
  Lock = 'lock',
  Unlock = 'unlock',
  CancelQueuedDeployments = 'cancel-queued-deployments'
}
