import { useCallback, useState } from 'react';
import clamp from 'lodash/clamp';

export const SIDEBAR_WIDTH_LOCALSTORAGE_KEY = 'sidebarWidth';
export const MIN_SIDEBAR_WIDTH = 200;
export const MAX_SIDEBAR_WIDTH = 700;

export const useSidebarWidth = (defaultWidth: number) => {
  const [sidebarWidth, setSidebarWidth] = useState(() => {
    const width = localStorage.getItem(SIDEBAR_WIDTH_LOCALSTORAGE_KEY);
    return clampWidth(width ? Number(width) : defaultWidth);
  });

  const setWidth = useCallback((width: number) => {
    width = clampWidth(width);
    localStorage.setItem(SIDEBAR_WIDTH_LOCALSTORAGE_KEY, String(width));
    setSidebarWidth(width);
  }, []);

  return [sidebarWidth, setWidth] as const;
};

const clampWidth = (width: number) => clamp(width, MIN_SIDEBAR_WIDTH, MAX_SIDEBAR_WIDTH);
