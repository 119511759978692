import type { AnimationEventHandler } from 'react';
import React from 'react';
import { Badge } from 'antd';
import { GiftOutlined } from '@ant-design/icons';
import styled from 'types/theme.types';
import Tooltip from 'components/common/tooltip';
import { useSessionStorageState, useUpdateEffect } from 'ahooks';
import { EventNames, track } from 'utils/analytics.utils';

interface Props {
  isRead: boolean;
  onClick: () => void;
}

export const AppNotificationsButton: React.FC<Props> = ({ isRead, onClick }) => {
  const [shouldWiggle, setShouldWiggle] = useSessionStorageState('should-app-notifications-button-wiggle', {
    defaultValue: true
  });

  useUpdateEffect(() => {
    if (!isRead) {
      setShouldWiggle(true);
    }
  }, [isRead, setShouldWiggle]);

  const onAnimationEnd: AnimationEventHandler<HTMLDivElement> = e => {
    if (e.target === e.currentTarget) {
      setShouldWiggle(false);
    }
  };

  const onAnimationStart: AnimationEventHandler<HTMLDivElement> = e => {
    if (e.target === e.currentTarget) {
      track(EventNames.IN_APP_WHATS_NEW_SHOW_NEW_ITEMS);
    }
  };

  return (
    <Tooltip placement="left" titleId="whats.new">
      <Wrapper
        color="black"
        onClick={onClick}
        className={shouldWiggle && !isRead ? 'wiggle' : ''}
        data-e2e="whats-new-button"
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}>
        <Badge dot={!isRead}>
          <GiftOutlined className="gift" />
        </Badge>
      </Wrapper>
    </Tooltip>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;

  &.wiggle {
    animation: 1s wiggle ease;
    animation-iteration-count: 5;
  }

  .gift {
    font-size: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(-3deg);
    }
    20% {
      transform: rotate(20deg);
    }
    40% {
      transform: rotate(-15deg);
    }
    60% {
      transform: rotate(5deg);
    }
    90% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
