import React from 'react';
import { FormattedMessage } from 'react-intl';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { SelectOption } from 'components/common/input-components/select';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';

const prefix = 'settings.credentials.aws';
const hourInSeconds = 3600;

export const predefinedDurations: { label: string; valueInSeconds: number }[] = [
  { label: `${prefix}.duration.1_HOUR`, valueInSeconds: hourInSeconds },
  { label: `${prefix}.duration.2_HOUR`, valueInSeconds: hourInSeconds * 2 },
  { label: `${prefix}.duration.4_HOUR`, valueInSeconds: hourInSeconds * 4 },
  { label: `${prefix}.duration.5_HOUR`, valueInSeconds: hourInSeconds * 5 },
  { label: `${prefix}.duration.8_HOUR`, valueInSeconds: hourInSeconds * 8 },
  { label: `${prefix}.duration.12_HOUR`, valueInSeconds: hourInSeconds * 12 }
];

export const defaultDuration = predefinedDurations.find(duration => duration.label.includes('5_HOUR'));

interface DurationSelectProps {
  form: SubFormProps['form'];
}

export const DurationSelect = ({ form }: DurationSelectProps) => {
  return (
    <ControlledSelect
      data-e2e="new-credential-aws-duration-dropdown"
      label={{ id: `${prefix}.duration` }}
      name="value.duration"
      defaultValue={defaultDuration?.valueInSeconds}
      form={form}>
      {predefinedDurations.map((duration, index) => (
        <SelectOption
          value={duration.valueInSeconds}
          key={index}
          data-e2e={`new-credential-aws-duration-dropdown-${duration.valueInSeconds}`}>
          <FormattedMessage id={duration.label} />
        </SelectOption>
      ))}
    </ControlledSelect>
  );
};
