import React, { type ReactNode } from 'react';
import styled from 'types/theme.types';
import { Route, Routes } from 'react-router-dom';
import NavBar from 'components/layout/navbar/navbar';

export const MainLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <OuterContainer>
      <Routes>
        {['/p/:projectId/*', '/*'].map(path => (
          <Route key={path} path={path} element={<NavBar />} />
        ))}
      </Routes>
      <MainLayoutContainer>{children}</MainLayoutContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const MainLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryGray};
  flex: 1;
  max-height: 100%;
  overflow: auto;
`;

export default MainLayout;
