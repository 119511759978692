import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import type { AnyObjectSchema } from 'yup';
import type { Blueprint } from 'types/api.types';
import type {
  TemplateSettingsStepCommonValues,
  TemplateWizardStep,
  VCSStepValues
} from 'components/templates/templates-wizard/common/template-wizard.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import without from 'lodash/without';
import flatten from 'lodash/flatten';
import union from 'lodash/union';
import values from 'lodash/values';
import type { WizardStepName } from 'components/common/steps';
import type { WizardStepConfig } from 'components/common/use-wizard-navigator.hook';
import { getInitialGitProvider } from 'utils/vcs.utils';

export const getFormInitialValues = (schema: AnyObjectSchema, template?: Blueprint) => {
  return {
    ...pick(template, Object.keys(schema.fields))
  };
};

export const bindSshKeysField = (
  settingsStepForm: UseFormReturnType<Pick<Blueprint, 'sshKeys'>>,
  vcsStepForm: UseFormReturnType<Pick<Blueprint, 'sshKeys'>>,
  currentStep: TemplateWizardStep
) => {
  const [settingsSshKeys, setSettingsSshKeys] = settingsStepForm.useManualField('sshKeys');
  const [vcsSshKeys, setVcsSshKeys] = vcsStepForm.useManualField('sshKeys');

  if (!isEqual(settingsSshKeys, vcsSshKeys)) {
    if (currentStep === 'settings') setVcsSshKeys(settingsSshKeys as BlueprintApi.SshKey[]);
    if (currentStep === 'vcs') setSettingsSshKeys(vcsSshKeys as BlueprintApi.SshKey[]);
  }
};

export const getStepToIsValid = (stepConfig: WizardStepConfig) =>
  pickBy(
    {
      settings: () => !!stepConfig['settings']?.formState?.isValid,
      vcs: () => !!stepConfig['vcs']?.formState?.isValid,
      credentials: () => !!stepConfig['credentials']?.formState?.isValid,
      variables: () => !stepConfig['variables']?.hasError,
      projects: () => !!stepConfig['projects']?.formState?.isValid,
      'environment-details': () => !!stepConfig['environment-details']?.formState?.isValid,
      mapping: () => !!stepConfig['mapping']?.formState?.isValid,
      requirements: () => !!stepConfig['requirements']?.formState?.isValid,
      config: () => !!stepConfig['config']?.formState?.isValid,
      done: () => !!stepConfig['done']?.formState?.isValid
    } as Record<TemplateWizardStep, () => boolean>,
    (_, step: TemplateWizardStep) => !!stepConfig[step]
  );

export const getIsDirty = (stepConfig: WizardStepConfig) =>
  Object.values(
    pickBy<any>(
      {
        settings: !!stepConfig['settings']?.formState?.isDirty,
        vcs: !!stepConfig['vcs']?.formState?.isDirty,
        credentials: !!stepConfig['credentials']?.formState?.isDirty,
        variables: !!(
          stepConfig['variables']?.environmentVariablesIsDirty ||
          stepConfig['variables']?.terraformVariablesIsDirty ||
          stepConfig['variables']?.configurationSetIsDirty
        ),
        projects: !!stepConfig['projects']?.formState?.isDirty,
        'environment-details': !!stepConfig['environment-details']?.formState?.isDirty,
        mapping: !!stepConfig['mapping']?.formState?.isDirty,
        done: !!stepConfig['done']?.formState?.isDirty,
        config: !!stepConfig['config']?.formState?.isDirty,
        requirements: !!stepConfig['requirements']?.formState?.isDirty
      } as Record<TemplateWizardStep, boolean>,
      (_, step: TemplateWizardStep) => !!stepConfig[step]
    )
  ).some(isDirty => isDirty);

export const getIsSkippable = (currentStep: WizardStepName) => {
  const skippableSteps: WizardStepName[] = ['credentials'];
  return skippableSteps.includes(currentStep);
};

const iacTypeFields: Record<BlueprintApi.IacType, (`${BlueprintApi.IacType}Version` | keyof Blueprint)[]> = {
  k8s: [],
  pulumi: ['pulumiVersion'],
  terragrunt: ['terragruntVersion', 'opentofuVersion', 'terraformVersion', 'isTerragruntRunAll', 'terragruntTfBinary'],
  opentofu: ['opentofuVersion'],
  terraform: ['terraformVersion'],
  cloudformation: [],
  helm: [],
  ansible: ['ansibleVersion']
};

function getAllUniqueTemplatesFields(excludedTemplateType: Blueprint['type']) {
  return union(flatten(values(omit(iacTypeFields, [excludedTemplateType]))));
}

const omittedFormFields: Record<BlueprintApi.IacType, (`${BlueprintApi.IacType}Version` | keyof Blueprint)[]> = {
  k8s: getAllUniqueTemplatesFields('k8s'),
  pulumi: getAllUniqueTemplatesFields('pulumi'),
  terraform: without(getAllUniqueTemplatesFields('terraform'), 'terraformVersion'),
  opentofu: without(getAllUniqueTemplatesFields('opentofu'), 'opentofuVersion'),
  terragrunt: without(getAllUniqueTemplatesFields('terragrunt'), 'terraformVersion', 'opentofuVersion'),
  cloudformation: getAllUniqueTemplatesFields('cloudformation'),
  helm: getAllUniqueTemplatesFields('helm'),
  ansible: without(getAllUniqueTemplatesFields('ansible'), 'ansibleVersion')
};

const omitFormUnusedDefaults = <T extends TemplateSettingsStepCommonValues>(
  canonizedFormValues: T & VCSStepValues,
  blueprintType: BlueprintApi.BlueprintType
) => {
  if (blueprintType in omittedFormFields) {
    return omit(canonizedFormValues, omittedFormFields[blueprintType as BlueprintApi.IacType]) as T & VCSStepValues;
  }

  return canonizedFormValues;
};

export const canonizeFormValues = <T extends TemplateSettingsStepCommonValues>(formValues: T & VCSStepValues) => {
  let canonizedFormValues = { ...formValues };
  canonizedFormValues = omitFormUnusedDefaults(canonizedFormValues, formValues.type);
  canonizedFormValues.repository = formValues.repository.trim();
  return canonizedFormValues;
};

export const isVcsIntegrated = (formValues: VCSStepValues) => {
  const gitProvider = getInitialGitProvider(formValues);
  return gitProvider && gitProvider !== 'Other' && !formValues.isHelmRepository;
};
