import React, { type ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useHasOrganizations } from 'hooks/use-has-organization.hook';
import { ONBOARDING_ROUTE_PREFIX } from 'components/routes/routes';
import type { RolesApi } from '@env0/role-service/api';
import { useHasPermission } from 'hooks/use-has-permission';
import type { PermissionOperator } from 'hooks/use-has-permission';

export type PermissionContainerProps = {
  requiredPermissions?: RolesApi.RBACPermission[];
  permissionOperator?: PermissionOperator;
  fallback: React.ComponentType<{ reason?: string }>;
  children?: ReactNode;
};

const PermissionContainer: React.FunctionComponent<PermissionContainerProps> = ({
  children,
  requiredPermissions,
  permissionOperator,
  fallback: Fallback
}) => {
  const { hasOrganizations, isLoadingOrganizations } = useHasOrganizations();
  const location = useLocation();
  const notInOnboarding = !location.pathname.startsWith(ONBOARDING_ROUTE_PREFIX);

  const { isAuthorized, unauthorizedReason } = useHasPermission(requiredPermissions || [], { permissionOperator });

  if (!isLoadingOrganizations && !hasOrganizations && notInOnboarding) {
    // assuming organizations already fetched during login, so no need to present loader
    return <Navigate replace to={`${ONBOARDING_ROUTE_PREFIX}/welcome`} />;
  }

  if (!isAuthorized) {
    return <Fallback reason={unauthorizedReason} />;
  }

  return <>{children}</>;
};

export default observer(PermissionContainer);
