import React, { useCallback } from 'react';
import Button from 'components/common/button';
import type { CustomButtonProps } from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import FormButtonsContainer from 'components/common/buttons-container';
import styled from 'types/theme.types';

export type ControlledSaveDiscardButtonsProps = {
  form: UseFormReturnType;
  saveMessageId?: string;
  saveButtonDataE2E?: string;
  cancelMessageId?: string;
  cancelButtonDataE2E?: string;
  onDiscard?: () => void;
  inContainer?: boolean;
  alwaysEnableDiscard?: boolean;
  alwaysEnableSave?: boolean;
  saveButtonType?: CustomButtonProps['type'];
  className?: string;
};

const ControlledSaveDiscardButtons = ({
  form,
  saveMessageId,
  saveButtonDataE2E,
  cancelMessageId,
  cancelButtonDataE2E,
  onDiscard,
  inContainer,
  alwaysEnableDiscard,
  alwaysEnableSave = false,
  saveButtonType = 'primary',
  className
}: ControlledSaveDiscardButtonsProps) => {
  const {
    canSave,
    submit,
    discardChanges,
    formState: { isSubmitting, isDirty, isValid }
  } = form;

  const onDiscardClicked = useCallback(() => {
    discardChanges();
    onDiscard?.();
  }, [discardChanges, onDiscard]);

  const buttons = (
    <>
      <StyledButton
        data-e2e={cancelButtonDataE2E || `controlled-button-discard`}
        disabled={isSubmitting || (!alwaysEnableDiscard && !isDirty)}
        onClick={onDiscardClicked}>
        <FormattedMessage id={cancelMessageId || 'cancel'} />
      </StyledButton>
      <StyledButton
        data-e2e={saveButtonDataE2E || `controlled-button-save`}
        isLoading={isSubmitting}
        disabled={alwaysEnableSave ? !isValid : !canSave}
        onClick={submit}
        type={saveButtonType}>
        <FormattedMessage id={saveMessageId || 'save.changes'} />
      </StyledButton>
    </>
  );

  return inContainer ? (
    <FormButtonsContainer data-e2e={`controlled-save-discard-buttons`} className={className}>
      {buttons}
    </FormButtonsContainer>
  ) : (
    <>{buttons}</>
  );
};

const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    padding: 0px !important;
  }
`;

export default ControlledSaveDiscardButtons;
