import { matchPath, useLocation } from 'react-router-dom';

type T = string | undefined;

export const useCurrentProjectId = <ReturnType extends T = string | undefined>(): ReturnType => {
  return useMatchPathParams<{ projectId: string }>('/p/:projectId/*')?.projectId as ReturnType;
};

export const useCurrentEnvironmentId = () =>
  useMatchPathParams<{ environmentId: string }>('/p/:projectId/environments/:environmentId/*')?.environmentId;

const useModuleIdFromRoute = () => useMatchPathParams<{ moduleId: string }>('/registry/modules/:moduleId/*')?.moduleId;

const useTemplateIdFromRoute = () => useMatchPathParams<{ templateId: string }>('/templates/:templateId/*')?.templateId;

const isCreatingNewTemplateOrModule = ({
  moduleIdFromRoute,
  templateIdFromRoute
}: {
  templateIdFromRoute?: string;
  moduleIdFromRoute?: string;
}) => templateIdFromRoute === 'add-new-template' || moduleIdFromRoute === 'add-new-module';

export const useCurrentTemplateId = () => {
  const templateIdFromRoute = useTemplateIdFromRoute();
  // when the url is /templates/add-new-template, the templateIdFromRoute is 'add-new-template' and we fail to validate the permissions because the template isn't in the responses
  if (isCreatingNewTemplateOrModule({ templateIdFromRoute })) return undefined;

  return templateIdFromRoute;
};

export const useIsCreateNewTemplateOrModule = () => {
  const moduleIdFromRoute = useModuleIdFromRoute();
  const templateIdFromRoute = useTemplateIdFromRoute();

  return isCreatingNewTemplateOrModule({ moduleIdFromRoute, templateIdFromRoute });
};

export const useNewEnvironmentProjectAndTemplateIds = () => {
  const createNewVcsEnvironment = useMatchPathParams<{ projectId: string }>('/p/:projectId/add-new-environment/vcs');
  const createNewEnvironmentFromTemplate = useMatchPathParams<{ projectId: string; templateId: string }>(
    '/p/:projectId/templates/:templateId/run-now'
  );

  if (createNewVcsEnvironment) {
    return {
      projectId: createNewVcsEnvironment.projectId,
      isCreatingNewEnvironment: true,
      isCreatingNewEnvironmentFromTemplate: false
    };
  }

  if (createNewEnvironmentFromTemplate) {
    return {
      projectId: createNewEnvironmentFromTemplate.projectId,
      templateId: createNewEnvironmentFromTemplate.templateId,
      isCreatingNewEnvironment: true,
      isCreatingNewEnvironmentFromTemplate: true
    };
  }

  return { isCreatingNewEnvironment: false, isCreatingNewEnvironmentFromTemplate: false };
};

export const useCurrentModuleId = () => {
  const moduleIdFromRoute = useModuleIdFromRoute();
  // when the url is /registry/modules/add-new-template, the moduleIdFromRoute is 'add-new-template' and we fail to validate the permissions because the template isn't in the responses
  if (isCreatingNewTemplateOrModule({ moduleIdFromRoute })) return undefined;

  return moduleIdFromRoute;
};

export const useCurrentConfigurationSetId = () => {
  const projectId = useCurrentProjectId();

  return useMatchPathParams<{ configurationSetId: string }>(
    `${projectId ? `/p/${projectId}` : ''}/variables/set/:configurationSetId/settings`
  )?.configurationSetId;
};

const useMatchPathParams = <T extends { [K in keyof T]?: string | undefined }>(pathPattern: string): T | undefined => {
  const location = useLocation();
  const match = matchPath(pathPattern, location.pathname);

  return match?.params as T | undefined;
};
