import { useCallback } from 'react';
import * as Yup from 'yup';

import useStores from 'hooks/use-stores.hooks';
import useForm from 'hooks/use-form.hook';
import type { NewOrganization, Organization } from 'types/api.types';

type UseOrganizationFormProps = {
  initialValues?: Partial<Organization>;
  navigationPath?: string;
  defaultOrgName?: string;
  shouldClearAfterSubmit?: boolean;
};

const schema = Yup.object()
  .shape({
    name: Yup.string()
      .required('organization.settings.general.form.name.empty')
      .max(50, 'organization.settings.general.form.name.invalid.length')
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9-_ ]{0,49}$/, 'organization.settings.general.form.name.invalid.character'),
    description: Yup.string().optional(),
    photoUrl: Yup.string().optional()
  })
  .defined();

const useOrganizationForm = ({
  initialValues,
  navigationPath,
  shouldClearAfterSubmit = true
}: UseOrganizationFormProps = {}) => {
  const { organizationsStore } = useStores();

  const onSubmit = useCallback(
    async (formValues: NewOrganization) => {
      if (initialValues) {
        await organizationsStore.updateOrganizationDetails({ ...initialValues, ...formValues } as Organization);
      } else {
        await organizationsStore.createOrganization(formValues, navigationPath);
      }
    },
    [organizationsStore, initialValues, navigationPath]
  );

  return useForm<NewOrganization>({ schema, initialValues, onSubmit, shouldClearAfterSubmit });
};

export default useOrganizationForm;
