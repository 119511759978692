import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { iacVersionList } from 'components/templates/templates-wizard/settings/versions-list';
import type { VersionFieldFormProps } from 'components/templates/templates-wizard/settings/version-field';
import { VersionField } from 'components/templates/templates-wizard/settings/version-field';

const opentofuVersionKey = 'opentofuVersion';
const opentofuPrefix = `templates.add.new.field.${opentofuVersionKey}`;
const latestVersionKeyword = 'latest';
const resolveFromOpenTofuCodeVersionKeyword = 'RESOLVE_FROM_CODE';

export const OpentofuVersionField: React.FC<VersionFieldFormProps> = ({ form, disabled, disableInfo }) => {
  const intl = useIntl();
  const versions = useMemo<BlueprintApi.OpentofuVersion[]>(() => {
    const versions: BlueprintApi.OpentofuVersion[] = [...iacVersionList.opentofu];
    versions.unshift(resolveFromOpenTofuCodeVersionKeyword);
    versions.unshift(latestVersionKeyword);

    return versions;
  }, []);

  const translateVersionText = (version: BlueprintApi.OpentofuVersion): string => {
    if (version === resolveFromOpenTofuCodeVersionKeyword) {
      return intl.formatMessage({ id: `${opentofuPrefix}.options.autoCodeResolve` });
    }

    if (version === latestVersionKeyword) {
      return intl.formatMessage({ id: `${opentofuPrefix}.options.latest` });
    }

    return version;
  };

  return (
    <VersionField
      form={form}
      versions={versions as ReadonlyArray<string>}
      translateFunction={translateVersionText}
      prefix={opentofuPrefix}
      versionKey={opentofuVersionKey}
      type="opentofu"
      disabled={disabled}
      disableInfo={disableInfo}
    />
  );
};
