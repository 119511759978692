// This file is meant to be exporting enums only
// Please refrain from importing anything to it and from introducing anything here other than enums
// Doing so will make downstream services include runtime code from this service
export enum NotificationEndpointType {
  SLACK = 'Slack',
  TEAMS = 'Teams',
  EMAIL = 'Email',
  WEBHOOK = 'Webhook'
}

export enum EventNameType {
  EnvironmentDeployStarted = 'environmentDeployStarted',
  EnvironmentDestroyStarted = 'environmentDestroyStarted',
  DeploySucceeded = 'deploySucceeded',
  DestroySucceeded = 'destroySucceeded',
  DeployFailed = 'deployFailed',
  DestroyFailed = 'destroyFailed',
  DeploymentWaitingForUser = 'deploymentWaitingForUser',
  DeploymentCancelled = 'deploymentCancelled',
  EnvironmentDeployResumed = 'environmentDeployResumed',
  EnvironmentDestroyResumed = 'environmentDestroyResumed',
  EnvironmentMarkedForAutoDestroy = 'environmentMarkedForAutoDestroy',
  DriftDetected = 'driftDetected',
  DriftUndetected = 'driftUndetected',
  DriftFailed = 'driftFailed',
  BudgetExceeded = 'budgetExceeded'
}

export enum EmailRecipientPlaceholder {
  $ENVIRONMENT_CREATOR$ = '$ENVIRONMENT_CREATOR$',
  $DEPLOYER$ = '$DEPLOYER$'
}
