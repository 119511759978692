import { setLinksByTag } from 'components/common/link';
import { links } from 'constants/external-links';
import { Text } from 'components/common/form-components';
import React from 'react';

export const ProjectRoleAssignmentHeader = () => {
  return (
    <Text
      data-e2e="project-role-header"
      id="roles"
      info={{
        id: 'card-headers.role-assignments.columns.roles.info',
        values: setLinksByTag({
          a: links.docs.USER.PROJECT_ROLES,
          docs: links.docs.USER.CUSTOM_ROLE_PERMISSIONS
        })
      }}
    />
  );
};
