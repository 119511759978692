import { FieldAndLabel, FieldRow } from 'components/common/form-components';
import RadioButtons from 'components/common/radio-buttons';
import React from 'react';
import type { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import styled from '@emotion/styled';

export type BlockRadioButtonProps = {
  onChange: (value: any) => void;
  value?: string;
  options: Array<CheckboxOptionType | string | number>;
  disabled?: boolean;
  label: React.ReactNode;
};
const blockRadioButtons = ({ onChange, value, options, disabled, label }: BlockRadioButtonProps) => (
  <Container>
    <FieldAndLabel>
      <FieldRow>{label}</FieldRow>
      <FieldRow>
        <RadioButtons
          disabled={disabled}
          onChange={onChange}
          block
          options={options}
          value={value}
          optionType={'button'}
          buttonStyle={'solid'}></RadioButtons>
      </FieldRow>
    </FieldAndLabel>
  </Container>
);

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;
export default blockRadioButtons;
