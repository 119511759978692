import type { Auth0UserProfile } from 'auth0-js';
import { action, observable } from 'mobx';
import BaseService from 'services/base-service';

export class UserStore extends BaseService {
  @observable profile?: Auth0UserProfile;

  @action setProfile(profile: Auth0UserProfile) {
    this.profile = profile;
  }

  get userId() {
    return this.profile && this.profile.sub;
  }
}
