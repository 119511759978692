import type {
  Blueprint,
  BlueprintRevisions,
  CreateBlueprint,
  GitProviderTypes,
  UpdateBlueprint
} from 'types/api.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  create: (data: CreateBlueprint) =>
    client.post<CreateBlueprint, AxiosResponse<Blueprint>>(`blueprints`, data).then(returnData),

  update: (blueprintId: string, data: UpdateBlueprint) =>
    client.put<UpdateBlueprint, AxiosResponse<Blueprint>>(`blueprints/${blueprintId}`, data).then(returnData),

  remove: (blueprintId: string): Promise<void> => client.delete(`blueprints/${blueprintId}`),

  getById: (blueprintId: string) => client.get<Blueprint>(`blueprints/${blueprintId}`).then(returnData),

  getRevisions: (blueprintId: string) =>
    client.get<BlueprintRevisions>(`blueprints/${blueprintId}/revisions`).then(returnData),

  getForOrganization: (organizationId: string) =>
    client.get<Blueprint[]>(`blueprints`, { params: { organizationId } }).then(returnData),

  getForProject: (projectId: string) =>
    client.get<Blueprint[]>(`blueprints`, { params: { projectId } }).then(returnData),

  addBlueprintToProject: (projectId: string, blueprintId: string) =>
    client.patch(`blueprints/${blueprintId}/projects`, { projectId }),

  removeBlueprintFromProject: (projectId: string, blueprintId: string) =>
    client.delete(`blueprints/${blueprintId}/projects/${projectId}`, { hideNotification: true }),

  getRepositories: (gitProvider: GitProviderTypes, params: BlueprintApi.GetAvailableRepositories.Request.QueryParams) =>
    client
      .get<BlueprintApi.GetAvailableRepositories.Response.Body>(
        `blueprints/${gitProvider.toLowerCase()}/repositories`,
        { params }
      )
      .then(returnData),

  getPagedRepositories: (
    gitProvider: GitProviderTypes,
    params: BlueprintApi.GetAvailableRepositories.Request.QueryParams
  ) =>
    client
      .get<BlueprintApi.GetAvailableRepositories.Response.Body>(
        `blueprints/${gitProvider.toLowerCase()}/repositories`,
        {
          params
        }
      )
      .then(response => ({ data: response.data, maxPages: response.headers['x-max-pages'] })),

  getVcsRepositoryRevisions: (params: BlueprintApi.GetVcsRepositoryRevisions.Request.QueryParams) =>
    client
      .get<BlueprintApi.GetVcsRepositoryRevisions.Response>(`blueprints/vcs-revisions`, { params })
      .then(returnData),

  getVariablesFromRepository: (params: BlueprintApi.GetVariablesFromRepository.Request.QueryParams) =>
    client
      .get<BlueprintApi.GetVariablesFromRepository.Response>(`blueprints/variables-from-repository`, { params })
      .then(returnData),
  getWorkflowFile: (blueprintId: string, revision?: string) =>
    client
      .get<BlueprintApi.GetWorkflowFile.Response>(`workflowFile`, {
        params: { blueprintId, revision },
        hideNotification: true
      })
      .then(returnData),
  getEnvironmentDiscovery: (projectId: string) =>
    client
      .get<BlueprintApi.GetEnvironmentDiscovery.Response>(`environment-discovery/projects/${projectId}`, {
        hideNotification: true
      })
      .then(returnData),
  upsertEnvironmentDiscovery: (projectId: string, data: BlueprintApi.EnableEnvironmentDiscovery.Request.Body) =>
    client
      .put<BlueprintApi.EnableEnvironmentDiscovery.Response>(`environment-discovery/projects/${projectId}`, data)
      .then(returnData),
  deleteEnvironmentDiscovery: (projectId: string) =>
    client.delete<void>(`environment-discovery/projects/${projectId}`, {}).then(returnData)
});
