import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'project-policies'] as const;
  const cacheKeys = {
    byProjectId: (projectId: string) => [...baseKey, 'byProjectId', { projectId }] as const
  } as const;

  return cacheKeys;
};

export const useGetProjectPolicies = (projectId?: string, enabled = true) => {
  const projectIdFromUrl = useCurrentProjectId<string>();
  const currentProjectId = projectId || projectIdFromUrl;
  const apiClient = useApiClient();
  const { byProjectId } = useCacheKeys();

  return useQuery({
    queryKey: byProjectId(currentProjectId),
    queryFn: async ({ queryKey: [, , , { projectId }] }) =>
      !projectId ? undefined : apiClient.environments.getPolicies(projectId),
    enabled
  });
};

export const useUpdateProjectPolicies = (projectId?: string) => {
  const projectIdFromUrl = useCurrentProjectId<string>();
  const currentProjectId = projectId || projectIdFromUrl;
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { byProjectId } = useCacheKeys();

  return useMutation({
    mutationFn: (newPolicies: EnvironmentApi.UpdatePolicies.Request.Body) =>
      apiClient.environments.updatePolicies(newPolicies),
    onSuccess: policies => {
      const queryKey = byProjectId(currentProjectId);
      queryClient.setQueryData(queryKey, policies);
      queryClient.refetchQueries({ queryKey });
    }
  });
};
