import React, { type ReactNode } from 'react';
import styled from 'types/theme.types';
import { Modal } from 'antd';
import { css } from '@emotion/css';
import type { ModalProps } from 'antd/lib/modal';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/common/loader';
import { Explanation } from 'components/common/form-components';

export interface CommonModalProps extends ModalProps, Omit<ModalTitleProps, 'id'> {
  titleId?: string;
  isLoading?: boolean;
  wrapClassName?: string;
}

const CommonModal: React.FunctionComponent<CommonModalProps> = ({
  isLoading,
  wrapClassName,
  children,
  titleId,
  subtitleId,
  subtitleValues,
  titleValues,
  ...props
}) => {
  return (
    <Modal
      wrapClassName={`${wrapClassName} ${modalStyles} ${isLoading ? 'loading' : ''}`}
      title={
        titleId && (
          <ModalTitle id={titleId} titleValues={titleValues} subtitleId={subtitleId} subtitleValues={subtitleValues} />
        )
      }
      {...props}>
      <div className="loading-overlay">
        <Loader />
      </div>
      {children}
    </Modal>
  );
};

interface ModalTitleProps {
  id: string;
  subtitleId?: string;
  subtitleValues?: Record<string, React.ReactNode | ((parts: string | ReactNode) => ReactNode)>;
  titleValues?: Record<string, React.ReactNode | ((parts: string | ReactNode) => ReactNode)>;
}
const ModalTitle: React.FunctionComponent<ModalTitleProps> = ({ id, subtitleId, subtitleValues, titleValues }) => {
  return (
    <>
      <Title>
        <FormattedMessage id={id} values={titleValues} />
      </Title>
      {subtitleId && (
        <Explanation>
          <FormattedMessage id={subtitleId} values={subtitleValues} />
        </Explanation>
      )}
    </>
  );
};

const Title = styled.div`
  color: ${({ theme }) => theme.primaryBlue};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 19px;
  margin-top: 19px;
`;

const modalStyles = css`
  .ant-modal {
    &-body {
      > .loading-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        transition: all 420ms ease-in-out;
      }
      > :not(.loading-overlay) {
        transition: all 420ms ease-in-out;
      }
    }
    &-header > *,
    &-footer > * {
      transition: all 420ms ease-in-out;
    }
  }
  &.loading {
    .ant-modal {
      &-header,
      &-footer {
        > * {
          opacity: 0.13;
          pointer-events: none;
        }
      }
      &-body {
        > :not(.loading-overlay) {
          opacity: 0.13;
          pointer-events: none;
        }
        > .loading-overlay {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export default CommonModal;
