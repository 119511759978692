import { useEffect, useMemo, useRef } from 'react';
import { useAsyncPromise } from 'hooks/use-async-promise';
import useStores from 'hooks/use-stores.hooks';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';

type Props = {
  vcsForm: VCSForm;
};

export const useGitRevisions = ({ vcsForm }: Props) => {
  const { blueprintsStore } = useStores();
  const { setError, clearErrors, setValue, watch } = vcsForm;
  const { repository, tokenId, githubInstallationId, bitbucketClientKey } = watch();
  const prevRepository = useRef(repository);

  useUpdateEffect(() => {
    if (prevRepository.current && prevRepository.current !== repository) {
      setValue('revision', '');
    }
    prevRepository.current = repository;
  }, [repository, setValue]);

  const gitAuthKeys = useMemo(() => {
    const isVcsIntegrated = !!(tokenId || githubInstallationId || bitbucketClientKey);
    return isVcsIntegrated ? { tokenId, githubInstallationId, bitbucketClientKey } : undefined;
  }, [githubInstallationId, tokenId, bitbucketClientKey]);

  const [revisionsError, availableRevisions, revisionsLoading] = useAsyncPromise(async () => {
    return gitAuthKeys
      ? await blueprintsStore.loadVcsRevisions({
          repository,
          tokenId: gitAuthKeys.tokenId,
          githubInstallationId: gitAuthKeys.githubInstallationId || undefined,
          bitbucketClientKey: gitAuthKeys.bitbucketClientKey || undefined
        })
      : undefined;
  }, [blueprintsStore, gitAuthKeys, repository]);

  useEffect(() => {
    revisionsError && setError('revision', { message: 'templates.add.new.field.revisions.error' });
  }, [revisionsError, setError]);

  useEffect(() => {
    !revisionsError && clearErrors('revision');
  }, [revisionsError, clearErrors]);

  return { revisionsLoading, availableRevisions };
};
