import useApiClient from 'hooks/use-api-client';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';
import partition from 'lodash/partition';
import { useMemo } from 'react';

export const ENVIRONMENT_IMPORT_BASE_KEY = ['environment-import'] as const;
const useCacheKeys = () => {
  const cacheKeys = {
    byEnvironmentDiscoveryConfigId: (environmentDiscoveryConfigId: string) =>
      [...ENVIRONMENT_IMPORT_BASE_KEY, 'byEnvironmentDiscoveryConfigId', { environmentDiscoveryConfigId }] as const
  } as const;

  return cacheKeys;
};

const useCommonHookSetup = () => {
  return { apiClient: useApiClient(), queryClient: getQueryClientInstance(), ...useCacheKeys() };
};

const useFindDiscoveredEnvironments = (environmentDiscoveryConfigId: string) => {
  const { apiClient, byEnvironmentDiscoveryConfigId } = useCommonHookSetup();
  return useQuery({
    queryKey: byEnvironmentDiscoveryConfigId(environmentDiscoveryConfigId),
    enabled: !!environmentDiscoveryConfigId,
    queryFn: () => apiClient.environmentImport.findDiscoveredEnvironments(environmentDiscoveryConfigId)
  });
};

export const useDiscoverEnvironments = (environmentDiscoveryConfigId: string) => {
  const { apiClient, byEnvironmentDiscoveryConfigId } = useCommonHookSetup();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: byEnvironmentDiscoveryConfigId(environmentDiscoveryConfigId),
    mutationFn: () => apiClient.environmentImport.discoverEnvironments(environmentDiscoveryConfigId),
    onSuccess: () =>
      queryClient.refetchQueries({ queryKey: byEnvironmentDiscoveryConfigId(environmentDiscoveryConfigId) })
  });
};

export const useCuratedDiscoveredEnvironments = (environmentDiscoveryConfigId: string) => {
  const query = useFindDiscoveredEnvironments(environmentDiscoveryConfigId);

  const [discoveredEnvironments, importedEnvironments] = useMemo(
    () =>
      partition(
        query.data,
        // eslint-disable-next-line lodash/matches-prop-shorthand
        environment => environment.status === 'discovered'
      ) as [
        EnvironmentImportApi.DiscoveredUnimportedEnvironment[],
        EnvironmentImportApi.DiscoveredImportedEnvironment[]
      ],
    [query.data]
  );

  return { ...query, discoveredEnvironments, importedEnvironments };
};
