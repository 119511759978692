import omit from 'lodash/omit';
import type { CostApi } from '@env0/cost-service/api';
import { getResponseErrorStatus, type HttpClient, returnData } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';

export type GetCostArgs = {
  id: string;
  timespan: CostApi.Timespan;
  granularity?: CostApi.Granularity;
  // TODO: GetCostArgs is used to all apis for no reason. Needs separation and dedicated interface to each API.
  // move those to OrganizationCost specific interface after RQ refactor
  projectIds?: string[];
  accumulated?: boolean;
};

export default (client: HttpClient) => {
  return {
    isCostEnabled: (projectId: string) =>
      client.get<CostApi.IsCostEnabled.Response>(`costs/project/${projectId}/enabled`).then(returnData),
    getProjectCostsCredentials: (projectId: string) =>
      client
        .get<CostApi.GetProjectCostsCredentials.Response>(`costs/project/${projectId}/credentials`)
        .then(returnData),
    getProjectsWithCost: (organizationId: string) =>
      client
        .get<CostApi.GetProjectsWithCostCredentials.Response>(`costs/projects/enabled`, { params: { organizationId } })
        .then(returnData),
    addProjectCostsCredentials: (projectId: string, credentialsId: string) =>
      client.put<
        CostApi.AddProjectCostsCredentials.Request.Body,
        AxiosResponse<CostApi.AddProjectCostsCredentials.Response>
      >(`costs/project/${projectId}/credentials`, {
        credentialsId
      }),
    removeProjectCostsCredentials: (projectId: string, credentialsId: string) =>
      client.delete<void>(`costs/project/${projectId}/credentials/${credentialsId}`),
    getEnvironmentCost: async (args: GetCostArgs) =>
      client
        .get<CostApi.CostQueryResponse>(`costs/environments/${args.id}`, {
          params: { timespan: args.timespan, granularity: args.granularity },
          hideNotification: true
        })
        .then(returnData),
    getProjectCost: (args: GetCostArgs) =>
      client
        .get<CostApi.CostQueryResponse>(`costs/projects/${args.id}`, {
          params: { timespan: args.timespan, granularity: args.granularity },
          hideNotification: true
        })
        .then(returnData),
    getOrganizationCosts: (args: GetCostArgs) => {
      const params: Omit<GetCostArgs, 'id' | 'projectIds' | 'accumulated'> & {
        organizationId: string;
        projectIds?: string;
        accumulated?: string;
      } = {
        ...omit(args, 'projectIds', 'id', 'accumulated'),
        organizationId: args.id,
        projectIds: args.projectIds?.join(','),
        accumulated: args.accumulated?.toString()
      };
      return client
        .get<CostApi.CostQueryResponseV2>(`costs`, {
          params,
          hideNotification: true
        })
        .then(returnData);
    },
    getWeeklyCost: async ({
      environmentIds,
      projectIds,
      organizationId
    }: {
      environmentIds?: string[];
      projectIds?: string[];
      organizationId?: string[];
    }): Promise<CostApi.GetWeeklyCosts.Response> => {
      const params: CostApi.GetWeeklyCosts.Request.QueryParams = {};
      if (environmentIds) params.environmentIds = environmentIds.join(',');
      if (projectIds) params.projectIds = projectIds.join(',');
      if (organizationId) params.organizationId = organizationId[0];
      return client.get<CostApi.GetWeeklyCosts.Response>(`costs/weekly`, { params }).then(returnData);
    },
    getProjectBudget: (projectId: string) =>
      client
        .get<CostApi.GetProjectBudget.Response>(`costs/project/${projectId}/budget`, { hideNotification: true })
        .then(returnData)
        .catch(error => {
          if (getResponseErrorStatus(error) === 404) {
            return null;
          }

          throw error;
        }),
    removeProjectBudget: (projectId: string) => client.delete<void>(`costs/project/${projectId}/budget`),
    upsertProjectBudget: (projectId: string, budgetPayload: CostApi.UpsertProjectBudget.Request.Body) =>
      client.put<CostApi.UpsertProjectBudget.Request.Body, CostApi.UpsertProjectBudget.Response>(
        `costs/project/${projectId}/budget`,
        budgetPayload
      ),
    getBudgetSummary: (projectIds: string) =>
      client
        .get<CostApi.GetBudgetSummary.Response>('costs/budget/summary', { params: { projectIds } })
        .then(returnData),
    getAccumulatedProjectCosts: (projectId: string) =>
      client
        .get<CostApi.GetAccumulatedProjectCosts.Response>('costs/budget/accumulated', {
          hideNotification: true,
          params: { projectId }
        })
        .then(returnData)
        .catch(error => {
          if (getResponseErrorStatus(error) === 404) {
            return null;
          }

          throw error;
        })
  };
};
