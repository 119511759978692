import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import type { DataNode } from 'antd/lib/tree';

export interface NodeProps extends DataNode {
  children?: NodeProps[];
  'data-e2e'?: string;
}
export const ROOT_KEY = '-root-';

type MapItemsOptions = {
  rootTitle?: string;
  withRoot?: boolean;
  disabledItemKeys?: string[];
  disabled?: boolean;
  leafRenderer?: (item: TreeItem) => EmotionJSX.Element;
  parentRenderer?: (item: TreeItem) => EmotionJSX.Element;
};

export type BaseTreeItem = {
  id: string;
  name: string;
};

export type TreeItem = BaseTreeItem & {
  children?: TreeItem[];
};

export const mapItemsToAntdTreeNodes = (
  rootItems: TreeItem[],
  { rootTitle, withRoot, disabledItemKeys, disabled, leafRenderer, parentRenderer }: MapItemsOptions,
  dataE2EPrefix: string = ''
): NodeProps[] => {
  const disabledItemsSet = new Set(disabledItemKeys);

  const mapper = (
    items: undefined | TreeItem[],
    disabledItemKeys?: Set<string>,
    disabled?: boolean
  ): NodeProps[] | undefined => {
    return items?.map(item => {
      const isDisabled = Boolean(disabled || disabledItemKeys?.has(item.id));
      return {
        title: renderTitle({
          item,
          leafRenderer,
          parentRenderer
        }),
        key: item.id,
        value: item.id,
        'data-e2e': `${dataE2EPrefix ? dataE2EPrefix + '-' : ''}tree-node-${item.name}`,
        disabled: isDisabled,
        ...(item.children ? { children: mapper(item.children, disabledItemKeys, disabled) } : undefined)
      };
    });
  };
  const tree = mapper(rootItems, disabledItemsSet, disabled);
  return !withRoot
    ? tree!
    : [
        {
          title: rootTitle,
          key: ROOT_KEY,
          'data-e2e': `${dataE2EPrefix ? dataE2EPrefix + '-' : ''}tree-node-root`,
          children: tree
        }
      ];
};

const renderTitle = ({
  item,
  leafRenderer,
  parentRenderer
}: {
  item: TreeItem;
  leafRenderer?: (item: TreeItem) => EmotionJSX.Element;
  parentRenderer?: (item: TreeItem) => EmotionJSX.Element;
}) => {
  const isLeaf = !item.children;

  if (isLeaf) {
    return leafRenderer ? leafRenderer(item) : item.name;
  }

  return parentRenderer ? parentRenderer(item) : item.name;
};
