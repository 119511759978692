import type { SingleUseBlueprint } from 'utils/deploy.utils';
import { deployTemplate, redeployEnvironment } from 'utils/deploy.utils';
import type { Blueprint, ConfigurationProperty, Environment } from 'types/api.types';
import type { NetworkStore } from 'stores/mobx/network.store';
import type { EnvironmentsStore } from 'stores/mobx/environments.store';
import type { EnvironmentApi, WorkflowSubEnvironmentRequest } from '@env0/environment-service/api';
import isEmpty from 'lodash/isEmpty';

const IN_PROGRESS_STATUSES: (EnvironmentApi.Environment['status'] | EnvironmentApi.DeploymentLogStatus)[] = [
  'DEPLOY_IN_PROGRESS',
  'DESTROY_IN_PROGRESS',
  'PR_PLAN_IN_PROGRESS',
  'REMOTE_PLAN_IN_PROGRESS',
  'ABORTING',
  'IN_PROGRESS'
];

export interface RunEnvironmentArgs {
  organizationId: string;
  projectId: string;
  template: Blueprint | SingleUseBlueprint;
  navigate: (path: string) => void;
  environmentName: string;
  blueprintRevision?: string;
  comment?: string;
  isRedeploy?: boolean;
  environment?: Environment;
  changedConfigurationProperties: ConfigurationProperty[];
  configurationSetChanges: EnvironmentApi.ConfigurationSetChanges;
  approveAutomatically: boolean;
  ttlRequest?: EnvironmentApi.TTLRequest;
  workspaceName?: string;
  continuousDeployment?: boolean;
  driftDetectionRequest?: EnvironmentApi.DriftDetectionRequest;
  networkStore: NetworkStore;
  environmentsStore: EnvironmentsStore;
  pullRequestPlanDeployments?: boolean;
  terragruntWorkingDirectory?: string;
  isRemoteBackend?: boolean;
  subEnvironments?: Record<string, WorkflowSubEnvironmentRequest>;
  isWorkflowFileValid: boolean;
  workflowDeploymentOptions?: EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'];
  isSingleUse?: boolean;
  k8sNamespace?: string;
  targets?: string[];
  blueprintId?: string;
}

export const isInProgress = (status?: EnvironmentApi.Environment['status'] | EnvironmentApi.DeploymentLogStatus) => {
  return !!status && IN_PROGRESS_STATUSES.includes(status);
};

export const isInProgressOrWaiting = (
  status?: EnvironmentApi.Environment['status'] | EnvironmentApi.DeploymentLogStatus
) => {
  return isInProgress(status) || status === 'WAITING_FOR_USER';
};

export const isActive = (status: EnvironmentApi.Environment['status']) => status === 'ACTIVE';

export const runEnvironment = ({
  organizationId,
  projectId,
  template,
  navigate,
  environmentName,
  blueprintRevision,
  comment,
  isRedeploy,
  environment,
  changedConfigurationProperties,
  configurationSetChanges,
  approveAutomatically,
  driftDetectionRequest,
  ttlRequest,
  workspaceName,
  continuousDeployment,
  networkStore,
  environmentsStore,
  pullRequestPlanDeployments,
  terragruntWorkingDirectory,
  isRemoteBackend,
  subEnvironments,
  workflowDeploymentOptions,
  isSingleUse,
  k8sNamespace,
  targets,
  blueprintId
}: Omit<RunEnvironmentArgs, 'isWorkflowFileValid'>) => {
  if (!environmentName) {
    return;
  }

  if (Array.isArray(targets) && isEmpty(targets)) {
    targets = undefined;
  }

  if (isRedeploy && environment) {
    return redeployEnvironment({
      environment,
      projectId,
      changedConfigurationProperties,
      configurationSetChanges,
      environmentName,
      blueprintRevision,
      comment,
      requiresApproval: !approveAutomatically,
      ttlRequest,
      navigate,
      networkStore,
      environmentsStore,
      subEnvironments,
      targets,
      workflowDeploymentOptions,
      blueprintId
    });
  }

  return deployTemplate({
    organizationId,
    projectId,
    blueprint: template,
    changedConfigurationProperties,
    configurationSetChanges,
    navigate,
    environmentName,
    blueprintRevision,
    comment,
    approveAutomatically,
    driftDetectionRequest,
    ttlRequest,
    workspaceName,
    continuousDeployment,
    networkStore,
    environmentsStore,
    pullRequestPlanDeployments,
    terragruntWorkingDirectory,
    isRemoteBackend,
    k8sNamespace,
    subEnvironments,
    isSingleUse,
    targets
  });
};

export const isWorkflowEnvironment = (environment: Environment) => !!environment?.latestDeploymentLog?.workflowFile;

export const isSubEnvironment = (environment: Environment) => !!environment?.workflowEnvironmentId;

export const isRemoteBackendEnvironment = (environment: Environment) => !!environment?.isRemoteBackend;

export const isEnvironmentLocked = (environment: Environment) => environment.isLocked;

export const isEnvironmentLockingProhibited = (environment: Environment) =>
  !isEnvironmentLocked(environment) && isInProgressOrWaiting(environment.status);

export const generateWorkspaceName = (environmentName: string) => {
  return `${environmentName.replaceAll('-', ' ').replaceAll(/\s+/g, '-')}-${Math.random().toString().slice(2, 10)}`;
};

export const isEnvironmentFailedToDestroyByTtl = (environment?: Environment) =>
  environment?.latestDeploymentLog.type === 'destroy' &&
  environment?.status === 'FAILED' &&
  environment?.latestDeploymentLog.triggerName === 'ttl';
