import React from 'react';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledCredentialTextInput from 'components/common/form-controlled/controlled-credential-text-input';

const prefix = 'settings.credentials.gcp';

const GcpServiceAccountInputs = ({ form, usageType }: CredentialsInputProps) => {
  if (usageType !== 'DEPLOYMENT') throw new Error('GCP Service Account is for deployment only');

  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.GCP_SERVICE_ACCOUNT_DOCS;
  return (
    <>
      <Link messageId="settings.credentials.gcp.credentials.explanation" url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.projectId` }} name="value.projectId" form={form} />
      <ControlledCredentialTextInput
        isSensitive={true}
        label={{ id: `${prefix}.serviceAccountKey` }}
        name="value.serviceAccountKey"
        form={form}
        required
      />
    </>
  );
};

export default GcpServiceAccountInputs;
