import type { NewCredential, Credential } from 'types/api.types';
import type { HttpClient } from 'services/api-client/client';
import type { ConfigurationApi } from '@env0/configuration-service/api';
import type { AxiosResponse } from 'axios';
import type { CredentialsApi } from '@env0/credentials-service/api';
import { returnData } from 'services/api-client/client';
import { isSafeRequestError } from 'axios-retry';
import type { AxiosError } from 'axios';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

export default (client: HttpClient) => ({
  getAll: (organizationId: string): Promise<{ data: Credential[] }> =>
    client.get<Credential[]>('credentials', {
      params: {
        organizationId
      }
    }),
  create: (organizationId: string, payload: NewCredential): Promise<{ data: Credential }> =>
    client.post<NewCredential & { organizationId: string }, AxiosResponse<Credential>>('credentials', {
      ...payload,
      organizationId
    }),
  update: (credentialId: string, updateFields: Partial<Credential>) =>
    client.patch<Partial<Credential>, AxiosResponse<Credential>>(`credentials/${credentialId}`, updateFields),
  delete: (credentialId: string) => client.delete(`credentials/${credentialId}`),
  getDeploymentCredentialsForProject: (projectId: string) =>
    client.get<ConfigurationApi.Credentials.Deployment.DeploymentCredentialsToProject>(
      `credentials/deployment/project/${projectId}`
    ),

  putDeploymentCredentialsForProject: (projectId: string, credentialIds: string[]) =>
    client.put<ConfigurationApi.Credentials.Deployment.DeploymentCredentialsToProject>(
      `credentials/deployment/project/${projectId}`,
      { credentialIds }
    ),

  getOidcJwtSub: (organizationId: string, retryUntilExists = false) =>
    client
      .get<CredentialsApi.GetOidcJwtToken.Response>(
        `api-keys/oidc-sub`,
        omitBy(
          {
            params: { organizationId },
            'axios-retry': retryUntilExists
              ? {
                  retries: 10,
                  retryDelay: () => 3000,
                  retryCondition: (error: AxiosError) => isSafeRequestError(error) || error?.response?.status === 404
                }
              : null
          },
          isNil
        )
      )
      .then(returnData)
});
