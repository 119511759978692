import React, { type Ref, useMemo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import sortBy from 'lodash/sortBy';
import OrganizationItem from 'components/layout/navbar/navbar-organization-item';
import { Item } from 'components/layout/navbar/navbar-panel.utils';
import CreateOrganizationModal from 'components/organizations/create-organization-modal';
import useStores from 'hooks/use-stores.hooks';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';
import { group } from 'utils/analytics.utils';
import pick from 'lodash/pick';
import { TOP_BAR_HEIGHT } from 'constants/themes.constants';

interface Props {
  innerRef?: Ref<HTMLDivElement>;
  onClose: () => void;
  onChange: (organization: Organization) => void;
  className?: string;
}

const OrganizationSwitcher = ({ innerRef, onClose, onChange, className }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    organizationsStore: { organizations, currentOrganizationId, currentOrganization }
  } = useStores();

  const openModal = () => {
    setModalOpen(true);
    onClose();
  };

  const items = useMemo(
    () =>
      sortBy(
        Object.values(organizations).filter(({ id }) => id !== currentOrganizationId),
        org => org.name
      ),
    [organizations, currentOrganizationId]
  );

  useEffect(() => {
    if (currentOrganization) {
      group(currentOrganization.id, pick(currentOrganization, ['name', 'mode', 'role']));
    }
  }, [currentOrganization]);

  return (
    <Wrapper ref={innerRef} className={className} data-e2e="navbar-organization-switcher">
      {items.map(organization => (
        <OrganizationItem
          key={organization.id}
          organization={organization}
          onClick={() => onChange(organization)}
          data-e2e={`navbar-organization-switcher-item-${organization.id}`}
        />
      ))}
      <CreateOrganizationItem onClick={openModal} data-e2e="navbar-organization-switcher-add">
        <StyledPlusIcon />
        <span>
          <FormattedMessage id="navigation.new-organization" />
        </span>
      </CreateOrganizationItem>
      <CreateOrganizationModal open={modalOpen} hideModal={() => setModalOpen(false)} />
    </Wrapper>
  );
};

export default OrganizationSwitcher;

const Wrapper = styled.div`
  /* Subtract bottom organization item height + its 1px border and top logo bar height */
  max-height: calc(100vh - var(--item-height) - 1px - ${TOP_BAR_HEIGHT});
  overflow-y: auto;
  background: ${({ theme }) => theme.menuItemBlue};
`;

const CreateOrganizationItem = styled(Item)`
  color: ${({ theme }) => theme.textMetalBlue};
  transition: color 0.1s ease-in-out;

  &:hover {
    color: white;
  }
`;

const StyledPlusIcon = styled(PlusOutlined)`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textMetalBlue};
  transition: color 0.1s ease-in-out;

  *:hover > & {
    color: ${({ theme }) => theme.menuItemGreen};
  }
`;
