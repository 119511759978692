import React, { useCallback, useMemo, useState } from 'react';
import Button from 'components/common/button';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import { useModal } from 'hooks/modal.hooks';
import { FormattedMessage } from 'react-intl';
import type { CredentialProviderType, Credential, CredentialUsageType } from 'types/api.types';
import AddNewCredentialSuccessModal from 'components/organizations/credentials/add-new-credential-success-modal';
import useCredentialModalForm from 'components/organizations/credentials/credential-modal-form.hook';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import {
  getCredentialsTypeByUsageType,
  getCredentialTypesByUsageTypeAndCredentialProvider
} from 'utils/credential.utils';
import EditIcon from 'components/common/edit-icon';
import CredentialFields from 'components/organizations/credentials/credential-fields';

type CredentialModalProps = {
  credentialProvider?: CredentialProviderType;
  open: boolean;
  hideModal: () => void;
  usageType: CredentialUsageType;
  credential?: Credential;
};

export type CredentialsInputProps = {
  form: SubFormProps['form'];
  usageType: CredentialUsageType;
};

export const CredentialModal = ({
  credentialProvider,
  open,
  hideModal,
  usageType,
  credential
}: CredentialModalProps) => {
  const prefix = `settings.credentials.${credential ? 'update' : 'add.new'}`;
  const credentialTypeOptions = useMemo(
    () =>
      credentialProvider
        ? getCredentialTypesByUsageTypeAndCredentialProvider(usageType, credentialProvider)
        : getCredentialsTypeByUsageType(usageType),
    [usageType, credentialProvider]
  );
  const initialType = credential?.type || credentialTypeOptions[0];
  const [successCredential, setSuccessCredential] = useState<Credential | null>(null);

  const onCreatedHandler = useCallback(
    (newCredential: Credential) => {
      if (!credential) {
        setSuccessCredential(newCredential);
      }
      hideModal();
    },
    [hideModal, credential]
  );

  const form = useCredentialModalForm({
    initialValues: {
      type: initialType,
      name: credential?.name
    },
    onCreated: onCreatedHandler,
    credentialId: credential?.id,
    usageType
  });

  return (
    <>
      {successCredential && (
        <AddNewCredentialSuccessModal
          usageType={usageType}
          credential={successCredential}
          hideModal={() => setSuccessCredential(null)}
        />
      )}

      <ControlledModal
        data-e2e="credential-modal"
        open={open}
        form={form}
        onCancel={hideModal}
        titleId={`${prefix}.${usageType}.title`}
        saveMessageId={`${credential ? 'save.changes' : 'settings.tokens.add.new.button.text'}`}>
        <CredentialFields
          usageType={usageType}
          credentialTypeOptions={credentialTypeOptions}
          form={form}
          isUpdate={!!credential}
        />
      </ControlledModal>
    </>
  );
};

interface AddNewCredentialModalButtonProps {
  usageType: CredentialUsageType;
}

export const AddNewCredentialModalButton = ({ usageType }: AddNewCredentialModalButtonProps) => {
  const { showModal, hideModal, isModalOpen } = useModal();
  return (
    <>
      <Button
        onClick={showModal}
        type="primary"
        data-e2e={`organization-add-new-${usageType.toLowerCase()}-credentials`}>
        <FormattedMessage id="settings.credentials.add.new.button" />
      </Button>

      <CredentialModal usageType={usageType} open={isModalOpen} hideModal={hideModal} />
    </>
  );
};

interface UpdateCredentialModalButtonProps {
  usageType: CredentialUsageType;
  credential: Credential;
}

export const UpdateCredentialModalButton = ({ usageType, credential }: UpdateCredentialModalButtonProps) => {
  const { showModal, hideModal, isModalOpen } = useModal();
  return (
    <>
      <EditIcon onClick={showModal} />

      <CredentialModal usageType={usageType} open={isModalOpen} hideModal={hideModal} credential={credential} />
    </>
  );
};

export default CredentialModal;
