import { useCallback, useState } from 'react';

export const useModal = (startAsOpen = false) => {
  const [isModalOpen, setIsModalOpen] = useState(startAsOpen);

  return {
    showModal: useCallback(() => setIsModalOpen(true), []),
    hideModal: useCallback(() => setIsModalOpen(false), []),
    isModalOpen
  };
};
