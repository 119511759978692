import type { HttpClient } from 'services/api-client/client';
import type { NotificationsApi } from '@env0/notification-service/api';
import { returnData } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  findEndpointsByOrganizationId: async (organizationId: string) =>
    client
      .get<NotificationsApi.FindNotificationEndpoints.Response>('notifications/endpoints', {
        params: { organizationId }
      })
      .then(returnData),
  deleteEndpoint: async (endpointId: string) => client.delete(`notifications/endpoints/${endpointId}`),
  createEndpoint: async (request: NotificationsApi.CreateNotificationEndpoint.Request.Body) =>
    client
      .post<
        NotificationsApi.CreateNotificationEndpoint.Request.Body,
        AxiosResponse<NotificationsApi.CreateNotificationEndpoint.Response>
      >('notifications/endpoints', request)
      .then(returnData),
  updateEndpoint: async (id: string, request: NotificationsApi.UpdateNotificationEndpoint.Request.Body) =>
    client
      .patch<
        NotificationsApi.UpdateNotificationEndpoint.Request.Body,
        AxiosResponse<NotificationsApi.UpdateNotificationEndpoint.Response>
      >(`notifications/endpoints/${id}`, request)
      .then(returnData),
  findNotificationSettingsByProjectId: async (projectId: string) =>
    client
      .get<NotificationsApi.FindProjectNotificationSettings.Response>(`notifications/projects/${projectId}`)
      .then(returnData),
  updateNotificationSettings: async (
    projectId: string,
    endpointId: string,
    body: NotificationsApi.UpdateProjectNotificationSettings.Request.Body
  ) => {
    const path = `notifications/projects/${projectId}/endpoints/${endpointId}`;
    return client
      .put<
        NotificationsApi.UpdateProjectNotificationSettings.Request.Body,
        AxiosResponse<NotificationsApi.UpdateProjectNotificationSettings.Response>
      >(path, body)
      .then(returnData);
  },
  testWebhookNotificationEndpoint: async (endpointId: string) => {
    const path = `notifications/endpoints/${endpointId}/test`;
    return client
      .post<
        NotificationsApi.TestWebhookNotificationEndpoint.Request.Body,
        AxiosResponse<NotificationsApi.TestWebhookNotificationEndpoint.Response>
      >(path)
      .then(returnData);
  }
});
