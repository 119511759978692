import type { Blueprint } from 'types/api.types';
import type { VCSStepValues } from 'components/templates/templates-wizard/common/template-wizard.types';
import { useMemo } from 'react';
import { getVcsStepSchema } from 'components/templates/templates-wizard/common/template-wizard-schemas';
import useForm from 'hooks/use-form.hook';
import noop from 'lodash/noop';
import { getFormInitialValues } from 'components/templates/templates-wizard/common/template-wizard-utils';
import type { ConfigurationTemplateType } from 'types/api.types';

interface UseConfigurationTemplateWizardProps {
  blueprint?: Blueprint;
  type: ConfigurationTemplateType;
}

export const useConfigurationTemplateWizardHook = ({ blueprint, type }: UseConfigurationTemplateWizardProps) => {
  const schema = useMemo(() => getVcsStepSchema(type), [type]);
  const initialValues = useMemo(() => getFormInitialValues(schema, blueprint), [schema, blueprint]);

  const vcsStepForm = useForm<VCSStepValues>({
    initialValues,
    schema,
    onSubmit: noop
  });

  return {
    isSaveEnabled: vcsStepForm.formState.isValid && vcsStepForm.formState.isDirty,
    vcsStepForm
  };
};
