import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import { remoteBackendUrl } from 'constants/config';
import type { RemoteBackendApi } from '@env0/remote-backend-service/api';

export default (client: HttpClient) => ({
  getStateVersions: (workspaceId: string): Promise<RemoteBackendApi.StateVersion[]> =>
    client
      .get(`/workspaces/${workspaceId}/versions`, { baseURL: remoteBackendUrl })
      .then(returnData)
      .then(response => response.data.versions),
  downloadRemoteState: (workspaceId: string, remoteStateVersionId: string): Promise<string> =>
    client
      .get(`/api/v2/workspaces/${workspaceId}/versions/${remoteStateVersionId}/download-state`, {
        baseURL: remoteBackendUrl,
        transformResponse: res => res
      })
      .then(returnData)
});
