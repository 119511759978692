import React, { forwardRef } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxProps as AntdCheckboxProps } from 'antd';
import styled from 'types/theme.types';
import omit from 'lodash/omit';

type Env0CheckboxProps = {
  big?: true;
};

type CheckboxProps = AntdCheckboxProps & Env0CheckboxProps;

const BigCheckbox = styled(AntdCheckbox)`
  & span.ant-checkbox {
    &:not(.ant-checkbox-indeterminate) {
      span.ant-checkbox-inner {
        ::after {
          transform: rotate(45deg) scale(1.25) translate(-35%, -45%);
        }
      }
    }

    span.ant-checkbox-inner {
      height: 20px;
      width: 20px;
    }
  }
`;

// must be `label` to bind text to checkbox input
const OuterCheckboxContainer = styled.label`
  display: flex;
  gap: 5px;
`;

const CheckboxTextContainer = styled.span<Env0CheckboxProps>`
  font-size: ${({ big }) => (big ? 14 : 13)}px;
  color: ${({ theme }) => theme.primaryBlack} !important;
  font-weight: 100;
  display: flex;
  align-items: ${({ big }) => (big ? 'center' : 'end')};
`;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ children, big, ...props }, ref) => {
  const CheckBoxComponent = big ? BigCheckbox : AntdCheckbox;

  return (
    <OuterCheckboxContainer>
      <CheckBoxComponent ref={ref} {...omit(props, ['hideError'])} />
      <CheckboxTextContainer big={big}>{children}</CheckboxTextContainer>
    </OuterCheckboxContainer>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
