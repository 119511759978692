import type { Blueprint, GitProviderTypes } from 'types/api.types';

export const isSelfHostedVcsIntegratedFields: (keyof Blueprint)[] = [
  'isBitbucketServer',
  'isGitLabEnterprise',
  'isGitHubEnterprise'
];

export const hasRevisionSupport = (blueprint: Blueprint) => !blueprint.isHelmRepository;

export const isGitProviderIntegrated = (blueprint: Blueprint) =>
  isSaasVcsIntegrated(blueprint) || isSelfHostedVcsIntegrated(blueprint);

export const isSaasVcsIntegrated = (blueprint: Blueprint) =>
  Boolean(blueprint.githubInstallationId) ||
  Boolean(blueprint.isGitLab) ||
  Boolean(blueprint.bitbucketClientKey) ||
  Boolean(blueprint.isAzureDevOps);

export const isSelfHostedVcsIntegrated = (partialBlueprint: Partial<Blueprint>) =>
  isSelfHostedVcsIntegratedFields.some(field => partialBlueprint[field]);

export const isHostedGitProvider = (provider?: GitProviderTypes) =>
  provider === 'BitBucketServer' || provider === 'GitLabEnterprise' || provider === 'GitHubEnterprise';

export const getEditTemplateUrl = (blueprintId: string) => {
  return `/templates/${blueprintId}/settings`;
};

export const getEditSingleUseTemplateUrl = (blueprintId: string, projectId: string, environmentId: string) => {
  return `/p/${projectId}/environments/${environmentId}/templates/${blueprintId}/settings/vcs`;
};
