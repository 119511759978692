import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'types/theme.types';
import { EllipsisContainer } from 'components/common/form-components';
import { getEventDisplayText } from 'components/projects/settings/notifications/event-names-display-text';
import { EventNameType } from '@env0/notification-service/api.enum';

type Props = {
  eventNames: EventNameType[];
};

const CUSTOM_TEXT_PREFIX = 'projects.settings.notifications.events';

const allEventNamesCount = Object.keys(EventNameType).length;
const NotificationEventNames: React.FunctionComponent<Props> = ({ eventNames }) => {
  const intl = useIntl();

  const enabledEventsCount = eventNames.length;
  const text = useMemo(() => {
    if (enabledEventsCount === 0) {
      return intl.formatMessage({ id: 'none' });
    }
    if (enabledEventsCount === allEventNamesCount) {
      return intl.formatMessage({ id: `${CUSTOM_TEXT_PREFIX}.all` });
    }

    return eventNames.map(eventName => getEventDisplayText(intl, eventName)).join(', ');
  }, [eventNames, enabledEventsCount, intl]);

  const eventNumOutOfAllText = `(${enabledEventsCount}/${allEventNamesCount})`;

  return (
    <HorizontalDiv>
      <EllipsisContainer data-e2e="event-names-description" maxWidth={400}>
        {text}
      </EllipsisContainer>
      <StyledSpan data-e2e="event-names-number">{eventNumOutOfAllText}</StyledSpan>
    </HorizontalDiv>
  );
};

const HorizontalDiv = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  margin-left: 5px;
`;

export default NotificationEventNames;
