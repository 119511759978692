import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { NotificationsApi } from '@env0/notification-service/api';

export default (client: HttpClient) => ({
  getAppNotifications: () => {
    return client
      .get<NotificationsApi.FindInAppNotifications.Response>(`notifications/in-app`, { hideNotification: true })
      .then(returnData);
  }
});
