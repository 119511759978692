import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { AccountApi } from '@env0/account-service/api';
import type { CreateVcsProviderUserMapping, UpdateVcsProviderUserMapping } from 'types/api.types';

export default (client: HttpClient) => ({
  getVcsProviderUserMappings: () => {
    return client.get<AccountApi.VcsProviderUserMapping[]>(`users/me/vcs-provider-user-mappings`).then(returnData);
  },
  createVcsProviderUserMapping: (payload: CreateVcsProviderUserMapping) => {
    return client
      .post<AccountApi.VcsProviderUserMapping>(`users/me/vcs-provider-user-mappings`, payload)
      .then(returnData);
  },
  updateVcsProviderUserMapping: (payload: UpdateVcsProviderUserMapping) => {
    return client
      .put<AccountApi.VcsProviderUserMapping>(`vcs-provider-user-mappings/${payload.id}`, payload)
      .then(returnData);
  }
});
