import useForm from 'hooks/use-form.hook';
import noop from 'lodash/noop';
import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import type { Optional } from 'utility-types';
import { useCallback } from 'react';
import defaults from 'lodash/defaults';
import isGlob from 'is-glob';
import type { UpsertEnvironmentDiscoveryMapping } from 'components/projects/settings/environment-discovery/project-settings-environment-discovery';
import type { BlueprintApi } from '@env0/blueprint-service/api';

export const DEFAULT_TG_GLOB_PATTERN = '**/!(_envcommon)/terragrunt.hcl';
export const DEFAULT_TF_GLOB_PATTERN = '**/!(modules)/main.tf';
export const DEFAULT_WF_GLOB_PATTERN = '**/env0.workflow.{yaml,yml}';
export const DEFAULT_ENVIRONMENT_PLACEMENT = 'topProject';

export type EnvironmentDiscoveryMappingFormReturnType = ReturnType<typeof useEnvironmentDiscoveryMappingForm>;

type EnvironmentDiscoveryMappingFormProps = Optional<
  Omit<
    BlueprintApi.EnvironmentDiscoveryFields,
    'workspaceNaming' | 'autoDeployByCustomGlob' | 'createNewEnvironmentsFromPullRequests'
  >
>;

const environmentDiscoveryMappingSchema: SchemaOf<UpsertEnvironmentDiscoveryMapping> = Yup.object({
  globPattern: Yup.string()
    .default('')
    .test('is-glob', 'glob.validation.error', text => isGlob(text)),
  environmentPlacement: Yup.mixed<BlueprintApi.EnvironmentPlacementStrategy>()
    .required()
    .oneOf(['existingSubProject', 'topProject'])
    .default(DEFAULT_ENVIRONMENT_PLACEMENT),
  rootPath: Yup.string().optional()
});

const useEnvironmentDiscoveryMappingForm = ({
  globPattern,
  environmentPlacement,
  rootPath
}: EnvironmentDiscoveryMappingFormProps) => {
  const getInitialValues = useCallback<() => UpsertEnvironmentDiscoveryMapping>(() => {
    const schemaDefaults = environmentDiscoveryMappingSchema.getDefault() as UpsertEnvironmentDiscoveryMapping;
    return defaults<EnvironmentDiscoveryMappingFormProps, UpsertEnvironmentDiscoveryMapping>(
      {
        globPattern,
        rootPath,
        environmentPlacement
      },
      { ...schemaDefaults }
    );
  }, [globPattern, environmentPlacement, rootPath]);

  return useForm<UpsertEnvironmentDiscoveryMapping>({
    initialValues: getInitialValues(),
    schema: environmentDiscoveryMappingSchema,
    shouldUnregister: false,
    onSubmit: noop
  });
};

export default useEnvironmentDiscoveryMappingForm;
