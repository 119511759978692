import BaseService from 'services/base-service';
import type { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import type { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import type { ConfigurationProperty } from 'types/api.types';
import type { IReactionDisposer } from 'mobx';
import { action, reaction } from 'mobx';

type ExpressionsForReaction = () => ConfigurationPropertyStore[];

export abstract class HierarchicalVariablesStore extends BaseService {
  protected disposeChanges: (() => void) | undefined;

  @action
  public registerToChanges() {
    const reactionDisposers: IReactionDisposer[] = [];

    for (const expression of this.getExpressionsForReactions()) {
      reactionDisposers.push(
        reaction<ConfigurationPropertyStore[]>(expression, changedConfigurationProperties => {
          this.propagateVariableChanges(changedConfigurationProperties);
        })
      );
    }

    this.disposeChanges = () => reactionDisposers.forEach(reactionDisposer => reactionDisposer());
  }

  protected abstract getExpressionsForReactions(): ExpressionsForReaction[];

  protected findChangedProperty(
    configurationProperties: ConfigurationPropertyStore[],
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    return configurationProperties.find(env => {
      // if the variable was created in the UI, the ID is reflected from the parent store to the child stores
      // otherwise, it was created in server, so the matching is based on name
      const compareByName = env.data.name === changedConfigurationProperty.data.name;
      const compareById = env.id === changedConfigurationProperty.id;
      return compareById || compareByName;
    });
  }

  protected abstract isVariableOverridden(
    changedConfigurationProperty: ConfigurationPropertyStore,
    existingProperty: ConfigurationPropertyStore
  ): boolean;

  private propagatePropertiesChangesToChildScopes(
    changedChildStores: ConfigurationPropertiesStore[],
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    changedChildStores.forEach(childStore => {
      const existingProperty = this.findChangedProperty(
        childStore.allVariablesIncludingDeleted,
        changedConfigurationProperty
      );

      const variableWasOverride =
        existingProperty && this.isVariableOverridden(changedConfigurationProperty, existingProperty);

      const variableWasCreated = !existingProperty && changedConfigurationProperty.isShow;
      const variableWasDeleted = !!existingProperty && !changedConfigurationProperty.isShow && existingProperty.isShow;
      const variableWasUpdated =
        !!existingProperty &&
        changedConfigurationProperty.isShow &&
        !isEqual(
          omit(changedConfigurationProperty.data, ['id', 'scopeId', 'schema']),
          omit(existingProperty.data, ['id', 'scopeId', 'schema'])
        );

      if (variableWasOverride) {
        this.onPropagateChangesOfOverriddenVariable(childStore, changedConfigurationProperty, existingProperty, {
          variableWasCreated,
          variableWasDeleted,
          variableWasUpdated
        });
        return;
      }

      if (variableWasCreated) {
        childStore.addConfigurationProperty(
          this.buildPropagatedCreatedVariable(childStore, changedConfigurationProperty)
        );
      } else if (variableWasDeleted) {
        existingProperty.delete();
      } else if (variableWasUpdated) {
        existingProperty.propagatePropertyValue({
          ...this.buildPropagatedUpdatedVariable(changedConfigurationProperty, childStore)
        });
      }
    });
  }

  protected propagateVariableChanges(changedConfigurationProperties: ConfigurationPropertyStore[]): void {
    changedConfigurationProperties.forEach(changedEnvironmentConfig => {
      this.propagatePropertiesChangesToChildScopes(
        this.getRelevantStoresForPropagation(changedEnvironmentConfig),
        changedEnvironmentConfig
      );
    });
  }

  abstract buildPropagatedCreatedVariable(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertyStore;

  abstract buildPropagatedUpdatedVariable(
    changedConfigurationProperty: ConfigurationPropertyStore,
    childStore: ConfigurationPropertiesStore
  ): ConfigurationProperty;

  abstract getRelevantStoresForPropagation(
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertiesStore[];

  protected onPropagateChangesOfOverriddenVariable(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore,
    existingProperty: ConfigurationPropertyStore,
    changes: {
      variableWasCreated: boolean;
      variableWasDeleted: boolean;
      variableWasUpdated: boolean;
    }
  ) {}

  abstract cleanup(): void;
}
