import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { notification as notificationFunction } from 'antd';
import omit from 'lodash/fp/omit';
import { useIntl } from 'react-intl';
import useStores from 'hooks/use-stores.hooks';
import { observer } from 'mobx-react';
import type { NotificationArgs } from 'types/notifications.types';

const NotificationManager: React.FunctionComponent = () => {
  const { notificationStore } = useStores();
  const { notification } = notificationStore;

  const lastNotification = useRef<NotificationArgs | null>(null);

  const intl = useIntl();

  const onNotificationClose = useCallback(() => (lastNotification.current = null), []);

  useEffect(() => {
    if (notification) {
      if (!notification.message) {
        return;
      }

      const message = intl.formatMessage({ id: notification.message }, notification.messageVariables);

      if (
        lastNotification.current?.type !== notification?.type ||
        lastNotification.current?.message !== notification?.message
      ) {
        notificationFunction[notification.notificationType]({
          ...omit(['notificationType', 'messageVariables'], notification),
          message,
          style: { whiteSpace: 'pre-line', width: 'fit-content', maxWidth: 500 },
          className: `e2e-${notification.notificationType}-notification`, // only way to get an html identifier on the notification
          onClose: onNotificationClose,
          duration: notification.notificationType === 'error' ? 0 : 5
        });

        lastNotification.current = notification;
      }
    }
  }, [notification, intl, onNotificationClose]);

  return null;
};

export default observer(NotificationManager);
