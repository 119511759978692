import { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import { action, computed, observable } from 'mobx';
import some from 'lodash/some';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { ConfigurationProperty, VariablesPolicies } from 'types/api.types';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import type ServiceContainer from 'services/service-container';
import { HierarchicalVariablesStore } from 'stores/mobx/hierarchical-variables-store';

export const entireWorkflowAlias = 'entireWorkflow';

export class WorkflowVariablesStore extends HierarchicalVariablesStore {
  @observable
  private workflowConfigurationPropertiesStores: Record<string, ConfigurationPropertiesStore> = {};
  private entireWorkflowConfigurationPropertiesStore: ConfigurationPropertiesStore;

  constructor(service: ServiceContainer) {
    super(service);
    this.entireWorkflowConfigurationPropertiesStore = new ConfigurationPropertiesStore(this.service);
  }

  getExpressionsForReactions() {
    return [() => this.entireWorkflowConfigurationPropertiesStore.environmentTypeAndScopeConfigurationProperties];
  }

  public init(subEnvironments: Record<string, EnvironmentApi.WorkflowEnvironments.WorkflowSubEnvironment>) {
    this.workflowConfigurationPropertiesStores = Object.fromEntries(
      Object.keys(subEnvironments).map(alias => [alias, new ConfigurationPropertiesStore(this.service)])
    );
  }

  public async load(
    subEnvironments: Record<string, EnvironmentApi.WorkflowEnvironments.WorkflowSubEnvironment>,
    projectId: string | undefined,
    workflowEnvironmentId: string | undefined,
    variablesPolicies?: Partial<VariablesPolicies>
  ) {
    await Promise.allSettled(
      Object.keys(subEnvironments).map(alias => {
        const subEnvironment = subEnvironments[alias];
        const subEnvironmentConfigurationStore = this.getConfigurationPropertiesStoreByAlias(alias);
        subEnvironmentConfigurationStore.resetConfigurationSetChanges();
        return subEnvironmentConfigurationStore.loadProperties(
          {
            projectId,
            blueprintId: subEnvironment.templateId,
            workflowEnvironmentId: workflowEnvironmentId,
            scope: alias !== entireWorkflowAlias ? ConfigurationScope.ENVIRONMENT : ConfigurationScope.WORKFLOW,
            scopeId: subEnvironment.environmentId,
            environmentId: subEnvironment.environmentId
          },
          true,
          undefined,
          variablesPolicies
        );
      })
    );
  }

  // We call this in the hook on setup in order to propagate the workflow bp variables to the sub environments
  // since unlike project&org variables they don't get propagated from the API
  public propagateWorkflowBlueprintVariablesToSubEnvironments() {
    this.propagateVariableChanges(
      this.getConfigurationPropertiesStoreByAlias(entireWorkflowAlias).environmentVariables.filter(
        property => property.scope === ConfigurationScope.BLUEPRINT
      )
    );
  }

  public getConfigurationPropertiesStores() {
    return this.workflowConfigurationPropertiesStores;
  }

  public getConfigurationPropertiesStoreByAlias(environment: string) {
    return environment === entireWorkflowAlias
      ? this.entireWorkflowConfigurationPropertiesStore
      : this.workflowConfigurationPropertiesStores[environment];
  }

  public getConfigurationChanges(environment: string) {
    return this.getConfigurationPropertiesStoreByAlias(environment).getConfigurationPropertiesChangesForDeployment();
  }

  @computed get environmentErrors() {
    return map(
      pickBy(this.workflowConfigurationPropertiesStores, store => store.hasError),
      (_, alias) => alias
    );
  }

  @computed get hasError() {
    return (
      this.entireWorkflowConfigurationPropertiesStore.hasError ||
      some(this.workflowConfigurationPropertiesStores, store => store.hasError)
    );
  }

  protected isVariableOverridden(
    changedConfigurationProperty: ConfigurationPropertyStore,
    existingProperty: ConfigurationPropertyStore
  ): boolean {
    return existingProperty.scope === ConfigurationScope.ENVIRONMENT;
  }

  buildPropagatedCreatedVariable(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    const newData: ConfigurationProperty = {
      ...changedConfigurationProperty.data,
      scope: ConfigurationScope.WORKFLOW,
      id: changedConfigurationProperty.id
    };
    return new ConfigurationPropertyStore(childStore, newData);
  }

  buildPropagatedUpdatedVariable(
    changedConfigurationProperty: ConfigurationPropertyStore,
    childStore: ConfigurationPropertiesStore
  ) {
    return changedConfigurationProperty.data;
  }

  getRelevantStoresForPropagation(
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertiesStore[] {
    return Object.values(this.workflowConfigurationPropertiesStores);
  }

  @action
  public cleanup() {
    this.workflowConfigurationPropertiesStores = {};
    this.disposeChanges?.();
  }
}
