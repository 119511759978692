import Divider from 'components/common/divider';
import GitOnPremWebhookFields from 'components/templates/templates-wizard/vcs/git-on-prem-webhook.fields';
import { links } from 'constants/external-links';
import { bitbucketServerWebhookUrl, gitHubEnterpriseWebhookUrl, gitLabEnterpriseWebhookUrl } from 'constants/config';
import { isSsh } from 'components/templates/templates-wizard/url-utils';
import React from 'react';
import type { GitProviderTypes, Tokens } from 'types/api.types';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import SshKeysField from 'components/templates/templates-wizard/settings/ssh-keys-field';
import TokenSelectField from 'components/templates/templates-wizard/vcs/token-select-field';
import Link from 'components/common/link';

interface Props {
  gitProvider: GitProviderTypes;
  webhookSecret: string | null;
  form: VCSForm;
  tokens: Tokens;
}

export const AuthSection: React.FC<Props> = ({ form, gitProvider, webhookSecret, tokens }) => {
  const repository = form.watch('repository');
  const SshKeysFieldLabel = <SshKeysField form={form} />;
  const GitTokenFieldLabel = (
    <>
      <TokenSelectField
        width={49}
        label={{ id: 'templates.add.new.field.token' }}
        data-e2e="token-select-field"
        form={form}
        gitProvider={gitProvider}
        info={{
          id: 'templates.add.new.information.token',
          values: {
            link: (text: string) => <Link plainText messageId={text} url={links.docs.TEMPLATES.TOKENS} />
          }
        }}
        tokens={tokens}
      />
    </>
  );

  return (
    <div data-e2e="authentication-section">
      <Divider data-e2e="authentication-divider" titleId="templates.add.new.divider.authentication" />
      {gitProvider === 'BitBucketServer' && (
        <GitOnPremWebhookFields
          docsLink={links.docs.TEMPLATES.BITBUCKET_SERVER_INTEGRATION}
          gitProvider={gitProvider}
          secretDocsLink={links.BITBUCKET_SERVER.WEBHOOK_SECRET_DOCS}
          webhookBaseUrl={bitbucketServerWebhookUrl}
          data-e2e={'git-on-prem-webhook-fields-bit-bucket-server'}
          webhookSecret={webhookSecret!}
        />
      )}
      {gitProvider === 'GitLabEnterprise' && (
        <GitOnPremWebhookFields
          docsLink={links.docs.TEMPLATES.GITLAB_EE_INTEGRATION}
          gitProvider={gitProvider}
          secretDocsLink={links.GITLAB_EE.WEBHOOK_SECRET_DOCS}
          webhookBaseUrl={gitLabEnterpriseWebhookUrl}
          data-e2e={'git-on-prem-webhook-fields-git-lab-enterprise'}
          webhookSecret={webhookSecret!}
        />
      )}
      {gitProvider === 'GitHubEnterprise' && (
        <GitOnPremWebhookFields
          docsLink={links.docs.TEMPLATES.GITHUB_ENTERPRISE_INTEGRATION}
          gitProvider={gitProvider}
          secretDocsLink={links.GITHUB_ENTERPRISE.WEBHOOK_SECRET_DOCS}
          webhookBaseUrl={gitHubEnterpriseWebhookUrl}
          data-e2e={'git-on-prem-webhook-fields--git-hub-enterprise'}
          webhookSecret={webhookSecret!}
        />
      )}
      {gitProvider === 'Other' && (isSsh(repository) ? SshKeysFieldLabel : GitTokenFieldLabel)}
    </div>
  );
};
