import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { Module, ModuleReadme } from 'types/api.types';
import type { AxiosResponse } from 'axios';
import type { ModuleTestingApi } from '@env0/module-testing-service/api';

export const MODULE_TESTS_PAGE_SIZE = 25;

export default (client: HttpClient) => ({
  getModules: (organizationId: string) =>
    client.get<Module[]>(`modules`, { params: { organizationId } }).then(returnData),

  getModule: (id: string) => client.get<Module>(`modules/${id}`).then(returnData),

  getModuleTest: (moduleTestId: string) =>
    client.get<ModuleTestingApi.FullModuleTestRun>(`module/tests/${moduleTestId}`).then(returnData),

  getVersions: (moduleId: string) =>
    client
      .get<BlueprintApi.GetModuleVersions.Response>(`modules/${moduleId}/versions`)
      .then(returnData)
      .then(versions => versions.map(version => ({ id: version.version, ...version }))),

  getReadme: (moduleId: string, releaseId: string): Promise<ModuleReadme> =>
    client
      .get<BlueprintApi.GetModuleReadme.Response>(`modules/${moduleId}/versions/${encodeURIComponent(releaseId)}`)
      .then(returnData)
      .then(readme => ({ id: releaseId, content: readme })),

  deleteModule: (id: string) => client.delete<void>(`modules/${id}`),

  createModule: (module: BlueprintApi.CreateModule.Request.Body) =>
    client
      .post<BlueprintApi.CreateModule.Request.Body, AxiosResponse<Module>>('modules', { ...module, type: 'module' })
      .then(returnData),

  updateModule: (id: string, module: BlueprintApi.UpdateModule.Request.Body) =>
    client
      .patch<BlueprintApi.UpdateModule.Request.Body, AxiosResponse<Module>>(`modules/${id}`, module)
      .then(returnData),

  runModuleTest: (moduleId: string) => client.post(`module/${moduleId}/test`),

  getModuleTests: (moduleId: string, offset: string = '0', limit: number = MODULE_TESTS_PAGE_SIZE) =>
    client
      .get<ModuleTestingApi.GetModuleTestRuns.Response>(`module/${moduleId}/tests`, { params: { offset, limit } })
      .then(returnData)
});
