import type { DeploymentLog } from 'types/api.types';
import isEmpty from 'lodash/isEmpty';

export const isTargetedDeployment = (deployment?: DeploymentLog): boolean => {
  return deployment?.type === 'deploy' && !isEmpty(deployment.targets);
};

export type TargetsSelection = {
  selectionMode: string;
  selectedTargets?: string[];
};

export const targetsSelectionValidationError = ({
  selectionMode,
  selectedTargets
}: TargetsSelection): string | null => {
  if (selectionMode === 'all') {
    if (selectedTargets !== undefined) {
      throw new Error(`targets selection mode is 'all' but selected targets are not empty`);
    }
    return null;
  }

  if (selectedTargets === undefined || isEmpty(selectedTargets)) {
    return 'targeted-deployments.no-resource-selected-error';
  }
  return null;
};
