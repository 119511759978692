import React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from 'components/common/card';
import styled from 'types/theme.types';
import type { EnvironmentApi } from '@env0/environment-service/api';
import type {} from 'components/projects/settings/policies/use-policies-form.hook';
import { useEnvironmentSettingsForm } from 'components/projects/settings/policies/use-policies-form.hook';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import { TtlPolicySection } from 'components/policies/ttl-policy-section';
import RemoteStateSettings from 'components/projects/settings/policies/sections/remote-state-settings';
import { useUpdateProjectPolicies } from 'stores/rq/project-policies';
import Divider from 'components/common/divider';

type EnvironmentSettingsProps = {
  policies: EnvironmentApi.Policy;
};

export const messagePrefix = 'projects.settings.policies';

const EnvironmentSettings: React.FC<EnvironmentSettingsProps> = ({ policies }) => {
  const { mutateAsync: updatePolicies } = useUpdateProjectPolicies();

  const environmentSettingsForm = useEnvironmentSettingsForm({
    policies,
    onSubmit: async data => {
      await updatePolicies({
        ...data,
        projectId: policies.projectId
      });
    }
  });

  return (
    <Card title={<FormattedMessage id={`${messagePrefix}.title.environment.settings`} />}>
      <StyledFlexDiv>
        <StyledFlexItemDiv>
          <TtlPolicySection form={environmentSettingsForm} canInherit={true} />
        </StyledFlexItemDiv>
        <StyledFlexItemDiv>
          <RemoteStateSettings form={environmentSettingsForm} />
        </StyledFlexItemDiv>
      </StyledFlexDiv>

      <Divider orientationMargin="0%" />

      <ControlledSaveDiscardButtons
        saveButtonDataE2E="project-settings-policies-environment-settings-save"
        cancelButtonDataE2E="project-settings-policies-environment-settings-discard"
        form={environmentSettingsForm}
        cancelMessageId={'discard.changes'}
        inContainer={true}
      />
    </Card>
  );
};

export default EnvironmentSettings;

const StyledFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;

const StyledFlexItemDiv = styled.div`
  flex: 1;
`;
