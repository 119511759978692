import React, { type ReactNode } from 'react';
import styled from 'types/theme.types';
import env0Logo from 'assets/images/logo.svg';

export interface InvitationLayoutProps {
  children?: ReactNode;
}

const InvitationLayout: React.FunctionComponent<InvitationLayoutProps> = ({ children }) => {
  return (
    <Container>
      <LogoContainer>
        <img width="180px" src={env0Logo} alt="env0-logo" />
      </LogoContainer>
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.primaryGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 80px;
  left: calc(50% - 90px);
`;

export default InvitationLayout;
