import type { OrganizationUser } from 'types/api.types';
import { OrganizationUserStatus } from 'types/api.types';
import type { UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import find from 'lodash/find';
import type { RolesApi } from '@env0/role-service/api';

export const convertToUserRoleAssignmentsRows = (
  organizationUsers: OrganizationUser[],
  userPermissions: RolesApi.UserRoleAssignment[],
  currentUserId: string
): UserRoleAssignmentRow[] =>
  organizationUsers
    .filter(user => user.status !== OrganizationUserStatus.Invited)
    .map(user => {
      const userPermission = find(userPermissions, { userId: user.user.user_id });
      const assigned = Boolean(userPermission);
      const role = userPermission?.role;

      return {
        user,
        key: user.user.user_id,
        id: user.user.user_id,
        isCurrentUser: user.user.user_id === currentUserId,
        isOrganizationAdmin: user.role === 'Admin',
        assigned,
        role,
        initialData: {
          assigned,
          role
        }
      };
    });
