import type { Project, ProjectWithoutChildren } from 'types/api.types';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import { reportError } from 'utils/sentry.utils';

export const populateProjectsChildren = (projects: Record<string, Project>) => {
  const result: Record<string, Project> = mapValues(projects, (project: ProjectWithoutChildren) => ({
    ...project,
    children: []
  }));

  const missingParents = [];

  const projectByParent = groupBy(result, 'parentProjectId');
  const nonRootProjectsByParent: Record<string, Project[]> = omit(projectByParent, 'undefined');

  for (const [parentId, children] of Object.entries(nonRootProjectsByParent)) {
    const parent = result[parentId];
    if (parent) {
      parent.children = children;
    } else {
      missingParents.push({ parentId, children: children.map(p => p.id) });
    }
  }

  if (missingParents.length > 0) {
    const error = new Error(`Error building projects tree. Missing parent projects.`);
    reportError('use-projects', error, { missingParents });
  }

  return result;
};
