import React from 'react';
import styled from 'types/theme.types';

export interface ExpandIconProps {
  isOpen: boolean;
  className?: string;
}

const ExpandIcon: React.FunctionComponent<ExpandIconProps> = ({ isOpen, className }) => (
  <Arrow isOpen={isOpen} className={className} />
);

const Arrow = styled.div<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.primaryGreen} !important;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(135deg)' : 'rotate(45deg)')} !important;
  transform-origin: 62.5% 37.5%; // since this is a div with a border making the arrow out of its corner - the center of the arrow is a bit off the center of the div itself
  border-top: 3px solid ${({ theme }) => theme.primaryGreen};
  border-right: 3px solid ${({ theme }) => theme.primaryGreen};
  height: 8px;
  width: 8px;
  transition: transform 200ms ease-in;
`;

export default ExpandIcon;
