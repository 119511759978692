import React from 'react';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import useEnvironmentDiscoveryAutoCreateSettingsForm from 'components/projects/settings/environment-discovery/use-environment-discovery-auto-create-settings-form';
import WorkspaceNamingRadioButtons from 'components/templates/templates-wizard/settings/workspace-naming';
import styled from 'types/theme.types';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Text } from 'components/common/form-components';
import SaveDiscardButtons from 'components/common/save-discard-buttons';
import { Col } from 'components/common/grid';
import RadioCustomGlob from 'components/environments/triggers/environment-cd-settings/radio-custom-glob';
import ControlledRadioGroup from 'components/common/form-controlled/controlled-radio-group';
import type { SupportedDiscoveryTemplatesTypes } from 'components/projects/settings/environment-discovery/project-settings-environment-discovery';

type ProjectSettingsEnvironmentDiscoveryProps = {
  environmentDiscovery: BlueprintApi.EnvironmentDiscovery;
};

const ProjectSettingsEnvironmentDiscoveryAutoCreateSettings: React.FC<ProjectSettingsEnvironmentDiscoveryProps> = ({
  environmentDiscovery
}) => {
  const form = useEnvironmentDiscoveryAutoCreateSettingsForm({
    workspaceNaming: environmentDiscovery?.workspaceNaming,
    createNewEnvironmentsFromPullRequests: environmentDiscovery?.createNewEnvironmentsFromPullRequests,
    customGlobSwitch: environmentDiscovery?.autoDeployByCustomGlob ? 'customGlob' : 'templateFolder',
    autoDeployByCustomGlob: environmentDiscovery?.autoDeployByCustomGlob ?? undefined,
    templateType: environmentDiscovery.type as SupportedDiscoveryTemplatesTypes
  });

  const shouldDisableOtherFields = !form.getValues()['createNewEnvironmentsFromPullRequests'];
  return (
    <>
      <SectionWithMargin titleId={'project.settings.environment.discovery.create.from.pull.requests.title'}>
        <ControlledBigCheckbox
          form={form}
          name={'createNewEnvironmentsFromPullRequests'}
          data-e2e={'createNewEnvironmentsFromPullRequests-checkbox'}>
          <Text id={'project.settings.environment.discovery.create.from.pull.requests.checkbox'} />
        </ControlledBigCheckbox>
      </SectionWithMargin>
      <SectionWithMargin titleId={'project.settings.environment.discovery.workspace.naming.title'}>
        <WorkspaceNamingRadioButtons form={form} disabled={shouldDisableOtherFields} />
      </SectionWithMargin>
      <SectionWithMargin titleId={'project.settings.environment.discovery.mapping.runOn.title'}>
        <Col span={24}>
          <RadioGroupContainer
            form={form}
            name={'customGlobSwitch'}
            disabled={shouldDisableOtherFields}
            items={[
              {
                content: <Text id={'environment.auto-deploy.settings.redeploy-by-template-folder'} />,
                value: 'templateFolder'
              },
              {
                content: (
                  <RadioCustomGlob
                    form={form}
                    showTextInput={form.getValues('customGlobSwitch') === 'customGlob'}
                    value={form.getValues('autoDeployByCustomGlob')}
                    disabled={shouldDisableOtherFields}
                  />
                ),
                value: 'customGlob'
              }
            ]}
          />
        </Col>
      </SectionWithMargin>
      <SaveDiscardButtons
        discardButton={{
          disabled: !form.isDirty || form.isSubmitting,
          onClick: form.discardChanges,
          'data-e2e': 'discard-changes-environment-discovery-settings'
        }}
        saveButton={{
          isLoading: form.isSubmitting,
          disabled: !form.isDirty || !form.canSave || form.isSubmitting,
          onClick: form.submit,
          'data-e2e': 'save-environment-discovery-settings'
        }}
      />
    </>
  );
};

const SectionWithMargin = styled(Section)`
  margin-top: 1em;
`;

const RadioGroupContainer = styled(ControlledRadioGroup)`
  margin-top: 3em;
`;

export default ProjectSettingsEnvironmentDiscoveryAutoCreateSettings;
