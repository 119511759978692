import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { AxiosResponse } from 'axios';
import type { BlueprintApi } from '@env0/blueprint-service/api';

export default (client: HttpClient) => ({
  create: (data: BlueprintApi.CreateConfigurationTemplate) =>
    client
      .post<BlueprintApi.CreateCustomFlow.Request.Body, AxiosResponse<BlueprintApi.CustomFlowTemplate>>(
        `custom-flow`,
        data
      )
      .then(returnData),

  update: (data: BlueprintApi.UpdateConfigurationTemplateBody) =>
    client
      .put<BlueprintApi.UpdateCustomFlow.Request.Body, AxiosResponse<BlueprintApi.CustomFlowTemplate>>(
        `custom-flow`,
        data
      )
      .then(returnData),

  delete: (id: string) => client.delete<void>(`custom-flow/${id}`),

  getAssignments: (body: BlueprintApi.GetCustomFlowAssignments.Request.Body) =>
    client.post<BlueprintApi.GetCustomFlowAssignments.Response>('custom-flow/get-assignments', body).then(returnData),

  assignCustomFlow: (body: BlueprintApi.AssignCustomFlow.Request.Body) =>
    client.post<BlueprintApi.AssignCustomFlow.Response>('custom-flow/assign', body).then(returnData),

  unassignCustomFlow: (body: BlueprintApi.UnassignCustomFlow.Request.Body) =>
    client.post<BlueprintApi.UnassignCustomFlow.Response>('custom-flow/unassign', body).then(returnData)
});
