import gitUrlParse from 'git-url-parse';
import { VALID_GIT_URL_PROTOCOLS, VALID_HELM_URL_PROTOCOLS } from '@env0/blueprint-service/api.enum';

const getUrlProtocol = (url: string) => {
  let protocol = '';

  try {
    ({ protocol } = gitUrlParse(url));
  } catch {}

  return protocol;
};

export const validateGitUrl = (url: any) => VALID_GIT_URL_PROTOCOLS.includes(getUrlProtocol(url));
export const validateHelmRepoUrl = (url: any) => VALID_HELM_URL_PROTOCOLS.includes(getUrlProtocol(url));
export const isSsh = (url: string) => getUrlProtocol(url) === 'ssh';
