import { useGetProjectAgent } from 'stores/rq/project-agent-assignments';
import { useCurrentOrganization } from 'hooks/use-current-organization-id';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';

export const useIsSelfHosted = () => {
  const projectId = useCurrentProjectId();
  const { isSelfHostedK8s } = useCurrentOrganization();
  const { isLoading, data: projectAgent } = useGetProjectAgent({
    projectId: projectId!,
    enabled: !!isSelfHostedK8s && !!projectId
  });

  return {
    isOrgAllowSelfHosted: isSelfHostedK8s,
    isAllowSelfHostedFeatures: !isLoading && isSelfHostedK8s && !projectAgent?.isSaas,
    isLoading
  };
};
