import type React from 'react';
import styled from 'types/theme.types';

import { ReactComponent as TrashIcon } from 'assets/images/trash-icon.svg';

interface TrashProps {
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const Trash = styled(TrashIcon)<TrashProps>`
  cursor: pointer;
  transition: opacity 200ms ease-out;
  width: 16px;
  height: 19px;

  &:hover {
    opacity: 0.7;
  }
`;

export default Trash;
