import React from 'react';
import CommonModal from 'components/common/modal';
import Button from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import type { Credential, CredentialUsageType } from 'types/api.types';

type Props = {
  usageType: CredentialUsageType;
  credential: Credential;
  hideModal: () => void;
};

const AddNewCredentialSuccessModal: React.FunctionComponent<Props> = ({ credential, hideModal, usageType }) => (
  <>
    <CommonModal
      open
      titleId="settings.credentials.success.title"
      onCancel={hideModal}
      footer={
        <Button id="create-credential-success-ok" onClick={hideModal} type="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      }
      destroyOnClose
      centered>
      <FormattedMessage
        data-e2e="credential-success-modal-text"
        id={`settings.credentials.success.${usageType}.text`}
        values={{ name: credential.name }}
      />
    </CommonModal>
  </>
);

export default AddNewCredentialSuccessModal;
