import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import { FieldRow, Label } from 'components/common/form-components';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { SelectOption } from 'components/common/input-components/select';
import TokenSelectField from 'components/templates/templates-wizard/vcs/token-select-field';
import type { GitHostedProviderTypes, GitProviderTypes, Tokens } from 'types/api.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import {
  providersSupportingRepositoryIntegration,
  providersSupportingVcsRepositoryIntegration
} from 'components/templates/templates-wizard/vcs/use-git-provider.hook';
import { observer } from 'mobx-react';
import { useAsyncPromise } from 'hooks/use-async-promise';
import useStores from 'hooks/use-stores.hooks';
import type { OrganizationApi } from '@env0/organization-service/api';
import { isHostedGitProvider } from 'utils/blueprint.utils';
import ErrorContainer from 'components/common/error-container';
import Loader from 'components/common/loader';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { isGitProviderUsingTokenAndTokenIsMissing } from 'utils/vcs.utils';
import { VcsIntegrationMessage } from 'components/templates/templates-wizard/vcs/common/vcs-integration-message';
import { LocationSection } from 'components/templates/templates-wizard/vcs/common/location-section';
import { AuthSection } from 'components/templates/templates-wizard/vcs/common/auth-section';
import Divider from 'components/common/divider';
import styled from '@emotion/styled';
import useAuthTokens from './use-auth-tokens.hook';
import BlockRadioButtons from 'components/common/block-radio-buttons';
import ControlledVcsRepositorySelector from 'components/templates/templates-wizard/vcs/controlled-vcs-repository-selector';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { showHiddenFeatures } from 'constants/config';

export type TemplateWizardStepVCSFieldsProps = SubFormProps<VCSForm> & {
  gitProvider: GitProviderTypes;
  startVcsIntegration: (provider: GitProviderTypes) => void;
  isLoadingRepositories: boolean;
  repositories: BlueprintApi.GetAvailableRepositories.Response.Body;
  templateType: BlueprintApi.BlueprintType;
  isNewTemplate?: boolean;
  vcsRepositoryDisabled?: boolean;
  vcsRevisionDisabled?: boolean;
  showLocationSection?: boolean;
  showWebhookSection?: boolean;
};

const providerToWebhookTypeMapper: Record<GitHostedProviderTypes, OrganizationApi.GetWebhookSecret.WebhookType> = {
  BitBucketServer: 'bitbucket-server',
  GitLabEnterprise: 'gitlab-enterprise',
  GitHubEnterprise: 'github-enterprise'
};

const TemplateWizardStepVCSFields: React.FunctionComponent<TemplateWizardStepVCSFieldsProps> = ({
  form,
  gitProvider,
  startVcsIntegration,
  repositories,
  isLoadingRepositories,
  templateType: rawTemplateType,
  isNewTemplate,
  vcsRepositoryDisabled,
  vcsRevisionDisabled,
  showLocationSection = true,
  showWebhookSection = true
}) => {
  const { setValue, trigger, clearErrors, getValues } = form;
  const { organizationsStore } = useStores();
  const intl = useIntl();
  const templateType = intl.formatMessage({ id: rawTemplateType || 'opentofu' });
  const selectRepositoryFromDropdown = providersSupportingRepositoryIntegration.has(gitProvider);
  const selectRepositoryFromVcsDropdown =
    showHiddenFeatures() && providersSupportingVcsRepositoryIntegration.has(gitProvider);
  const showRevisionsDropdown = ['GitHub', 'GitLab', 'BitBucket', 'AzureDevOps'].includes(gitProvider);
  const repoMessageId = useMemo(() => `templates.add.new.field.repository.${gitProvider.toLowerCase()}`, [gitProvider]);

  const { tokenId, repository } = form.watch();

  const { tokens, authType, onChangeAuthType, authTypeOptions, isLoadingTokens } = useAuthTokens(form, gitProvider);

  const [error, webhookSecret, isLoadingWebhookSecret] = useAsyncPromise(
    () =>
      isHostedGitProvider(gitProvider)
        ? organizationsStore.getWebhookSecret(providerToWebhookTypeMapper[gitProvider as GitHostedProviderTypes])
        : Promise.resolve(null),
    [gitProvider, organizationsStore]
  );

  const initVSCData = useCallback(
    (newRepository: string) => {
      const currentRepository = repositories.find(repo => repo.httpsUrl === newRepository);
      if (!currentRepository) return;

      if (gitProvider === 'GitHub') {
        setValue('githubInstallationId', currentRepository.github?.installationId || null);
      }

      if (gitProvider === 'BitBucket') {
        setValue('bitbucketClientKey', currentRepository.bitbucket?.clientKey || null);
      }
    },
    [gitProvider, repositories, setValue]
  );

  // init VSC data from repositories - Part of them required for update
  useEffect(() => {
    if (!isNewTemplate) {
      initVSCData(repository);
    }
  }, [isNewTemplate, initVSCData, repository]);

  useEffect(() => {
    if (rawTemplateType !== 'cloudformation') {
      setValue('fileName', '');
    }
    trigger('fileName').then(() => {
      clearErrors('fileName');
    });
  }, [setValue, trigger, clearErrors, rawTemplateType]);

  useEffect(() => {
    const { vcsConnectionId } = getValues();
    vcsConnectionId && setValue('vcsConnectionId', undefined);
  }, [setValue, gitProvider, getValues]);

  const tokenField = (provider: GitProviderTypes, tokens: Tokens) => {
    const lowerCaseProvider = `${provider.toLowerCase()}`;
    return (
      <TokenSelectField
        width={49}
        label={{ id: `templates.add.new.field.authorize.as.${lowerCaseProvider}` }}
        mandatory
        info={{ id: `templates.add.new.field.authorize.as.${lowerCaseProvider}.info` }}
        data-e2e={`${lowerCaseProvider}-token-field`}
        form={form}
        gitProvider={provider}
        tokens={tokens}
      />
    );
  };

  const gitlabTokenField = tokenField('GitLab', tokens!);

  const gitLabAccessTokenSelectField = tokenField('Other', tokens!);

  const azureDevOpsTokenField = tokenField('AzureDevOps', tokens!);

  const repositoryLabelProps = {
    inline: true,
    width: 50,
    label: { id: repoMessageId },
    mandatory: true,
    info: { id: `${repoMessageId}.info`, values: { templateType } }
  };

  if (error) {
    return <ErrorContainer errorToReport={error} />;
  }

  if (isLoadingWebhookSecret || isLoadingTokens) {
    return <Loader />;
  }

  const repositoryTooltip = vcsRepositoryDisabled
    ? { placement: 'top', titleId: 'templates.add.new.field.repository.disabled' }
    : undefined;

  return (
    <Container>
      {gitProvider === 'GitLab' && (
        <BlockRadioButtons
          onChange={onChangeAuthType}
          value={authType}
          options={authTypeOptions}
          label={<Label id={'templates.add.new.field.authorize.type'} />}
        />
      )}
      {gitProvider === 'GitLab' && authType === 'GitLab' && gitlabTokenField}
      {gitProvider === 'GitLab' && authType === 'GIT' && gitLabAccessTokenSelectField}
      {gitProvider === 'AzureDevOps' && azureDevOpsTokenField}
      <FieldRow zeroBottomMargin>
        {selectRepositoryFromDropdown ? (
          <ControlledSelect
            {...repositoryLabelProps}
            showSearch
            form={form}
            name="repository"
            data-e2e="repository-select"
            loading={isLoadingRepositories}
            disabled={vcsRepositoryDisabled || isGitProviderUsingTokenAndTokenIsMissing(gitProvider, tokenId)}
            tooltip={repositoryTooltip}
            onChange={initVSCData}
            placeholder={<FormattedMessage id={`${repoMessageId}.placeholder`} />}
            customNotFoundContent={<FormattedMessage id="templates.add.new.field.repository.empty" />}>
            {repositories.map(repository => (
              <SelectOption
                data-e2e={`repository-option-${repository.name}`}
                key={`repository-option-${repository.name}`}
                value={repository.httpsUrl}>
                {repository.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        ) : selectRepositoryFromVcsDropdown ? (
          <ControlledVcsRepositorySelector
            {...repositoryLabelProps}
            vcsType={gitProvider}
            data-e2e="controlled-vcs-repository-selector"
            onChange={(_: any, { data: { vcsConnectionId } }: any) => setValue('vcsConnectionId', vcsConnectionId)}
            name="repository"
            label={{ id: 'vcs.repository-selector.field.name' }}
            info={{
              id: `vcs.repository-selector.field.info`,
              values: setLink(links.VCS.MANAGE_VCS)
            }}
            form={form}
          />
        ) : (
          <ControlledTextInput
            {...repositoryLabelProps}
            form={form}
            id="add-new-template-repository-input"
            name="repository"
            data-e2e="repository-url-field"
            placeholder={`templates.add.new.field.repository.${
              gitProvider === 'HelmRepository' ? 'helmRepo' : 'other'
            }.placeholder`}
            disabled={vcsRepositoryDisabled}
            tooltip={repositoryTooltip}
          />
        )}
      </FieldRow>
      {selectRepositoryFromDropdown && (
        <VcsIntegrationMessage gitProvider={gitProvider} onClick={() => startVcsIntegration(gitProvider)} />
      )}
      {showLocationSection && (
        <LocationSection
          form={form}
          rawTemplateType={rawTemplateType}
          gitProvider={gitProvider}
          vcsRevisionDisabled={vcsRevisionDisabled}
          showRevisionsDropdown={showRevisionsDropdown}
          templateType={templateType as BlueprintApi.BlueprintType}
        />
      )}
      {rawTemplateType === 'module' && (
        <div data-e2e={`module-tag-prefix-section`}>
          <Divider data-e2e={`module-tag-prefix-divider`} titleId="templates.add.new.divider.tag-prefix" />
          <FieldRow>
            {
              <ControlledTextInput
                inline={true}
                width={50}
                label={{ id: 'templates.add.new.field.repository.tag-prefix' }}
                info={{ id: 'templates.add.new.field.repository.tag-prefix.info' }}
                form={form}
                id="add-new-template-repository-tag-prefix-input"
                name="tagPrefix"
                data-e2e="repository-tag-prefix-field"
              />
            }
          </FieldRow>
        </div>
      )}
      {!selectRepositoryFromDropdown &&
        !selectRepositoryFromVcsDropdown &&
        (showWebhookSection || gitProvider === 'Other') &&
        rawTemplateType !== 'helm' && (
          <AuthSection form={form} gitProvider={gitProvider} webhookSecret={webhookSecret} tokens={tokens!} />
        )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1em;
`;

export default observer(TemplateWizardStepVCSFields);
