import React from 'react';
import type { CommonModalProps } from 'components/common/modal';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import Modal from 'components/common/modal';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import type { ControlledSaveDiscardButtonsProps } from 'components/common/form-controlled/controlled-save-discard-buttons';
import styled from 'types/theme.types';

interface Props extends CommonModalProps {
  form: UseFormReturnType;
  saveMessageId?: string;
  onCancel: () => void;
  saveButtonDataE2E?: string;
  cancelButtonDataE2E?: string;
  saveDiscardProps?: Partial<ControlledSaveDiscardButtonsProps>;
}

const ControlledModal: React.FC<Props> = ({
  form,
  onCancel,
  children,
  saveMessageId,
  saveButtonDataE2E,
  cancelButtonDataE2E,
  saveDiscardProps,
  ...props
}) => {
  return (
    <StyledModal
      centered
      destroyOnClose
      onCancel={() => onCancel()}
      footer={
        <ControlledSaveDiscardButtons
          form={form}
          saveMessageId={saveMessageId}
          onDiscard={onCancel}
          alwaysEnableDiscard={true}
          saveButtonDataE2E={saveButtonDataE2E}
          cancelButtonDataE2E={cancelButtonDataE2E}
          {...saveDiscardProps}
        />
      }
      {...props}>
      {children}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    height: 53px;
  }
`;

export default ControlledModal;
