import { action, computed, observable } from 'mobx';
import map from 'lodash/fp/map';
import keyBy from 'lodash/fp/keyBy';
import omit from 'lodash/fp/omit';
import set from 'lodash/fp/set';
import BaseService from 'services/base-service';
import type { TeamApi } from '@env0/team-service/api';
import type { User } from 'types/api.types';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

type UpdateTeamArgs = {
  name: string;
  description?: string;
  users?: User[];
};

export class TeamsStore extends BaseService {
  @observable teams: Record<string, TeamApi.Team> = {};
  @computed get teamsList() {
    return sortBy(values(this.teams), 'isDefaultTeam', 'name');
  }

  @action async getTeam(teamId: string) {
    const team = await this.service.apiClient.teams.getTeam(teamId);
    this.teams = set(team.id, team, this.teams);
  }

  @action async getTeams(organizationId: string) {
    const teams = await this.service.apiClient.teams.getTeams(organizationId);
    this.teams = keyBy('id', teams);
  }

  @action async createTeam(teamRequest: TeamApi.CreateTeam.Request.Body): Promise<TeamApi.Team> {
    const team = await this.service.apiClient.teams.createTeam(teamRequest);
    this.teams = set(team.id, team, this.teams);

    return team;
  }

  @action async updateTeam(teamId: string, teamRequest: UpdateTeamArgs) {
    const { name, description, users } = teamRequest;

    const request: TeamApi.UpdateTeam.Request.Body = {
      name,
      description,
      userIds: map('user_id', users) as string[]
    };

    const updatedTeam = await this.service.apiClient.teams.updateTeam(teamId, request);
    this.teams = set(updatedTeam.id, updatedTeam, this.teams);
  }

  @action async deleteTeam(teamId: string) {
    await this.service.apiClient.teams.deleteTeam(teamId);
    this.teams = omit(teamId, this.teams);
  }
}
