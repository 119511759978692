import React from 'react';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import { DurationSelect } from './duration-select';
import JwtOidcSub from 'components/organizations/credentials/jwt-oidc-sub';

const prefix = 'settings.credentials.aws';

const AwsOidcInputs = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.AWS_OIDC_DOCS;

  return (
    <>
      <Link messageId="settings.credentials.aws.role.oidc-explanation" url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.role.arn` }} name="value.roleArn" form={form} />
      <DurationSelect form={form} />
      <JwtOidcSub form={form} />
    </>
  );
};

export default AwsOidcInputs;
