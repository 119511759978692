import type { TtlValue } from 'types/api.types';
import type { EnvironmentApi } from '@env0/environment-service/api';
import isNull from 'lodash/fp/isNull';
import isFinite from 'lodash/fp/isFinite';
import dayjs, { type DurationUnitType, type Duration } from 'types/dayjs.types';
import humanizeDuration from 'humanize-duration';

export const daysCount = {
  year: 365,
  month: 30,
  week: 7
};

export const isTtlInHours = (ttl: TtlValue) => isFinite(ttl as number);

export const calculateTtlRequestValue = (ttl: TtlValue | undefined): EnvironmentApi.TTLRequest | undefined => {
  if (ttl === undefined) {
    return undefined;
  }

  if (isNull(ttl)) return { type: 'INFINITE' };

  return isTtlInHours(ttl)
    ? { type: 'HOURS', value: ttl.toString() }
    : { type: 'DATE', value: ttl as unknown as string };
};

export const convertTTLToDuration = (ttl: string) => {
  let [number, unit] = ttl.split('-');
  if (unit === 'M') {
    [number, unit] = [`${Number(number) * daysCount.month}`, 'd'];
  }
  return dayjs.duration(Number(number), unit as DurationUnitType);
};

export const formatDuration = (duration: Duration) =>
  humanizeDuration(duration.asHours(), {
    unitMeasures: {
      y: daysCount.year * 24,
      mo: daysCount.month * 24,
      w: daysCount.week * 24,
      d: 24,
      h: 1
    }
  });
