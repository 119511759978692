import React, { useMemo } from 'react';
import { SelectOptGroup, SelectOption } from 'components/common/input-components/select';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import { FormattedMessage, useIntl } from 'react-intl';
import type { CredentialUsageType } from 'types/api.types';
import { CredentialType, CredentialProviders } from 'types/api.types';
import AwsAssumedRoleInputs from 'components/organizations/credentials/aws-assumed-role-inputs';
import AwsAccessKeysInputs from 'components/organizations/credentials/aws-access-keys-inputs';
import AwsOidcInputs from 'components/organizations/credentials/aws-oidc-inputs';
import GcpCredentialInputs from 'components/organizations/credentials/gcp-credential-inputs';
import GcpServiceAccountInputs from 'components/organizations/credentials/gcp-service-account-inputs';
import AzureCredentialInputs from 'components/organizations/credentials/azure-credential-inputs';
import KubeconfigCredentialInputs from 'components/organizations/credentials/k8s-kubeconfig-credential-inputs';
import EKSCredentialInputs from 'components/organizations/credentials/k8s-eks-auth-inputs';
import type useCredentialModalForm from 'components/organizations/credentials/credential-modal-form.hook';
import AKSCredentialInputs from 'components/organizations/credentials/k8s-aks-auth-inputs';
import GKECredentialsInput from 'components/organizations/credentials/k8s-gke-auth-inputs';
import VaultOidcInputs from 'components/organizations/credentials/vault-oidc-inputs';
import GcpOidcInputs from 'components/organizations/credentials/gcp-oidc-inputs';
import AzureOidcInputs from 'components/organizations/credentials/azure-oidc-inputs';
import { getCredentialTypesByUsageTypeAndCredentialProvider } from 'utils/credential.utils';

type CredentialFieldsProps = {
  credentialTypeOptions: readonly CredentialType[];
  usageType: CredentialUsageType;
  form: ReturnType<typeof useCredentialModalForm>;
  isUpdate?: boolean;
};

export type CredentialsInputProps = {
  form: SubFormProps['form'];
  usageType: CredentialUsageType;
};

const typeToInput: Record<CredentialType, (props: CredentialsInputProps) => JSX.Element> = {
  [CredentialType.AWS_ASSUMED_ROLE]: AwsAssumedRoleInputs,
  [CredentialType.AWS_ASSUMED_ROLE_FOR_DEPLOYMENT]: AwsAssumedRoleInputs,
  [CredentialType.AWS_ACCESS_KEYS_FOR_DEPLOYMENT]: AwsAccessKeysInputs,
  [CredentialType.AWS_OIDC]: AwsOidcInputs,
  [CredentialType.GCP_CREDENTIALS]: GcpCredentialInputs,
  [CredentialType.GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT]: GcpServiceAccountInputs,
  [CredentialType.GCP_OIDC]: GcpOidcInputs,
  [CredentialType.AZURE_CREDENTIALS]: AzureCredentialInputs,
  [CredentialType.AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT]: AzureCredentialInputs,
  [CredentialType.AZURE_OIDC]: AzureOidcInputs,
  [CredentialType.K8S_KUBECONFIG_FILE]: KubeconfigCredentialInputs,
  [CredentialType.K8S_EKS_AUTH]: EKSCredentialInputs,
  [CredentialType.K8S_AZURE_AKS_AUTH]: AKSCredentialInputs,
  [CredentialType.K8S_GCP_GKE_AUTH]: GKECredentialsInput,
  [CredentialType.VAULT_OIDC]: VaultOidcInputs
};

const labelPrefix = 'settings.credentials';
const providerPrefix = 'projects.settings.menu.credentials.provider';

export const NewCredentialFields = ({ credentialTypeOptions, usageType, form, isUpdate }: CredentialFieldsProps) => {
  const intl = useIntl();
  const credentialType = form.watch('type');

  const credentialsFields = useMemo(() => {
    if (!credentialType) {
      return <></>;
    }
    const Component = typeToInput[credentialType];
    return <Component form={form} usageType={usageType} />;
  }, [credentialType, form, usageType]);

  const deploymentOptions = useMemo(() => {
    return CredentialProviders.map(provider => (
      <SelectOptGroup key={provider} label={intl.formatMessage({ id: `${providerPrefix}.${provider}` })}>
        {getCredentialTypesByUsageTypeAndCredentialProvider(usageType, provider).map(credentialType => (
          <SelectOption
            value={credentialType}
            key={credentialType}
            data-e2e={`new-credential-type-dropdown-option-${credentialType}`}>
            <FormattedMessage id={`${providerPrefix}.${credentialType}`} />
          </SelectOption>
        ))}
      </SelectOptGroup>
    ));
  }, [intl, usageType]);

  const costOptions = useMemo(() => {
    return credentialTypeOptions.map(value => (
      <SelectOption value={value} key={value} data-e2e={`new-credential-type-dropdown-option-${value}`}>
        <FormattedMessage id={`projects.settings.menu.credentials.provider.${value}`} />
      </SelectOption>
    ));
  }, [credentialTypeOptions]);

  const credentialsOptions = useMemo(() => {
    return usageType === 'DEPLOYMENT' ? deploymentOptions : costOptions;
  }, [usageType, deploymentOptions, costOptions]);

  return (
    <>
      <ControlledTextInput
        data-e2e="controlled-name"
        disabled={isUpdate}
        label={{ id: `${labelPrefix}.name` }}
        name="name"
        form={form}
      />
      <ControlledSelect
        data-e2e="new-credential-type-dropdown"
        label={{ id: `${labelPrefix}.type` }}
        name="type"
        form={form}
        hideError>
        {credentialsOptions}
      </ControlledSelect>
      {credentialsFields}
    </>
  );
};

export default NewCredentialFields;
