import { action, observable } from 'mobx';

import type { NotificationArgs } from 'types/notifications.types';
import BaseService from 'services/base-service';

export class NotificationStore extends BaseService {
  @observable notification: NotificationArgs | null = null;

  @action setNotification(notification: NotificationArgs | null) {
    this.notification = notification;
  }
}
