import isString from 'lodash/isString';

const QUOTA_EXCEEDED_ERR_CODE = 22;
const QUOTA_EXCEEDED_ERR_NAME = 'QUOTA_EXCEEDED_ERR';

class BrowserStorage {
  static setItem(key: string, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e: any) {
      if ((e?.code && e.code === QUOTA_EXCEEDED_ERR_CODE) || (e?.name && e.name === QUOTA_EXCEEDED_ERR_NAME)) {
        localStorage.clear();
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        throw e;
      }
    }
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static getItem(key: string): any | null {
    const data = localStorage.getItem(key);
    return isString(data) ? JSON.parse(data) : null;
  }
}

export default BrowserStorage;
