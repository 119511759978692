import withController from 'components/common/form-controlled/with-controller.hoc';
import BigCheckbox from 'components/common/input-components/big-checkbox';
import withLabel from './with-label.hoc';
import styled from 'types/theme.types';
import type { CheckboxProps } from 'antd';

const Checkbox = withLabel(
  withController<CheckboxProps>(BigCheckbox, {
    withoutRef: true,
    mapValueTo: 'checked',
    onChangeTransformer: e => e.target.checked
  })
);

export const ControlledBigCheckbox = styled(Checkbox)`
  @media (min-width: 768px) {
    height: 28px;
  }
`;

export default ControlledBigCheckbox;
