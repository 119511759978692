import semver from 'semver';

export const insertAt = <T>(array: T[], index: number, ...items: T[]) => {
  // Array.prototype.splice mutates the array, insertAt follows the immutability principle to make working with React easier
  const copy = [...array];
  copy.splice(index, 0, ...items);
  return copy;
};

export const sort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
  return [...array].sort(comparator);
};

export const sortByVersion = <T>(
  array: readonly T[],
  getVersion: (item: T) => string,
  order: 'asc' | 'desc' = 'desc'
) => {
  const semverComparator = order === 'asc' ? semver.lt : semver.gt;
  return sort([...array], (a, b) => {
    const versionA = getVersion(a);
    const versionB = getVersion(b);
    const invalid = !semver.valid(versionA) || !semver.valid(versionB);
    return invalid ? 0 : semverComparator(getVersion(a), getVersion(b)) ? -1 : 1;
  });
};

export const isSorted = <T>(array: T[], comparator: (a: T, b: T) => number) => {
  return array.every((_, index) => {
    if (index === 0) {
      return true;
    }
    return comparator(array[index - 1], array[index]) <= 0;
  });
};
