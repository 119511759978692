import React from 'react';
import { Steps as AntdSteps } from 'antd';
import type { StepProps as AntdStepProps } from 'antd/lib/steps';
import { FormattedMessage } from 'react-intl';
import styled from 'types/theme.types';
import type { TemplateWizardStep } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { ModuleWizardStep } from 'components/modules/module-wizard-form.hook';
import type { ConfigurationSetWizardStep } from 'components/settings/configuration-set-wizard';
import type { CloudConfigurationWizardStep } from 'components/cloud-compass/cloud-configuration-wizard/cloud-configuration-wizard';

type Props = {
  isNew: boolean;
  currentStepIndex?: number;
  steps: StepProp[];
  onClick?: (index: number) => void;
};

export type WizardStepName =
  | TemplateWizardStep
  | ModuleWizardStep
  | ConfigurationSetWizardStep
  | CloudConfigurationWizardStep;

type StepProp = { stepName: WizardStepName } & Pick<AntdStepProps, 'icon'>;

const Steps = ({ isNew, currentStepIndex, steps, onClick }: Props) => {
  return (
    <StyledSteps
      data-e2e="template-wizard-steps"
      current={currentStepIndex}
      type={!isNew ? 'navigation' : 'default'}
      items={steps.map((step, index) => {
        const disabled = isNew && index > (currentStepIndex ?? 0);
        return {
          key: step.stepName,
          title: (
            <MessageContainer isCurrent={index === currentStepIndex}>
              <FormattedMessage id={`step.name.${step.stepName}`} />
            </MessageContainer>
          ),
          icon: !isNew && step.icon,
          onClick: disabled ? undefined : () => onClick?.(index), // onClick still happens even if Step is disabled
          disabled,
          style: disabled ? {} : { cursor: 'pointer' },
          status: isNew ? undefined : 'process',
          'data-e2e': `step-${step.stepName}`
        };
      })}
    />
  );
};

const MessageContainer = styled.div<{ isCurrent: boolean }>`
  font-weight: ${({ isCurrent }) => (isCurrent ? 'bold' : 100)};
`;

const StyledSteps = styled(AntdSteps)`
  margin-bottom: 20px !important;
`;

export default Steps;
