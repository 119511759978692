import React from 'react';
import Lottie from 'lottie-react';
import styled from 'types/theme.types';
import LoaderAnimationData from 'assets/images/animations/env0-loader.json';
import LoaderRobotAnimationData from 'assets/images/animations/env0-robot-full-loader.json';
import LoaderRobotChristmasAnimationData from 'assets/images/animations/env0-robot-full-loader-christmas.json';
import LoaderRobotValentinesDayAnimationData from 'assets/images/animations/env0-robot-full-loader-valentines.json';
import LoaderRobotSaintPatricksDayAnimationData from 'assets/images/animations/env0-robot-full-loader-saint-patrick.json';
import LoaderRobot4thOfJulyAnimationData from 'assets/images/animations/env0-robot-full-loader-4th-of-july.json';
import LoaderRobotThanksgivingAnimationData from 'assets/images/animations/env0-robot-full-loader-thanksgiving.json';

export const loaderSizes = ['default', 'small', 'large'] as const;
export type LoaderSize = (typeof loaderSizes)[number];

export const loaderTypes = ['default', 'robot'] as const;
export type LoaderType = (typeof loaderTypes)[number];

export interface LoaderProps {
  size?: LoaderSize;
  type?: LoaderType;
  name?: string;
}

const SIZE_MAP: { [key in LoaderSize]: string } = {
  large: '150px',
  default: '100px',
  small: '50px'
};

const today = new Date();

const isChristmas = () => {
  const currentYear = today.getFullYear();

  const december20th = new Date(currentYear, 11, 20, 0, 0, 0, 0);
  const january6th = new Date(currentYear, 0, 6, 0, 0, 0, 0);

  return december20th <= today || today < january6th;
};

const isValentinesDay = () => {
  return today.getMonth() === 1 && today.getDate() === 14; // month count (specifically) starts at 0
};

const isSaintPatricksDay = () => {
  return today.getMonth() === 2 && today.getDate() === 17; // month count (specifically) starts at 0
};

const is4thOfJuly = () => {
  return today.getMonth() === 6 && today.getDate() === 4; // month count (specifically) starts at 0
};

const isThanksgiving = () => {
  const currentYear = today.getFullYear();
  const daysInAWeek = 7;
  const november = 10;
  const thursday = 5;

  const firstDay = new Date(currentYear, november, 1).getDay();
  const daysToFirstThursday = thursday - firstDay;
  const weeksToFourthThursday = daysToFirstThursday < 1 ? 4 : 3;
  const fourthThursday = daysToFirstThursday + daysInAWeek * weeksToFourthThursday;

  return today.getMonth() === november && today.getDate() === fourthThursday;
};

const getRobotLoader = () => {
  if (isValentinesDay()) return { data: LoaderRobotValentinesDayAnimationData, id: 'robot-valentines-day' };
  if (isSaintPatricksDay()) return { data: LoaderRobotSaintPatricksDayAnimationData, id: 'robot-saint-patricks-day' };
  if (is4thOfJuly()) return { data: LoaderRobot4thOfJulyAnimationData, id: 'robot-4th-of-july' };
  if (isChristmas()) return { data: LoaderRobotChristmasAnimationData, id: 'robot-christmas' };
  if (isThanksgiving()) return { data: LoaderRobotThanksgivingAnimationData, id: 'robot-thanksgiving' };

  return { data: LoaderRobotAnimationData, id: 'robot-default' };
};

const robotLoaderData = getRobotLoader();

export const Loader: React.FunctionComponent<LoaderProps> = ({
  size = 'default' as const,
  type = 'default',
  name = 'loader'
}) => {
  const animation = type === 'robot' ? robotLoaderData : { data: LoaderAnimationData, id: 'default' };

  return (
    <Container data-e2e={name}>
      <AnimationContainer size={size} data-e2e={`animation-container-${size}`}>
        <Lottie animationData={animation.data} data-e2e={`animation-${animation.id}`} loop autoplay />
      </AnimationContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnimationContainer = styled.div<{ size: LoaderSize }>`
  height: ${({ size }) => SIZE_MAP[size]};
  width: ${({ size }) => SIZE_MAP[size]};
`;

export default Loader;
