import useStores from 'hooks/use-stores.hooks';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const useTopBarTitle = (title?: string, page?: string) => {
  const { environmentsStore } = useStores();
  const params = useParams();
  const environmentId = params?.environmentId;
  const environmentName = environmentsStore.environments[environmentId || '']?.name;
  return useMemo(() => {
    if (page === 'environment' && environmentName) {
      return `${title}: ${environmentName}`;
    } else {
      return title;
    }
  }, [page, title, environmentName]);
};
