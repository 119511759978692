import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import React from 'react';

const GKECredentialsInput = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.K8S_GKE_AUTH;

  return (
    <>
      <Link messageId="settings.credentials.k8s.credentials.explanation" url={docsLink} />
      <ControlledTextInput key={'clusterName'} label={{ id: 'cluster.name' }} name={'value.clusterName'} form={form} />
      <ControlledTextInput
        key={'computeRegion'}
        label={{ id: 'settings.credentials.gke.computeRegion' }}
        name={'value.computeRegion'}
        form={form}
      />
    </>
  );
};

export default GKECredentialsInput;
