import React from 'react';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import JwtOidcSub from 'components/organizations/credentials/jwt-oidc-sub';

const prefix = 'settings.credentials.gcp.oidc';

const GcpOidcInputs = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.GCP_OIDC_DOCS;

  return (
    <>
      <Link messageId={`${prefix}.explanation`} url={docsLink} />
      <ControlledTextArea
        label={{ id: `${prefix}.credential-configuration-file-content` }}
        name="value.credentialConfigurationFileContent"
        form={form}
        autoSize={{ minRows: 6, maxRows: 12 }}
      />
      <JwtOidcSub form={form} />
    </>
  );
};

export default GcpOidcInputs;
