import type { CredentialUsageType } from 'types/api.types';
import { useCallback, useEffect, useMemo } from 'react';
import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import useForm from 'hooks/use-form.hook';
import type { CredentialType } from 'types/api.types';
import { CREDENTIALS_TYPE_MAPPING } from 'types/api.types';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import fromPairs from 'lodash/fromPairs';

type CredentialIdByType = Partial<Record<CredentialType, string>>;

export type ProjectCredentialsSettingsValues = {
  aws?: string;
  gcp?: string;
  azure?: string;
  k8s?: string;
  vault?: string;
};

const schema: SchemaOf<ProjectCredentialsSettingsValues> = Yup.object().shape({
  aws: Yup.string(),
  gcp: Yup.string(),
  azure: Yup.string(),
  k8s: Yup.string(),
  vault: Yup.string()
});

export type ProjectSettingsCredentialsForm = {
  usageType: CredentialUsageType;
  projectCredentialsByType: CredentialIdByType;
  onSave: (formCredentialValues: string[]) => Promise<void>;
};

const useProjectSettingsCredentialsForm = ({ projectCredentialsByType, onSave }: ProjectSettingsCredentialsForm) => {
  const onSubmit = useCallback(
    async ({ aws, gcp, azure, k8s, vault }: ProjectCredentialsSettingsValues) => {
      const credentialsIds = [aws, gcp, azure, k8s, vault].filter(Boolean) as string[];
      await onSave(credentialsIds);
    },
    [onSave]
  );

  const initialValues = useMemo<ProjectCredentialsSettingsValues>(() => {
    const defaultValues = fromPairs(uniq(values(CREDENTIALS_TYPE_MAPPING)).map(cloudProvider => [cloudProvider, '']));
    const transformedEntries = Object.entries(projectCredentialsByType).map(([credentialType, id]) => [
      CREDENTIALS_TYPE_MAPPING[credentialType as CredentialType],
      id
    ]);
    return { ...defaultValues, ...Object.fromEntries(transformedEntries) };
  }, [projectCredentialsByType]);

  const { resetFormValues, ...form } = useForm<ProjectCredentialsSettingsValues>({
    initialValues,
    onSubmit: onSubmit,
    schema
  });

  useEffect(() => {
    resetFormValues({ ...initialValues } as ProjectCredentialsSettingsValues);
  }, [resetFormValues, projectCredentialsByType, initialValues]);

  return { resetFormValues, ...form };
};

export default useProjectSettingsCredentialsForm;

export type ProjectCredentialsForm = ReturnType<typeof useProjectSettingsCredentialsForm>;
