import type { HttpClient } from 'services/api-client/client';
import type { CloudResourceApi } from '@env0/cloud-resource-service/api';
import { returnData } from 'services/api-client/client';

export default (client: HttpClient) => ({
  findCloudResources: async (
    body: CloudResourceApi.FindCloudResources.Request.Body
  ): Promise<CloudResourceApi.FindCloudResources.Response> =>
    client.post<CloudResourceApi.FindCloudResources.Response>('/cloud/resources', body).then(returnData),
  updateCloudResources: async (
    body: CloudResourceApi.UpdateCloudResources.Request.Body
  ): Promise<CloudResourceApi.UpdateCloudResources.Response> =>
    client.put<CloudResourceApi.UpdateCloudResources.Response>('/cloud/resources', body).then(returnData),
  codifyResources: async (body: CloudResourceApi.Codify.Request.Body) =>
    client.post<CloudResourceApi.Codify.Response>('/cloud/resources/codify', body).then(returnData),
  getChangeEventUrl: async (eventId: string) =>
    client.get<CloudResourceApi.GetChangeEventUrl.Response>(`cloud/resources/events/${eventId}`).then(returnData)
});
