import { type ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import { type GroupedRowType } from 'utils/table-group-by.utils';
import { useGetConfigurationSetById } from 'stores/rq/configuration-sets';
import { VariablesGroupHeader } from 'components/settings/variables-group-header';
import { Spin } from 'antd';
import React from 'react';

interface SensitiveVariablesGroupHeaderProps {
  record: GroupedRowType<ConfigurationPropertyStore>;
}

export const SetVariablesGroupHeader: React.FC<SensitiveVariablesGroupHeaderProps> = ({ record }) => {
  const { data, isLoading } = useGetConfigurationSetById(record.id!.replace('set-', ''));

  if (isLoading) {
    return <Spin size="small" data-e2e={`${record.id}-spinner`} />;
  }

  return (
    <VariablesGroupHeader
      data-e2e={`${record.id}-group-row`}
      record={record}
      titleParts={[{ text: 'From ' }, { text: data?.name ?? '', isBold: true }, { text: ' Set' }]}
    />
  );
};
