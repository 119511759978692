import { useNavigate } from 'react-router-dom';
import type { Action } from 'kbar';
import { useMemo } from 'react';
import flatMap from 'lodash/flatMap';

import { generatePerformableActions, generateRootActionForTemplate } from 'hooks/kbar/common/generate-template-actions';
import { useGetBlueprints } from 'stores/rq/blueprints';
import { useHasPermission } from 'hooks/use-has-permission';

export const rootId = 'TEMPLATES';
export const section = 'Specific Templates';
export const kindName = 'templates';

type useTemplatesSearchActionsProps = { enabled: boolean };

export default function useTemplatesSearchActions({ enabled }: useTemplatesSearchActionsProps) {
  const navigate = useNavigate();
  const { isAuthorized: hasTemplatesPermissions, isLoading } = useHasPermission('VIEW_ORGANIZATION');
  const { data: templates } = useGetBlueprints({ enabled: hasTemplatesPermissions && !isLoading && enabled });

  const searchSpecificTemplateActions: Action[] = useMemo(
    () => templates?.map(template => generateRootActionForTemplate(template, rootId, section)) ?? [],
    [templates]
  );
  const performableActions: Action[] = useMemo(
    () => flatMap(templates, template => generatePerformableActions(template, navigate, kindName)),
    [navigate, templates]
  );

  return useMemo(
    () => [...searchSpecificTemplateActions, ...performableActions].filter(Boolean),
    [searchSpecificTemplateActions, performableActions]
  );
}
