declare global {
  var stageConfig: { [key: string]: string };
}

let stageConfig = window.stageConfig;

// Our app depends on the config to be loaded before doing anything else
if (!window.stageConfig) {
  console.error('Configuration not exists. Check if stage config is properly loaded.');
  window.stageConfig = {};
} else {
  console.debug('Configuration loaded successfully');
}

export const showHiddenFeatures = () =>
  process.env.NODE_ENV === 'test' || !!localStorage.getItem('ENV0_SHOW_HIDDEN_FEATURES');

export const apiBaseUrl = localStorage.getItem('ENV0_API_BASE_URL') || stageConfig.API_BASE_URL;

export const auth0LoginCallbackUrl = localStorage.getItem('ENV0_APP_BASE_URL') || global.location.origin + '/';

export const showUserPasswordLogin = localStorage.getItem('allow-user-password');

export const graphQlUrl = stageConfig.EVENTS_GRAPHQL_API_URL;
export const appVersion = stageConfig.APP_GIT_VERSION;
export const appVersionHash = stageConfig.APP_GIT_HASH;
export const segmentSourceKey = stageConfig.SEGMENT_SOURCE_KEY;
export const sentryDSN = stageConfig.SENTRY_DSN;

export const remoteBackendHost = stageConfig.REMOTE_BACKEND_HOST;
export const remoteBackendUrl = `https://${stageConfig.REMOTE_BACKEND_HOST}`;

export const auth0Audience = stageConfig.AUTH0_AUDIENCE;
export const auth0ClientId = stageConfig.AUTH0_CLIENT_ID;
export const auth0Domain = stageConfig.AUTH0_DOMAIN;

export const stage = stageConfig.STAGE;
export const isDebug = JSON.parse(stageConfig.DEBUG || 'false');
export const isTestNodeEnv = stageConfig.NODE_ENV === 'test';
export const isDevNodeEnv = stageConfig.NODE_ENV === 'development';
export const isProd = stage === 'prod';

export const isReactQueryDevToolsEnabled = JSON.parse(stageConfig.ENABLE_REACT_QUERY_DEVTOOLS || 'false');
export const enableManualSentryErrorSending = JSON.parse(stageConfig.ENABLE_MANUAL_SENTRY_ERROR_SENDING || 'false');

export const vcsAppAuthorizeUrl = stageConfig.VCS_APP_AUTHORIZE_URL;
export const bitbucketServerWebhookUrl = stageConfig.BITBUCKET_SERVER_WEBHOOK_URL;
export const gitLabEnterpriseWebhookUrl = stageConfig.GITLAB_ENTERPRISE_WEBHOOK_URL;
export const gitHubEnterpriseWebhookUrl = stageConfig.GITHUB_ENTERPRISE_WEBHOOK_URL;

export const auth0SsoConnections = stageConfig.AUTH0_SSO_CONNECTIONS?.split(',') || [];

export const terraformVersions = stageConfig.TERRAFORM_VERSIONS;
export const opentofuVersions = stageConfig.OPENTOFU_VERSIONS;
export const terragruntVersions = stageConfig.TERRAGRUNT_VERSIONS;
export const pulumiVersions = stageConfig.PULUMI_VERSIONS;
export const ansibleVersions = stageConfig.ANSIBLE_VERSIONS;
