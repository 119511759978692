import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import { useCallback } from 'react';
import type { BillingApi } from '@env0/billing-service/api';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();
  const baseKey = [orgId, 'billing-information'] as const;
  return { base: baseKey };
};

export const useGetBillingInformation = () => {
  const apiClient = useApiClient();
  const { base } = useCacheKeys();

  return useQuery({
    queryKey: base,
    queryFn: async ({ queryKey: [orgId] }) => {
      try {
        return await apiClient.billing.getBillingInformation(orgId);
      } catch (error) {
        // prevent from infinity loop - when the billing information is not available
        return {
          tier: 'basic'
        } as BillingApi.BillingInfo;
      }
    },
    staleTime: Infinity
  });
};

export const useGetManageSubscriptionLink = () => {
  const organizationId = useCurrentOrganizationId();
  const apiClient = useApiClient();
  return useCallback(
    async () => await apiClient.billing.getManageSubscriptionLink(organizationId),
    [apiClient.billing, organizationId]
  );
};
