import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { CloudResourceApi } from '@env0/cloud-resource-service/api';

export default (client: HttpClient) => ({
  getCloudTrends: (queryName: CloudResourceApi.QueryName, configurationId: string) =>
    client
      .get<CloudResourceApi.FindTrendData.Response>('cloud/trends', {
        params: { queryName, configurationId } as CloudResourceApi.FindTrendData.Request.QueryParams
      })
      .then(returnData)
});
