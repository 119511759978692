import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import ErrorContainer from 'components/common/error-container';
import Loader from 'components/common/loader';
import type { Project } from 'types/api.types';
import { Info } from 'components/common/form-components';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { useApprovalPolicyForm, useCustomFlowForm } from 'components/projects/settings/policies/use-policies-form.hook';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { useGetProjectPolicies } from 'stores/rq/project-policies';
import Stack from 'components/common/stack';
import { ConfigurationTemplateSettings } from 'components/projects/settings/policies/common/configuration-template-settings';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import SubProjectWontBeAffectedAlert from 'components/projects/sub-projects-not-affected-alert';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import Tooltip from 'components/common/tooltip';
import { useCuratedProjects } from 'stores/rq/projects';
import isEmpty from 'lodash/isEmpty';
import { useGetCustomFlowAssignments } from 'stores/rq/custom-flow-assignments';
import DeploymentSettings from 'components/projects/settings/policies/sections/deployment-settings';
import PolicySettings from 'components/projects/settings/policies/sections/policy-settings';
import { useGetAllApprovalPolicyAssignments } from 'stores/rq/approval-policies-assignments';
import { useSubmitApprovalPolicy } from 'components/projects/settings/policies/common/use-approval-policy-form';
import { useSubmitCustomFlow } from 'components/projects/settings/policies/common/use-custom-flow-in-form';
import EnvironmentSettings from 'components/projects/settings/policies/sections/environment-settings';

type ProjectSettingsPoliciesProps = {
  project: Project;
};

type ProjectSettingsPoliciesFormProps = {
  policies: EnvironmentApi.Policy;
  projectId: string;
  approvalPolicyAssignments: BlueprintApi.ApprovalPolicyTemplateWithScope[];
  customFlowAssignments: BlueprintApi.RichCustomFlowAssigment[];
  projects: Record<string, Project>;
};

export const messagePrefix = 'projects.settings.policies';

const ProjectPoliciesForm: React.FC<ProjectSettingsPoliciesFormProps> = ({
  policies,
  approvalPolicyAssignments,
  customFlowAssignments,
  projectId,
  projects
}) => {
  const submitApprovalPolicy = useSubmitApprovalPolicy();
  const submitCustomFlow = useSubmitCustomFlow();

  const directlyAssignedApprovalPolicyAssignments = approvalPolicyAssignments.filter(
    assignment => assignment.scopeId === projectId
  );

  const inheritedApprovalPolicyAssignments = approvalPolicyAssignments
    .filter(assignment => assignment.scopeId !== projectId)
    .map(assignment => ({ ...assignment, inheritedFrom: projects[assignment.scopeId].name }));

  const directlyAssignedCustomFlowAssignments = customFlowAssignments.filter(
    assignment => assignment.scopeId === projectId
  );
  const inheritedCustomFlowAssignments = customFlowAssignments
    .filter(assignment => assignment.scopeId !== projectId)
    .map(assignment => ({ ...assignment, inheritedFrom: projects[assignment.scopeId].name }));

  const approvalPolicyForm = useApprovalPolicyForm({
    policies,
    initialValues: directlyAssignedApprovalPolicyAssignments,
    onSubmit: async (data, form) => {
      await submitApprovalPolicy(data, form, {
        approvalPolicyAssignments: directlyAssignedApprovalPolicyAssignments
      });
    }
  });

  const customFlowForm = useCustomFlowForm({
    initialValues: directlyAssignedCustomFlowAssignments,
    onSubmit: async (data, form) => {
      await submitCustomFlow(data, form, {
        customFlowAssignments: directlyAssignedCustomFlowAssignments
      });
    }
  });

  const isApprovalPolicyEnabled = () =>
    !isEmpty([...approvalPolicyForm.watch('approvalPolicyAssignments'), ...inheritedApprovalPolicyAssignments]);

  return (
    <>
      <DeploymentSettings policies={policies} />

      <ConfigurationTemplateSettings
        type={'custom-flow'}
        form={customFlowForm}
        projectId={projectId}
        inheritedAssignments={inheritedCustomFlowAssignments}
        assignmentsFieldName="customFlowAssignments"
        blueprintFieldName="customFlowTemplate"
        reorder
      />

      <ConfigurationTemplateSettings
        type={'approval-policy'}
        form={approvalPolicyForm}
        projectId={projectId}
        inheritedAssignments={inheritedApprovalPolicyAssignments}
        assignmentsFieldName="approvalPolicyAssignments"
        blueprintFieldName="blueprint">
        <Tooltip
          placement={'topLeft'}
          titleId={
            (isApprovalPolicyEnabled() && 'before.run.settings.approval.automatically.opa.disabled.tooltip') || ''
          }>
          <ControlledBigCheckbox
            form={approvalPolicyForm}
            name="autoApproveDefault"
            data-e2e="project-settings-policies-auto-approve-checkbox"
            disabled={isApprovalPolicyEnabled()}>
            <FormattedMessage id={`${messagePrefix}.auto-approve.checkbox`} />
            <Info alignTop={false} information={<FormattedMessage id={`${messagePrefix}.auto-approve.tooltip`} />} />
          </ControlledBigCheckbox>
        </Tooltip>
      </ConfigurationTemplateSettings>

      <EnvironmentSettings policies={policies} />

      <PolicySettings policies={policies} />
    </>
  );
};

const ProjectSettingsPolicies: React.FC<ProjectSettingsPoliciesProps> = () => {
  const { data: policies, isLoading: isLoadingPolicies, error: policiesError } = useGetProjectPolicies();
  const projectId = useCurrentProjectId();

  const {
    isPending: cfPending,
    error: cfError,
    data: customFlowAssignments
  } = useGetCustomFlowAssignments({ scope: 'PROJECT', scopeId: projectId! });

  const {
    isPending: isPendingApprovalPolicies,
    error: approvalPolicyError,
    data: allApprovalPolicyAssignments
  } = useGetAllApprovalPolicyAssignments({ scope: 'PROJECT', scopeId: projectId! });

  const { projects, isLoading: isLoadingProjects, error: getProjectsError } = useCuratedProjects();

  const isLoading = isLoadingPolicies || isPendingApprovalPolicies || isLoadingProjects || cfPending;
  const error = policiesError || approvalPolicyError || getProjectsError || cfError;

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorContainer data-e2e="project-settings-policies-error" errorToReport={error} />;
  }

  return (
    <Stack>
      <SubProjectWontBeAffectedAlert pageType={'configuration'} />
      <ProjectPoliciesForm
        policies={policies!}
        projectId={projectId!}
        approvalPolicyAssignments={allApprovalPolicyAssignments!}
        customFlowAssignments={customFlowAssignments!}
        projects={projects}
      />
    </Stack>
  );
};

export default observer(ProjectSettingsPolicies);
