import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import {
  BACKWARD_COMPATIBLE_TF_VERSION_API_VALUE,
  BACKWARD_COMPATIBLE_TF_VERSION_FALLBACK,
  iacVersionList
} from 'components/templates/templates-wizard/settings/versions-list';
import type { VersionFieldFormProps } from 'components/templates/templates-wizard/settings/version-field';
import { VersionField } from 'components/templates/templates-wizard/settings/version-field';

const terraformPrefix = 'templates.add.new.field.terraformVersion';
const terraformVersionKey = 'terraformVersion';
const latestVersionKeyword = 'latest';
const resolveFromTerraformCodeVersionKeyword = 'RESOLVE_FROM_TERRAFORM_CODE';

export const TerraformVersionField: React.FC<VersionFieldFormProps> = ({ form }) => {
  const intl = useIntl();
  const terraformVersion = form.watch().terraformVersion;
  const versions = useMemo<BlueprintApi.TerraformVersion[]>(() => {
    const versions: BlueprintApi.TerraformVersion[] = [...iacVersionList.terraform];
    versions.unshift(resolveFromTerraformCodeVersionKeyword);
    versions.unshift(latestVersionKeyword);

    const shouldAddBackwardCompatible = terraformVersion === BACKWARD_COMPATIBLE_TF_VERSION_API_VALUE;
    if (shouldAddBackwardCompatible) {
      versions.unshift(BACKWARD_COMPATIBLE_TF_VERSION_API_VALUE);
    }

    return versions;
  }, [terraformVersion]);

  const translateVersionText = (version: BlueprintApi.TerraformVersion): string => {
    if (version === BACKWARD_COMPATIBLE_TF_VERSION_API_VALUE) {
      return (
        intl.formatMessage({ id: `${terraformPrefix}.options.deprecated` }) + BACKWARD_COMPATIBLE_TF_VERSION_FALLBACK
      );
    }

    if (version === resolveFromTerraformCodeVersionKeyword) {
      return intl.formatMessage({ id: `${terraformPrefix}.options.autoCodeResolve` });
    }

    if (version === latestVersionKeyword) {
      return intl.formatMessage({ id: `${terraformPrefix}.options.latest` });
    }

    return version;
  };

  return (
    <VersionField
      form={form}
      versions={versions as ReadonlyArray<string>}
      translateFunction={translateVersionText}
      prefix={terraformPrefix}
      versionKey={terraformVersionKey}
      type="terraform"
    />
  );
};
