import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import useStores from 'hooks/use-stores.hooks';
import { DurationSelect } from 'components/organizations/credentials/duration-select';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';

const prefix = 'settings.credentials.aws';

const AwsAssumedRoleInputs = ({ form, usageType }: CredentialsInputProps) => {
  const { organizationsStore } = useStores();
  const { currentOrganizationId } = organizationsStore;

  const docsLink =
    usageType === 'COSTS'
      ? links.docs.ENABLING_COST_MONITORING.CREATE_AWS_IAM_POLICY_ROLE
      : links.docs.DEPLOYMENT_CREDENTIALS.CREATE_AWS_ROLE;
  return (
    <>
      <Link messageId="settings.credentials.aws.role.explanation" url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.role.arn` }} name="value.roleArn" form={form} />
      <DurationSelect form={form} />
      <ControlledTextInput
        label={{ id: `${prefix}.external.id` }}
        name="value.externalId"
        disabled={true}
        value={currentOrganizationId}
        form={form}
      />
    </>
  );
};

export default AwsAssumedRoleInputs;
