import React, { useEffect, useState } from 'react';
import { useEnvironmentLimitsForm } from 'components/projects/settings/policies/use-policies-form.hook';
import type { EnvironmentApi } from '@env0/environment-service/api';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { FormattedMessage } from 'react-intl';
import { Info } from 'components/common/form-components';
import BigCheckbox from 'components/common/input-components/big-checkbox';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import styled from 'types/theme.types';
import ControlledNumberInput from 'components/common/form-controlled/controlled-number-input';
import Card from 'components/common/card';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import { useUpdateProjectPolicies } from 'stores/rq/project-policies';
import Divider from 'components/common/divider';

const MIN_NUM_OF_ENVIRONMENTS = 1;

const PolicySettings: React.FC<{
  policies: EnvironmentApi.Policy;
}> = ({ policies }) => {
  const { mutateAsync: updatePolicies } = useUpdateProjectPolicies();

  const environmentLimitsForm = useEnvironmentLimitsForm({
    policies,
    onSubmit: async data => {
      await updatePolicies({
        ...data,
        projectId: policies.projectId
      });
    }
  });

  const { setValue } = environmentLimitsForm;

  const [isNumOfEnvsUserLimitEnabled, setIsNumOfEnvsUserLimitEnabled] = useState(!!policies?.numberOfEnvironments);
  const [isNumOfEnvsTotalLimitEnabled, setIsNumOfEnvsTotalLimitEnabled] = useState(
    !!policies?.numberOfEnvironmentsTotal
  );

  useEffect(() => {
    if (!isNumOfEnvsUserLimitEnabled && policies?.numberOfEnvironments) {
      setValue('numberOfEnvironments', null);
    }
  }, [isNumOfEnvsUserLimitEnabled, policies?.numberOfEnvironments, setValue]);
  useEffect(() => {
    if (!isNumOfEnvsTotalLimitEnabled && policies?.numberOfEnvironmentsTotal) {
      setValue('numberOfEnvironmentsTotal', null);
    }
  }, [isNumOfEnvsTotalLimitEnabled, policies?.numberOfEnvironmentsTotal, setValue]);

  return (
    <Card title={<FormattedMessage id={`${messagePrefix}.title.environment.limits`} />}>
      <StyledSectionDiv>
        <ControlledBigCheckbox
          form={environmentLimitsForm}
          name="disableDestroyEnvironments"
          data-e2e="project-settings-policies-disable-destroy-checkbox">
          <FormattedMessage id={`${messagePrefix}.disable-destroy.checkbox`} />
          <Info alignTop={false} information={<FormattedMessage id={`${messagePrefix}.disable-destroy.tooltip`} />} />
        </ControlledBigCheckbox>
        <NumOfEnvironmentsLimitContainer>
          <BigCheckbox
            checked={isNumOfEnvsUserLimitEnabled}
            disabled={environmentLimitsForm.formState.isSubmitting}
            onChange={e => setIsNumOfEnvsUserLimitEnabled(e.target.checked)}
            data-e2e="project-settings-policies-num-of-envs-checkbox">
            <FormattedMessage id={`${messagePrefix}.num-env-user-limit.checkbox`} />
            <Info
              alignTop={false}
              information={
                <FormattedMessage
                  id={`${messagePrefix}.num-env-user-limit.tooltip`}
                  values={setLink(links.docs.POLICIES.ENVIRONMENT_LIMITS)}
                />
              }
            />
          </BigCheckbox>
          <StyledControlledNumberInput
            form={environmentLimitsForm}
            inline={true}
            name="numberOfEnvironments"
            min={MIN_NUM_OF_ENVIRONMENTS}
            data-e2e="project-settings-policies-num-of-envs-input"
            disabled={!isNumOfEnvsUserLimitEnabled}
          />
        </NumOfEnvironmentsLimitContainer>
        <NumOfEnvironmentsLimitContainer>
          <BigCheckbox
            checked={isNumOfEnvsTotalLimitEnabled}
            disabled={environmentLimitsForm.formState.isSubmitting}
            onChange={e => setIsNumOfEnvsTotalLimitEnabled(e.target.checked)}
            data-e2e="project-settings-policies-num-of-envs-total-checkbox">
            <FormattedMessage id={`${messagePrefix}.num-env-total-limit.checkbox`} />
            <Info
              alignTop={false}
              information={
                <FormattedMessage
                  id={`${messagePrefix}.num-env-total-limit.tooltip`}
                  values={setLink(links.docs.POLICIES.ENVIRONMENT_LIMITS)}
                />
              }
            />
          </BigCheckbox>
          <StyledControlledNumberInput
            form={environmentLimitsForm}
            inline={true}
            name="numberOfEnvironmentsTotal"
            min={MIN_NUM_OF_ENVIRONMENTS}
            data-e2e="project-settings-policies-num-of-envs-total-input"
            disabled={!isNumOfEnvsTotalLimitEnabled}
          />
        </NumOfEnvironmentsLimitContainer>
      </StyledSectionDiv>

      <Divider orientationMargin="0%" />

      <ControlledSaveDiscardButtons
        saveButtonDataE2E="project-settings-policies-settings-save"
        cancelButtonDataE2E="project-settings-policies-settings-discard"
        form={environmentLimitsForm}
        cancelMessageId={'discard.changes'}
        inContainer={true}
      />
    </Card>
  );
};

const StyledSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const NumOfEnvironmentsLimitContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledControlledNumberInput = styled(ControlledNumberInput)`
  margin-left: 10px;
  border-color: ${({ theme }) => theme.primaryDisabledGray} !important;
`;

export default PolicySettings;
