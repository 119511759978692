import { DeploymentStepStatus } from 'types/api.types';
import type { EnvironmentApi } from '@env0/environment-service/api';

export const getLogKey = (stepName: string, deploymentLogId: string) => `${deploymentLogId}-step-${stepName}`;

export const isStepWaitingForApproval = (stepStatus: DeploymentStepStatus) =>
  stepStatus === DeploymentStepStatus.WAITING_FOR_USER;

export const isEnvironmentWaitingForApproval = (envStatus: EnvironmentApi.Environment['status']) =>
  envStatus === 'WAITING_FOR_USER';
