import React from 'react';
import { TemplateTypeAvatar } from 'components/common/template-type-avatar';
import ControlledRadioGroup from 'components/common/form-controlled/controlled-radio-group';
import type { SettingsForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';

type TerragruntTfBinaryFieldProps = {
  form: SettingsForm;
};

const terragruntTfBinaries = [
  {
    name: 'OpenTofu',
    value: 'opentofu'
  },
  {
    name: 'Terraform',
    value: 'terraform'
  }
];

export const TerragruntTfBinaryField = ({ form }: TerragruntTfBinaryFieldProps) => {
  return (
    <ControlledRadioGroup
      hideError
      info={{ id: 'templates.wizard.step.settings.terragrunt-tf-binary.info' }}
      label={{ id: 'templates.wizard.step.settings.terragrunt-tf-binary.label' }}
      horizontal
      form={form}
      name="terragruntTfBinary"
      items={terragruntTfBinaries.map(terragruntTfBinary => ({
        content: (
          <>
            <TemplateTypeAvatar
              templateType={terragruntTfBinary.value as BlueprintApi.BlueprintType}
              hideTooltip
              size={28}
            />
            {terragruntTfBinary.name}
          </>
        ),
        value: terragruntTfBinary.value
      }))}
    />
  );
};
