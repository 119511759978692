import { getRepositoryUrl } from './vcs.utils';

const AzureDevopsDomain = 'dev.azure.com';
const BitbucketDomain = 'bitbucket.org';
const GithubDomain = 'github.com';
const GitlabDomain = 'gitlab.com';

const buildUrl = ({
  repository,
  revision,
  url,
  path,
  isTag
}: {
  repository: string;
  revision: string;
  url?: string;
  path?: string;
  isTag: boolean;
}) => {
  if (repository.includes(BitbucketDomain)) {
    return `${url}${revision ? `/src/${revision}/${path}` : ''}`;
  } else if (repository.includes(AzureDevopsDomain)) {
    return `${url}?path=/${path}&version=${isTag ? 'GT' : 'GB'}${revision}`;
  } else {
    return `${url}${revision ? `/tree/${revision}/${path}` : ''}`;
  }
};

export const getGitRevisionUrl = ({
  repository,
  revision,
  path,
  isTag = false
}: {
  repository: string;
  revision?: string;
  path?: string;
  isTag?: boolean;
}) => {
  const url = getRepositoryUrl(repository);
  const fixedRevision = revision ?? (repository.includes(AzureDevopsDomain) ? 'main' : path ? 'master' : '');
  const fixedPath = path ?? '';
  const builtUrl = buildUrl({ repository, revision: fixedRevision, url, path: fixedPath, isTag });
  return builtUrl.endsWith('/') || repository.includes(AzureDevopsDomain) ? builtUrl : `${builtUrl}/`;
};

export const getGitPrUrl = (repository: string, prNumber: string) => {
  const url = getRepositoryUrl(repository);
  const separator = repository.includes(GithubDomain)
    ? 'pull'
    : repository.includes(GitlabDomain)
    ? '-/merge_requests'
    : repository.includes(BitbucketDomain)
    ? 'pull-requests'
    : repository.includes(AzureDevopsDomain)
    ? 'pullrequest'
    : '';
  const builtUrl = [url, separator, prNumber].filter(_ => _).join('/');

  return builtUrl.endsWith('/') ? builtUrl : `${builtUrl}/`;
};
