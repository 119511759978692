import { useState } from 'react';
import { Grid, type GlobalToken } from 'antd';
import { useToken } from 'antd/lib/theme/internal';

const { useBreakpoint } = Grid;

const getInitialBreakpoints = (tokens: GlobalToken): ReturnType<typeof useBreakpoint> => {
  if (typeof window === 'undefined') {
    return {};
  }
  const { screenXSMax, screenSM, screenMD, screenLG, screenXL, screenXXL } = tokens;
  const width = window.document.documentElement.clientWidth;
  return {
    xs: width < screenXSMax,
    sm: width >= screenSM,
    md: width >= screenMD,
    lg: width >= screenLG,
    xl: width >= screenXL,
    xxl: width >= screenXXL
  };
};

const useIsMobile = () => {
  // When a breakpoint is true, all the breakpoints above it are also true.
  const [, tokens] = useToken();
  const [initialBreakpoints] = useState(() => getInitialBreakpoints(tokens));
  const dynamicBreakpoints = useBreakpoint();
  const { sm, md, xl } = { ...initialBreakpoints, ...dynamicBreakpoints };
  return {
    isMobile: Boolean(!md),
    isDesktop: Boolean(md),
    isLargeDesktop: Boolean(xl),
    isLandscapeMobile: Boolean(sm)
  };
};

export default useIsMobile;
