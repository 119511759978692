import Divider from 'components/common/divider';
import { FieldRow } from 'components/common/form-components';
import RevisionsSelectField from 'components/templates/templates-wizard/vcs/revisions-select-field';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import React from 'react';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { GitProviderTypes } from 'types/api.types';

interface Props {
  rawTemplateType: BlueprintApi.BlueprintType;
  templateType: BlueprintApi.BlueprintType;
  form: VCSForm;
  vcsRevisionDisabled?: boolean;
  showRevisionsDropdown: boolean;
  gitProvider: GitProviderTypes;
}

const configurationTemplateTypes = ['custom-flow', 'approval-policy'];

function getPathPlaceholder(templateType: BlueprintApi.BlueprintType, gitProvider: GitProviderTypes) {
  if (configurationTemplateTypes.includes(templateType)) {
    return `templates.add.new.field.path.${templateType}.placeholder`;
  } else if (gitProvider === 'HelmRepository') {
    return 'templates.add.new.field.path.helmRepo.placeholder';
  } else {
    return 'templates.add.new.field.path.placeholder';
  }
}

export const LocationSection: React.FC<Props> = ({
  showRevisionsDropdown,
  templateType,
  rawTemplateType,
  form,
  gitProvider,
  vcsRevisionDisabled
}) => {
  const pathInfoProps = switchPathInfo(rawTemplateType, templateType, gitProvider);
  const pathLabelId = getPathLabelId(rawTemplateType, gitProvider);

  return (
    <div data-e2e={`${rawTemplateType}-location-section`}>
      <Divider
        data-e2e={`${rawTemplateType}-location-divider`}
        titleId="templates.add.new.divider.location"
        titleValues={{ templateType }}
      />
      <FieldRow>
        {rawTemplateType !== 'module' && !vcsRevisionDisabled && (
          <RevisionsSelectField
            gitProvider={gitProvider}
            vcsForm={form}
            showDropdown={showRevisionsDropdown}
            data-e2e="revisions-select"
          />
        )}
        <ControlledTextInput
          inline
          width={50}
          label={{
            id: pathLabelId,
            values: { templateType }
          }}
          info={pathInfoProps}
          form={form}
          data-e2e="template-path-field"
          name={gitProvider === 'HelmRepository' ? 'helmChartName' : 'path'}
          placeholder={getPathPlaceholder(rawTemplateType, gitProvider)}
          placeholderValues={{ templateType }}
        />
      </FieldRow>
      {rawTemplateType === 'cloudformation' && (
        <FieldRow>
          <ControlledTextInput
            inline
            width={49}
            mandatory
            label={{ id: `templates.add.new.field.fileName.${rawTemplateType}` }}
            info={{ id: `templates.add.new.field.fileName.${rawTemplateType}.info` }}
            form={form}
            data-e2e="template-fileName-field"
            name="fileName"
            placeholder={`templates.add.new.field.fileName.${rawTemplateType}.placeholder`}
          />
        </FieldRow>
      )}
    </div>
  );
};

const switchPathInfo = (
  rawTemplateType: BlueprintApi.BlueprintType,
  templateType: string,
  gitProvider: GitProviderTypes
) => {
  const infoPrefix = 'templates.add.new.field.path.info';

  if (gitProvider === 'HelmRepository') {
    return {
      id: `${infoPrefix}.helmRepo`
    };
  }

  switch (rawTemplateType) {
    case 'workflow':
      return {
        id: `${infoPrefix}.workflow`,
        values: setLink(links.docs.ENVIRONMENTS.CREATE_NEW_WORKFLOW)
      };
    case 'custom-flow':
      return {
        id: `${infoPrefix}.custom-flow`,
        values: setLink(links.docs.TEMPLATES.PROJECT_LEVEL_CUSTOM_FLOWS)
      };
    case 'approval-policy':
      return {
        id: `${infoPrefix}.approval-policy`,
        values: setLink(links.docs.POLICIES.APPROVAL_POLICIES)
      };
    case 'module':
      return {
        id: `${infoPrefix}.module`
      };
    default:
      return {
        id: `${infoPrefix}`,
        values: { templateType }
      };
  }
};

const getPathLabelId = (templateType: BlueprintApi.BlueprintType, gitProvider: GitProviderTypes) => {
  const infoPrefix = 'templates.add.new.field.path';

  if (configurationTemplateTypes.includes(templateType)) {
    return `${infoPrefix}.${templateType}`;
  } else if (gitProvider === 'HelmRepository') {
    return `${infoPrefix}.helmRepo`;
  } else {
    return infoPrefix;
  }
};
