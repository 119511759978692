import styled from 'types/theme.types';
import { ReactComponent as UnstyledEditIcon } from 'assets/images/editable-icon-green.svg';

const EditIcon = styled(UnstyledEditIcon)`
  font-size: 19px;
  opacity: 1;
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export default EditIcon;
