import type { Action } from 'kbar';
import type { Project } from 'types/api.types';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PROJECT_SETTINGS_MENU_KEYS } from 'components/pages/project-settings';
import capitalize from 'lodash/capitalize';
import flatMap from 'lodash/flatMap';
import { useCuratedProjects } from 'stores/rq/projects';
import { useMemo } from 'react';
import TemplateIcon from 'assets/images/template-icon.svg';
import SettingsIcon from 'assets/images/settings-icon.svg';
import EnvironmentIcon from 'assets/images/environment-icon.svg';
import VariableIcon from 'assets/images/variables-icon.svg';

import { useHasPermission } from 'hooks/use-has-permission';
import compact from 'lodash/compact';

const generateSettingsAction = (
  { id, name }: Pick<Project, 'id' | 'name'>,
  navigate: (path: string) => void
): Action[] => [
  {
    id: `PROJECT_${id}_GOTO_settings_PAGE`,
    parent: `PROJECT_${id}_SECTION`,
    name: `Go to Settings`,
    keywords: `Go to settings`,
    section: name,
    icon: SettingsIcon
  },
  ...PROJECT_SETTINGS_MENU_KEYS.map(key => ({
    id: `PROJECT_${id}_GOTO_settings_PAGE_${key}`,
    parent: `PROJECT_${id}_GOTO_settings_PAGE`,
    name: capitalize(key),
    keywords: key,
    perform: () => navigate(`/p/${id}/settings/${key}`),
    section: 'settings'
  }))
];

const generateProjectPageAction = (
  id: string,
  name: string,
  page: string,
  icon: string,
  navigate: NavigateFunction
) => ({
  id: `PROJECT_${id}_GOTO_${page}_PAGE`,
  parent: `PROJECT_${id}_SECTION`,
  name: `Go to ${capitalize(page)}`,
  keywords: `Go to ${page}`,
  perform: () => navigate(`/p/${id}/${page}`),
  icon,
  section: name
});

export default function useProjectNavigation() {
  const navigate = useNavigate();
  const { activeProjects: projectsMap } = useCuratedProjects();
  const { isAuthorized: canViewProjects } = useHasPermission('VIEW_PROJECT');
  const { isAuthorized: canEditProjectSettings } = useHasPermission('EDIT_PROJECT_SETTINGS');
  const activeProjects = useMemo(() => Object.values(projectsMap), [projectsMap]);

  return useMemo(
    () =>
      compact(
        flatMap(activeProjects, ({ id, name }) => [
          {
            id: `PROJECT_${id}_SECTION`,
            name: name,
            keywords: name,
            section: 'projects'
          },
          generateProjectPageAction(id, name, 'environments', EnvironmentIcon, navigate),
          canViewProjects && generateProjectPageAction(id, name, 'templates', TemplateIcon, navigate),
          canViewProjects && generateProjectPageAction(id, name, 'variables', VariableIcon, navigate),
          ...(canEditProjectSettings ? generateSettingsAction({ id: id, name: name }, navigate) : [])
        ])
      ),
    [activeProjects, canEditProjectSettings, canViewProjects, navigate]
  );
}
