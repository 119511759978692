import type { ReactNode } from 'react';
import React from 'react';
import { Typography } from 'antd';

export const TooltipEllipsisText = ({ text }: { text: ReactNode }) => (
  <Typography.Text ellipsis={{ tooltip: text }}>
    {/* NOTE: Fragment is necessary since without it for some reason it doesn't know to show tooltip when resizing */}
    <>{text}</>
  </Typography.Text>
);
