import type { ErrorInfo, ReactNode } from 'react';
import React from 'react';
import ErrorContainer from 'components/common/error-container';

import * as logger from 'utils/logger.utils';
import { reportError } from 'utils/sentry.utils';

interface ErrorBoundaryProps {
  renderErrorMessageOnError: boolean;
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    reportError('ErrorBoundary', error, { ...errorInfo });
    logger.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, renderErrorMessageOnError } = this.props;

    if (hasError && renderErrorMessageOnError) {
      return <ErrorContainer />;
    }

    return children;
  }
}

export default ErrorBoundary;
