import { BulkOperationType } from '@env0/bulk-operations-service/api.enum';
import type { HttpClient } from './client';
import type { BulkOperationsApi } from '@env0/bulk-operations-service/api';
import type { AxiosResponse } from 'axios';

interface BulkOperationsClient {
  [BulkOperationType.Approve]: (
    request: BulkOperationsApi.BulkApprove.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkApprove.Response>>;
  [BulkOperationType.Archive]: (
    request: BulkOperationsApi.BulkArchive.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkArchive.Response>>;
  [BulkOperationType.Cancel]: (
    request: BulkOperationsApi.BulkCancel.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkCancel.Response>>;
  [BulkOperationType.CancelQueuedDeployments]: (
    request: BulkOperationsApi.BulkCancelQueuedDeployments.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkCancelQueuedDeployments.Response>>;
  [BulkOperationType.Deploy]: (
    request: BulkOperationsApi.BulkDeploy.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkDeploy.Response>>;
  [BulkOperationType.Destroy]: (
    request: BulkOperationsApi.BulkDestroy.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkDestroy.Response>>;
  [BulkOperationType.Lock]: (
    request: BulkOperationsApi.BulkLock.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkLock.Response>>;
  [BulkOperationType.RunTask]: (
    request: BulkOperationsApi.BulkRunTask.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkRunTask.Response>>;
  [BulkOperationType.Unlock]: (
    request: BulkOperationsApi.BulkUnlock.Request.Body
  ) => Promise<AxiosResponse<BulkOperationsApi.BulkUnlock.Response>>;
}

const api = (client: HttpClient) =>
  Object.values(BulkOperationType).reduce(
    (acc, type) => ({
      ...acc,
      [type]: (data: object) => client.post(`/bulk-operations/${type}`, data)
    }),
    {}
  ) as BulkOperationsClient;

export default api;
