import React from 'react';
import styled from 'types/theme.types';
import { WifiOutlined } from '@ant-design/icons';
import Tooltip from 'components/common/tooltip';
import { FormattedMessage } from 'react-intl';
import { links } from 'constants/external-links';
import TrackableLink from 'components/common/trackable-link';
import { EventNames } from 'utils/analytics.utils';

export const DrawerHeader = () => {
  return (
    <Wrapper>
      <FormattedMessage id="whats.new" />
      <Tooltip titleId="rss.feed" placement="left">
        <TrackableLink
          trackingData={{ eventName: EventNames.IN_APP_WHATS_NEW_RSS_FEED_CLICKED }}
          url={links.docs.CHANGE_LOG.RSS_FEED}
          className="rss-feed">
          <WifiOutlined />
        </TrackableLink>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  font-size: 16px;
  height: 20px;

  .rss-feed {
    padding: 3px;
    display: grid;
    font-size: 20px;
    place-items: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    color: black;
    transform: translateY(-50%) rotate(50deg);

    &:hover {
      cursor: pointer;
    }
  }
`;
