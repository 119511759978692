import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import { useQuery, useMutation } from '@tanstack/react-query';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import type { Blueprint, CreateBlueprint, UpdateBlueprint } from 'types/api.types';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import { filterOutById } from 'stores/rq/common/react-query-utils';
import uniq from 'lodash/uniq';
import flow from 'lodash/fp/flow';
import orderBy from 'lodash/fp/orderBy';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();
  const baseKey = [orgId, 'blueprints'] as const;
  return {
    all: [...baseKey, 'all'] as const,
    single: (id: string) => [...baseKey, 'single', { id }] as const,
    byProjectId: (projectId?: string) => [...baseKey, 'projectBlueprints', { projectId }] as const
  } as const;
};

const useCommonHookSetup = () => {
  return {
    apiClient: useApiClient(),
    queryClient: getQueryClientInstance(),
    organizationId: useCurrentOrganizationId(),
    ...useCacheKeys()
  };
};

export interface UseGetBlueprintsOptions {
  enabled?: boolean;
}

export const useGetBlueprints = ({ enabled = true }: UseGetBlueprintsOptions = {}) => {
  const { apiClient, organizationId, all } = useCommonHookSetup();

  return useQuery({
    queryKey: all,
    queryFn: async ({ queryKey: [organizationId] }) =>
      sortBlueprints(await apiClient.blueprints.getForOrganization(organizationId)),
    enabled: isString(organizationId) && enabled
  });
};

export const useCreateBlueprint = () => {
  const { apiClient, queryClient, all, single, byProjectId } = useCommonHookSetup();
  return useMutation({
    mutationFn: (blueprint: CreateBlueprint) => apiClient.blueprints.create(blueprint),
    onSuccess: (newBlueprint: Blueprint) => {
      queryClient.setQueryData(single(newBlueprint.id), newBlueprint);
      queryClient.refetchQueries({ queryKey: all });
      newBlueprint?.projectIds?.forEach(projectId => queryClient.refetchQueries({ queryKey: byProjectId(projectId) }));
    }
  });
};

export const useUpdateBlueprint = () => {
  const { apiClient, queryClient, all, single, byProjectId } = useCommonHookSetup();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateBlueprint }) => apiClient.blueprints.update(id, data),
    onSuccess: (updatedBlueprint: Blueprint) => {
      queryClient.setQueryData(single(updatedBlueprint.id), updatedBlueprint);
    },
    onError: (_error, { id }) => {
      queryClient.refetchQueries({ queryKey: single(id) });
    },
    onSettled: updatedBlueprint => {
      queryClient.refetchQueries({ queryKey: all });
      updatedBlueprint?.projectIds?.forEach(projectId =>
        queryClient.refetchQueries({ queryKey: byProjectId(projectId) })
      );
    }
  });
};

export const useRemoveBlueprint = () => {
  const { apiClient, queryClient, all, single, byProjectId } = useCommonHookSetup();

  return useMutation({
    mutationFn: (id: string) => apiClient.blueprints.remove(id),
    onMutate: (id: string) => {
      const previousAllBlueprints: Blueprint[] | undefined = queryClient.getQueryData(all);
      const previousSingleBlueprint: Blueprint[] | undefined = queryClient.getQueryData(single(id));
      queryClient.setQueryData(all, filterOutById<Blueprint>(id));
      queryClient.removeQueries({ queryKey: single(id) });
      return { previousAllBlueprints, previousSingleBlueprint };
    },
    onError: (_error, id, mutateContext) => {
      queryClient.setQueryData(all, mutateContext?.previousAllBlueprints);
      queryClient.setQueryData(single(id), mutateContext?.previousSingleBlueprint);
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: byProjectId() });
    }
  });
};

export const useRemoveBlueprintsFromProject = () => {
  const { apiClient, queryClient, all, byProjectId, single } = useCommonHookSetup();

  return useMutation({
    mutationFn: ({ projectId, blueprintIds }: { projectId: string; blueprintIds: string[] }) =>
      Promise.all(
        blueprintIds.map(blueprintId => apiClient.blueprints.removeBlueprintFromProject(projectId, blueprintId))
      ).catch(err => {
        if (err?.response?.status === 404) {
          return [];
        } else {
          throw err;
        }
      }),
    onSettled: (_, _err, { projectId, blueprintIds }) => {
      blueprintIds.forEach(blueprintId => queryClient.refetchQueries({ queryKey: single(blueprintId) }));
      queryClient.refetchQueries({ queryKey: byProjectId(projectId) });
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useAddBlueprintsToProject = () => {
  const { apiClient, queryClient, all, single, byProjectId } = useCommonHookSetup();

  return useMutation({
    mutationFn: ({ projectId, blueprintIds }: { projectId: string; blueprintIds: string[] }) =>
      Promise.all(blueprintIds.map(blueprintId => apiClient.blueprints.addBlueprintToProject(projectId, blueprintId))),
    onSettled: (_, _err, { projectId, blueprintIds }) => {
      blueprintIds.forEach(blueprintId => queryClient.refetchQueries({ queryKey: single(blueprintId) }));
      queryClient.refetchQueries({ queryKey: byProjectId(projectId) });
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useGetBlueprintsByProjectId = (projectId: string) => {
  const { apiClient, queryClient, all, byProjectId } = useCommonHookSetup();

  // for fetch by project id we return only the projectId value and not all the template projectIds like other requests so we set the value from existing cache
  const setBlueprintProjectIdsFromExistingCache = (allBlueprints?: Blueprint[]) => {
    return (blueprint: Blueprint) => {
      const existingBlueprintProjects =
        allBlueprints?.find(storedBlueprint => storedBlueprint.id === blueprint.id)?.projectIds || [];
      return {
        ...blueprint,
        projectIds: uniq([blueprint.projectId!, ...existingBlueprintProjects].flat())
      };
    };
  };
  const { data: allBlueprints } = useGetBlueprints();
  return useQuery<Blueprint[], any, Blueprint[], ReturnType<typeof byProjectId>>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: byProjectId(projectId),
    enabled: !isEmpty(allBlueprints),
    queryFn: async ({ queryKey: [, , , { projectId }] }) => {
      const blueprintsForProject: Blueprint[] = await apiClient.blueprints.getForProject(projectId!);
      return sortBlueprints(blueprintsForProject.map(setBlueprintProjectIdsFromExistingCache(allBlueprints)));
    },
    placeholderData: () => {
      const allBlueprints = queryClient.getQueryData<Blueprint[]>(all);
      return sortBlueprints(allBlueprints?.filter(blueprint => blueprint.projectIds?.includes(projectId)));
    }
  });
};

export const useGetBlueprint = (blueprintId: string | null | undefined) => {
  const { apiClient, queryClient, all, single } = useCommonHookSetup();

  return useQuery<Blueprint, any, Blueprint, ReturnType<typeof single>>({
    queryKey: single(blueprintId!),
    queryFn: ({ queryKey: [, , , { id }] }) => apiClient.blueprints.getById(id),
    enabled: isString(blueprintId),
    placeholderData: () => {
      const allBlueprints = queryClient.getQueryData<Blueprint[]>(all);
      return allBlueprints?.find(blueprint => blueprint.id === blueprintId);
    }
  });
};

function sortBlueprints(blueprints: Blueprint[]): Blueprint[];
function sortBlueprints(blueprints?: Blueprint[]): undefined | Blueprint[];
function sortBlueprints(blueprints?: Blueprint[]) {
  if (!blueprints) {
    return undefined;
  }
  return flow(orderBy('createdAt', 'desc'))(blueprints) as Blueprint[];
}
