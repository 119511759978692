import React, { useState } from 'react';
import { AppNotificationsDrawer } from 'components/app-notifications/drawer/app-notifications-drawer';
import { AppNotificationsButton } from 'components/app-notifications/app-notifications-button';
import { NotificationRow } from 'components/app-notifications/drawer/notification-row';
import { Divider } from 'antd';
import { useAppNotifications } from 'components/app-notifications/use-app-notifications';
import ErrorContainer from 'components/common/error-container';
import { EventNames, track } from 'utils/analytics.utils';
import map from 'lodash/map';

export const AppNotifications = () => {
  const [open, setOpen] = useState(false);
  const { notifications, isRead, setRead, shouldShowError, error } = useAppNotifications();

  const onAppNotificationsButtonClick = () => {
    track(EventNames.IN_APP_WHATS_NEW_ICON_CLICKED);
    setOpen(true);
    setRead();
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppNotificationsButton onClick={onAppNotificationsButtonClick} isRead={isRead} />
      <AppNotificationsDrawer onClose={onClose} open={open}>
        {shouldShowError ? (
          <ErrorContainer errorToReport={error} />
        ) : (
          map(notifications, notifications => (
            <React.Fragment key={notifications.id}>
              <NotificationRow notification={notifications} />
              <Divider style={{ margin: 0 }} />
            </React.Fragment>
          ))
        )}
      </AppNotificationsDrawer>
    </>
  );
};
