import type { UseFormReturnType } from 'hooks/use-form.hook';
import React from 'react';
import ControlledRadioGroup from 'components/common/form-controlled/controlled-radio-group';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { FormattedMessage } from 'react-intl';

type WorkspaceNamingRadioButtonsProps = {
  form: UseFormReturnType;
  disabled?: boolean;
};
const workspaceNamingValues: BlueprintApi.WorkspaceNaming[] = ['default', 'environmentName'];

const workspaceNamingRadioButtons = workspaceNamingValues.map(option => ({
  content: <FormattedMessage id={`project.settings.environment.discovery.workspace.naming.${option}.value`} />,
  value: option
}));

const WorkspaceNamingRadioButtons: React.FunctionComponent<WorkspaceNamingRadioButtonsProps> = ({ form, disabled }) => (
  <ControlledRadioGroup form={form} name={'workspaceNaming'} items={workspaceNamingRadioButtons} disabled={disabled} />
);

export default WorkspaceNamingRadioButtons;
