import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { FormattedMessage } from 'react-intl';
import { INHERIT_FROM_ORG } from 'constants/ttl.constants';
import styled from 'types/theme.types';
import BigCheckbox from 'components/common/input-components/big-checkbox';
import { TtlInputNumber } from 'components/policies/ttl-input-number';
import { TtlRadioButtons } from 'components/policies/ttl-radio-buttons';
import type { TtlPolicy } from 'components/policies/common-ttl';
import { validTimeUnits, INIT_TTL_VALUE } from 'components/policies/common-ttl';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';

export interface MaxTTLSectionProps {
  canInherit?: boolean;
  form: UseFormReturnType<TtlPolicy>;
}

export const MaxTTLSection: React.FC<MaxTTLSectionProps> = ({ canInherit, form }) => {
  const maxTtlButtons = <TtlRadioButtons form={form} name={'maxTtl'} />;

  return (
    <>
      <Row>
        {canInherit && (
          <BigCheckbox
            checked={form.getValues('maxTtl') === INHERIT_FROM_ORG}
            disabled={form.formState.isSubmitting}
            onChange={(inheritCheckboxChangeEvent: CheckboxChangeEvent) => {
              form.setValue('maxTtl', inheritCheckboxChangeEvent.target.checked ? INHERIT_FROM_ORG : INIT_TTL_VALUE);
              form.setValue(
                'defaultTtl',
                inheritCheckboxChangeEvent.target.checked ? INHERIT_FROM_ORG : INIT_TTL_VALUE
              );
            }}
            data-e2e="project-settings-max-ttl-use-org-settings-checkbox">
            <FormattedMessage id={'project.settings.policies.use.org.settings'} />
          </BigCheckbox>
        )}
      </Row>
      <Row>
        <MaxEnvironmentTTLContainer>
          {maxTtlButtons}
          <TtlInputNumber form={form} name={'maxTtl'} validTimeUnits={validTimeUnits} />
        </MaxEnvironmentTTLContainer>
      </Row>
    </>
  );
};

const MaxEnvironmentTTLContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
