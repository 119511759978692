import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import type { TokenTypes } from '@env0/configuration-service/api.enum';
import useApiClient from 'hooks/use-api-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import keyBy from 'lodash/fp/keyBy';
import { getQueryClientInstance } from './common/query-client-provider';
import type { Token } from 'types/api.types';

const useCacheKeys = () => {
  return {
    byTokenId: (orgId: string, tokenId?: string) => {
      return ['vcs-access-tokens', orgId, tokenId] as const;
    },
    byTokenType: (orgId: string, tokenType: TokenTypes) => {
      return ['vcs-access-tokens', orgId, tokenType] as const;
    }
  };
};

export function useGetTokens(tokenType: TokenTypes) {
  const apiClient = useApiClient();
  const orgId = useCurrentOrganizationId();
  const { byTokenType } = useCacheKeys();
  return useQuery({
    queryKey: byTokenType(orgId, tokenType),
    queryFn: async () => {
      if (!tokenType) {
        return {};
      }
      const tokens = await apiClient.configurations.getTokens(orgId, tokenType);
      return keyBy('id', tokens);
    }
  });
}

export function useGetToken(tokenId?: string) {
  const orgId = useCurrentOrganizationId();
  const apiClient = useApiClient();
  const { byTokenId } = useCacheKeys();
  return useQuery({
    queryKey: byTokenId(orgId, tokenId),
    queryFn: async () => {
      if (!tokenId) {
        return {} as any;
      }
      return apiClient.configurations.getTokenById(tokenId);
    }
  });
}

export function useCreateToken() {
  const apiClient = useApiClient();
  const orgId = useCurrentOrganizationId();
  const queryClient = getQueryClientInstance();
  const { byTokenId, byTokenType } = useCacheKeys();
  return useMutation({
    mutationFn: async (token: Token) => {
      return apiClient.configurations.createToken(token);
    },
    onSuccess: token => {
      queryClient.setQueryData(byTokenId(orgId, token.id), token);
      queryClient.refetchQueries({ queryKey: byTokenType(orgId, token.type) });
    }
  });
}
