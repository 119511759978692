import { observer } from 'mobx-react';
import React from 'react';
import type { Project } from 'types/api.types';
import ProjectSettingsCostCredentials from 'components/projects/settings/credentials/project-settings-cost-credentials';
import ProjectSettingsDeployCredentials from 'components/projects/settings/credentials/project-settings-deploy-credentials';
import ErrorContainer from 'components/common/error-container';
import Loader from 'components/common/loader';
import Stack from 'components/common/stack';
import { useCuratedCredentials } from 'stores/rq/credentials';
import SubProjectWontBeAffectedAlert from 'components/projects/sub-projects-not-affected-alert';

type Props = { project: Project };

const ProjectSettingsCredentials: React.FunctionComponent<Props> = ({ project }) => {
  const { isLoading, error } = useCuratedCredentials();

  if (error) {
    return <ErrorContainer errorToReport={error} />;
  } else if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack>
      <SubProjectWontBeAffectedAlert pageType={'configuration'} />
      <ProjectSettingsDeployCredentials project={project} />
      <ProjectSettingsCostCredentials project={project} />
    </Stack>
  );
};

export default observer(ProjectSettingsCredentials);
