import type { Blueprint } from 'types/api.types';
import useForm from 'hooks/use-form.hook';
import noop from 'lodash/noop';
import type { AnyObjectSchema, SchemaOf } from 'yup';
import type { DeepPartial, UnpackNestedValue } from 'react-hook-form';

type UseSettingsStepFormProps<T extends object> = {
  readonly: boolean;
  settingsSchema: AnyObjectSchema;
  template?: Blueprint;
  getInitialValues: (schema: AnyObjectSchema) => UnpackNestedValue<DeepPartial<T>>;
};

export type SettingsStepForm<T extends object> = ReturnType<typeof useSettingsStepForm<T>>;

const useSettingsStepForm = <T extends object>({
  readonly,
  settingsSchema,
  getInitialValues
}: UseSettingsStepFormProps<T>) => {
  return useForm<T>({
    initialValues: getInitialValues(settingsSchema) as UnpackNestedValue<DeepPartial<T>>,
    schema: settingsSchema as SchemaOf<UnpackNestedValue<T>>,
    shouldUnregister: false,
    onSubmit: noop,
    readonly
  });
};

export default useSettingsStepForm;
