import React from 'react';

import styled from 'types/theme.types';
import { FormattedMessage } from 'react-intl';
import type { CustomerIcon } from './customers-logos';
import CustomersLogos from './customers-logos';
import theme from 'constants/themes.constants';

interface TrustedByProps {
  backgroundColor?: string;
  numOfLogosInOneLine?: number;
  logosColor?: string;
  logos?: CustomerIcon[];
}

const TrustedBy: React.FC<TrustedByProps> = ({
  backgroundColor = theme.loginPageBottomPart,
  numOfLogosInOneLine = 4,
  logos,
  logosColor
}) => {
  return (
    <BottomContainer backgroundColor={backgroundColor}>
      <CustomersHeader titleColor={logosColor}>
        <FormattedMessage id="login.bullets.trusted-by" />
      </CustomersHeader>
      <LogosContainer numOfLogosInOneLine={numOfLogosInOneLine}>
        <CustomersLogos logos={logos} logosColor={logosColor} />
      </LogosContainer>
    </BottomContainer>
  );
};

const CustomersHeader = styled.div<{ titleColor?: string }>`
  padding: 30px 0;
  font-size: 16px;
  color: ${({ titleColor, theme }) => titleColor || theme.primaryWhite};
`;

const LogosContainer = styled.div<{ numOfLogosInOneLine: number }>`
  display: flex;
  padding: 0 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1 0 ${({ numOfLogosInOneLine }) => 100 / numOfLogosInOneLine}%;
  }

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const BottomContainer = styled.div<{ backgroundColor: string }>`
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  background: ${({ backgroundColor }) => backgroundColor};
  align-items: center;
  z-index: 20;

  @media (max-width: 1024px) {
    flex-basis: 10%;
  }

  :before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 2px;
    background: linear-gradient(
      90deg,
      #7affc3 0%,
      #00edb9 27%,
      #23e9d8 36%,
      #3ce7ef 45%,
      #45e6f7 50%,
      #3eddf8 52%,
      #2ac3fa 60%,
      #1eb4fc 67%,
      #1aaefc 73%,
      #3aa2fd 78%,
      #5e96fe 84%,
      #778dfe 90%,
      #8787ff 96%,
      #8c85ff 100%
    );
  }
`;

export default TrustedBy;
