import {
  useAssignCustomFlow,
  useInvalidateCustomFlowAssignmentsCache,
  useUnassignCustomFlow
} from 'stores/rq/custom-flow-assignments';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import type { PoliciesCustomFlowFormSchema } from 'components/projects/settings/policies/use-policies-form.hook';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { UseFormMethods } from 'react-hook-form/dist/types';
import differenceBy from 'lodash/differenceBy';

import type { DeepPartial, UnpackNestedValue } from 'react-hook-form';

import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import { useCreateCustomFlow, useDeleteCustomFlow, useUpdateCustomFlow } from 'stores/rq/custom-flows';
import isEqual from 'lodash/isEqual';

export interface CustomFlowForm {
  customFlowEnabled: boolean;
  customFlow?: BlueprintApi.CustomFlowTemplate;
  customFlowAssignments: BlueprintApi.RichCustomFlowAssigment[];
}

type Form = UseFormMethods<PoliciesCustomFlowFormSchema>;

const getUpdatedCustomFlows = (
  initialCustomFlowAssignments: BlueprintApi.RichCustomFlowAssigment[],
  currentCustomFlowAssignments: BlueprintApi.RichCustomFlowAssigment[]
) => {
  const initialCustomFlowsById: { [key: string]: BlueprintApi.RichCustomFlowAssigment['customFlowTemplate'] } =
    initialCustomFlowAssignments?.reduce((acc, assignment) => {
      return { ...acc, [assignment.customFlowTemplate.id!]: assignment.customFlowTemplate };
    }, {});
  return currentCustomFlowAssignments
    .filter(assignment => assignment.customFlowTemplate.id)
    .map(assignment => assignment.customFlowTemplate)
    .filter(assignment => !isEqual(assignment, initialCustomFlowsById[assignment.id!]));
};

export const useSubmitCustomFlow = () => {
  const { mutateAsync: create } = useCreateCustomFlow();
  const { mutateAsync: update } = useUpdateCustomFlow();
  const { mutateAsync: deleteCustomFlow } = useDeleteCustomFlow();
  const { mutateAsync: assign } = useAssignCustomFlow();
  const { mutateAsync: unassign } = useUnassignCustomFlow();
  const projectId = useCurrentProjectId();
  const invalidateCustomFlowAssignmentsCache = useInvalidateCustomFlowAssignmentsCache();

  return async (
    data: PoliciesCustomFlowFormSchema,
    form: Form,
    initialFormValues: UnpackNestedValue<DeepPartial<CustomFlowForm>>
  ) => {
    const initialCustomFlowAssignments =
      initialFormValues.customFlowAssignments as BlueprintApi.RichCustomFlowAssigment[];

    if (form.formState.dirtyFields['customFlowAssignments']) {
      const assignmentsWithOrder = data.customFlowAssignments.map((assignment, order) => ({ ...assignment, order }));
      const assignmentsToRemove = differenceBy(initialCustomFlowAssignments, assignmentsWithOrder, 'hash');
      const updatedCustomFlows = getUpdatedCustomFlows(initialCustomFlowAssignments, assignmentsWithOrder);
      const updateCustomFlowPromises = updatedCustomFlows.map(assignment => {
        return update(
          { ...assignment, id: assignment.id! },
          {
            onSuccess: () => {
              invalidateCustomFlowAssignmentsCache({ scope: 'PROJECT', scopeId: projectId! });
            }
          }
        );
      });
      const assignmentsToCreate = differenceBy(assignmentsWithOrder, initialCustomFlowAssignments, x => !x.hash);
      const createAndAssignCustomFlowsPromises = assignmentsToCreate.map(async assignment => {
        const res = await create({
          ...assignment.customFlowTemplate,
          name: `custom-flow-${assignment.scope}-${assignment.scopeId}-${uuid()}`,
          scopeId: projectId!
        });
        await assign([
          { blueprintId: res.id!, scope: assignment.scope, scopeId: assignment.scopeId, order: assignment.order }
        ]);
      });
      const assignmentsToUpdateOrder: BlueprintApi.RichCustomFlowAssigment[] = assignmentsWithOrder.reduce(
        (assignmentsAcc, assignment) => {
          if (
            assignment.hash &&
            (!initialCustomFlowAssignments[assignment.order] ||
              assignment.hash !== initialCustomFlowAssignments[assignment.order].hash)
          ) {
            assignmentsAcc.push(assignment);
          }

          return assignmentsAcc;
        },
        []
      );

      await Promise.all([
        !isEmpty(assignmentsToRemove) ? unassign(assignmentsToRemove) : Promise.resolve(),
        ...updateCustomFlowPromises,
        ...createAndAssignCustomFlowsPromises,
        assign(assignmentsToUpdateOrder)
      ]);

      await Promise.all(assignmentsToRemove.map(assignment => deleteCustomFlow(assignment.blueprintId!)));
    }
  };
};
