import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';

const useAsyncPromise = <T>(promiseFn: () => Promise<T>, deps?: DependencyList): [Error | null, T | null, boolean] => {
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const runWhenMounted = (fn: (...args: any) => any) => {
      if (isMounted) {
        fn();
      }
    };

    const onFulfilled = (promiseResult: T) => runWhenMounted(() => setResult(promiseResult));
    const onRejected = (promiseError: Error) => runWhenMounted(() => setError(promiseError));
    const onFinally = () => runWhenMounted(() => setLoading(false));

    setLoading(true);
    setError(null);
    setResult(null);
    promiseFn().then(onFulfilled, onRejected).finally(onFinally);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, deps);

  return [error, result, loading];
};

export { useAsyncPromise };
