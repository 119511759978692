import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { Alert } from 'antd';
import { useCuratedProjects } from 'stores/rq/projects';
import { isParentProject } from 'utils/sub-project.utils';

interface Props {
  pageType: 'configuration' | 'cost';
}

const SubProjectsNotAffectedAlert: React.FunctionComponent<Props> = ({ pageType }) => {
  const { projects } = useCuratedProjects();
  const currentProjectId = useCurrentProjectId();
  const intl = useIntl();
  const isInParentProject = useMemo(
    () => !!currentProjectId && isParentProject(projects[currentProjectId]),
    [projects, currentProjectId]
  );
  const messageId = `project.settings.sub-project.wont-be-affected.${pageType}.warning`;

  return isInParentProject ? (
    <Alert
      showIcon
      message={intl.formatMessage({ id: messageId })}
      type="warning"
      data-e2e={'sub-projects-not-affected-alert'}
    />
  ) : null;
};

export default observer(SubProjectsNotAffectedAlert);
