import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { useCallback, useState } from 'react';
import type { AuthType } from 'components/templates/templates-wizard/vcs/use-git-provider.hook';
import { type GitProviderTypes, TokenTypes } from 'types/api.types';
import { useGetToken, useGetTokens } from 'stores/rq/vcs-auth-tokens';

const authTypeOptions: { label: string; value: AuthType }[] = [
  { label: 'OAuth', value: TokenTypes.GitLab },
  { label: 'Access Token', value: TokenTypes.GIT }
];

const useAuthTokens = ({ setValue, initialFormValues }: VCSForm, gitProvider?: GitProviderTypes) => {
  const { data: initialToken, isLoading: isLoadingToken } = useGetToken(initialFormValues?.tokenId);
  const [authType, setAuthType] = useState<AuthType | undefined>();

  const getTokenType = () => {
    if (authType) return authType;

    const isEditingAzureDevopsToken =
      gitProvider === 'AzureDevOps' && initialFormValues?.isAzureDevOps && initialToken?.type === 'AzureDevops';
    const isEditingGitLabToken =
      gitProvider === 'GitLab' &&
      initialFormValues?.isGitLab &&
      (initialToken?.type === 'GitLab' || initialToken?.type === 'GIT');

    if (isEditingAzureDevopsToken || isEditingGitLabToken) {
      return initialToken.type;
    }

    // creating a new token, OtherVCS is the default
    switch (gitProvider) {
      case 'GitLab':
        return TokenTypes.GitLab;
      case 'AzureDevOps':
        return TokenTypes.AzureDevOps;
      default:
        return TokenTypes.GIT;
    }
  };

  const tokenType = getTokenType();

  const { data: tokens, isLoading: isLoadingTokens } = useGetTokens(tokenType);

  const onChangeAuthType = useCallback(
    (value: AuthType) => {
      setAuthType(value);
      //reset selected token
      setValue('tokenName', '');
      setValue('tokenId', '');
    },
    [setValue]
  );

  return {
    isLoadingTokens: isLoadingTokens || isLoadingToken,
    authType: tokenType,
    onChangeAuthType,
    authTypeOptions,
    tokens
  };
};

export default useAuthTokens;
