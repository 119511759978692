import React from 'react';
import styled from 'types/theme.types';
import { links } from 'constants/external-links';
import TrackableLink from 'components/common/trackable-link';
import { EventNames } from 'utils/analytics.utils';

export const DrawerFooter: React.FC = () => {
  return (
    <Wrapper>
      <TrackableLink
        trackingData={{ eventName: EventNames.IN_APP_WHATS_NEW_VIEW_ALL_CLICKED }}
        url={links.docs.CHANGE_LOG.INDEX}
        messageId={'whats.new.view.all.releases'}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 16px;
  display: grid;
  place-items: center;
`;
