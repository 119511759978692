import { QueryClient } from '@tanstack/react-query';

export const initializeQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        // TL;DR - This will prevent more loaders.
        // RQ has a limit to how long the cache lives (regardless if it's stale or not).
        // The default is 5m. after that - it just "disappears" (gets deleted).
        // We want to set it to Infinity, so we'd always have some sort of cache to display when the query fetches.
        gcTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  });
