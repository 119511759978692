import { returnData, type HttpClient } from 'services/api-client/client';
import type { VcsApi } from '@env0/vcs-service/api';

export default (client: HttpClient) => ({
  listVcsConnections: (organizationId: string) =>
    client.get<VcsApi.ListVcsConnections.Response>(`vcs/connections`, { params: { organizationId } }).then(returnData),
  getVcsRepositories: (organizationId: string, vcsType: string) =>
    client
      .get<VcsApi.GetVcsRepositories.Response>(`vcs/connections/repositories`, { params: { organizationId, vcsType } })
      .then(returnData),
  updateVcsConnection: (vcsConnectionId: string, data: VcsApi.UpdateVcsConnection.Request.Body) =>
    client.put<VcsApi.UpdateVcsConnection.Request.Body, VcsApi.UpdateVcsConnection.Response>(
      `vcs/connections/${vcsConnectionId}`,
      data
    ),
  createVcsConnection: (data: VcsApi.CreateVcsConnection.Request.Body) =>
    client.post<VcsApi.CreateVcsConnection.Request.Body, VcsApi.CreateVcsConnection.Response>(`vcs/connections`, data),
  deleteVcsConnection: (vcsConnectionId: string) => client.delete<void>(`vcs/connections/${vcsConnectionId}`)
});
