import { type ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import { type GroupedRowType } from 'utils/table-group-by.utils';
import { VariablesGroupHeader } from 'components/settings/variables-group-header';

interface SensitiveVariablesGroupHeaderProps {
  record: GroupedRowType<ConfigurationPropertyStore>;
}

export const SensitiveAndReadonlyVariablesGroupHeader: React.FC<SensitiveVariablesGroupHeaderProps> = ({ record }) => {
  return (
    <VariablesGroupHeader
      data-e2e="read-only-and-sensitive-group-row"
      record={record}
      titleParts={[
        { text: 'Read Only ', isBold: true },
        { text: 'and ' },
        { text: 'Sensitive Variables', isBold: true }
      ]}
      info={{ id: 'settings.variables.sensitive-and-readonly.explanation' }}
    />
  );
};
