import type { History } from 'history';
import { Router as ReactRouter } from 'react-router-dom';
import { useLayoutEffect, useState, type ReactNode } from 'react';

interface Props {
  history: History;
  children?: ReactNode;
}

const Router = ({ history, children }: Props) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <ReactRouter navigator={history} location={state.location} navigationType={state.action}>
      {children}
    </ReactRouter>
  );
};

export default Router;
