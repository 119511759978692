import useApiClient from 'hooks/use-api-client';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import type { TeamApi } from '@env0/team-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { safeOffset } from 'stores/rq/common/react-query-utils';

const DEFAULT_TEAMS_PAGE_SIZE = 20;

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'teams'] as const;
  const cacheKeys = {
    byTeamName: (teamName?: string) => [...baseKey, 'all', { teamName }] as const
  } as const;

  return cacheKeys;
};

export interface UseTeamsOptions {
  limit?: number;
  teamName?: string;
}

export const useGetTeams = ({ limit = DEFAULT_TEAMS_PAGE_SIZE, teamName }: UseTeamsOptions = {}) => {
  const apiClient = useApiClient();
  const { byTeamName } = useCacheKeys();

  return useInfiniteQuery({
    queryKey: byTeamName(teamName),
    queryFn: async ({ pageParam, queryKey: [orgId] }) => {
      const teamsResponse = await apiClient.teams.getTeams(orgId, {
        limit: limit.toString(),
        offset: safeOffset(pageParam),
        name: teamName
      });
      return teamsResponse as { teams: TeamApi.Team[]; nextPageKey?: string };
    },
    select: data => data?.pages?.flatMap(page => page.teams),
    refetchOnMount: 'always',
    placeholderData: keepPreviousData,
    initialPageParam: '0',
    getNextPageParam: lastPage => {
      return lastPage?.nextPageKey;
    }
  });
};
