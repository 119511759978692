import type { getInitialGitProvider } from 'utils/vcs.utils';
import { ReactComponent as RawHelmIcon } from 'assets/images/helm-logo-on-white.svg';
import styled from 'types/theme.types';
import { ReactComponent as RawBitbucketIcon } from 'assets/images/bitbucket.svg';
import { ReactComponent as RawGitlabIcon } from 'assets/images/gitlab.svg';
import { ReactComponent as RawGithubIcon } from 'assets/images/github.svg';
import { ReactComponent as RawGitIcon } from 'assets/images/git.svg';
import { ReactComponent as RawAzureDevOpsIcon } from 'assets/images/azure-devops.svg';

interface Props {
  providerName: ReturnType<typeof getInitialGitProvider>;
}

const VcsIcon = ({ providerName }: Props) => {
  if (!providerName) return <GitIcon />;
  return {
    BitBucket: <CenteredBitbucketIcon />,
    BitBucketServer: <CenteredBitbucketIcon />,
    GitHub: <CenteredGithubIcon />,
    GitHubEnterprise: <CenteredGithubIcon />,
    GitLab: <CenteredGitlabIcon />,
    AzureDevOps: <CenteredAzureDevopsIcon />,
    HelmRepository: <CenteredHelmRepositoryIcon />,
    GitLabEnterprise: <CenteredGitlabIcon />,
    Other: <GitIcon />
  }[providerName];
};

export default VcsIcon;

const CenteredBitbucketIcon = styled(RawBitbucketIcon)`
  margin-top: 5px;
  // Make the logo proportional to the avatar size
  transform: scale(0.8);
`;

const CenteredGithubIcon = styled(RawGithubIcon)`
  margin-top: 3px;
  // Make the logo proportional to the avatar size
  transform: scale(0.8);
`;

const CenteredGitlabIcon = styled(RawGitlabIcon)`
  margin-top: 4px;
  // Make the logo proportional to the avatar size
  transform: scale(0.8);
`;

const CenteredAzureDevopsIcon = styled(RawAzureDevOpsIcon)`
  margin-top: 3px;
  margin-right: 1px;
  // Make the logo proportional to the avatar size
  transform: scale(0.8);
`;

const CenteredHelmRepositoryIcon = styled(RawHelmIcon)`
  // Make the logo proportional to the avatar size
  transform: scale(0.75);
`;

const GitIcon = styled(RawGitIcon)`
  margin-top: 6px;
`;
