import * as React from 'react';
import styled from '@emotion/styled';
import { Label } from 'components/common/form-components';
import { Typography } from 'antd';

export const intlPrefix = 'organization.settings.notifications';

export const FieldContainer = styled.div`
  min-height: 5em;
`;

export const IdField = (props: { id: string }) => {
  return (
    <FieldContainer>
      <Label id={'id'} />
      <Typography.Text data-e2e="notification-target-id" code copyable={{ text: props.id }}>
        {props.id}
      </Typography.Text>
    </FieldContainer>
  );
};
