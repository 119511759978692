import { returnData, type HttpClient } from 'services/api-client/client';
import type { CreateProjectPayload, ProjectWithoutChildren, UpdateProjectPayload } from 'types/api.types';
import type { AxiosResponse } from 'axios';
import type { RolesApi } from '@env0/role-service/api';
import type { ProjectApi } from '@env0/project-service/api';

export default (client: HttpClient) => ({
  findByOrganizationId: (organizationId: string) =>
    client.get<ProjectWithoutChildren[]>('projects', {
      params: {
        organizationId
      }
    }),
  create: (payload: CreateProjectPayload) =>
    client.post<CreateProjectPayload, AxiosResponse<ProjectWithoutChildren>>('projects', payload),
  update: (projectId: string, payload: UpdateProjectPayload) =>
    client.put<UpdateProjectPayload, AxiosResponse<ProjectWithoutChildren>>(`projects/${projectId}`, payload),
  archive: (projectId: string) => client.delete(`projects/${projectId}`),
  move: (projectId: string, targetProjectId: string | null) =>
    client.post(`projects/${projectId}/move`, { targetProjectId }),
  getUsers: (projectId: string) => client.get<RolesApi.UserRoleAssignment[]>(`permissions/projects/${projectId}`),
  removeUser: (projectId: string, userId: string) =>
    client.delete(`permissions/projects/${projectId}/users/${encodeURI(userId)}`),
  assignUser: (projectId: string, userId: string, role: RolesApi.RBACPermissionRole) =>
    client.post<{ userId: string; role: RolesApi.RBACPermissionRole }, AxiosResponse<RolesApi.UserRoleAssignment>>(
      `permissions/projects/${projectId}`,
      {
        userId,
        role
      }
    ),
  updateUser: (projectId: string, userId: string, role: RolesApi.RBACPermissionRole) =>
    client.put<{ role: RolesApi.RBACPermissionRole }, AxiosResponse<RolesApi.UserRoleAssignment>>(
      `permissions/projects/${projectId}/users/${encodeURI(userId)}`,
      {
        role
      }
    ),
  getModulesTestingProject: (organizationId: string) =>
    client
      .get<ProjectApi.GetModulesTestingProject.Response>(`projects/modules/testing/${organizationId}`, {
        hideNotification: true
      })
      .then(returnData)
});
