import { action, observable } from 'mobx';
import BaseService from 'services/base-service';
import type { AxiosError } from 'axios';

export class PayGateStore extends BaseService {
  @observable activePayGateErrorText: string | null = null;
  private cachedPageRoutes: Record<string, AxiosError> = {};
  private cachedPayGatedEndpoints: Record<string, AxiosError> = {};

  cacheOrThrowError = (error: AxiosError | null) => {
    if (!error || !error.config) return;

    const { url, method } = error.config;
    const cachedError = url && this.cachedPayGatedEndpoints[url];

    if (cachedError) {
      throw cachedError;
    }

    if (method && ['GET', 'get'].includes(method) && url) {
      this.cachedPayGatedEndpoints[url] = error;
    }
  };

  @action setActivePayGateError(payGateError: AxiosError | null) {
    this.activePayGateErrorText = payGateError?.response?.data?.toString() ?? null;
  }

  @action clearActivePayGateError() {
    this.activePayGateErrorText = null;
  }

  cachePageRoute(route: string, error: AxiosError) {
    if (!this.cachedPageRoutes[route]) {
      this.cachedPageRoutes[route] = error;
    }
  }

  checkCachedRoute(route: string) {
    const cachedError = this.cachedPageRoutes[route];

    if (cachedError) {
      this.setActivePayGateError(cachedError);
    }
  }
}
