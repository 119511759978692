import { useCallback, useMemo } from 'react';
import mapValues from 'lodash/mapValues';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { Blueprint } from 'types/api.types';
import type { SettingsForm } from 'components/templates/templates-wizard/common/template-wizard.types';

export enum RetryOptions {
  never = 'never',
  onDeploy = 'onDeploy',
  onDestroy = 'onDestroy',
  both = 'both'
}

const FORM_FIELD_NAME = 'retry';
export const ERROR_REGEX_DEFAULT_VALUE = '';
export const TIMES_DEFAULT_OPTION = 2;
export const TIMES_MAX_OPTION = 3;

export const defaultRetryConfig = { times: TIMES_DEFAULT_OPTION, errorRegex: ERROR_REGEX_DEFAULT_VALUE };

export const useRetryField = (form: SettingsForm) => {
  const [retry, setRetry] = form.useManualField<Blueprint['retry']>(FORM_FIELD_NAME);

  const getRetryProperty = useCallback(
    (property: keyof BlueprintApi.RetryDeployOrDestroyConfig) =>
      retry?.onDeploy?.[property] || retry?.onDestroy?.[property],
    [retry]
  );

  const setRetryProperty = useCallback(
    (property: keyof BlueprintApi.RetryDeployOrDestroyConfig, value: any) => {
      setRetry(mapValues(retry, onDeployOrDestroy => onDeployOrDestroy && { ...onDeployOrDestroy, [property]: value }));
    },
    [retry, setRetry]
  );

  const retryType = useMemo(() => {
    if (!retry || (!retry.onDestroy && !retry.onDeploy)) return RetryOptions.never;
    if (retry.onDeploy && retry.onDestroy) return RetryOptions.both;
    if (retry.onDeploy) return RetryOptions.onDeploy;
    if (retry.onDestroy) return RetryOptions.onDestroy;
  }, [retry]);

  const timesOption = useMemo(() => {
    const times = getRetryProperty('times');
    return times ?? TIMES_DEFAULT_OPTION;
  }, [getRetryProperty]);

  const errorRegex = useMemo(() => getRetryProperty('errorRegex') || ERROR_REGEX_DEFAULT_VALUE, [getRetryProperty]);

  const onRetryTypeChange = useCallback(
    (key: RetryOptions) => {
      const onCycleRetry = retry?.onDeploy ?? retry?.onDestroy ?? defaultRetryConfig;
      switch (key) {
        case RetryOptions.never:
          setRetry({ onDeploy: null, onDestroy: null });
          break;
        case RetryOptions.onDeploy:
          setRetry({ onDeploy: onCycleRetry, onDestroy: null });
          break;
        case RetryOptions.onDestroy:
          setRetry({ onDeploy: null, onDestroy: onCycleRetry });
          break;
        case RetryOptions.both:
          setRetry({ onDeploy: onCycleRetry, onDestroy: onCycleRetry });
          break;
      }
    },
    [retry, setRetry]
  );

  const onTimesOptionChange = useCallback((key: number) => setRetryProperty('times', key), [setRetryProperty]);

  const onErrorRegexChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setRetryProperty('errorRegex', value),
    [setRetryProperty]
  );

  return {
    retry,
    retryType,
    timesOption,
    errorRegex,
    onRetryTypeChange,
    onTimesOptionChange,
    onErrorRegexChange
  };
};
