import React, { useEffect, useMemo, useState } from 'react';
import styled from 'types/theme.types';
import { Avatar } from 'antd';

import OrganizationPlaceholder from 'assets/images/organization-placeholder.svg';
import InvalidIcon from 'assets/images/invalid-icon-red.svg';

import type { UseFormReturnType } from 'hooks/use-form.hook';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import { CopyableIdWithLabel } from 'components/common/copyable-id';

export interface OrganizationFormProps {
  form: UseFormReturnType;
  organizationId?: string;
}

const OrganizationForm: React.FunctionComponent<OrganizationFormProps> = ({ form, organizationId }) => {
  const photoUrl = form.watch('photoUrl');
  const [hasLogoPreviewError, setHasLogoPreviewError] = useState(false);

  const onLogoPreviewError = () => {
    setHasLogoPreviewError(true);
    return true;
  };

  useEffect(() => {
    setHasLogoPreviewError(false);
  }, [photoUrl]);

  const photoUrlSrc = useMemo(
    () => (hasLogoPreviewError ? InvalidIcon : photoUrl || OrganizationPlaceholder),
    [photoUrl, hasLogoPreviewError]
  ) as string;

  return (
    <FieldsContainer>
      <StyledAvatar src={photoUrlSrc} alt="logo-preview" onError={onLogoPreviewError} />
      <InputsContainer>
        {organizationId && (
          <CopyableIdWithLabel text={organizationId} label={{ id: 'id' }} showCodeStyle entityName={'organization'} />
        )}
        <ControlledTextInput
          data-e2e="organization-name-input"
          label={{ id: 'organizations.create.form.field.name' }}
          name="name"
          form={form}
          mandatory
          autoFocus
        />
        <ControlledTextInput
          data-e2e="organization-photo-url-input"
          label={{ id: 'organizations.create.form.field.logo' }}
          name="photoUrl"
          form={form}
          optional
        />
      </InputsContainer>
      <ControlledTextArea
        id="organizations-create-description"
        label={{ id: 'description' }}
        name="description"
        form={form}
        optional
      />
    </FieldsContainer>
  );
};

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const InputsContainer = styled.div`
  flex-grow: 1;
  margin-left: 1em;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const StyledAvatar = styled(Avatar)`
  height: 170px;
  width: 170px;
  align-self: flex-start;

  @media (max-width: 768px) {
    align-self: center;
  }
`;

export default OrganizationForm;
