import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { RolesApi } from '@env0/role-service/api';
import type { AxiosResponse } from 'axios';
import type { ScopeId } from 'components/common/role-assignments/types';

export default (client: HttpClient) => ({
  createRole: (payload: RolesApi.Create.Request.Body) => {
    return client.post<RolesApi.Create.Response>('roles', payload).then(returnData);
  },
  updateRole: (roleId: string, payload: RolesApi.Update.Request.Body) => {
    return client.put<RolesApi.Update.Response>(`roles/${roleId}`, payload).then(returnData);
  },
  getRoles: (organizationId: string) => {
    return client.get<RolesApi.Role[]>('roles', { params: { organizationId } }).then(returnData);
  },
  getRole: (roleId: string) => {
    return client.get<RolesApi.FindById.Response>(`roles/${roleId}`).then(returnData);
  },
  deleteRole: (roleId: string) => {
    return client.delete(`roles/${roleId}`);
  },

  getTeamRoleAssignments: (scopeId: ScopeId) =>
    client
      .get<RolesApi.TeamRoleAssignment[]>(`roles/assignments/teams`, {
        params: { ...scopeId }
      })
      .then(returnData),

  assignRoleToTeam: (scopeId: ScopeId, teamId: string, role: RolesApi.RBACPermissionRole) => {
    return client
      .put<RolesApi.UpsertTeamRoleAssignment.Request.Body, AxiosResponse<RolesApi.TeamRoleAssignment>>(
        `roles/assignments/teams`,
        {
          teamId,
          ...scopeId,
          role
        }
      )
      .then(returnData);
  },

  removeTeamRoleAssignment: (scopeId: ScopeId, teamId: string) =>
    client
      .delete('roles/assignments/teams', {
        params: { ...scopeId, teamId }
      })
      .then(returnData)
});
