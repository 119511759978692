import React, { type ReactNode, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import useStores from 'hooks/use-stores.hooks';

interface Props {
  bypass: boolean;
  mobileWidth: number;
  children?: ReactNode;
}

const NavBarDrawer = ({ bypass, mobileWidth, children }: Props) => {
  const { navbarStore } = useStores();

  const closeMobileSidebar = useCallback(() => {
    navbarStore.setOpen(false);
  }, [navbarStore]);

  useEffect(() => {
    closeMobileSidebar();
  }, [bypass, closeMobileSidebar]);

  return bypass ? (
    <>{children}</>
  ) : (
    <Drawer
      bodyStyle={{ padding: 0 }}
      open={navbarStore.isOpen}
      placement="left"
      width={mobileWidth}
      onClose={closeMobileSidebar}
      closable={false}
      destroyOnClose={false}>
      {children}
    </Drawer>
  );
};

export default observer(NavBarDrawer);
