import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { CloudResourceApi } from '@env0/cloud-resource-service/api';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  getAll: (organizationId: string) =>
    client
      .get<CloudResourceApi.FindCloudConfigurationByOrgId.Response>('/cloud/configurations', {
        params: { organizationId }
      })
      .then(returnData),
  deleteById: (id: string) => client.delete(`/cloud/configurations/${id}`).then(returnData),

  testById: (id: string) =>
    client
      .post<
        CloudResourceApi.TestCloudConfiguration.Request.Body,
        AxiosResponse<CloudResourceApi.TestCloudConfiguration.Response>
      >(`/cloud/configurations/${id}/test`)
      .then(returnData),

  createConfiguration: (data: CloudResourceApi.CreateCloudConfiguration.Request.Body) =>
    client
      .post<
        CloudResourceApi.CreateCloudConfiguration.Request.Body,
        AxiosResponse<CloudResourceApi.CreateCloudConfiguration.Response>
      >(`cloud/configurations`, data)
      .then(returnData),

  updateConfiguration: (cloudConfigurationId: string, data: CloudResourceApi.UpdateCloudConfiguration.Request.Body) =>
    client
      .put<
        CloudResourceApi.UpdateCloudConfiguration.Request.Body,
        AxiosResponse<CloudResourceApi.UpdateCloudConfiguration.Response>
      >(`cloud/configurations/${cloudConfigurationId}`, data)
      .then(returnData),

  getCloudConfigurationById: (cloudConfigurationId: string) =>
    client
      .get<CloudResourceApi.FindCloudConfigurationById.Response>(`cloud/configurations/${cloudConfigurationId}`)
      .then(returnData)
});
