import useStores from 'hooks/use-stores.hooks';
import dayjs from 'types/dayjs.types';
import capitalize from 'lodash/capitalize';

export enum TrialStatus {
  EXPIRED,
  CLOSE_TO_EXPIRE,
  DEFAULT
}
type TimeSize = 'EXPIRED' | 'MINUTES' | 'HOURS' | 'DAYS';

const getTrialStatus = (minutes: number) => {
  const week = 10080;
  if (minutes <= 0) return TrialStatus.EXPIRED;
  if (minutes < week) return TrialStatus.CLOSE_TO_EXPIRE;
  return TrialStatus.DEFAULT;
};

const getTimeSize = (minutes: number): TimeSize => {
  if (minutes <= 0) return 'EXPIRED';
  if (minutes <= 60) return 'MINUTES';
  if (minutes <= 60 * 24) return 'HOURS';
  return 'DAYS';
};

function getRemainingMessage(remainingMinutes: number) {
  const timeSize = getTimeSize(remainingMinutes);
  const timeDiff = dayjs.duration(remainingMinutes, 'minutes');

  const byTimeSize: Record<TimeSize, number> = {
    DAYS: timeDiff.asDays(),
    EXPIRED: 0,
    HOURS: timeDiff.asHours(),
    MINUTES: timeDiff.asMinutes()
  };
  return `${byTimeSize[timeSize].toFixed()} ${capitalize(timeSize)}`;
}

export const useTrialCountdown = () => {
  const {
    organizationsStore: { currentOrganization }
  } = useStores();
  const remainingMinutes = dayjs(currentOrganization.trialEnd).diff(dayjs(), 'minutes');
  const message = getRemainingMessage(remainingMinutes);

  return {
    message,
    status: getTrialStatus(remainingMinutes)
  };
};
