import { useEffect } from 'react';
import useStores from 'hooks/use-stores.hooks';
import type { SubscribeParameter } from 'stores/mobx/common/subscription-event-handler';

const useSubscription = ({ stream, key, onEvent, skipInitEvent = false }: SubscribeParameter) => {
  const { graphqlEventsStore } = useStores();

  useEffect(() => {
    return graphqlEventsStore.subscribe({ stream, key, onEvent, skipInitEvent });
    // eslint-disable-next-line
  }, [key, onEvent, skipInitEvent]);
};

export default useSubscription;
