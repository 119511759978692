import React from 'react';
import type { Project } from 'types/api.types';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import { useProjectGeneralSettingsForm } from 'components/projects/settings/general/project-settings-general-form.hook';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import Card from 'components/common/card';
import { ArchiveProjectButton } from 'components/projects/settings/archive-project-button';
import { Col, Row } from 'components/common/grid';
import styled from 'types/theme.types';
import { useIntl } from 'react-intl';
import { CopyableIdWithLabel } from 'components/common/copyable-id';
import { MoveProjectButton } from 'components/projects/settings/move-project-button';

export interface ProjectGeneralUsersProp {
  project: Project;
}

const ProjectGeneralUsers: React.FC<ProjectGeneralUsersProp> = ({ project }) => {
  const form = useProjectGeneralSettingsForm(project);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'projects.settings.menu.general' });

  return (
    <Card title={<>{title}</>}>
      <CopyableIdWithLabel text={project.id} label={{ id: 'id' }} showCodeStyle entityName={'project'} />

      <ControlledTextInput label={{ id: 'name' }} name="name" form={form} />
      <ControlledTextArea label={{ id: 'description', optional: true }} name="description" form={form} />
      <StyledRow>
        <StyledButtonsContainer>
          <ArchiveProjectButton isSubmitting={form.isSubmitting} project={project} />
          <LeftMarginContainer>
            <MoveProjectButton id={project.id} name={project.name} />
          </LeftMarginContainer>
        </StyledButtonsContainer>
        <Col>
          <ControlledSaveDiscardButtons form={form} cancelMessageId={'discard.changes'} inContainer={true} />
        </Col>
      </StyledRow>
    </Card>
  );
};

const LeftMarginContainer = styled.div`
  margin-left: 1vw;
`;

const StyledButtonsContainer = styled(Col)`
  display: flex;
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: baseline;
`;

export default ProjectGeneralUsers;
