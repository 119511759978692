import React from 'react';
import { Badge, Radio } from 'antd';
import type { RadioGroupProps, RadioChangeEvent } from 'antd/lib/radio';
import styled from 'types/theme.types';
import Tooltip from 'components/common/tooltip';

export type RadioButtonOpts<T = string> = {
  value: T;
  content: string | React.ReactElement;
  disabled?: boolean;
  ribbonText?: string;
  tooltipId?: string;
};

export interface RadioButtonsProps extends Omit<RadioGroupProps, 'onChange' | 'size'> {
  onChange?: (value: string) => void;
  buttons?: RadioButtonOpts[];
  size?: RadioGroupProps['size'] | 'huge';
  block?: boolean;
}

const RadioButtons: React.FC<RadioButtonsProps> = React.forwardRef<HTMLDivElement, RadioButtonsProps>(
  ({ onChange, buttons, size, children, ...props }, ref) => {
    const handleOnChange = (e: RadioChangeEvent) => onChange?.(e.target.value);
    const ButtonComponent = size === 'huge' ? HugeButton : Radio.Button;

    return (
      <RadioGroupContainer ref={ref} onChange={handleOnChange} {...props}>
        <Container>
          {children}
          {buttons?.map(({ value, content, disabled, ribbonText, tooltipId }) => {
            const button = (
              <Tooltip placement="top" titleId={tooltipId!}>
                <ButtonComponent data-e2e={`radio-button-${value}`} key={value} value={value} disabled={!!disabled}>
                  {size === 'huge' ? <HugeButtonContentContainer>{content}</HugeButtonContentContainer> : content}
                </ButtonComponent>
              </Tooltip>
            );
            return ribbonText ? (
              <Badge.Ribbon text={ribbonText} key={value}>
                {button}
              </Badge.Ribbon>
            ) : (
              button
            );
          })}
        </Container>
      </RadioGroupContainer>
    );
  }
);

RadioButtons.displayName = 'RadioButtons';

const RadioGroupContainer = styled(Radio.Group)`
  .ant-radio-button-wrapper::before {
    // making our big-buttons with 'border-radius' without letting this 'before' element to interrupt
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

const HugeButton = styled(Radio.Button)`
  width: 205px;
  height: 80px;
  border-radius: 4px !important;
  border: 1px solid ${({ theme }) => theme.nonEditableBorderGray};
  padding: 0;
`;

export const HugeButtonContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  user-select: none;
`;

export default RadioButtons;
