import type { Duration } from 'types/dayjs.types';
import { convertTTLToDuration, formatDuration } from 'utils/ttl.utils';

export const TTL_OPTIONS = {
  UNLIMITED: 'unlimited',
  DESTROY: 'destroy',
  SPECIFIC: 'specific',
  VIOLATE_POLICY: 'violate'
};

export const ttlDurationOptions: TtlStringValue[] = ['6-h', '12-h', '1-d', '3-d', '1-w', '2-w', '1-M'];

export type TtlStringValue = `${number}-${'M' | 'w' | 'd' | 'h'}`;

export type TtlSelectOption = {
  value: TtlStringValue;
  key: TtlStringValue;
  duration: Duration;
  title: string;
};

export const TTL_SELECT_OPTIONS = ttlDurationOptions.map(ttl => ({
  value: ttl,
  key: ttl,
  duration: convertTTLToDuration(ttl),
  title: formatDuration(convertTTLToDuration(ttl))
}));

export const INFINITE_VALUE = null;
export const INHERIT_FROM_ORG = 'inherit';
