import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledCredentialTextInput from 'components/common/form-controlled/controlled-credential-text-input';

const KubeconfigCredentialInputs = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.K8S_KUBECONFIG_AUTH;

  return (
    <>
      <Link messageId="settings.credentials.k8s.credentials.explanation" url={docsLink} />
      <ControlledCredentialTextInput
        isSensitive={true}
        textArea={true}
        autoSize={{ minRows: 3, maxRows: 10 }}
        key={'kubeConfig'}
        label={{ id: 'settings.credentials.k8s.kubeConfig' }}
        name={`value.kubeConfig`}
        form={form}
      />
    </>
  );
};

export default KubeconfigCredentialInputs;
