import React from 'react';
import ControlledSensitiveTextInput from 'components/common/form-controlled/controlled-sensitive-text-input';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import useStores from 'hooks/use-stores.hooks';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';

type ControlledCredentialTextInputProps = {
  isSensitive?: boolean;
  textArea?: boolean;
} & (
  | React.ComponentProps<typeof ControlledTextInput>
  | React.ComponentProps<typeof ControlledSensitiveTextInput>
  | React.ComponentProps<typeof ControlledTextArea>
);

const ControlledCredentialTextInput: React.FC<ControlledCredentialTextInputProps> = ({
  isSensitive,
  textArea,
  ...inputProps
}) => {
  const {
    organizationsStore: { isCurrentOrganizationSelfHosted }
  } = useStores();

  return isSensitive && isCurrentOrganizationSelfHosted ? (
    <ControlledSensitiveTextInput position={'relative'} textArea={textArea} {...inputProps} />
  ) : textArea ? (
    <ControlledTextArea {...inputProps} />
  ) : (
    <ControlledTextInput {...inputProps} />
  );
};

export default ControlledCredentialTextInput;
