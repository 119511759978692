import type { ComponentType, LazyExoticComponent, ReactElement, ReactNode } from 'react';
import React, { Suspense, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import ErrorBoundary from 'components/common/error-boundary';
import Loader from 'components/common/loader';
import PermissionContainer from 'components/common/permission-container';
import ForbiddenRoute from 'components/routes/forbidden-route';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { KbarWrapper } from 'components/kbar/kbar-wrapper';
import { useCuratedProjects } from 'stores/rq/projects';
import type { RolesApi } from '@env0/role-service/api';
import type { PermissionOperator } from 'hooks/use-has-permission';

type AppRouteProps = {
  LazyComponent: LazyExoticComponent<ComponentType<any>> | ComponentType<any>;
  rbacPermissions?: RolesApi.RBACPermission[];
  permissionOperator?: PermissionOperator;
  isPublic?: boolean;
  children?: (content: ReactNode) => ReactElement;
};

const AppRoute: React.FunctionComponent<AppRouteProps> = ({
  LazyComponent,
  rbacPermissions,
  permissionOperator,
  isPublic,
  children = content => <>{content}</>
}) => {
  const {
    authStore,
    organizationsStore: { currentOrganization }
  } = useStores();
  const { isFetched: fetchedProjects } = useCuratedProjects({ enabled: authStore.isAuthenticated });

  const isProjectRoute = !!useCurrentProjectId();

  useEffect(() => {
    if (!isPublic) {
      authStore.login();
    }
  }, [isPublic, authStore]);

  const loader = <Loader size={'large'} type={'robot'} />;

  const loaderPage = children(<Loader size={'large'} type={'robot'} />);

  if (!isPublic && !authStore.isAuthenticated) {
    return <Loader size={'large'} type={'robot'} />;
  }

  if (isProjectRoute) {
    if (!isPublic && authStore.isAuthenticated && !fetchedProjects) {
      return loaderPage;
    }
  }

  return children(
    <Suspense fallback={loader}>
      <ErrorBoundary renderErrorMessageOnError>
        <PermissionContainer
          fallback={ForbiddenRoute}
          requiredPermissions={rbacPermissions}
          permissionOperator={permissionOperator}>
          <LazyComponent />
          <KbarWrapper data-e2e={'kbar'} />
        </PermissionContainer>
      </ErrorBoundary>
    </Suspense>
  );
};

export default observer(AppRoute);
