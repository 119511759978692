import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { Alert } from 'antd';
import { useCuratedProjects } from 'stores/rq/projects';
import { useHasPermission } from 'hooks/use-has-permission';

const ProjectArchivedAlert: React.FunctionComponent = () => {
  const [isCurrentProjectArchived, setCurrentProjectArchived] = useState<boolean>(false);
  const { organizationsStore } = useStores();
  const { projects } = useCuratedProjects();
  const currentProjectId = useCurrentProjectId();
  const intl = useIntl();
  useEffect(
    () => setCurrentProjectArchived(!!currentProjectId && !!projects[currentProjectId]?.isArchived),
    [projects, currentProjectId]
  );
  const { isAuthorized: hasEditProjectSettingsPermission } = useHasPermission('EDIT_PROJECT_SETTINGS');

  const getArchivedProjectWarningMessage = () => {
    const archivedProjectWarningParts = ['projects.layout.archived.warning'];
    const hasAccessToArchiveProject = organizationsStore.currentOrganization && hasEditProjectSettingsPermission;

    if (hasAccessToArchiveProject) archivedProjectWarningParts.push('projects.layout.archived.warning.admin');

    return archivedProjectWarningParts.map(id => intl.formatMessage({ id })).join('. ');
  };

  return isCurrentProjectArchived ? (
    <Alert showIcon message={getArchivedProjectWarningMessage()} type="warning" />
  ) : null;
};

export default observer(ProjectArchivedAlert);
