import useApiClient from 'hooks/use-api-client';
import { useQuery } from '@tanstack/react-query';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useStores from 'hooks/use-stores.hooks';
import { useEffect } from 'react';
import { NotificationTypes } from 'types/notifications.types';
import { getApiErrorMessage } from 'services/api-client/client';
import type { AxiosError } from 'axios';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'oidc-token'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all']
  } as const;

  return cacheKeys;
};

const useActivateOidcInCurrentOrganizationIfInactive = (activate: boolean) => {
  const { organizationsStore } = useStores();
  const organizationId = useCurrentOrganizationId();

  useEffect(() => {
    if (activate && !organizationsStore.organizations[organizationId].enableOidc) {
      // Creating the policy will initiate an async call to create the OIDC JWT Sub
      organizationsStore.updateOrganizationPolicies(organizationId, { enableOidc: true });
    }
  }, [activate, organizationId, organizationsStore]);
};

export const useOidcJwtSub = (enabled = false, activateOidcInCurrentOrganizationIfInactive = false) => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();
  const { notificationStore } = useStores();
  useActivateOidcInCurrentOrganizationIfInactive(activateOidcInCurrentOrganizationIfInactive);

  const query = useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.credentials.getOidcJwtSub(orgId, true),
    enabled,
    refetchOnReconnect: false
  });

  useEffect(() => {
    if (query.error) {
      notificationStore.setNotification({
        notificationType: NotificationTypes.error,
        message: getApiErrorMessage(query.error as AxiosError)
      });
    }
  }, [notificationStore, query.error]);

  return query;
};
