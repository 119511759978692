import React, { type ReactNode, useCallback, useState } from 'react';
import Card from 'components/common/card';
import type { ColumnProps, ShowMoreOptions } from 'components/common/table';
import Table from 'components/common/table';
import SaveDiscardButtons from 'components/common/save-discard-buttons';
import { useAsyncPromise } from 'hooks/use-async-promise';
import ErrorContainer from 'components/common/error-container';
import styled from 'types/theme.types';
import { notification, type TableProps } from 'antd';
import { useIntl } from 'react-intl';

type RoleAssignmentsCardStatus = 'LOADING_DATA' | 'READY' | 'SAVING_DATA';

interface RoleAssignmentsCardProps {
  rows: any[];
  columns: ColumnProps[];
  getRowKey: (row: any) => string;
  onDiscard: () => void;
  onSave: () => Promise<void>;
  loadRows?: () => Promise<void>;
  onFilter: (searchTerm?: string) => Promise<void> | void;
  filterError?: string | null;
  cardHeader: ReactNode;
  filterPlaceholderId: string;
  additionalFooter?: ReactNode;
  showMore?: ShowMoreOptions;
  hasChanges?: boolean;
  showFooter?: boolean;
  isLoading?: boolean;
  scroll?: TableProps<any>['scroll'];
  pageSize?: number;
}

const RoleAssignmentsCard: React.FunctionComponent<RoleAssignmentsCardProps> = ({
  rows,
  columns,
  getRowKey,
  onDiscard,
  onSave,
  loadRows,
  onFilter,
  cardHeader,
  filterPlaceholderId,
  additionalFooter,
  showMore,
  hasChanges = false,
  showFooter = true,
  isLoading = false,
  filterError,
  scroll,
  ...props
}) => {
  const intl = useIntl();
  const [cardStatus, setCardStatus] = useState<RoleAssignmentsCardStatus>('READY');

  const invokeWithCardStatus = useCallback(
    async (status: RoleAssignmentsCardStatus, fn: () => Promise<void> | void, ...args: any[]) => {
      try {
        setCardStatus(status);
        await fn.apply(this, args as []);
      } finally {
        setCardStatus('READY');
      }
    },
    []
  );
  const onSaveClick = async () => {
    try {
      await invokeWithCardStatus('SAVING_DATA', onSave);
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'team.role.assignments.failed' })
      });
    }
  };
  const onTableFilter = async (searchTerm?: string) => await invokeWithCardStatus('LOADING_DATA', onFilter, searchTerm);

  const [loadingInitialDataError] = useAsyncPromise(async () => {
    if (loadRows) {
      await invokeWithCardStatus('LOADING_DATA', loadRows);
    }
  }, [loadRows, invokeWithCardStatus]);

  const footer = () =>
    showFooter ? (
      <>
        {additionalFooter}
        <SaveDiscardButtons
          isInline
          saveButton={{
            'data-e2e': 'save-role-changes',
            onClick: onSaveClick,
            disabled: cardStatus === 'SAVING_DATA' || !hasChanges,
            isLoading: cardStatus === 'SAVING_DATA'
          }}
          discardButton={{
            'data-e2e': 'discard-role-changes',
            onClick: onDiscard,
            disabled: cardStatus === 'SAVING_DATA' || !hasChanges
          }}
        />
      </>
    ) : (
      additionalFooter
    );

  if (loadingInitialDataError) {
    return <ErrorContainer errorToReport={loadingInitialDataError} />;
  }

  return (
    <Card title={cardHeader} {...props}>
      <StyledTable
        scroll={scroll}
        dataSource={rows}
        columns={columns}
        rowKey={getRowKey}
        paginated={true}
        isLoading={cardStatus === 'LOADING_DATA' || isLoading}
        showMore={showMore}
        filterable={{
          onFilter: onTableFilter,
          placeholder: filterPlaceholderId,
          disabled: hasChanges,
          error: filterError
        }}
        pagination={{ pageSize: props.pageSize, position: ['bottomCenter'] }}
      />
      {footer()}
    </Card>
  );
};

const StyledTable = styled(Table)`
  min-height: 300px;
`;

export default RoleAssignmentsCard;
