import { css } from '@emotion/react';
import { Spin, Button } from 'antd';
import React, { type CSSProperties } from 'react';
import type { Env0Theme } from 'constants/themes.constants';
import styled from 'types/theme.types';
import { Link, type LinkProps } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import theme from 'constants/themes.constants';

export type ButtonSize = 'small' | 'large';
export type ButtonType = 'primary' | 'default' | 'danger' | 'link';

export interface CustomButtonProps {
  id?: string;
  type?: ButtonType;
  shape?: 'round' | 'circle';
  size?: ButtonSize;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  href?: string;
  isExternalLink?: boolean;
  isLoading?: boolean;
  addPlus?: boolean;
  block?: boolean;
  flatBottom?: boolean;
  style?: CSSProperties;
  className?: string;
  invertColor?: boolean;
  'data-e2e'?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const CustomButton = ({
  type = 'default',
  onClick,
  children,
  href,
  isExternalLink,
  isLoading = false,
  addPlus = false,
  ...properties
}: CustomButtonProps) => {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!href) event.preventDefault();
    onClick?.(event);
  };

  const loaderColor = type === 'default' ? theme.primaryBlue : theme.primaryWhite;
  const loader = <LoadingOutlined style={{ fontSize: 18, color: loaderColor }} spin />;

  const styledButton = (
    <StyledButton onClick={onClickHandler} type={type} role={href ? 'link' : 'button'} {...properties}>
      {addPlus && !isLoading && <span>+&nbsp;</span>}
      {isLoading ? <Spin style={{ height: 18 }} size="small" indicator={loader} /> : children}
    </StyledButton>
  );

  if (href) {
    return isExternalLink ? (
      <a href={href} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
        {styledButton}
      </a>
    ) : (
      <StyledLink
        to={href}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        block={properties.block}>
        {styledButton}
      </StyledLink>
    );
  } else {
    return styledButton;
  }
};

export const StyledLink = styled(({ block, ...props }: LinkProps & { block?: boolean }) => <Link {...props} />)`
  border-radius: 4px;
  ${({ block }) => (block ? 'width: 100%;' : '')}
`;

const dangerCss = (theme: Env0Theme) => css`
  &:hover:enabled {
    background-color: ${theme.errorRedHover} !important;
    border: 1px solid ${theme.errorRedHover} !important;
  }

  &:disabled {
    background-color: ${theme.primaryDisabledGray} !important;
    border: 1px solid ${theme.primaryDisabledGray} !important;
    color: #fff !important;
    cursor: not-allowed;
  }
`;

const defaultCss = (theme: Env0Theme) => css`
  &:hover:enabled {
    background-color: ${theme.secondaryHover} !important;
    color: ${theme.primaryBlue} !important;
    border-color: ${theme.primaryBlue} !important;
  }

  &:disabled {
    background-color: #fff !important;
    border: 1px solid ${theme.secondaryDisabledGray} !important;
    color: ${theme.secondaryDisabledGray} !important;
    cursor: not-allowed;
  }
`;

const primaryCss = (theme: Env0Theme) => css`
  &:hover:enabled {
    background-color: ${theme.secondaryGreen} !important;
    border-color: ${theme.secondaryGreen} !important;
  }

  &:disabled {
    background-color: ${theme.primaryDisabledGray} !important;
    border: 1px solid ${theme.primaryDisabledGray} !important;
    color: #fff !important;
    cursor: not-allowed;
  }
`;

const smallSizeCss = css`
  font-size: 12px !important;
  min-width: 120px !important;
  padding: 5px 10px !important;
`;

const defaultSizeCss = css`
  font-size: 13px !important;
  min-width: 130px !important;
  padding: 5px 25px !important;
`;

const largeSizeCss = css`
  font-size: 15px !important;
  min-width: 170px !important;
  padding: 10px 25px !important;
  text-transform: uppercase;
`;

const sizeStyleMap: Record<ButtonSize | 'default', any> = {
  small: smallSizeCss,
  default: defaultSizeCss,
  large: largeSizeCss
};

const getTypeStyleMap = (theme: Env0Theme): Record<ButtonType, any> => ({
  danger: dangerCss(theme),
  primary: primaryCss(theme),
  default: defaultCss(theme),
  link: null
});

type ButtonExtendedType = CustomButtonProps & { role: string };

const StyledButton = styled<React.ComponentType<ButtonExtendedType>>(
  ({ type, ...props }) => (
    <Button type={type === 'danger' ? 'primary' : type} danger={type === 'danger'} {...(props as any)} />
  ),
  {
    shouldForwardProp: propName => propName !== 'flatBottom' && propName !== 'invertColor'
  }
)`
  ${props => sizeStyleMap[props.size || 'default']}
  ${props => getTypeStyleMap(props.theme)[props.type || 'default']}
  pointer-events: ${(props: ButtonExtendedType) => props.disabled && 'none'};

  &:hover:enabled {
    ${(props: ButtonExtendedType) =>
      props.size === 'large' &&
      props.type === 'primary' &&
      !props.disabled &&
      'box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3) !important;'}
  }

  .ant-spin-dot-item {
    background-color: ${props => (props.invertColor ? props.theme.primaryBlue : '#fff')};
  }

  ${(props: ButtonExtendedType) =>
    props.flatBottom &&
    `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
`;

/**
 * A Fix
 * Mouse events don't trigger at disabled button in Chrome
 *
 * This fixes the scenario when we wrap a disabled button with a Tooltip.
 * Without this fix the Tooltip message will not disappear "onmouseleave"
 *
 * relevant snippet: https://github.com/ant-design/ant-design/pull/4865/files#diff-186839a30bf8b9d67a4b10bf7c091d5f
 */
CustomButton.__ANT_BUTTON = true;

export default CustomButton;
