import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { FormattedMessage } from 'react-intl';
import { Info } from 'components/common/form-components';
import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import { ProjectVcsPrCommentsCheckbox } from 'components/projects/settings/policies/sections/vcs-pr-comments-checkbox';

const ContinuousDeploymentSettings: React.FC<{ form: UseFormReturnType }> = ({ form }) => {
  return (
    <Section titleId={`${messagePrefix}.title.cd`}>
      <ControlledBigCheckbox
        form={form}
        name="continuousDeploymentDefault"
        data-e2e="project-settings-policies-continuous-deployment-checkbox">
        <FormattedMessage id={`${messagePrefix}.continuous-deployment.checkbox`} />
        <Info
          alignTop={false}
          information={<FormattedMessage id={`${messagePrefix}.continuous-deployment.tooltip`} />}
        />
      </ControlledBigCheckbox>
      <ControlledBigCheckbox
        form={form}
        name="runPullRequestPlanDefault"
        data-e2e="project-settings-policies-run-pr-plan-checkbox">
        <FormattedMessage id={`${messagePrefix}.run-pr-plan.checkbox`} />
        <Info alignTop={false} information={<FormattedMessage id={`${messagePrefix}.run-pr-plan.tooltip`} />} />
      </ControlledBigCheckbox>
      <ProjectVcsPrCommentsCheckbox form={form} />
    </Section>
  );
};

export default ContinuousDeploymentSettings;
