import { FormattedMessage } from 'react-intl';
import { Panel, PanelGrowSection, PanelSubTitle, PanelType } from 'components/layout/navbar/navbar-panel.utils';
import OrganizationMenu from 'components/layout/navbar/navbar-organization-menu';
import type { Organization } from 'types/api.types';

interface Props {
  organization: Organization;
  onPanelChange: (panel: PanelType) => void;
  currentPanel: PanelType;
}

const OrganizationPanel = ({ organization, onPanelChange, currentPanel }: Props) => {
  return (
    <Panel visible={currentPanel === PanelType.Organization}>
      <PanelGrowSection data-e2e="navbar-organization-menu">
        <OrganizationMenu organization={organization} onPanelChange={onPanelChange} />
      </PanelGrowSection>
    </Panel>
  );
};

export default OrganizationPanel;
