import { useCallback, useMemo, useState } from 'react';
import { useCuratedRoles } from 'stores/rq/roles';
import type { TeamRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { Role } from 'types/api.types';
import type { RolesApi } from '@env0/role-service/api';
import filter from 'lodash/filter';

const getInitialRole = (defaultRoles: Role[], allCustomRoles: Role[]) => {
  if (defaultRoles.length) return defaultRoles[0].id!;
  if (allCustomRoles.length) return allCustomRoles[0].id!;

  return undefined;
};

export const useTeamRoleAssignmentRows = (defaultRoles: Role[]) => {
  const { allCustomRoles } = useCuratedRoles();
  const [rows, setRows] = useState<TeamRoleAssignmentRow[]>([]);

  const mergeRow = useCallback(
    (teamId: string, newData: Partial<TeamRoleAssignmentRow>) =>
      setRows(
        rows.map(row =>
          row.id === teamId
            ? {
                ...row,
                ...newData
              }
            : row
        )
      ),
    [rows, setRows]
  );

  const onChangeIsAssigned = useCallback(
    (teamId: string, assigned: boolean) => {
      const role = assigned ? getInitialRole(defaultRoles, allCustomRoles) : undefined;

      return mergeRow(teamId, { assigned, role });
    },
    [defaultRoles, allCustomRoles, mergeRow]
  );

  const onChangeAssignmentRole = useCallback(
    (teamId: string, role: RolesApi.RBACPermissionRole) => mergeRow(teamId, { role }),
    [mergeRow]
  );

  const rowsToRemove = useMemo(
    () =>
      filter(rows, {
        assigned: false,
        initialData: {
          assigned: true
        }
      }),
    [rows]
  );

  const rowsToAssign = useMemo(
    () =>
      filter(rows, {
        assigned: true,
        initialData: {
          assigned: false
        }
      }).filter(row => !!row.role),
    [rows]
  );

  const rowsToUpdate = useMemo(
    () =>
      filter(rows, {
        assigned: true,
        initialData: {
          assigned: true
        }
      }).filter(row => row.initialData.role !== row.role),
    [rows]
  );

  const hasChanges = rowsToRemove.length + rowsToAssign.length + rowsToUpdate.length > 0;

  return {
    rows,
    setRows,
    mergeRow,
    onChangeIsAssigned,
    onChangeAssignmentRole,
    rowsToRemove,
    rowsToAssign,
    rowsToUpdate,
    hasChanges
  };
};
