import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';

export default (client: HttpClient) => ({
  findDiscoveredEnvironments: async (
    environmentDiscoveryConfigId: string
  ): Promise<EnvironmentImportApi.FindDiscoveredEnvironments.Response> =>
    client
      .get(`environment-import/discovered`, {
        params: { environmentDiscoveryConfigId }
      })
      .then(returnData),

  discoverEnvironments: async (environmentDiscoveryConfigId: string): Promise<void> =>
    client
      .post('environment-import/discover', null, {
        params: { environmentDiscoveryConfigId }
      })
      .then(returnData),

  importEnvironments: async (
    body: EnvironmentImportApi.BatchImport.Request.Body
  ): Promise<EnvironmentImportApi.BatchImport.Response> =>
    client.post('environment-import/batch', body).then(returnData),

  rerunImportEnvironments: async (
    body: EnvironmentImportApi.BatchImport.Request.Body
  ): Promise<EnvironmentImportApi.BatchImport.Response> =>
    client.post('environment-import/rerun', body).then(returnData)
});
