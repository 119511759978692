import React from 'react';
import Checkbox from 'components/common/input-components/checkbox';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { TeamRoleAssignmentRow, UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import { useHasPermission } from 'hooks/use-has-permission';

type AssignmentRecord = UserRoleAssignmentRow | TeamRoleAssignmentRow;

type Props = {
  record: AssignmentRecord;
  onChangeIsAssigned: (userId: string, assigned: boolean) => void;
  scopeName: 'organization' | 'project' | 'environment';
};

const RoleAssignmentCardAssignCheckbox: React.FunctionComponent<Props> = ({
  record,
  onChangeIsAssigned,
  scopeName
}) => {
  const onChange = (event: CheckboxChangeEvent) => onChangeIsAssigned(record.id, event.target.checked);
  const { isAuthorized: hasEditRolePermission, isLoading } = useHasPermission(
    scopeName === 'environment'
      ? 'ASSIGN_ROLE_ON_ENVIRONMENT'
      : scopeName === 'project'
      ? 'EDIT_PROJECT_SETTINGS'
      : 'EDIT_ORGANIZATION_SETTINGS'
  );

  if (isLoading) {
    return null;
  }

  const e2eId = buildE2eId(record, scopeName);

  if (!hasEditRolePermission || isRowUneditable(record)) {
    const title = getTooltipMessage(record);

    return (
      <Tooltip placement="top" title={title}>
        <Checkbox data-e2e={e2eId} checked={record?.assigned || isRowUneditable(record)} disabled />
      </Tooltip>
    );
  }

  return <Checkbox data-e2e={e2eId} checked={record.assigned} onChange={onChange} />;
};

export default RoleAssignmentCardAssignCheckbox;

const isUserRecord = (record: AssignmentRecord): record is UserRoleAssignmentRow =>
  !!(record as UserRoleAssignmentRow).user;

const buildE2eId = (record: AssignmentRecord, scopeName: string) => {
  if (isUserRecord(record)) {
    return `${scopeName}-settings-users-assign-${record.id}`;
  } else {
    return `${scopeName}-settings-teams-assign-${(record as TeamRoleAssignmentRow).team.name}`;
  }
};

const isRowUneditable = (record: AssignmentRecord) =>
  isUserRecord(record) && (record.isOrganizationAdmin || record.isCurrentUser);

const getTooltipMessage = (record: AssignmentRecord) => {
  if (isUserRecord(record)) {
    if (record.isCurrentUser) {
      return <FormattedMessage id={'card-headers.role-assignments.disabled-current-user.explanation'} />;
    }
    if (record.isOrganizationAdmin) {
      return <FormattedMessage id={'card-headers.role-assignments.disabled-org-admin.explanation'} />;
    }
  }

  return <FormattedMessage id={'card-headers.role-assignments.disabled-lacking-permissions.explanation'} />;
};
