import useStores from 'hooks/use-stores.hooks';

export const useCurrentOrganizationId = () => {
  const {
    organizationsStore: { currentOrganizationId }
  } = useStores();

  return currentOrganizationId!;
};

export const useCurrentOrganization = () => {
  const { organizationsStore } = useStores();

  return organizationsStore.currentOrganization;
};
