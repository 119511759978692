import styled from 'types/theme.types';

interface Props {
  gap?: number;
}

const Stack = styled.div<Props>`
  & > *:not(:last-child) {
    margin-bottom: ${props => props.gap ?? 25}px !important;
  }
`;

export default Stack;
