import { action, autorun, observable } from 'mobx';
import isError from 'lodash/isError';
import AWSAppSyncClient from 'aws-appsync';
import type { Subscription } from 'apollo-client/util/Observable';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { graphQlUrl } from 'constants/config';
import gql from 'graphql-tag';
import { onAnyEventPushed } from '@env0/common-appsync-schema/graphql/subscriptions';
import { getLastEvent } from '@env0/common-appsync-schema/graphql/queries';
import type { Event, SubscribeParameter, SubscriptionVariables } from 'stores/mobx/common/subscription-event-handler';
import { SubscriptionEventHandler } from 'stores/mobx/common/subscription-event-handler';
import type ServiceContainer from 'services/service-container';
import BaseService from 'services/base-service';
import { reportError } from 'utils/sentry.utils';
import localForage from 'localforage';
import { StatusCodes } from 'http-status-codes';

type SubscriptionQueryResponse = {
  data: { onAnyEventPushed: Event };
};

type DeltaQueryResponse = { data?: { getLastEvent: Event } | null };

const getSubscriptionQuery = (variables: SubscriptionVariables) => {
  return {
    variables,
    query: gql(onAnyEventPushed)
  };
};

const isUnauthorized = (error: any) =>
  error?.statusCode === StatusCodes.UNAUTHORIZED ||
  error?.message?.includes('UnauthorizedException') ||
  error?.errorType?.includes('UnauthorizedException');
const isTimeout = (error: any) => error?.message?.toLowerCase().includes('timeout');

export class GraphqlEventsStore extends BaseService {
  @observable private client: AWSAppSyncClient<any> | null = null;

  @action private setClient(client: AWSAppSyncClient<any> | null) {
    this.client = client;
  }

  subscribe({ stream, key, onEvent, skipInitEvent }: SubscribeParameter) {
    if (!key) return () => {};

    // subscriptions are split due to a bug in aws-appsync - https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/573
    let subscription: Subscription;
    let deltaSubscription: Subscription;

    autorun(() => {
      if (this.client) {
        const variables = { stream: `${stream}:${key}` };

        deltaSubscription = this.client.sync({
          deltaQuery: {
            query: gql(getLastEvent),
            variables,
            update: (_: any, { data }: DeltaQueryResponse) =>
              this.subscriptionEventHandler.onDeltaQueryUpdate({
                event: data?.getLastEvent ?? null,
                onEvent,
                variables,
                skipInitEvent
              })
          }
        });

        subscription = this.client.subscribe({ ...getSubscriptionQuery(variables) }).subscribe(
          {
            next: ({ data: { onAnyEventPushed } }: SubscriptionQueryResponse) =>
              this.subscriptionEventHandler.onSubscriptionQueryUpdate({
                onAnyEventPushed,
                onEvent,
                variables
              })
          },
          error => {
            reportError('Subscription', error, { stream, key });
          }
        );
      }
    });

    return () => {
      subscription?.unsubscribe();
      deltaSubscription?.unsubscribe();
    };
  }

  private onAuthorization = () => {
    if (!this.client) this.setClient(this.getClient());
  };

  private getClient = () => {
    const token = this.service.authStore.getAccessToken();

    return token ? this.newAwsAppSyncClient() : null;
  };

  private newAwsAppSyncClient = () => {
    const appSyncClient = new AWSAppSyncClient({
      disableOffline: false,
      offlineConfig: { storage: localForage },
      url: graphQlUrl,
      region: 'really doesnt matter when using oidc and custom domain url',
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken: (() => this.service.authStore.getAccessToken()) as () => string
      }
    });

    const report = async (error: any, extraInfo?: Record<string, unknown>) => {
      const errorToThrow = isError(error) ? error : new Error(error?.message || 'Graphql Error');
      await reportError('GraphqlEventsStore', errorToThrow, extraInfo);
    };

    const graphqlErrorHandlerLink = onError(errorHandler => {
      errorHandler?.graphQLErrors?.forEach(error => {
        // ignore authorization errors that take place on idle browsers
        if (isUnauthorized(error)) {
          return;
        }

        report(error, { ...errorHandler });
      });
    });

    const networkErrorHandlerLink = new RetryLink({
      attempts: {
        max: 1,
        retryIf: async error => {
          if (isUnauthorized(error)) {
            await this.service.authStore.silentRenewSession();

            return true;
          } else if (isTimeout(error)) {
            return true;
          } else {
            await report(error, { error }); // send error as metadata in case error is not instanceof Error;
            return false;
          }
        }
      }
    });

    // error handlers come first, and specifically network error handler should be the first
    appSyncClient.link = networkErrorHandlerLink.concat(graphqlErrorHandlerLink).concat(appSyncClient.link);

    return appSyncClient;
  };

  constructor(service: ServiceContainer, private subscriptionEventHandler = new SubscriptionEventHandler()) {
    super(service);
    autorun(this.onAuthorization);
  }
}
