import useStores from 'hooks/use-stores.hooks';
import isEmpty from 'lodash/isEmpty';
import useAuthentication from 'hooks/use-authentication.hooks';

export const useHasOrganizations = (): { hasOrganizations?: boolean; isLoadingOrganizations: boolean } => {
  const { organizationsStore } = useStores();
  const isAuthenticated = useAuthentication();

  const { organizations, isDoneFetchingOrganizations } = organizationsStore;
  if (!isAuthenticated || !isDoneFetchingOrganizations) return { isLoadingOrganizations: true };
  return {
    hasOrganizations: !isEmpty(organizations),
    isLoadingOrganizations: false
  };
};
