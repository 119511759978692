import React from 'react';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import JwtOidcSub from 'components/organizations/credentials/jwt-oidc-sub';

const prefix = 'settings.credentials.vault';

const VaultOidcInputs = ({ form }: CredentialsInputProps) => {
  return (
    <>
      <Link messageId={`${prefix}.oidc-explanation`} url={links.docs.DEPLOYMENT_CREDENTIALS.VAULT_OIDC_DOCS} />
      <ControlledTextInput label={{ id: `${prefix}.address` }} name="value.address" form={form} />
      <ControlledTextInput label={{ id: `${prefix}.version` }} name="value.version" form={form} />
      <ControlledTextInput label={{ id: `${prefix}.role-name` }} name="value.roleName" form={form} />
      <ControlledTextInput
        label={{ id: `${prefix}.jwt-auth-backend-path` }}
        name="value.jwtAuthBackendPath"
        form={form}
      />
      <ControlledTextInput label={{ id: `${prefix}.namespace` }} name="value.namespace" form={form} />
      <JwtOidcSub form={form} />
    </>
  );
};

export default VaultOidcInputs;
