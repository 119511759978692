import { intl } from 'components/localization';
import isString from 'lodash/isString';
import type { GpgKey, KeyType, SshKey } from 'types/api.types';
import type { TestContext } from 'yup';

const duplicateNameMessage = 'organization.settings.keys.errors.duplicate-name-message';
const invalidKeyFormat = 'organization.settings.keys.errors.invalid-key-format';

// using ReturnType<typeof useForm> causes type errors
export const onKeySetFailure = (form: any, keyType: KeyType) => {
  return (err: any) => {
    if (err?.response?.status === 422) {
      form.setError('value', {
        message: intl.formatMessage({ id: invalidKeyFormat }, { keyType })
      });
    } else {
      form.setError('value', { message: !isString(err) ? err?.id : err });
    }
  };
};

export const testDuplicateNames =
  (keys: SshKey[] | GpgKey[], keyType: KeyType) =>
  (value: string, { createError }: TestContext) =>
    keys.map(key => key.name).includes(value)
      ? createError({
          message: intl.formatMessage(
            { id: duplicateNameMessage },
            {
              keyType: keyType,
              name: value
            }
          )
        })
      : true;
