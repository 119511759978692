import useForm, { type UseFormProps } from 'hooks/use-form.hook';
import type { SchemaOf } from 'yup';
import * as yup from 'yup';
import { NotificationEndpointType } from '@env0/notification-service/api.enum';
import { useCallback } from 'react';
import useStores from 'hooks/use-stores.hooks';
import mapValues from 'lodash/mapValues';
import type { NotificationsApi } from '@env0/notification-service/api';
import { EmailRecipientPlaceholder } from '@env0/notification-service/api.enum';

export type FormValues = NotificationsApi.UpdateNotificationEndpoint.Request.Body;

export const useNotificationEndpointForm = (
  onSubmitForm: () => void,
  initialValues?: Pick<NotificationsApi.NotificationEndpoint, 'name' | 'type' | 'value' | 'id'>
) => {
  const { notificationSettingsStore } = useStores();

  const onSubmit = useCallback<UseFormProps<FormValues>['onSubmit']>(
    async formValues => {
      if (initialValues) {
        await notificationSettingsStore.updateNotificationEndpoint(initialValues.id, formValues);
      } else {
        await notificationSettingsStore.createNewNotificationEndpoint(formValues);
      }
      onSubmitForm();
    },
    [initialValues, notificationSettingsStore, onSubmitForm]
  );

  return useForm({ schema, onSubmit, initialValues });
};

const errorIds = mapValues(
  {
    nameEmpty: 'name-empty',
    urlEmpty: 'url-empty',
    emailEmpty: 'email-empty',
    commaDelimiterEmails: 'comma-delimited-emails',
    webhookSecretEmpty: 'webhook-secret-empty'
  },
  v => `organization.settings.notifications.error.${v}`
);

const schema: SchemaOf<FormValues> = yup.object({
  name: yup.string().required(errorIds.nameEmpty).default(''),
  type: yup
    .mixed<NotificationEndpointType>()
    .oneOf(Object.values(NotificationEndpointType))
    .default(NotificationEndpointType.SLACK),
  value: yup
    .string()
    .default('')
    .when('type', {
      is: NotificationEndpointType.EMAIL,
      then: yup
        .string()
        .required(errorIds.emailEmpty)
        .test('comma-delimited-emails', errorIds.commaDelimiterEmails, value => {
          return value!
            .split(/[\s,]+/)
            .every(
              part =>
                yup.string().email().required(errorIds.commaDelimiterEmails).isValidSync(part) ||
                yup.mixed<EmailRecipientPlaceholder>().oneOf(Object.values(EmailRecipientPlaceholder)).isValidSync(part)
            );
        }),
      otherwise: yup.string().required(errorIds.urlEmpty)
    }),
  environmentCreator: yup.boolean().optional(),
  deployer: yup.boolean().optional(),
  webhookSecret: yup
    .string()
    .optional()
    .nullable()
    .test('is-non-empty-string', errorIds.webhookSecretEmpty, value => value !== '')
});
