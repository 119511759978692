import useApiClient from 'hooks/use-api-client';
import { useMutation } from '@tanstack/react-query';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';

const getCustomFlowCacheKey = (organizationId: string, name: string) => [organizationId, 'custom-flows', name];

export const useCreateCustomFlow = () => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationId();

  return useMutation({
    mutationFn: (customFlow: BlueprintApi.CreateConfigurationTemplate) => apiClient.customFlows.create(customFlow),
    onSuccess: data => {
      getQueryClientInstance().setQueryData(getCustomFlowCacheKey(organizationId, data.name), data);
    }
  });
};

export const useDeleteCustomFlow = () => {
  const apiClient = useApiClient();

  return useMutation({
    mutationFn: (id: string) => apiClient.customFlows.delete(id)
  });
};

export const useUpdateCustomFlow = () => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationId();

  return useMutation({
    mutationFn: (customFlow: BlueprintApi.UpdateConfigurationTemplateBody) => apiClient.customFlows.update(customFlow),
    onSuccess: data => {
      getQueryClientInstance().setQueryData(getCustomFlowCacheKey(organizationId, data.name), data);
    }
  });
};
