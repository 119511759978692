import type { RolesApi } from '@env0/role-service/api';
import type { OrganizationUser, Role } from 'types/api.types';
import type { ColumnProps } from 'components/common/table';
import type { UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import RoleAssignmentCardRoleSelect from 'components/common/role-assignments/role-assignment-card-role-select';
import RoleAssignmentCardAssignCheckbox from 'components/common/role-assignments/role-assignment-card-assign-checkbox';
import { UserAvatarWithName } from 'components/common/user-avatar';
import { compareUsersByAssignment, compareUsersByEmail, compareUsersByName } from 'utils/user.utils';
import React from 'react';
import { ProjectRoleAssignmentHeader } from 'components/common/role-assignments/project-role-assignment-header';

export const buildUserRoleAssignmentsColumns = ({
  onChangeAssignmentRole,
  onChangeIsAssigned,
  defaultRoles,
  scopeName
}: {
  onChangeAssignmentRole: (userId: string, role: RolesApi.RBACPermissionRole) => void;
  onChangeIsAssigned: (userId: string, assigned: boolean) => void;
  defaultRoles: Role[];
  scopeName: 'organization' | 'project' | 'environment';
}): ColumnProps[] => [
  {
    title: 'assign',
    dataIndex: 'assigned',
    render: (_, record: UserRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardAssignCheckbox
          scopeName={scopeName}
          record={record}
          onChangeIsAssigned={onChangeIsAssigned}
        />
      );
    },
    defaultSortOrder: 'ascend',
    sorter: compareUsersByAssignment
  },
  {
    title: 'name',
    dataIndex: ['user', 'user'],
    render: user => <UserAvatarWithName user={user} />,
    sorter: (user1, user2) => compareUsersByName(user1.user.user, user2.user.user)
  },
  {
    title: 'email',
    dataIndex: ['user'],
    sorter: (user1, user2) => compareUsersByEmail(user1.user, user2.user),
    render: (user: OrganizationUser) => (user.user?.app_metadata?.isApiKey ? '' : user.user?.email)
  },
  {
    title: scopeName === 'project' ? <ProjectRoleAssignmentHeader /> : 'role',
    dataIndex: 'role',
    render: (_, record: UserRoleAssignmentRow) => {
      return (
        <RoleAssignmentCardRoleSelect
          scopeName={scopeName}
          record={record}
          onChangeAssignmentRole={onChangeAssignmentRole}
          defaultRoles={defaultRoles}
          tab={'users'}
        />
      );
    }
  }
];
