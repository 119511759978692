import gitUrlParse from 'git-url-parse';
import { vcsAppAuthorizeUrl, stage } from 'constants/config';
import type { GitProviderTypes, Blueprint } from 'types/api.types';

type GenerateVcsRedirectLinkParams = {
  gitProvider: GitProviderTypes;
  organizationId: string;
  accessToken: string;
};

export const generateVcsRedirectLink = ({
  gitProvider,
  organizationId,
  accessToken
}: GenerateVcsRedirectLinkParams) => {
  const url = new URL(vcsAppAuthorizeUrl);
  url.searchParams.append('provider', gitProvider.toLowerCase());
  url.searchParams.append('organizationId', organizationId);
  url.searchParams.append('token', accessToken);
  url.searchParams.append('stage', stage);

  return url.toString();
};

export const isGitProviderUsingToken = (gitProvider: GitProviderTypes) => {
  return gitProvider === 'GitLab' || gitProvider === 'AzureDevOps';
};

export const isGitProviderUsingTokenAndTokenIsMissing = (gitProvider: GitProviderTypes, tokenId?: string) =>
  isGitProviderUsingToken(gitProvider) && !tokenId;

export const getInitialGitProvider = ({
  repository,
  githubInstallationId,
  isGitLab,
  isAzureDevOps,
  isHelmRepository,
  bitbucketClientKey,
  isBitbucketServer,
  isGitLabEnterprise,
  isGitHubEnterprise
}: Pick<
  Blueprint,
  | 'repository'
  | 'githubInstallationId'
  | 'isGitLab'
  | 'isAzureDevOps'
  | 'isHelmRepository'
  | 'bitbucketClientKey'
  | 'isBitbucketServer'
  | 'isGitLabEnterprise'
  | 'isGitHubEnterprise'
>): GitProviderTypes | undefined => {
  if (!repository) return undefined;
  if (githubInstallationId) return 'GitHub';
  if (isGitLab) return 'GitLab';
  if (isAzureDevOps) return 'AzureDevOps';
  if (isHelmRepository) return 'HelmRepository';
  if (bitbucketClientKey) return 'BitBucket';
  if (isBitbucketServer) return 'BitBucketServer';
  if (isGitLabEnterprise) return 'GitLabEnterprise';
  if (isGitHubEnterprise) return 'GitHubEnterprise';
  return 'Other';
};

export const getRepositoryUrl = (repository: string) => {
  let repoUrl = gitUrlParse(repository);

  if (repository.startsWith('http') && !!repoUrl.user) {
    repository = repository.replace(new RegExp(`${repoUrl.user}(:.*)?@`), '');
  }

  repoUrl = gitUrlParse(repository);
  repoUrl.resource = repoUrl.resource.replace(/:\d+$/, '');

  const url = repoUrl.toString('https');
  const suffix = '.git';

  return url.endsWith(suffix) ? url.substring(0, url.length - suffix.length) : url;
};

export const getShortenRepo = (repoUrl: string, providerName?: GitProviderTypes) => {
  const fixedRepoUrl = getRepositoryUrl(repoUrl);

  if (providerName === 'HelmRepository') {
    return fixedRepoUrl.split('/').slice(-2)[0];
  } else {
    let repoName: string;
    let projectName: string;
    let _git: string;

    if (providerName === 'AzureDevOps') {
      [repoName, _git, projectName] = fixedRepoUrl.split('/').reverse();
    } else {
      [repoName, projectName] = fixedRepoUrl.split('/').reverse();
    }
    return `${projectName}/${repoName}`;
  }
};
