import styled from '@emotion/styled';

export const BigGreenTitle = styled.h1`
  color: ${({ theme }) => theme.primaryGreen};
  font-weight: bold;
  font-size: 55px;
  line-height: 40px;
`;

export const PurpleSubtitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${({ theme }) => theme.subtitleDarkBlue};
  margin-bottom: 1em;
  width: 85%;
`;

export const PurpleText = styled.div`
  color: ${({ theme }) => theme.subtitleDarkBlue};

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;
