import React from 'react';
import { iacVersionList } from 'components/templates/templates-wizard/settings/versions-list';
import type {
  SimpleVersionFieldFormProps,
  SimpleVersionIacType
} from 'components/templates/templates-wizard/settings/version-field';
import { VersionField } from 'components/templates/templates-wizard/settings/version-field';

type PrefixAndKey = {
  prefix: string;
  versionKey: string;
};

const pulumiPrefix = 'templates.add.new.field.pulumiVersion';
const pulumiVersionKey = 'pulumiVersion';
const ansiblePrefix = 'templates.add.new.field.ansibleVersion';
const ansibleVersionKey = 'ansibleVersion';

const typeToPrefixAndKey: Record<SimpleVersionIacType, PrefixAndKey> = {
  pulumi: { prefix: pulumiPrefix, versionKey: pulumiVersionKey },
  ansible: { prefix: ansiblePrefix, versionKey: ansibleVersionKey }
};

export const SimpleVersionField: React.FC<SimpleVersionFieldFormProps> = ({ form, type }) => {
  return (
    <VersionField
      form={form}
      versions={iacVersionList[type]}
      prefix={typeToPrefixAndKey[type].prefix}
      versionKey={typeToPrefixAndKey[type].versionKey}
      type={type}
    />
  );
};
