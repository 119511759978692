import React, { type ReactNode } from 'react';
import Divider from 'components/common/divider';
import styled from 'types/theme.types';

export const Section: React.FC<{ titleId?: string; children?: ReactNode; title?: ReactNode }> = ({
  children,
  titleId,
  title
}) => {
  return (
    <>
      <Divider titleId={titleId} title={title} color={'#000000'} orientationMargin={'0'} />
      <PaddingStyle>{children}</PaddingStyle>
    </>
  );
};

const PaddingStyle = styled.div`
  padding: 12px;

  > * {
    padding-bottom: 6px;
  }
`;
