import { returnData } from 'services/api-client/client';
import type { HttpClient } from 'services/api-client/client';
import type { SharedApi } from '@env0/shared/api';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  uploadScreenshot: (image: string) => {
    return client
      .post<SharedApi.UploadScreenshot.Request.Body, AxiosResponse<SharedApi.UploadScreenshot.Response>>(
        `/screenshot/upload`,
        { image }
      )
      .then(returnData);
  }
});
