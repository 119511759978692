import React from 'react';
import type { ComponentType, ReactNode } from 'react';
import styled from 'types/theme.types';
import Helmet from 'react-helmet';
import TopBar from './topbar/topbar';
import type { BreadcrumbSelector } from 'components/breadcrumbs/use-breadcrumbs';
import usePageTracking from 'hooks/use-page-tracking';
import ProjectArchivedAlert from 'components/projects/project-archived-alert';

type InnerLayerProps = {
  title: string;
  breadcrumbs?: BreadcrumbSelector;
  page: string;
  container?: ComponentType<{ children?: ReactNode }>;
  children?: ReactNode;
};

function InnerLayout({
  title,
  breadcrumbs,
  page,
  container: Container = CenteredContentContainer,
  children
}: InnerLayerProps) {
  usePageTracking(title);
  return (
    <>
      <TopBar title={title} page={page} breadcrumbs={breadcrumbs} />
      <Helmet>
        <title>env0 - {title}</title>
      </Helmet>
      <Wrapper>
        <ProjectArchivedAlert />
        <Container>{children}</Container>
      </Wrapper>
    </>
  );
}

export default InnerLayout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  flex: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 25px;
  overflow: visible;
  min-height: 0;
`;

export const CenteredContentContainer = styled(ContentContainer)`
  width: 90%;
  max-width: 1400px;
  margin: auto;
`;

export const FullSizeContentContainer = styled(ContentContainer)`
  padding-top: 0;
`;
