import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import type { Project } from 'types/api.types';
import ProjectSettingsCredentialsCard from 'components/projects/settings/credentials/project-settings-credentials-card';
import { links } from 'constants/external-links';
import { useCuratedCostCredentialsForProject, useUpdateCostCredentialsForProject } from 'stores/rq/credentials';

const defaultEmpty = {};
const ProjectSettingsCostCredentials: React.FunctionComponent<{ project: Project }> = ({ project }) => {
  const projectId = project.id;
  const { isLoading, error, costCredentialIdByType } = useCuratedCostCredentialsForProject(projectId);

  const { mutateAsync: updateCostCredentialIdsForProject } = useUpdateCostCredentialsForProject(projectId);

  const onSave = useCallback(
    async (credentialIds: string[]) => {
      await updateCostCredentialIdsForProject(credentialIds);
    },
    [updateCostCredentialIdsForProject]
  );

  return (
    <ProjectSettingsCredentialsCard
      usageType="COSTS"
      credentialsError={error}
      loadingCredentials={isLoading}
      onSave={onSave}
      existingProjectCredentials={costCredentialIdByType ?? defaultEmpty}
      docsHref={links.docs.ENABLING_COST_MONITORING.ROOT}
    />
  );
};

export default observer(ProjectSettingsCostCredentials);
