import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useCreateApprovalPolicy, useDeleteApprovalPolicy, useUpdateApprovalPolicy } from 'stores/rq/approval-policies';
import {
  useAssignApprovalPolicy,
  useUnassignApprovalPolicyById,
  useInvalidateApprovalPolicyAssignmentsCache
} from 'stores/rq/approval-policies-assignments';
import type { UseFormMethods } from 'react-hook-form/dist/types';
import type { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import type { PoliciesApprovalPolicyFormSchema } from 'components/projects/settings/policies/use-policies-form.hook';

type Form = UseFormMethods<PoliciesApprovalPolicyFormSchema>;

export const getModifiedApprovalPolicies = (
  initialApprovalPolicyAssignments: BlueprintApi.ApprovalPolicyTemplateWithScope[],
  currentApprovalPolicyAssignments: BlueprintApi.ApprovalPolicyTemplateWithScope[]
) => {
  const initialApprovalPoliciesById: { [key: string]: BlueprintApi.ApprovalPolicyTemplateWithScope['blueprint'] } =
    initialApprovalPolicyAssignments.reduce((acc, assignment) => {
      return { ...acc, [assignment.blueprint.id!]: assignment.blueprint };
    }, {});
  return currentApprovalPolicyAssignments
    .filter(assignment => assignment.blueprint.id)
    .map(assignment => assignment.blueprint)
    .filter(approvalPolicy => !isEqual(approvalPolicy, initialApprovalPoliciesById[approvalPolicy.id!]));
};

/**
 * The create approval policy handler asserts for unique name (using assertConfigurationTemplateUniqueness), to support multiple approval policies, we need
 * to generate unique name for each.
 */
export const generateUniqueApprovalPolicyName = (scope: BlueprintApi.ApprovalPolicyScope, scopeId: string) => {
  return `approval-policy-${scope}-${scopeId}-${uuid()}`;
};

export const useSubmitApprovalPolicy = () => {
  const projectId = useCurrentProjectId();

  const { mutateAsync: create } = useCreateApprovalPolicy({ scope: 'PROJECT' });
  const { mutateAsync: update } = useUpdateApprovalPolicy();
  const { mutateAsync: deletePolicy } = useDeleteApprovalPolicy();
  const { mutateAsync: assign } = useAssignApprovalPolicy({ scope: 'PROJECT' });
  const { mutateAsync: unassignById } = useUnassignApprovalPolicyById({ scope: 'PROJECT' });
  const invalidateApprovalPolicyAssignmentsCache = useInvalidateApprovalPolicyAssignmentsCache();

  return async (
    data: PoliciesApprovalPolicyFormSchema,
    form: Form,
    initialFormValues: UnpackNestedValue<DeepPartial<PoliciesApprovalPolicyFormSchema>>
  ) => {
    /*
    Need the cast here because initialFormValues is defined as DeepPartial which is inherited from initialFormValues of useForm hook.
    But initialFormValues.approvalPolicyAssignments cannot be partial so, it is ok to cast.
     */
    const initialApprovalPolicyAssignments =
      initialFormValues.approvalPolicyAssignments as BlueprintApi.ApprovalPolicyTemplateWithScope[];

    if (form.formState.dirtyFields['approvalPolicyAssignments']) {
      const assignmentsToRemove = differenceBy(initialApprovalPolicyAssignments, data.approvalPolicyAssignments, 'id');
      const removeAssignmentsPromises = assignmentsToRemove.map(assignment =>
        unassignById({ id: assignment.id!, scopeId: projectId! })
      );

      const modifiedApprovalPolicies = getModifiedApprovalPolicies(
        initialApprovalPolicyAssignments,
        data.approvalPolicyAssignments
      );
      const modifyApprovalPoliciesPromises = modifiedApprovalPolicies.map(approvalPolicy => {
        return update(
          { ...approvalPolicy, id: approvalPolicy.id! },
          {
            onSuccess: () => {
              invalidateApprovalPolicyAssignmentsCache({ scopeId: projectId!, scope: 'PROJECT' });
            }
          }
        );
      });
      const assignmentsToCreate = differenceBy(data.approvalPolicyAssignments, initialApprovalPolicyAssignments, 'id');
      const createAndAssignApprovalPoliciesPromises = assignmentsToCreate.map(async assignment => {
        const res = await create({
          ...assignment.blueprint,
          name: generateUniqueApprovalPolicyName(assignment.scope, assignment.scopeId),
          scopeId: projectId!
        });
        await assign({ blueprintId: res.id!, scope: assignment.scope, scopeId: assignment.scopeId });
      });

      await Promise.all([
        ...removeAssignmentsPromises,
        ...modifyApprovalPoliciesPromises,
        ...createAndAssignApprovalPoliciesPromises
      ]);

      const deletePoliciesPromises = assignmentsToRemove.map(assignment => deletePolicy(assignment.blueprint.id!));
      await Promise.all(deletePoliciesPromises);
    }
  };
};
