import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  create: (data: BlueprintApi.CreateApprovalPolicy.Request.Body) =>
    client
      .post<BlueprintApi.CreateApprovalPolicy.Request.Body, AxiosResponse<BlueprintApi.ApprovalPolicyTemplate>>(
        `approval-policy`,
        data
      )
      .then(returnData),

  delete: (id: string) => client.delete(`approval-policy/delete/${id}`),

  update: (data: BlueprintApi.UpdateApprovalPolicy.Request.Body) =>
    client
      .put<BlueprintApi.UpdateApprovalPolicy.Request.Body, AxiosResponse<BlueprintApi.ApprovalPolicyTemplate>>(
        `approval-policy`,
        data
      )
      .then(returnData),

  getApprovalPoliciesAssignmentsByScope: ({
    scope,
    scopeId
  }: BlueprintApi.GetApprovalPolicyByScope.Request.PathParams) =>
    client
      .get<BlueprintApi.GetApprovalPolicyByScope.Response>(`approval-policy/${scope}/${scopeId}`, {
        hideNotification: true
      })
      .then(returnData),

  assignApprovalPolicy: (
    body: BlueprintApi.AssignApprovalPolicy.Request.Body
  ): Promise<BlueprintApi.AssignApprovalPolicy.Response> =>
    client.post<BlueprintApi.AssignApprovalPolicy.Response>(`approval-policy/assignment`, body).then(returnData),

  unassignApprovalPolicy: ({ scope, scopeId }: BlueprintApi.UnassignApprovalPolicy.Request.PathParams) =>
    client.delete<void>(`approval-policy/assignment/${scope}/${scopeId}`).then(returnData),

  unassignApprovalPolicyById: ({ id }: BlueprintApi.UnassignApprovalPolicyById.Request.QueryParams) =>
    client.delete<void>(`approval-policy/assignment?id=${encodeURIComponent(id)}`).then(returnData)
});
