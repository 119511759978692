import { Radio } from 'antd';
import type { ReactElement } from 'react';
import React from 'react';
import styled from 'types/theme.types';
import isFunction from 'lodash/fp/isFunction';

export interface RadioGroupProps {
  items: RadioItem[];
  horizontal?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<any>>;
  value?: any;
  disabled?: boolean;
}

type Element = string | ReactElement;
type isSelectedFunction = (isSelected: boolean) => Element;

interface RadioItem {
  content: isSelectedFunction | Element;
  value: any;
  disabled?: boolean;
}

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;
const StyledVerticalRadio = styled(Radio)`
  display: flex !important;
  padding-bottom: 5px !important;

  > * {
    align-self: baseline !important;
    top: 3px;
  }
`;

const StyledHorizontalRadio = styled(Radio)``;

const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  items,
  horizontal,
  value: selected,
  onChange,
  disabled
}) => {
  const RadioElement = horizontal ? StyledHorizontalRadio : StyledVerticalRadio;

  return (
    <StyledRadioGroup disabled={disabled} value={selected} onChange={e => onChange?.(e.target.value)}>
      {items?.map(({ content, value, disabled }) => (
        <RadioElement data-e2e={`radio-${value}`} key={value} value={value} disabled={disabled}>
          {isFunction(content) ? content(selected === value) : content}
        </RadioElement>
      ))}
    </StyledRadioGroup>
  );
};

export default RadioGroup;
