import { useCallback } from 'react';
import { useAppNotificationsApi } from 'stores/rq/app-notifications';
import { useLocalStorageState } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';

export const useAppNotifications = () => {
  const { data: notifications, error, isPending } = useAppNotificationsApi();
  const [lastRead, setLastRead] = useLocalStorageState('app-notifications-last-read-time', { defaultValue: '' });

  // eslint-disable-next-line lodash/prop-shorthand
  const latestNotificationDate = maxBy(notifications, ({ isoDate }) => new Date(isoDate).getTime())?.isoDate;

  const shouldShowError = error && isEmpty(notifications);

  const setRead = useCallback(() => {
    setLastRead(latestNotificationDate ?? '');
  }, [setLastRead, latestNotificationDate]);

  const isRead = lastRead === latestNotificationDate || isPending;

  return { notifications, isRead, setRead, error, shouldShowError };
};
