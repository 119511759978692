import type { Blueprint } from 'types/api.types';
import type { Action } from 'kbar';
import SettingsIcon from 'assets/images/settings-icon.svg';
import { getGitRevisionUrl } from 'utils/template.utils';
import TerraformIcon from 'assets/images/terraform-icon.svg';
import TerragruntIcon from 'assets/images/terragrunt-icon.svg';
import ModulesIcon from 'assets/images/terraform-module-icon.svg';

import type { Optional } from 'utility-types';

type BlueprintDetailsForRootAction = Pick<Blueprint, 'id' | 'name' | 'type'>;
type BlueprintDetailsForPerformableAction = Omit<BlueprintDetailsForRootAction, 'type'> &
  Pick<Blueprint, 'repository'> &
  Optional<Pick<Blueprint, 'revision' | 'path'>>;

export type KindName = 'templates' | 'modules';
export const generatePerformableActions = (
  { id, name: templateName, repository, revision, path }: BlueprintDetailsForPerformableAction,
  navigate: (path: string) => void,
  kindName: KindName
): Action[] => {
  const performableActions = [
    {
      id: `${id}_SETTINGS`,
      parent: id,
      name: `Settings`,
      keywords: `${kindName} ${templateName} Settings`,
      section: templateName,
      perform: () => navigate(`/${kindName}/${id}/settings`),
      icon: SettingsIcon
    },
    {
      id: `${id}_REVIEW_CODE`,
      parent: id,
      name: 'Review Code',
      keywords: `${kindName} ${templateName} Review Code`,
      section: templateName,
      perform: () => window.open(getGitRevisionUrl({ repository, revision, path }), '_blank'),
      icon: SettingsIcon
    }
  ];

  if (kindName === 'modules')
    performableActions.push({
      id: `${id}_GOTO`,
      parent: id,
      name: 'Goto Module Page',
      keywords: `Go to ${kindName} ${templateName}`,
      section: templateName,
      perform: () => navigate(`/${kindName}/${id}`),
      icon: ModulesIcon
    });

  return performableActions;
};

export const generateRootActionForTemplate = (
  { id, name, type }: BlueprintDetailsForRootAction,
  rootId: string,
  section: string
): Action => ({
  id,
  parent: `GENERAL_${rootId}_SECTION`,
  name,
  keywords: `${rootId} ${name}`,
  section,
  icon: type === 'terraform' ? TerraformIcon : type === 'terragrunt' ? TerragruntIcon : undefined
});
