import type { PayGateApi } from '@env0/common-pay-gate/api';
import { useGetBillingInformation } from 'stores/rq/billing';

export const usePayGateFeature = (featureName: PayGateApi.FeatureName) => {
  const { isLoading, data } = useGetBillingInformation();

  const activated = (data?.[featureName] as PayGateApi.BooleanPayGateValue)?.activated || data?.tier === 'trial';

  return {
    payGateTitleId: activated || isLoading ? '' : 'paygate.feature.disabled',
    activated,
    isLoading
  };
};
