import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import type { Credential, NewCredential, CredentialType } from 'types/api.types';
import { getCredentialsTypeByUsageType } from 'utils/credential.utils';
import { useMemo } from 'react';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import difference from 'lodash/difference';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';

const deploymentCredentialTypes = new Set(getCredentialsTypeByUsageType('DEPLOYMENT'));
const costCredentialTypes = new Set(getCredentialsTypeByUsageType('COSTS'));

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'credentials'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all'],
    deploymentCredentialsForProject: (projectId: string) => [...baseKey, 'project-deployment', { projectId }] as const,
    deploymentCredentialsForProjects: (projectIds: string[]) =>
      [
        ...baseKey,
        'project-deployment',
        { ...Object.fromEntries(projectIds.map(projectId => [projectId, projectId])) }
      ] as const,
    costCredentialsForProject: (projectId: string) => [...baseKey, 'project-cost', { projectId }] as const
  } as const;

  return cacheKeys;
};

export const useGetCredentials = () => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();
  const [orgId] = all;

  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.credentials.getAll(orgId).then(({ data }) => data),
    enabled: !!orgId
  });
};

export const useCuratedCredentials = () => {
  const { data: allCredentials = [], ...rest } = useGetCredentials();

  const data = useMemo(() => {
    const costCredentials = allCredentials.filter(credential => costCredentialTypes.has(credential.type));
    const deploymentCredentials = allCredentials.filter(credential => deploymentCredentialTypes.has(credential.type));

    return {
      allCredentials,
      costCredentials,
      deploymentCredentials
    };
  }, [allCredentials]);

  return { ...rest, ...data };
};

export const useCreateCredential = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { all } = useCacheKeys();
  const [orgId] = all;

  return useMutation({
    mutationFn: (payload: NewCredential) => apiClient.credentials.create(orgId, payload).then(({ data }) => data),
    onSuccess: newCredential => {
      queryClient.setQueryData(all, (credentials: Credential[] = []) => [...credentials, newCredential]);
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useUpdateCredential = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<Credential> }) =>
      apiClient.credentials.update(id, data).then(({ data }) => data),
    onSuccess: updatedCredential => {
      queryClient.setQueryData(all, (credentials: Credential[] = []) =>
        credentials.map(credential => (credential.id === updatedCredential.id ? updatedCredential : credential))
      );
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useRemoveCredential = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (id: string) => apiClient.credentials.delete(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(all, (credentials: Credential[] = []) =>
        credentials.filter(credential => credential.id !== id)
      );
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useGetDeploymentCredentialsForProject = (projectId: string) => {
  const apiClient = useApiClient();
  const { deploymentCredentialsForProject } = useCacheKeys();

  return useQuery({
    queryKey: deploymentCredentialsForProject(projectId),
    queryFn: () =>
      apiClient.credentials.getDeploymentCredentialsForProject(projectId).then(({ data }) => data.credentialIds)
  });
};

export const useGetDeploymentCredentialsForProjects = (projectIds: string[]) => {
  const apiClient = useApiClient();
  const { deploymentCredentialsForProjects } = useCacheKeys();

  return useQuery({
    queryKey: deploymentCredentialsForProjects(projectIds),
    enabled: projectIds.length > 0,
    queryFn: async () => {
      return Object.fromEntries(
        await Promise.all(
          projectIds.map(projectId =>
            apiClient.credentials
              .getDeploymentCredentialsForProject(projectId)
              .then(({ data }) => [projectId, data.credentialIds])
          )
        )
      ) as Record<string, string[]>;
    }
  });
};

export const useCuratedDeploymentCredentialsForProject = (projectId: string) => {
  const { data: credentialIds = [], ...rest } = useGetDeploymentCredentialsForProject(projectId);
  const { data: allCredentials = [] } = useGetCredentials();

  const data = useMemo(() => {
    const deploymentCredentials = allCredentials.filter(credential => credentialIds.includes(credential.id));
    const credentialTypeToCredential = keyBy(deploymentCredentials, credential => credential.type);
    const deploymentCredentialIdByType = mapValues(credentialTypeToCredential, credential => credential.id);
    return {
      deploymentCredentials,
      deploymentCredentialIdByType
    };
  }, [allCredentials, credentialIds]);

  return { ...rest, ...data };
};

export const useUpdateDeploymentCredentialsForProject = (projectId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { deploymentCredentialsForProject } = useCacheKeys();

  return useMutation({
    mutationFn: (data: string[]) =>
      apiClient.credentials.putDeploymentCredentialsForProject(projectId, data).then(({ data }) => data.credentialIds),
    onSuccess: updatedCredentials => {
      queryClient.setQueryData(deploymentCredentialsForProject(projectId), updatedCredentials);
      queryClient.refetchQueries({ queryKey: deploymentCredentialsForProject(projectId) });
    }
  });
};

export const useGetCostCredentialsForProject = (projectId: string) => {
  const apiClient = useApiClient();
  const { costCredentialsForProject } = useCacheKeys();

  return useQuery({
    queryKey: costCredentialsForProject(projectId),
    queryFn: () =>
      apiClient.costs
        .getProjectCostsCredentials(projectId)
        .then(credentials => credentials.map(credential => credential.credentialsId))
  });
};

export const useCuratedCostCredentialsForProject = (projectId: string) => {
  const { data: credentialIds = [], ...rest } = useGetCostCredentialsForProject(projectId);
  const { data: allCredentials = [] } = useGetCredentials();

  const data = useMemo(() => {
    const costCredentials = allCredentials.filter(credential => credentialIds.includes(credential.id));
    const credentialTypeToCredential = keyBy(costCredentials, credential => credential.type);
    const costCredentialIdByType: Partial<Record<CredentialType, string>> = mapValues(
      credentialTypeToCredential,
      credential => credential.id
    );
    return {
      costCredentials,
      costCredentialIdByType
    };
  }, [allCredentials, credentialIds]);

  return { ...rest, ...data };
};

export const useUpdateCostCredentialsForProject = (projectId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { costCredentialsForProject } = useCacheKeys();

  return useMutation({
    mutationFn: async (updatedCredentials: string[]) => {
      const credentials = await apiClient.costs.getProjectCostsCredentials(projectId);
      const credentialsBefore = credentials.map(credential => credential.credentialsId);
      const toRemove = difference(credentialsBefore, updatedCredentials);
      const toAdd = difference(updatedCredentials, credentialsBefore);

      await Promise.all([
        ...toRemove.map(credentialId => apiClient.costs.removeProjectCostsCredentials(projectId, credentialId)),
        ...toAdd.map(credentialId => apiClient.costs.addProjectCostsCredentials(projectId, credentialId))
      ]);

      return updatedCredentials;
    },
    onSuccess: updatedCredentials => {
      queryClient.setQueryData(costCredentialsForProject(projectId), updatedCredentials);
      queryClient.refetchQueries({ queryKey: costCredentialsForProject(projectId) });
    }
  });
};
