import React, { type ReactNode, useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import compact from 'lodash/compact';
import { HoverItem } from 'components/layout/navbar/navbar-panel.utils';
import { useCloseOnMobile } from 'components/layout/navbar/navbar-panel.hooks';
import useIsMobile from 'hooks/use-is-mobile';
import { useHasPermission } from 'hooks/use-has-permission';
import { castPath } from 'utils/router.utils';
import { ReactComponent as EnvironmentsIcon } from 'assets/images/project-environments.svg';
import { ReactComponent as EnvironmentsIconActive } from 'assets/images/project-environments-active.svg';
import { ReactComponent as TemplatesIcon } from 'assets/images/templates.svg';
import { ReactComponent as TemplatesIconActive } from 'assets/images/templates-active.svg';
import { ReactComponent as VariablesIcon } from 'assets/images/variables.svg';
import { ReactComponent as VariablesIconActive } from 'assets/images/variables-active.svg';
import { ReactComponent as CostsIcon } from 'assets/images/project-costs.svg';
import { ReactComponent as CostsIconActive } from 'assets/images/project-costs-active.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as SettingsIconActive } from 'assets/images/settings-active.svg';

interface Props {
  projectId?: string;
}

const ProjectMenu = ({ projectId }: Props) => {
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const closeOnMobile = useCloseOnMobile();
  const hasPermissionToProjectSettings = useHasPermission('EDIT_PROJECT_SETTINGS', { projectId }).isAuthorized;
  const hasPermissionToViewProject = useHasPermission('VIEW_PROJECT', { projectId }).isAuthorized;

  const items = useMemo<ProjectMenuItem[]>(
    () =>
      compact([
        {
          key: 'project-menu-environments',
          title: <FormattedMessage id="navigation.project.menu.environments" />,
          to: `/p/${projectId}/environments`,
          icon: <EnvironmentsIcon />,
          activeIcon: <EnvironmentsIconActive />
        },
        hasPermissionToViewProject && {
          key: 'project-menu-templates',
          title: <FormattedMessage id="navigation.project.menu.templates" />,
          to: `/p/${projectId}/templates`,
          icon: <TemplatesIcon />,
          activeIcon: <TemplatesIconActive />
        },
        !isMobile &&
          hasPermissionToViewProject && {
            key: 'project-menu-variables',
            title: <FormattedMessage id="navigation.project.menu.variables" />,
            to: `/p/${projectId}/variables`,
            icon: <VariablesIcon />,
            activeIcon: <VariablesIconActive />
          },
        hasPermissionToProjectSettings && {
          key: 'project-menu-costs',
          title: <FormattedMessage id="navigation.project.menu.costs" />,
          to: `/p/${projectId}/costs`,
          icon: <CostsIcon />,
          activeIcon: <CostsIconActive />
        },
        hasPermissionToProjectSettings && {
          key: 'project-menu-settings',
          title: <FormattedMessage id="navigation.project.menu.settings" />,
          to: `/p/${projectId}/settings`,
          icon: <SettingsIcon />,
          activeIcon: <SettingsIconActive />
        }
      ]),
    [projectId, isMobile, hasPermissionToProjectSettings, hasPermissionToViewProject]
  );

  return (
    <>
      {items.map(item => {
        const active = Boolean(matchPath(castPath(item.to), location.pathname));
        return (
          <Link key={item.key} to={item.to} onClick={closeOnMobile}>
            <HoverItem active={active} data-e2e={item.key}>
              {hovered => (
                <>
                  {active || hovered ? item.activeIcon : item.icon}
                  <span>{item.title}</span>
                </>
              )}
            </HoverItem>
          </Link>
        );
      })}
    </>
  );
};

export default ProjectMenu;

interface ProjectMenuItem {
  key: string;
  title: ReactNode;
  to: string;
  icon: ReactNode;
  activeIcon: ReactNode;
}
