import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { SelectOptGroup, SelectOption } from 'components/common/input-components/select';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { useIntl } from 'react-intl';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { SettingsForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { sortByVersion } from 'utils/array.utils';

export type VersionFieldFormProps = SubFormProps<SettingsForm> & {
  disabled?: boolean;
  disableInfo?: boolean;
  type?: BlueprintApi.IacType;
};

export type SimpleVersionIacType = Extract<BlueprintApi.IacType, 'pulumi' | 'ansible'>;

export type SimpleVersionFieldFormProps = VersionFieldFormProps & {
  type: SimpleVersionIacType;
};

type VersionFieldProps = VersionFieldFormProps & {
  versions: readonly string[];
  prefix: string;
  versionKey: string;
  type: BlueprintApi.BlueprintType;
  translateFunction?: (version: string) => string;
  disabled?: boolean;
  disableInfo?: boolean;
};

export const VersionField: React.FC<VersionFieldProps> = ({
  form,
  versions,
  prefix,
  versionKey,
  type,
  translateFunction,
  disabled,
  disableInfo
}: VersionFieldProps) => {
  const intl = useIntl();

  const versionByGroups = useMemo(() => {
    return groupBy(
      sortByVersion(versions, version => version),
      version => {
        const versionParts = version?.split('.');
        if (!versionParts || versionParts.length === 1) return intl.formatMessage({ id: `${prefix}.options.dynamic` });
        const [major, minor] = versionParts;
        return `${major}.${minor}.x`;
      }
    );
  }, [versions, intl, prefix]);

  return (
    <ControlledSelect
      data-e2e={`${type}-version-select`}
      name={versionKey}
      label={{ id: prefix }}
      info={disableInfo ? undefined : { id: `${prefix}.info` }}
      form={form}
      showArrow
      showSearch
      hideError
      disabled={disabled}>
      {versionByGroups &&
        Object.keys(versionByGroups).map(versionGroup => (
          <SelectOptGroup key={versionGroup} label={versionGroup}>
            {versionByGroups[versionGroup].map(version => {
              return (
                <SelectOption key={version} value={version as string} data-e2e={`version-${version}`}>
                  {translateFunction ? translateFunction(version) : version}
                </SelectOption>
              );
            })}
          </SelectOptGroup>
        ))}
    </ControlledSelect>
  );
};
