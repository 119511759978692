import type { PushEventMutationVariables } from '@env0/common-appsync-schema/api';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

export type SubscriptionVariables = { stream: string; key?: string };

export type EventHandler = (event?: Event) => Promise<any>;

export type SubscribeParameter = SubscriptionVariables & { onEvent: EventHandler; skipInitEvent?: boolean };

export type Event = PushEventMutationVariables & { id: string };

type AnyEventSubscriptionQueryUpdate = {
  onAnyEventPushed: Event;
};

export type SubscriptionQueryUpdate = {
  onEvent: EventHandler;
  variables: SubscriptionVariables;
} & AnyEventSubscriptionQueryUpdate;

export class SubscriptionEventHandler {
  private readonly lastEventByStreamAndKey: Record<string, Event> = {};

  async onSubscriptionQueryUpdate({ onAnyEventPushed, onEvent, variables }: SubscriptionQueryUpdate) {
    const event = onAnyEventPushed as Event;
    await onEvent(event);
    this.setLastReceivedEvent({ queryVariables: variables, event });
  }

  async onDeltaQueryUpdate({
    event,
    onEvent,
    variables,
    skipInitEvent = false
  }: {
    event: Event | null;
    onEvent: EventHandler;
    variables: SubscriptionVariables;
    skipInitEvent?: boolean;
  }) {
    event = isNil(event) ? ({ id: 'no-delta' } as Event) : event;
    const lastReceivedEvent = this.getLastReceivedEvent(variables);
    if (event.id !== lastReceivedEvent?.id) {
      if (!skipInitEvent || isString(lastReceivedEvent?.id)) await onEvent(event);
      this.setLastReceivedEvent({ queryVariables: variables, event });
    }
  }

  getLastReceivedEvent = ({ stream }: { stream: string }): Event => this.lastEventByStreamAndKey[stream];

  private setLastReceivedEvent = ({
    queryVariables: { stream: queryStream },
    event
  }: {
    queryVariables: { stream: string };
    event: Event;
  }) => {
    this.lastEventByStreamAndKey[queryStream] = event;
  };
}
