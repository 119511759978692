import { useCallback, useEffect, useMemo, useState } from 'react';
import type { EventNames } from 'utils/analytics.utils';
import { track } from 'utils/analytics.utils';
import {
  getIsDirty,
  getIsSkippable,
  getStepToIsValid
} from 'components/templates/templates-wizard/common/template-wizard-utils';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import type { WizardStepName } from 'components/common/steps';
import type { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';

export type WizardData = ReturnType<typeof useWizardNavigator>;

type WizardFormSteps = Exclude<WizardStepName, 'variables'>;
type WizardConfigurationStoreSteps = Extract<WizardStepName, 'variables'>;

export type WizardStepConfig = Partial<Record<WizardFormSteps, UseFormReturnType>> &
  Partial<Record<WizardConfigurationStoreSteps, ConfigurationPropertiesStore>>;

type EventTracking = {
  stepToEventMapper: Record<WizardStepName, EventNames>;
  eventProperties?: any;
};

export interface UseWizardNavigatorProps {
  steps: WizardStepName[];
  stepToConfig: WizardStepConfig;
  onSubmit: () => void | Promise<void>;
  onSkip?: (step: WizardStepName) => void | Promise<void>;
  onStepMoved?: (oldStep: WizardStepName, newStep: WizardStepName) => void;
  isEditMode: boolean;
  eventTracking?: EventTracking;
  defaultStep?: WizardStepName;
}

const useWizardNavigator = ({
  steps,
  stepToConfig,
  onSubmit,
  onSkip,
  onStepMoved,
  isEditMode,
  eventTracking,
  defaultStep
}: UseWizardNavigatorProps) => {
  const [isSavingDisabled, setIsSavingDisabled] = useState(false);
  const [currentStep, internalSetCurrentStep] = useState(defaultStep ?? steps[0]);

  const currentStepIndex = steps.indexOf(currentStep);

  useEffect(() => {
    if (!eventTracking) return;

    const { stepToEventMapper, eventProperties } = eventTracking;

    if (stepToEventMapper[currentStep]) {
      track(stepToEventMapper[currentStep]!, eventProperties);
    }
  }, [currentStep, eventTracking]);

  const setCurrentStep = useCallback(
    (newStep: WizardStepName) => {
      internalSetCurrentStep(oldStep => {
        onStepMoved?.(oldStep, newStep);
        return newStep;
      });
    },
    [onStepMoved]
  );

  const stepToIsValid = useMemo(() => getStepToIsValid(stepToConfig), [stepToConfig]);
  const isStepValid = useCallback((step: WizardStepName) => stepToIsValid[step]!(), [stepToIsValid]);
  const isCurrentStepValid = useMemo(() => isStepValid(currentStep), [currentStep, isStepValid]);
  const areAllStepsValid = useMemo(() => steps.every(isStepValid), [steps, isStepValid]);

  const isDirty = useMemo(() => getIsDirty(stepToConfig), [stepToConfig]);
  const isSkippable = useMemo(() => getIsSkippable(currentStep), [currentStep]);

  const gotoNextStep = useCallback(async () => {
    const isLastStep = currentStepIndex === steps.length - 1;

    if (isEditMode || isLastStep) {
      setIsSavingDisabled(true);
      try {
        await onSubmit();
      } finally {
        setIsSavingDisabled(false);
      }
    } else setCurrentStep(steps[currentStepIndex + 1]);
  }, [currentStepIndex, steps, isEditMode, setCurrentStep, onSubmit]);

  const gotoPreviousStep = useCallback(async () => {
    setCurrentStep(currentStepIndex === 0 ? currentStep : steps[currentStepIndex - 1]);
  }, [currentStepIndex, steps, currentStep, setCurrentStep]);

  const skipStep = useCallback(async () => {
    if (!isSkippable) return;
    await onSkip?.(currentStep);
    (stepToConfig[currentStep] as UseFormReturnType)?.discardChanges();
    await gotoNextStep();
  }, [isSkippable, stepToConfig, currentStep, gotoNextStep, onSkip]);

  return {
    gotoNextStep,
    gotoPreviousStep,
    setCurrentStep,
    setIsSavingDisabled,
    currentStep,
    currentStepIndex,
    isCurrentStepValid,
    areAllStepsValid,
    isSavingDisabled,
    isDirty,
    isSkippable,
    skipStep
  };
};

export default useWizardNavigator;
