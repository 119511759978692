import * as Yup from 'yup';
import type { EnvironmentApi } from '@env0/environment-service/api';
import useForm from 'hooks/use-form.hook';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { CRON_MISSING_ERROR, validateCron } from 'components/environments/helpers/cronValidationHelper';
import type { UseFormMethods } from 'react-hook-form/dist/types';

export type PoliciesDeploymentFormSchema = {
  includeCostEstimation: boolean;
  outputsAsInputsEnabled: boolean;
  skipApplyWhenPlanIsEmpty: boolean;
  skipRedundantDeployments: boolean;
  continuousDeploymentDefault: boolean;
  runPullRequestPlanDefault: boolean;
  vcsPrCommentsEnabledDefault: boolean;
  driftDetection: {
    enabled: boolean;
    cron?: string;
  };
};

export type PoliciesCustomFlowFormSchema = {
  customFlowAssignments: any[];
};

export type PoliciesApprovalPolicyFormSchema = {
  approvalPolicyAssignments: any[];
  autoApproveDefault: boolean;
};

export type PoliciesEnvironmentSettingsFormSchema = {
  maxTtl: string | null;
  defaultTtl: string | null;
  forceRemoteBackend: boolean;
};

export type PoliciesEnvironmentLimitsFormSchema = {
  disableDestroyEnvironments: boolean;
  numberOfEnvironments: number | null;
  numberOfEnvironmentsTotal: number | null;
};

const schemaShapes = {
  deployment: Yup.object().shape({
    includeCostEstimation: Yup.boolean().required(),
    outputsAsInputsEnabled: Yup.boolean().required(),
    skipApplyWhenPlanIsEmpty: Yup.boolean().required(),
    skipRedundantDeployments: Yup.boolean().required(),
    continuousDeploymentDefault: Yup.boolean().required(),
    runPullRequestPlanDefault: Yup.boolean().required(),
    vcsPrCommentsEnabledDefault: Yup.boolean().required(),
    driftDetection: Yup.object({
      enabled: Yup.boolean().default(false),
      cron: Yup.string()
        .default('')
        .when('enabled', {
          is: true,
          then: Yup.string().required(CRON_MISSING_ERROR).test(validateCron(true))
        })
    })
  }),
  customFlow: Yup.object().shape({
    customFlowAssignments: Yup.array().of(Yup.object())
  }),
  approvalPolicy: Yup.object().shape({
    approvalPolicyAssignments: Yup.array().of(Yup.object()),
    autoApproveDefault: Yup.boolean().required()
  }),
  environmentSettings: Yup.object().shape({
    maxTtl: Yup.string().nullable().default(null),
    defaultTtl: Yup.string().nullable().default(null),
    forceRemoteBackend: Yup.boolean().required().default(false)
  }),
  environmentLimits: Yup.object().shape({
    disableDestroyEnvironments: Yup.boolean().required(),
    numberOfEnvironments: Yup.number().nullable().default(null),
    numberOfEnvironmentsTotal: Yup.number().nullable().default(null)
  })
};

export const useDeploymentForm = ({
  policies,
  onSubmit,
  initialValues = {}
}: {
  policies: EnvironmentApi.Policy;
  onSubmit: (data: PoliciesDeploymentFormSchema) => Promise<void>;
  initialValues?: Partial<PoliciesDeploymentFormSchema>;
}) => {
  return useForm<PoliciesDeploymentFormSchema>({
    schema: schemaShapes.deployment,
    onSubmit,
    initialValues: {
      includeCostEstimation: policies.includeCostEstimation ?? false,
      outputsAsInputsEnabled: policies.outputsAsInputsEnabled ?? false,
      skipApplyWhenPlanIsEmpty: policies.skipApplyWhenPlanIsEmpty ?? false,
      skipRedundantDeployments: policies.skipRedundantDeployments ?? false,
      continuousDeploymentDefault: policies.continuousDeploymentDefault ?? false,
      runPullRequestPlanDefault: policies.runPullRequestPlanDefault ?? false,
      vcsPrCommentsEnabledDefault: policies.vcsPrCommentsEnabledDefault ?? false,
      driftDetection: {
        enabled: policies.driftDetectionEnabled ?? false,
        cron: policies.driftDetectionCron ?? ''
      },
      ...initialValues
    }
  });
};

export const useCustomFlowForm = ({
  initialValues,
  onSubmit
}: {
  initialValues?: BlueprintApi.RichCustomFlowAssigment[];
  onSubmit: (data: PoliciesCustomFlowFormSchema, form: UseFormMethods<PoliciesCustomFlowFormSchema>) => Promise<void>;
}) => {
  const form = useForm<PoliciesCustomFlowFormSchema>({
    schema: schemaShapes.customFlow,
    initialValues: {
      customFlowAssignments: initialValues
    },
    onSubmit
  });

  return form;
};

export const useApprovalPolicyForm = ({
  policies,
  initialValues,
  onSubmit
}: {
  policies: EnvironmentApi.Policy;
  initialValues?: BlueprintApi.ApprovalPolicyTemplateWithScope[];
  onSubmit: (
    data: PoliciesApprovalPolicyFormSchema,
    form: UseFormMethods<PoliciesApprovalPolicyFormSchema>
  ) => Promise<void>;
}) => {
  const form = useForm<PoliciesApprovalPolicyFormSchema>({
    schema: schemaShapes.approvalPolicy,
    onSubmit,
    initialValues: {
      approvalPolicyAssignments: initialValues,
      autoApproveDefault: !policies.requiresApprovalDefault
    }
  });
  return form;
};

export const useEnvironmentSettingsForm = ({
  policies,
  initialValues = {},
  onSubmit
}: {
  policies: EnvironmentApi.Policy;
  initialValues?: Partial<PoliciesEnvironmentSettingsFormSchema>;
  onSubmit: (data: PoliciesEnvironmentSettingsFormSchema) => Promise<void>;
}) => {
  return useForm<PoliciesEnvironmentSettingsFormSchema>({
    schema: schemaShapes.environmentSettings,
    onSubmit,
    initialValues: {
      maxTtl: policies.maxTtl,
      defaultTtl: policies.defaultTtl,
      forceRemoteBackend: policies.forceRemoteBackend ?? false,
      ...initialValues
    }
  });
};

export const useEnvironmentLimitsForm = ({
  policies,
  initialValues = {},
  onSubmit
}: {
  policies: EnvironmentApi.Policy;
  initialValues?: Partial<PoliciesEnvironmentLimitsFormSchema>;
  onSubmit: (data: PoliciesEnvironmentLimitsFormSchema) => Promise<void>;
}) => {
  return useForm<PoliciesEnvironmentLimitsFormSchema>({
    schema: schemaShapes.environmentLimits,
    onSubmit,
    initialValues: {
      disableDestroyEnvironments: policies.disableDestroyEnvironments ?? false,
      numberOfEnvironments: policies.numberOfEnvironments ?? null,
      numberOfEnvironmentsTotal: policies.numberOfEnvironmentsTotal ?? null,
      ...initialValues
    }
  });
};
