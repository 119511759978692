import { useQuery } from '@tanstack/react-query';
import useApiClient from 'hooks/use-api-client';
import type { NotificationsApi } from '@env0/notification-service/api';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

const INTERVAL_IN_HOURS = 1;
export const INTERVAL_IN_MILLIS = INTERVAL_IN_HOURS * 1000 * 60 * 60;
export const STALE_TIME_IN_MILLIS = INTERVAL_IN_MILLIS / 2;

export interface Notification {
  id: string;
  title: string;
  description: string;
  link: string;
  isoDate: string;
}

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'app-notifications'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all']
  } as const;

  return cacheKeys;
};

export const useAppNotificationsApi = () => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();
  return useQuery({
    queryKey: all,
    queryFn: () => apiClient.appNotifications.getAppNotifications(),
    select: data => formatFromServer(data.newFeatures),
    staleTime: STALE_TIME_IN_MILLIS,
    refetchInterval: INTERVAL_IN_MILLIS
  });
};

export const formatFromServer = (versions: NotificationsApi.NewFeaturesItem[]): Notification[] => {
  return versions.map(version => ({
    id: version.guid,
    title: version.title,
    description: version.contentSnippet,
    link: version.link,
    isoDate: version.isoDate
  }));
};
