import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStores from 'hooks/use-stores.hooks';
import type { OrganizationUser, Project } from 'types/api.types';
import type { ProjectRoles } from 'types/api.types';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { useCuratedRoles } from 'stores/rq/roles';
import RoleAssignmentsCard from 'components/common/role-assignments/role-assignments-card';
import { CardHeader } from 'components/common/card';
import { links } from 'constants/external-links';
import type { UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import { useUserRoleAssignmentRows } from 'hooks/use-user-role-assignment-rows';
import { buildUserRoleAssignmentsColumns } from 'components/common/role-assignments/build-user-role-assignments-columns';
import { convertToUserRoleAssignmentsRows } from 'components/common/role-assignments/convert-to-user-role-assignments-rows';
import type { RolesApi } from '@env0/role-service/api';
import { setLinksByTag } from 'components/common/link';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';

const intlPrefix = 'card-headers.user-role-assignments';
const scopeName = 'project';

const rowsToInput = (rows: UserRoleAssignmentRow[]) =>
  rows.map(row => ({ userId: row.id, role: row.role as ProjectRoles }));

const ProjectSettingsUsers: React.FunctionComponent<{ project: Project }> = ({ project }) => {
  const intl = useIntl();
  const [users, setUsers] = useState<OrganizationUser[]>([]);
  const [projectUsers, setProjectUsers] = useState<RolesApi.UserRoleAssignment[]>([]);
  const currentOrganizationId = useCurrentOrganizationId();

  const { organizationsStore, projectsStore, userStore } = useStores();
  const { defaultProjectRoles } = useCuratedRoles();
  const {
    onChangeIsAssigned,
    onChangeAssignmentRole,
    hasChanges,
    rows,
    setRows,
    rowsToAssign,
    rowsToRemove,
    rowsToUpdate
  } = useUserRoleAssignmentRows(defaultProjectRoles);
  const currentUserId = userStore.userId!;

  const columns = useMemo(
    () =>
      buildUserRoleAssignmentsColumns({
        onChangeIsAssigned,
        onChangeAssignmentRole,
        defaultRoles: defaultProjectRoles,
        scopeName
      }),
    [defaultProjectRoles, onChangeIsAssigned, onChangeAssignmentRole]
  );

  const handleSaveChanges = async () => {
    try {
      await Promise.all([
        projectsStore.removeUsers(project.id, rowsToInput(rowsToRemove)),
        projectsStore.assignUsers(project.id, rowsToInput(rowsToAssign)),
        projectsStore.updateUsers(project.id, rowsToInput(rowsToUpdate))
      ]);

      await fetchProjectUsers();
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'projects.users.failed' })
      });

      console.error(error);
    }
  };

  const initData = useCallback(
    (users: OrganizationUser[], projectUsers: RolesApi.UserRoleAssignment[]) => {
      setRows(convertToUserRoleAssignmentsRows(users, projectUsers, currentUserId));
    },
    [setRows, currentUserId]
  );

  const handleDiscardChanges = useCallback(() => {
    initData(users, projectUsers);
  }, [initData, users, projectUsers]);

  const fetchUsers = useCallback(
    async (query?: string) => {
      const usersList = await organizationsStore.getOrganizationUsers({ query, includeApiKeys: true });
      setUsers(usersList);
    },
    [organizationsStore]
  );

  useEffect(() => {
    initData(users, projectUsers);
  }, [initData, users, projectUsers]);

  const fetchProjectUsers = useCallback(async () => {
    const projectUsers = await projectsStore.fetchUsers(project.id);
    setProjectUsers(projectUsers);
  }, [projectsStore, project.id]);

  const loadData = useCallback(async () => {
    await Promise.all([fetchUsers(''), fetchProjectUsers()]);
  }, [fetchUsers, fetchProjectUsers]);

  const cardHeader = (
    <CardHeader
      titleId={`${intlPrefix}.title`}
      descriptionId={`${intlPrefix}.description`}
      descriptionValues={{
        scope: scopeName,
        ...setLinksByTag({
          manageUsers: links.docs.USER.PROJECT_ROLES,
          customRole: links.docs.USER.CUSTOM_ROLE_PERMISSIONS,
          orgPage: `/organizations/${currentOrganizationId}/users`
        })
      }}
    />
  );

  return (
    <RoleAssignmentsCard
      cardHeader={cardHeader}
      columns={columns}
      rows={rows}
      loadRows={loadData}
      getRowKey={(row: UserRoleAssignmentRow) => row.id}
      onDiscard={handleDiscardChanges}
      onSave={handleSaveChanges}
      hasChanges={hasChanges}
      filterPlaceholderId={`${intlPrefix}.filter`}
      onFilter={fetchUsers}
    />
  );
};

export default ProjectSettingsUsers;
