import React, { useCallback, useState } from 'react';
import type { CustomButtonProps } from 'components/common/button';
import Button from 'components/common/button';

interface ActionButtonProps extends CustomButtonProps {
  action: () => Promise<any>;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({ action, ...properties }) => {
  const [isRunningCallback, setIsRunningCallback] = useState(false);

  const onClickHandler = useCallback(async () => {
    setIsRunningCallback(true);
    try {
      await action();
    } finally {
      setIsRunningCallback(false);
    }
  }, [action]);

  return <Button isLoading={isRunningCallback} onClick={onClickHandler} disabled={isRunningCallback} {...properties} />;
};

export default ActionButton;
