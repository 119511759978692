import type {
  UseFormOptions,
  UnpackNestedValue,
  FieldName,
  SetFieldValue,
  FieldValues,
  DeepPartial
} from 'react-hook-form';
import { useForm as useReactHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SchemaOf } from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import type { ValuesType } from 'utility-types';
import type { UseFormMethods } from 'react-hook-form/dist/types';

export type UseFormProps<T extends FieldValues> = Omit<UseFormOptions<T>, 'defaultValues'> & {
  schema: SchemaOf<UnpackNestedValue<T>>;
  initialValues?: UnpackNestedValue<DeepPartial<T>>;
  onSubmit: (data: T, form: UseFormMethods<T>, initialFormValues: UnpackNestedValue<DeepPartial<T>>) => void;
  readonly?: boolean;
  shouldClearAfterSubmit?: boolean;
};

const useForm = <T extends object>({
  schema,
  onSubmit,
  readonly,
  initialValues,
  shouldClearAfterSubmit,
  shouldUnregister,
  ...props
}: UseFormProps<T>) => {
  const schemaDefaults = useMemo(() => schema.getDefault() as UnpackNestedValue<DeepPartial<T>>, [schema]);

  const initialFormValues = useMemo(() => ({ ...schemaDefaults, ...initialValues }), [schemaDefaults, initialValues]);

  const form = useReactHookForm<T>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
    shouldUnregister,
    ...props
  });

  const {
    getValues,
    formState: { isDirty, isSubmitting, isValid, isSubmitSuccessful }
  } = form;
  const {
    setValue: formSetValue,
    clearErrors,
    reset,
    register,
    watch,
    handleSubmit: formHandleSubmit,
    ...exportedForm
  } = form;

  const canSave = isValid && isDirty && !isSubmitting;

  const discardChanges = useCallback(() => {
    reset();
  }, [reset]);

  const resetFormValues = useCallback(
    (values: any) => {
      reset(values);
    },
    [reset]
  );

  const handleSubmit = useCallback(
    (data: UnpackNestedValue<DeepPartial<any>>) => onSubmit(data, form, initialFormValues),
    [onSubmit, form, initialFormValues]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      shouldClearAfterSubmit ? reset(initialFormValues) : reset(getValues() as UnpackNestedValue<DeepPartial<T>>);
    }
  }, [isSubmitSuccessful, shouldClearAfterSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = useMemo(() => formHandleSubmit(handleSubmit), [formHandleSubmit, handleSubmit]);

  const clear = useCallback(() => {
    reset(schemaDefaults);
  }, [reset, schemaDefaults]);

  const setValue: typeof form.setValue = useCallback(
    (name, value, config) => {
      const defaultConfig = { shouldDirty: true, shouldValidate: true };
      const configWithDefault = { ...defaultConfig, ...config };

      // Setting a value with shouldValidate doesn't clear errors for some reason
      if (configWithDefault.shouldValidate) {
        clearErrors(name);
      }
      return formSetValue(name, value, configWithDefault);
    },
    [clearErrors, formSetValue]
  );

  // use when you need to bind in yourself to the component
  const useManualField = useCallback(
    <F extends ValuesType<T>>(fieldName: FieldName<T>): [F, (newValue: F) => void] => {
      register(fieldName);
      const value = watch(fieldName) as F;
      const setter = (newValue: F) => setValue(fieldName, newValue as SetFieldValue<T>);
      return [value, setter];
    },
    [register, setValue, watch]
  );
  return {
    ...exportedForm,
    clearErrors,
    watch,
    register,
    clear,
    discardChanges,
    canSave,
    submit,
    readonly: readonly || isSubmitting,
    useManualField,
    isDirty,
    isSubmitting,
    resetFormValues,
    setValue,
    initialFormValues
  };
};

// Hack to support  ReturnType generic until TS will support it
class Wrapper<T extends object> {
  // wrapped has no explicit return type, so we can infer it
  wrapped(e: any) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useForm<T>(e);
  }
}

export type UseFormReturnType<T extends object = {}> = ReturnType<Wrapper<T>['wrapped']>;

export default useForm;
