import { action, observable, ObservableMap } from 'mobx';
import { getLogKey } from 'utils/deployment-steps-log.utils';
import type { DeploymentStep, DeploymentSteps, DeploymentStepLog, DeploymentLog } from 'types/api.types';
import { DeploymentStepStatus } from 'types/api.types';
import BaseService from 'services/base-service';

const LOG_POLLING_INTERVAL_MS = 3000;

export class DeploymentStepsStore extends BaseService {
  @observable deploymentSteps: DeploymentSteps = {};
  @observable deploymentStepsLogs: ObservableMap<string, DeploymentStepLog> = new ObservableMap();
  private pollingStatus: Record<string, boolean> = {};

  @action setDeploymentSteps(steps: DeploymentStep[], deploymentLogId: string) {
    this.deploymentSteps[deploymentLogId] = steps;
  }

  @action setDeploymentStepLog(logKey: string, deploymentStepLog: DeploymentStepLog) {
    this.deploymentStepsLogs.set(logKey, deploymentStepLog);
  }

  private shouldStopPolling(deploymentLogId: string, stepName: string) {
    return !this.pollingStatus[getLogKey(stepName, deploymentLogId)];
  }

  private setPollingStatus(deploymentLogId: string, stepName: string, status: boolean) {
    this.pollingStatus[getLogKey(stepName, deploymentLogId)] = status;
  }

  stopDeploymentStepLogPolling(deploymentLogId: string, stepName: string) {
    this.setPollingStatus(deploymentLogId, stepName, false);
  }

  async getDeploymentSteps(deploymentLog: DeploymentLog) {
    const steps = await this.getDeploymentStepsById(deploymentLog.id, deploymentLog.status);

    this.setDeploymentSteps(steps, deploymentLog.id);

    return steps;
  }

  async getDeploymentStepsById(deploymentLogId: string, deploymentStatus?: string) {
    const steps = await this.service.apiClient.deployments.getDeploymentStepsByDeploymentId(deploymentLogId);

    return steps.map(step => ({
      ...step,
      status:
        deploymentStatus === 'CANCELLED' &&
        [DeploymentStepStatus.NOT_STARTED, DeploymentStepStatus.WAITING_FOR_USER].includes(step.status)
          ? DeploymentStepStatus.CANCELLED
          : step.status
    }));
  }

  async getDeploymentStepLog(deploymentLogId: string, stepName: string) {
    let shouldPoll = true;
    const logKey = getLogKey(stepName, deploymentLogId);

    this.setPollingStatus(deploymentLogId, stepName, true);

    while (shouldPoll) {
      const isStepInProgress = this.isStepInProgress(deploymentLogId, stepName);
      const previousLog = this.deploymentStepsLogs.get(logKey);

      const nextLog = await this.getMergedNextDeploymentLog(deploymentLogId, stepName, previousLog);
      this.setDeploymentStepLog(logKey, nextLog);

      if (this.shouldStopPolling(deploymentLogId, stepName)) return;

      shouldPoll = nextLog.hasMoreLogs || isStepInProgress;
      if (isStepInProgress) {
        await new Promise(resolve => setTimeout(resolve, LOG_POLLING_INTERVAL_MS));
      }
    }
  }

  private async getMergedNextDeploymentLog(
    deploymentLogId: string,
    stepName: string,
    previousLog?: DeploymentStepLog
  ): Promise<DeploymentStepLog> {
    if (previousLog) {
      const nextLog = await this.service.apiClient.deployments.getStepLog(
        deploymentLogId,
        stepName,
        previousLog.nextStartTime
      );
      return {
        nextStartTime: nextLog.nextStartTime || previousLog.nextStartTime,
        hasMoreLogs: nextLog.hasMoreLogs,
        events: previousLog.events.concat(nextLog.events)
      };
    } else {
      return await this.service.apiClient.deployments.getStepLog(deploymentLogId, stepName);
    }
  }

  private isStepInProgress(deploymentLogId: string, stepName: string): boolean {
    const steps = this.deploymentSteps[deploymentLogId];
    const step = steps?.find(s => s.name === stepName);

    return step?.status === DeploymentStepStatus.IN_PROGRESS;
  }
}
