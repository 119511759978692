import React, { type ReactNode } from 'react';
import { IntlProvider, createIntlCache, createIntl } from 'react-intl';
import type { IntlConfig } from 'react-intl';
import ct from 'countries-and-timezones';
import { isTestNodeEnv } from 'constants/config';
import messages from 'constants/locales/en-US/en-US.translations.json';

const getTimeLocale = () => {
  if (isTestNodeEnv) return 'en-US';

  const testLocal = localStorage.getItem('env0_static_local');
  if (testLocal) return testLocal;

  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if ((ct.getCountry('US').timezones as string[]).includes(tz)) return 'en-US';
  } catch {}
  return 'en-UK';
};

export const timeLocale = getTimeLocale();

const cache = createIntlCache();

const intlConfig: IntlConfig = {
  locale: timeLocale,
  messages
};

const Localization: React.FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  return <IntlProvider {...intlConfig}>{children}</IntlProvider>;
};

export const intl = createIntl(intlConfig, cache);

export default Localization;
