import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import type { Project } from 'types/api.types';
import ProjectSettingsCredentialsCard from 'components/projects/settings/credentials/project-settings-credentials-card';
import { links } from 'constants/external-links';
import {
  useCuratedDeploymentCredentialsForProject,
  useUpdateDeploymentCredentialsForProject
} from 'stores/rq/credentials';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

const ProjectSettingsDeployCredentials: React.FunctionComponent<{
  project: Project;
  cardHeader?: EmotionJSX.Element;
  disabled?: boolean;
}> = ({ project, cardHeader, disabled }) => {
  const { isLoading, error, deploymentCredentialIdByType } = useCuratedDeploymentCredentialsForProject(project.id);
  const { mutateAsync: updateDeploymentCredentialIdsForProject } = useUpdateDeploymentCredentialsForProject(project.id);

  const onSave = useCallback(
    async (credentialIds: string[]) => {
      await updateDeploymentCredentialIdsForProject(credentialIds);
    },
    [updateDeploymentCredentialIdsForProject]
  );
  return (
    <ProjectSettingsCredentialsCard
      usageType="DEPLOYMENT"
      loadingCredentials={isLoading}
      credentialsError={error}
      onSave={onSave}
      existingProjectCredentials={deploymentCredentialIdByType}
      docsHref={links.docs.DEPLOYMENT_CREDENTIALS.CONNECT_CLOUD_ACCOUNT}
      cardHeader={cardHeader}
      disabled={disabled}
    />
  );
};

export default observer(ProjectSettingsDeployCredentials);
