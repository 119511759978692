import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { EnvironmentApi } from '@env0/environment-service/api';

export default (client: HttpClient) => ({
  getEnvironmentOutputsForProject: async (projectId: string) =>
    client
      .get<EnvironmentApi.GetEnvironmentsOutputs.Response>(`environment-outputs`, { params: { projectId } })
      .then(returnData)
});
