import React from 'react';
import { getGitPrUrl } from 'utils/template.utils';
import Link from 'components/common/link';
import theme from 'constants/themes.constants';
import { isSaasVcsIntegrated } from 'utils/blueprint.utils';
import type { Blueprint } from 'types/api.types';

export interface VcsLinkProps {
  prNumber?: string;
  blueprint?: Blueprint;
  deployerName: string;
}

const VcsPrLink = ({ blueprint, prNumber, deployerName }: VcsLinkProps) => {
  if (blueprint && prNumber && isSaasVcsIntegrated(blueprint)) {
    return (
      <Link
        onClick={e => e.stopPropagation()}
        plainText={true}
        url={getGitPrUrl(blueprint.repository, prNumber)}
        color={theme.primaryBlue}
        messageId={`PR-${prNumber}`}
      />
    );
  }
  return <>{deployerName}</>;
};

export default VcsPrLink;
