import type { DelegateBackoffFn, IExponentialBackoffOptions, IRetryBackoffContext, IFailureEvent } from 'cockatiel';
import { CancellationTokenSource, Policy, TaskCancelledError, TimeoutStrategy } from 'cockatiel';
import type { HttpError } from 'http-errors';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

export type Logger = {
  debug: (...data: any[]) => void;
  warning: (...data: any[]) => void;
  info: (...data: any[]) => void;
};
let logger: Logger | undefined = undefined;
export const setLogger = (l: Logger) => {
  logger = l;
};

export type TimeoutAndRetryOptions = {
  attempts: number;
  timeoutMs: number;
  cancelOnErrors?: string[];
};

export type RetryOnErrorsOptions = {
  attempts: number;
};

export type DelegateBackoffOptions<T> = {
  delegateBackoffFn: DelegateBackoffFn<IRetryBackoffContext<never> & { result: { error?: T } }>;
  attempts: number;
  cancelOnErrors?: string[];
};

function onFailure(
  cancellationTokenSource: CancellationTokenSource,
  cancelOnErrors?: TimeoutAndRetryOptions['cancelOnErrors'],
  errorFieldName = 'message'
) {
  let attempt = 1;
  return ({ duration, handled, reason }: IFailureEvent) => {
    const error = 'error' in reason ? reason.error : undefined;
    logger?.warning(`RetryStrategies attempt failed`, { error, handled, duration, attempt: attempt++ });
    if (cancelOnErrors?.some(cancelOnErrorText => get(error, errorFieldName)?.includes?.(cancelOnErrorText))) {
      logger?.info('Cancelling retry and timeout');
      cancellationTokenSource.cancel();
    }
  };
}

type RetryPolicyOptions = Partial<Pick<TimeoutAndRetryOptions, 'attempts' | 'cancelOnErrors'>> & { fieldName?: string };

function createRetryPolicy({ attempts, cancelOnErrors, fieldName }: RetryPolicyOptions = {}) {
  const cancellationTokenSource = new CancellationTokenSource();

  let retry = Policy.handleAll().retry();
  if (attempts) {
    retry = retry.attempts(attempts);
  }

  retry.onFailure(onFailure(cancellationTokenSource, cancelOnErrors, fieldName));

  return { retry, cancellationToken: cancellationTokenSource.token };
}

const withTimeoutAndRetry = <T>(
  fn: () => Promise<T>,
  { attempts, timeoutMs, cancelOnErrors }: TimeoutAndRetryOptions
) => {
  const { retry, cancellationToken } = createRetryPolicy({ attempts, cancelOnErrors });
  const timeout = Policy.timeout(timeoutMs, TimeoutStrategy.Aggressive);

  return Policy.wrap(retry, timeout).execute(fn, cancellationToken);
};

type ExponentialBackoffOptions = Partial<IExponentialBackoffOptions<unknown>> & { retryPolicy?: RetryPolicyOptions };

const withExponentialBackoff = <T>(fn: () => Promise<T>, exponentialBackoffOptions: ExponentialBackoffOptions) => {
  const { retry, cancellationToken } = createRetryPolicy(exponentialBackoffOptions.retryPolicy);
  return retry.exponential(exponentialBackoffOptions).execute(fn, cancellationToken);
};

const withDelegateBackoff = <T, U = Error>(
  fn: () => Promise<T>,
  { delegateBackoffFn, attempts, cancelOnErrors }: DelegateBackoffOptions<U>
) => {
  const { retry, cancellationToken } = createRetryPolicy({ attempts, cancelOnErrors });
  return retry
    .delegate(delegateBackoffFn as DelegateBackoffFn<IRetryBackoffContext<unknown>, void>)
    .execute(fn, cancellationToken);
};
const withTimeout = <T>(fn: () => Promise<T>, timeoutMs: number, fnName?: string) => {
  const timeout = Policy.timeout(timeoutMs, TimeoutStrategy.Aggressive);

  if (fnName) {
    timeout.onTimeout(() => logger?.debug(`Timeout reached on ${fnName}`));
  }

  return timeout.execute(fn);
};

const shouldRetry = (err: Error): boolean => {
  const error = err as HttpError<number> | undefined;
  const status = error?.status ?? error?.response?.status; // In Axios error the status is in response.status

  // Check for DNS errors or other network-related issues
  const isNetworkError = Boolean(
    error?.message &&
      (error.message.includes('getaddrinfo EAI_AGAIN') ||
        error.message.includes('ENOTFOUND') ||
        error.message.includes('ECONNREFUSED') ||
        error.message.includes('ETIMEDOUT'))
  );

  return isUndefined(status) || status >= 500 || isNetworkError;
};

const retryOnErrorsPolicy = <T>(fn: () => Promise<T>, { attempts }: RetryOnErrorsOptions) => {
  const retryPolicy = Policy.handleWhen(shouldRetry).retry().attempts(attempts);

  retryPolicy.onRetry(data => {
    logger?.debug('retrying', data);
  });

  retryPolicy.onGiveUp(data => {
    logger?.warning('give up retrying', data);
  });

  return retryPolicy;
};

const withRetryOnErrors = <T>(fn: () => Promise<T>, { attempts }: RetryOnErrorsOptions) => {
  const retryPolicy = retryOnErrorsPolicy(fn, { attempts });
  return retryPolicy.execute(fn);
};

const withRetryOnErrorsAndExponentialBackoff = <T>(
  fn: () => Promise<T>,
  {
    maxAttempts,
    ...exponentialBackoffOptions
  }: Partial<Omit<IExponentialBackoffOptions<unknown>, 'maxAttempts'>> & { maxAttempts: number }
) => {
  const retryPolicy = retryOnErrorsPolicy(fn, { attempts: maxAttempts });
  retryPolicy.exponential(exponentialBackoffOptions);
  return retryPolicy.execute(fn);
};

export const retryStrategies = {
  withTimeoutAndRetry,
  withExponentialBackoff,
  withDelegateBackoff,
  withTimeout,
  withRetryOnErrors,
  withRetryOnErrorsAndExponentialBackoff,
  TimeoutError: TaskCancelledError
};
