import React, { useState, type MouseEvent } from 'react';
import styled from 'types/theme.types';

interface Props {
  width: number;
  setWidth: (width: number) => void;
}

const NavbarResizer = ({ width, setWidth }: Props) => {
  const [dragging, setDragging] = useState(false);
  const [startWidth, setStartWidth] = useState(0);
  const [startX, setStartX] = useState(0);

  const onMouseDown = (e: MouseEvent) => {
    if (e.button !== 0) return;
    setDragging(true);
    setStartWidth(width);
    setStartX(e.clientX);
    window.getSelection()?.removeAllRanges();
  };

  const onMouseMove = (e: MouseEvent) => {
    if (e.buttons !== 1) {
      setDragging(false);
      return;
    }
    const delta = e.clientX - startX;
    setWidth(startWidth + delta);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  return (
    <>
      <Handle onMouseDown={onMouseDown} />
      {dragging && <Overlay onMouseMove={onMouseMove} onMouseUp={onMouseUp} />}
    </>
  );
};

export default NavbarResizer;

const Overlay = styled.div`
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: col-resize;
`;

const Handle = styled.div`
  position: absolute;
  top: 0;
  right: -4px;
  height: 100%;
  width: 10px;
  cursor: ew-resize;
`;
