import React from 'react';

import useOrganizationForm from 'components/organizations/organization-form.hooks';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import OrganizationForm from 'components/organizations/organization-form';

export type CreateOrganizationModalProps = {
  hideModal: () => void;
  open: boolean;
};

const CreateOrganizationModal: React.FunctionComponent<CreateOrganizationModalProps> = ({ open, hideModal }) => {
  const form = useOrganizationForm();

  return (
    <ControlledModal
      open={open}
      form={form}
      onCancel={hideModal}
      titleId="organizations.create.form.new.title"
      subtitleId="organizations.create.form.new.explanation"
      saveMessageId="organizations.create"
      saveButtonDataE2E="save-organization-button">
      <OrganizationForm form={form} />
    </ControlledModal>
  );
};

export default CreateOrganizationModal;
