import React from 'react';
import styled from 'types/theme.types';
import { Input } from 'antd';
import { getCommonInputCssFromProps } from 'components/common/input-components/common-styles';
import type { TextAreaRef, TextAreaProps as AntdTextAreaProps } from 'antd/lib/input/TextArea';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'components/common/form-components';
import omit from 'lodash/omit';

export type TextAreaProps = AntdTextAreaProps & {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  autoSize?: { minRows?: number; maxRows?: number } | boolean;
  fontSize?: number;
  invalid?: boolean;
  error?: string | null;
  'data-e2e'?: string;
};

const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
  (
    {
      error,
      id,
      name,
      value,
      onChange,
      disabled,
      placeholder,
      autoSize = { minRows: 3 },
      fontSize,
      invalid,
      maxLength,
      ...otherProps
    },
    ref
  ) => {
    return (
      <>
        <StyledTextArea
          {...omit(otherProps, ['hideError'])}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoSize={autoSize}
          placeholder={placeholder}
          invalid={invalid}
          ref={ref}
          maxLength={maxLength}
          fontSize={fontSize}
          error={error}
          data-e2e={otherProps['data-e2e'] ?? undefined}
        />
        {error && (
          <ErrorMessage>
            <FormattedMessage id={error} />
          </ErrorMessage>
        )}
      </>
    );
  }
);

TextArea.displayName = 'TextArea';

const StyledTextArea = styled(Input.TextArea)<TextAreaProps>`
  ${getCommonInputCssFromProps}
`;

export default TextArea;
