export const ORGANIZATION_SETTINGS_TABS = [
  'general',
  'users',
  'teams',
  'roles',
  'credentials',
  'api-keys',
  'policy',
  'keys',
  'audit-logs',
  'integrations',
  'notifications',
  'agents',
  'billing'
] as const;

export type OrganizationSettingsTabName = (typeof ORGANIZATION_SETTINGS_TABS)[number];
