import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'ahooks';
import OrganizationItem from 'components/layout/navbar/navbar-organization-item';
import {
  OrganizationButton,
  PanelOrganizationSection,
  PanelSecondarySection
} from 'components/layout/navbar/navbar-panel.utils';
import OrganizationSwitcher from 'components/layout/navbar/navbar-organization-switcher';
import useStores from 'hooks/use-stores.hooks';
import styled from 'types/theme.types';
import type { Organization } from 'types/api.types';
import { ReactComponent as SwitchArrows } from 'assets/images/switch-arrows.svg';

type Props = {
  organization: Organization;
};

const OrganizationContainer = ({ organization }: Props) => {
  const { organizationsStore } = useStores();
  const switcherRef = useRef<HTMLDivElement>(null);
  const openSwitcherButtonRef = useRef<HTMLDivElement>(null);
  const [switcherOpen, setSwitcherOpen] = useState(false);

  useClickAway(() => setSwitcherOpen(false), [switcherRef, openSwitcherButtonRef]);

  const switchOrganization = useCallback(
    (organization: Organization) => {
      organizationsStore.switchCurrentOrganization(organization.id, undefined, '/');
      setSwitcherOpen(false);
    },
    [organizationsStore]
  );

  return (
    <>
      <PanelSecondarySection>
        <StyledOrganizationSwitcher
          innerRef={switcherRef}
          open={switcherOpen}
          onClose={() => setSwitcherOpen(false)}
          onChange={switchOrganization}
        />
      </PanelSecondarySection>
      <PanelOrganizationSection data-e2e="navbar-organization-current">
        <OrganizationItem
          ref={openSwitcherButtonRef}
          organization={organization}
          onClick={() => setSwitcherOpen(switcherOpen => !switcherOpen)}
          nameProps={{
            'data-e2e': 'navbar-organization-button-name',
            'data-org-id': organization.id
          }}
          appendedElement={hovered => (
            <StyledOrganizationButton isOpen={switcherOpen} hovered={hovered}>
              <SwitchArrows />
            </StyledOrganizationButton>
          )}
        />
      </PanelOrganizationSection>
    </>
  );
};

export default OrganizationContainer;

const StyledOrganizationButton = styled(OrganizationButton)<{ isOpen: boolean; hovered: boolean }>`
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.1s ease-in-out;

  ${({ hovered }) => hovered && `color: white;`}
  ${({ isOpen }) => isOpen && `color: #00F2B6;`}

  svg {
    pointer-events: none;
  }
`;

const StyledOrganizationSwitcher = styled(OrganizationSwitcher)<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${({ open }) => (open ? -100 : 0)}%);
  transition: transform 0.15s ease-in-out;
`;
