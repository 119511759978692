import useApiClient from 'hooks/use-api-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';

const KEY = 'custom-flow-assignments';

const useCacheKeys = () => {
  const orgId = useCurrentProjectId();

  const baseKey = [orgId, KEY] as const;

  return {
    byScope: ({ scope, scopeId }: { scope: BlueprintApi.CustomFlowScope; scopeId: string }) =>
      [...baseKey, { scope, scopeId }] as const
  };
};

export const useInvalidateCustomFlowAssignmentsCache = () => {
  const { byScope } = useCacheKeys();
  return ({ scope, scopeId }: { scope: BlueprintApi.CustomFlowScope; scopeId: string }) =>
    getQueryClientInstance().invalidateQueries({
      queryKey: byScope({ scopeId, scope })
    });
};

export const useAssignCustomFlow = () => {
  const apiClient = useApiClient();
  const projectId = useCurrentProjectId();

  const invalidate = useInvalidateCustomFlowAssignmentsCache();

  return useMutation({
    mutationFn: (args: BlueprintApi.AssignCustomFlow.Request.Body) => apiClient.customFlows.assignCustomFlow(args),
    onSuccess: () => {
      invalidate({ scopeId: projectId!, scope: 'PROJECT' });
    }
  });
};

export const useUnassignCustomFlow = () => {
  const apiClient = useApiClient();
  const projectId = useCurrentProjectId();
  const invalidate = useInvalidateCustomFlowAssignmentsCache();

  return useMutation({
    mutationFn: async (
      args: BlueprintApi.UnassignCustomFlow.Request.Body
    ): Promise<BlueprintApi.CustomFlowAssignmentRequest[]> => {
      await apiClient.customFlows.unassignCustomFlow(args);
      return [...args];
    },

    onSuccess: () => {
      invalidate({ scopeId: projectId!, scope: 'PROJECT' });
    }
  });
};

export const useGetCustomFlowAssignments = ({
  scope,
  scopeId
}: {
  scope: BlueprintApi.CustomFlowScope;
  scopeId?: string;
}) => {
  const apiClient = useApiClient();
  const { byScope } = useCacheKeys();

  return useQuery({
    queryFn: async (): Promise<BlueprintApi.RichCustomFlowAssigment[]> =>
      apiClient.customFlows.getAssignments([{ scope, scopeId: scopeId! }]),
    queryKey: byScope({ scope, scopeId: scopeId! }),
    enabled: !!scopeId
  });
};
