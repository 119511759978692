import { useCallback, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import union from 'lodash/union';

import { useModal } from 'hooks/modal.hooks';
import useStores from 'hooks/use-stores.hooks';
import type { EventNameType } from '@env0/notification-service/api.enum';
import type { NotificationsApi } from '@env0/notification-service/api';

const useNotificationEventsModalState = (projectId: string) => {
  const { notificationSettingsStore } = useStores();
  const { showModal, hideModal: closeModal, isModalOpen: isOpen } = useModal();
  const [eventNames, setEventNames] = useState([] as EventNameType[]);
  const [initialEventNames, setInitialEventNames] = useState([] as EventNameType[]);
  const [endpoint, setEndpoint] = useState<NotificationsApi.NotificationEndpoint | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  const noChanges = useMemo(
    () => isEqual(initialEventNames.slice().sort(), eventNames.slice().sort()),
    [initialEventNames, eventNames]
  );

  const openModal = useCallback(
    (endpoint: NotificationsApi.NotificationEndpoint, eventNames: EventNameType[]) => {
      setInitialEventNames(eventNames);
      setEventNames(eventNames);
      setEndpoint(endpoint);
      showModal();
    },
    [showModal]
  );

  const save = useCallback(async () => {
    if (!noChanges && endpoint) {
      try {
        setIsSaving(true);
        await notificationSettingsStore.updateNotificationSettings(projectId, endpoint.id, eventNames);
        closeModal();
      } finally {
        setIsSaving(false);
      }
    }
  }, [closeModal, endpoint, eventNames, notificationSettingsStore, projectId, noChanges]);

  const changeEventNameChecked = useCallback(
    (eventName: EventNameType, checked: boolean) => {
      if (checked) {
        setEventNames(eventNames => union(eventNames, [eventName]));
      } else {
        setEventNames(eventNames => difference(eventNames, [eventName]));
      }
    },
    [setEventNames]
  );

  return {
    // actions
    save,
    openModal,
    closeModal,
    changeEventNameChecked,
    // outputs
    isSaving,
    isOpen,
    eventNames,
    noChanges,
    endpoint
  };
};

export type NotificationEventsModalState = ReturnType<typeof useNotificationEventsModalState>;

export default useNotificationEventsModalState;
