import { useState } from 'react';

export interface UseUncontrolledOptions<T> {
  value?: T;
  defaultValue?: T;
  finalValue?: T;
  onChange?(value: T): void;
}

export function useUncontrolled<T>({
  value,
  defaultValue,
  finalValue,
  onChange = () => {}
}: UseUncontrolledOptions<T>): [T, (value: T) => void, boolean] {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue !== undefined ? defaultValue : finalValue);

  const handleUncontrolledChange = (value: T) => {
    setUncontrolledValue(value);
    onChange?.(value);
  };

  return value !== undefined ? [value, onChange, true] : [uncontrolledValue as T, handleUncontrolledChange, false];
}
