import React, { type Key, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { TreeDataNode } from 'antd';
import { match } from 'fuzzy';
import flow from 'lodash/flow';
import sortBy from 'lodash/sortBy';
import type { Project } from 'types/api.types';

export const NODE_HEIGHT = 42;

export enum NodeType {
  Project = 'project',
  AddProject = 'add-project'
}

export interface ProjectNode extends TreeDataNode {
  type: NodeType.Project;
  project: Project;
  renderedTitle: ReactNode;
  score: number;
  propagatedScore: number;
  children: ProjectNode[];
}

export interface AddProjectNode extends TreeDataNode {
  type: NodeType.AddProject;
  title: ReactNode;
}

const HIGHLIGHT_DELIMITER = '|:|';

const buildScoreTree = (projects: Project[], search?: string | null): ProjectNode[] => {
  return projects.map(project => {
    const children = buildScoreTree(project.children, search);
    let score: number;
    let renderedTitle: ReactNode;
    if (!search || (search.length > 6 && project.id.startsWith(search))) {
      score = 1;
      renderedTitle = project.name;
    } else {
      const result = match(search, project.name, { pre: HIGHLIGHT_DELIMITER, post: HIGHLIGHT_DELIMITER });
      score = result?.score ?? 0;
      renderedTitle =
        result?.rendered
          .split(HIGHLIGHT_DELIMITER)
          .map((part, index) => (index % 2 === 0 ? part : <b key={index}>{part}</b>)) ?? project.name;
    }
    const propagatedScore = Math.max(score, ...children.map(child => child.propagatedScore));
    return {
      type: NodeType.Project,
      key: project.id,
      title: project.name,
      disabled: !project.isReadable,
      project,
      renderedTitle,
      score,
      propagatedScore,
      children,
      'data-e2e': `navbar-projects-tree-item-${project.id}`
    };
  });
};

const filterScoreTree = (tree: ProjectNode[]): ProjectNode[] => {
  return tree
    .filter(node => node.propagatedScore > 0)
    .map(node => ({
      ...node,
      children: node.score > 0 ? node.children : filterScoreTree(node.children)
    }));
};

const sortScoreTree = (tree: ProjectNode[]): ProjectNode[] => {
  return sortBy(
    tree,
    node => -node.propagatedScore,
    node => node.project.name.toLowerCase()
  ).map(node => ({
    ...node,
    children: sortScoreTree(node.children)
  }));
};

export const buildProjectsTreeData = flow(buildScoreTree, filterScoreTree, sortScoreTree);

export const addProjectTreeNode: AddProjectNode = {
  type: NodeType.AddProject,
  key: 'add-project',
  title: <FormattedMessage id="navigation.project.add-new" />,
  className: 'navbar-projects-tree-item-add'
};

export const getSearchExpandedKeys = (tree: ProjectNode[]): Key[] => {
  return tree.flatMap(node => {
    const hasNestedMatch = node.children.some(child => child.propagatedScore > 0);
    return hasNestedMatch ? [node.key, ...getSearchExpandedKeys(node.children)] : [];
  });
};

export const getKeyPath = (projects: Record<string, Project>, targetProjectId: string): string[] => {
  const parentProjectId = projects[targetProjectId]?.parentProjectId;
  return !parentProjectId ? [] : [...getKeyPath(projects, parentProjectId), parentProjectId];
};
