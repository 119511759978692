import omitBy from 'lodash/omitBy';
import { useMemo } from 'react';
import { returnData } from 'services/api-client/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { CreateProjectPayload, ProjectWithoutChildren, UpdateProjectPayload } from 'types/api.types';
import { Policy } from 'cockatiel';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import type { RBACPermissionsAssignments } from 'stores/rq/user-permissions';
import { useGetUserPermissions } from 'stores/rq/user-permissions';
import { isProjectReadable } from 'hooks/use-has-permission';
import isError from 'lodash/isError';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import { populateProjectsChildren } from 'utils/populate-projects-children.utils';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'projects'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all'],
    modulesTesting: [...baseKey, 'modulesTesting']
  } as const;

  return cacheKeys;
};

export interface UseGetProjectsOptions {
  enabled?: boolean;
}

export const useGetModulesTestingProject = () => {
  const apiClient = useApiClient();
  const { modulesTesting } = useCacheKeys();

  return useQuery({
    queryKey: modulesTesting,
    queryFn: ({ queryKey: [orgId] }) => apiClient.projects.getModulesTestingProject(orgId)
  });
};

export const useGetProjects = ({ enabled = true }: UseGetProjectsOptions = {}) => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();

  return useQuery({
    queryKey: all,
    queryFn: async ({ queryKey: [orgId] }) => {
      if (!orgId) return [];

      const retry = Policy.handleWhen(
        // The first project is created asynchronously after the organization creation, sometimes we need to query for it multiple times until it exists
        (error: Error) => error.message === noProjectsError
      )
        .retry()
        .delay(500)
        .attempts(16);

      return Policy.wrap(retry)
        .execute(async () => {
          const { data: projects } = await apiClient.projects.findByOrganizationId(orgId);
          if (projects.length === 0) {
            throw new Error(noProjectsError);
          }
          return projects;
        })
        .catch(error => {
          if (isError(error) && error.message === noProjectsError) {
            console.warn(`${noProjectsError}, returning empty array`, orgId);
            return [];
          }
          throw error;
        });
    },
    enabled,
    placeholderData: []
  });
};

export const useCreateProject = (parentProjectId?: string) => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  const { refetch: refetchUserPermissions } = useGetUserPermissions();

  return useMutation({
    mutationFn: async (payload: CreateProjectPayload) => {
      const response = await apiClient.projects.create({ ...payload, parentProjectId }).then(returnData);
      await refetchUserPermissions();
      return response;
    },
    onSuccess: project => {
      queryClient.setQueryData(all, (projects?: ProjectWithoutChildren[]) =>
        projects ? [...projects, project] : [project]
      );
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useUpdateProject = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (request: { id: string; data: UpdateProjectPayload }) =>
      apiClient.projects.update(request.id, request.data).then(returnData),
    onSuccess: project => {
      queryClient.setQueryData(all, (projects?: ProjectWithoutChildren[]) =>
        projects?.map(p => (p.id === project.id ? project : p))
      );
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useArchiveProject = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (id: string) => apiClient.projects.archive(id).then(returnData),
    onSuccess: (_, id) => {
      queryClient.setQueryData(all, (projects?: ProjectWithoutChildren[]) => {
        return projects?.map(p => ({ ...p, isArchived: p.id === id ? true : p.isArchived }));
      });
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useMoveProject = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (request: { id: string; targetProjectId: string | null }) =>
      apiClient.projects.move(request.id, request.targetProjectId).then(returnData),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useCuratedProjects = (options?: UseGetProjectsOptions) => {
  const { data = [], isPlaceholderData, isFetched, error, refetch: refetchProjects } = useGetProjects(options);
  const {
    data: userPermissionsByScope = {},
    isPlaceholderData: isLoadingPermissions,
    refetch: refetchPermissions
  } = useGetUserPermissions();
  const curatedProjects = useMemo(() => curateProjects(data, userPermissionsByScope), [data, userPermissionsByScope]);

  return {
    isFetched,
    isLoading: isPlaceholderData || isLoadingPermissions,
    error,
    refetch: () => [refetchPermissions(), refetchProjects()],
    ...curatedProjects
  };
};

export const curateProjects = (data: ProjectWithoutChildren[], userPermissionsByScope: RBACPermissionsAssignments) => {
  const projectsWithoutChildren = keyBy(data, 'id');
  const projectsWithReadablePermission = initProjectsReadability(projectsWithoutChildren, userPermissionsByScope);
  const projects = populateProjectsChildren(projectsWithReadablePermission);
  const projectsAsArray = values(projects);
  const activeProjects = populateProjectsChildren(omitBy(projectsWithReadablePermission, 'isArchived'));
  const activeRootProjects = omitBy(activeProjects, 'parentProjectId');

  return {
    projects,
    projectsAsArray,
    activeProjects,
    activeRootProjects
  };
};

const noProjectsError = 'No projects in the organization';

const initProjectsReadability = (
  projects: Record<string, ProjectWithoutChildren>,
  userPermissionsByScope: RBACPermissionsAssignments
) => {
  return mapValues(projects, project => ({
    ...project,
    isReadable: userPermissionsByScope ? isProjectReadable(project, userPermissionsByScope) : true,
    children: []
  }));
};
