import React, { forwardRef } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'components/common/form-components';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import isString from 'lodash/fp/isString';
import styled from 'types/theme.types';

export type SubFormProps<FormType = UseFormReturnType> = {
  form: FormType;
};

type ControlledInputProps = SubFormProps & {
  name: string;
  onFocus?: () => void;
  onChange?: (...event: any[]) => void;
  hideError?: boolean;
  disabled?: boolean;
  errorSide?: 'right' | 'bottom';
  errorClassName?: string;
};

export type WithControllerProps<T> = Omit<T, 'form'> & ControlledInputProps;

export interface WithControllerOptions {
  withoutRef?: boolean;
  mapValueTo?: string;
  onChangeTransformer?: (...event: any[]) => unknown;
}

const withController = function <PropsType>(Component: React.FC<any>, options?: WithControllerOptions) {
  const ControlledComponent = forwardRef<any, WithControllerProps<PropsType>>((props, ref) => {
    const { form, name, hideError, disabled, errorSide, errorClassName, onChange, ...additionalProps } = props;

    form.watch(name);

    const error = get(form.errors, name + '.message');
    const defaultValue = get(form.getValues() as Record<string, unknown>, name);

    const transformFormProps = (formProps: ControllerRenderProps) => {
      const { ref, ...transformedProp }: ControllerRenderProps & { [key: string]: any } = formProps;

      const { mapValueTo, withoutRef, onChangeTransformer } = options || {};

      if (isString(mapValueTo)) {
        transformedProp[mapValueTo] = formProps.value;
      }

      if (!withoutRef) {
        transformedProp.ref = ref;
      }

      if (onChange || onChangeTransformer) {
        // onChangeTransformer used to transform onChange argument to the changed value, as react-hook-form expected to receive.
        // this is usually needed to integrate with external ui lib like antd, and control its components.
        transformedProp.onChange = (...args) => {
          const transformedArgs: unknown[] = onChangeTransformer ? [onChangeTransformer(...args)] : args;
          formProps.onChange(...transformedArgs); // call the controlled onChanges
          onChange?.(...args);
        };
      }

      return transformedProp;
    };

    const controlled = (
      <>
        <Controller
          render={(formProps: ControllerRenderProps) => {
            return (
              <Component
                ref={ref}
                data-e2e={`controlled-${name}`}
                invalid={error}
                hideError={true}
                disabled={form.readonly || !!disabled}
                {...transformFormProps(formProps)}
                {...additionalProps}
              />
            );
          }}
          name={name}
          control={form.control}
          defaultValue={defaultValue}
        />
        {!hideError && (
          <ErrorMessage data-e2e={`controlled-${name}-error`} className={errorClassName}>
            {error && <FormattedMessage id={error?.id || error} values={error?.values} />}
          </ErrorMessage>
        )}
      </>
    );

    return errorSide ? (
      <StyledDisplay display={errorSide === 'bottom' ? 'block' : 'flex'}>{controlled}</StyledDisplay>
    ) : (
      controlled
    );
  });

  ControlledComponent.displayName = `withController(${Component.displayName || Component.name})`;
  return ControlledComponent;
};

const StyledDisplay = styled.div<{ display: string }>`
  display: ${({ display }) => display};
  width: 100%;
`;

export default withController;
