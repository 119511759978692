import keyBy from 'lodash/fp/keyBy';
import omit from 'lodash/fp/omit';
import set from 'lodash/fp/set';
import { action, observable } from 'mobx';
import type { GitProviderTypes, Token, Tokens, TokenTypes } from 'types/api.types';
import BaseService from 'services/base-service';

export class ConfigurationStore extends BaseService {
  @observable tokens: Tokens = {};

  @action setTokens(tokens: Token[]) {
    this.tokens = keyBy('id', tokens);
  }

  @action updateToken(token: Token) {
    this.tokens = set(token.id!, token, this.tokens);
  }

  @action removeTokenAction(tokenId: string) {
    this.tokens = omit(tokenId, this.tokens);
  }

  deleteToken = async (token: Token) => {
    this.service.networkStore.startNetwork('token-delete');

    try {
      await this.service.apiClient.configurations.deleteToken(token.id!);
      await this.removeTokenAction(token.id!);
    } catch (err) {
      await this.getTokens(token.type as TokenTypes);
    } finally {
      this.service.networkStore.endNetwork('token-delete');
    }
  };

  getTokens = async (tokenType: TokenTypes) => {
    this.service.networkStore.startNetwork('tokens');
    let tokens: Token[];

    try {
      tokens = await this.service.apiClient.configurations.getTokens(
        this.service.organizationsStore.currentOrganizationId!,
        tokenType
      );

      if (tokens) this.setTokens(tokens);
    } finally {
      this.service.networkStore.endNetwork('tokens');
    }

    return tokens;
  };

  async generateGitAuthToken(gitProvider: GitProviderTypes, authCode: string): Promise<Token> {
    const token = await this.service.apiClient.configurations.generateGitAuthToken(
      this.service.organizationsStore.currentOrganizationId!,
      gitProvider,
      authCode
    );

    this.updateToken(token);
    return token;
  }
}
