import React from 'react';
import styled from 'types/theme.types';
import Card from 'components/common/card';
import ErrorContainer from 'components/common/error-container';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import useProjectSettingsCredentialsForm from 'components/projects/settings/credentials/project-settings-credentials-form.hook';
import type { CredentialType, CredentialUsageType } from 'types/api.types';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import ProjectCredentialsCardContent from 'components/projects/settings/credentials/project-credentials-card-content';
import ProjectCredentialsCardHeader from 'components/projects/settings/credentials/project-credentials-card-header';

type CredentialIdByType = Partial<Record<CredentialType, string>>;

type ProjectSettingsCredentialsCardProps = {
  usageType: CredentialUsageType;
  existingProjectCredentials: CredentialIdByType;
  onSave?: (formCredentialValues: string[]) => Promise<void>;
  docsHref: string;
  loadingCredentials: boolean;
  credentialsError: any;
  cardHeader?: EmotionJSX.Element;
  disabled?: boolean;
};

const ProjectSettingsCredentialsCard: React.FunctionComponent<ProjectSettingsCredentialsCardProps> = ({
  usageType,
  onSave = async () => {},
  docsHref,
  existingProjectCredentials,
  loadingCredentials,
  credentialsError,
  cardHeader,
  disabled
}) => {
  const form = useProjectSettingsCredentialsForm({
    usageType,
    projectCredentialsByType: existingProjectCredentials,
    onSave
  });

  if (credentialsError) {
    return <ErrorContainer errorToReport={credentialsError} />;
  }
  const CardHeader = () => <ProjectCredentialsCardHeader docsHref={docsHref} usageType={usageType} />;
  const Content = (
    <>
      <ProjectCredentialsCardContent form={form} usageType={usageType} disabled={disabled} />
      <FooterContainer>
        <ControlledSaveDiscardButtons
          form={form}
          cancelMessageId={'discard.changes'}
          inContainer={false}
          saveButtonDataE2E={`save-project-${usageType.toLowerCase()}-credentials-btn`}
        />
      </FooterContainer>
    </>
  );

  return (
    <Card title={cardHeader || <CardHeader />} loading={loadingCredentials}>
      {Content}
    </Card>
  );
};

const FooterContainer = styled.div`
  text-align: right;
  margin-top: 40px;

  button,
  a {
    width: 210px;
  }

  button:first-of-type {
    margin-right: 8px;
    margin-top: 16px;
  }
`;

export default ProjectSettingsCredentialsCard;
