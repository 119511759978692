import BaseService from 'services/base-service';
import { observable, action } from 'mobx';
import find from 'lodash/find';
import reject from 'lodash/reject';

import type { EnrichedProjectNotificationSetting } from 'types/api.types';
import type { NotificationsApi } from '@env0/notification-service/api';
import type { EventNameType } from '@env0/notification-service/api.enum';

export class NotificationSettingsStore extends BaseService {
  @observable notificationEndpoints: NotificationsApi.NotificationEndpoint[] = [];
  @observable notificationSettingsByProjectId: { [id: string]: EnrichedProjectNotificationSetting[] } = {};

  @action private setNotificationEndpoints(endpoints: NotificationsApi.NotificationEndpoint[]) {
    this.notificationEndpoints = endpoints;
  }

  @action private setProjectNotificationSettings(
    projectId: string,
    notificationSettings: NotificationsApi.ProjectNotificationSetting[]
  ) {
    this.notificationSettingsByProjectId[projectId] = this.notificationEndpoints.map(endpoint => {
      const setting = find(notificationSettings, { notificationEndpointId: endpoint.id });
      return setting ? { ...setting, endpoint } : { endpoint, notificationEndpointId: endpoint.id, eventNames: [] };
    });
  }

  async loadOrganizationNotificationEndpoints(orgId: string) {
    const endpoints = await this.service.apiClient.notifications.findEndpointsByOrganizationId(orgId);
    this.setNotificationEndpoints(endpoints);
  }

  async deleteNotificationEndpoint(endpointId: string) {
    await this.service.apiClient.notifications.deleteEndpoint(endpointId);
    this.setNotificationEndpoints(reject(this.notificationEndpoints, { id: endpointId }));
  }

  async createNewNotificationEndpoint(
    newEndpoint: Omit<NotificationsApi.CreateNotificationEndpoint.Request.Body, 'organizationId'>
  ) {
    const request = { ...newEndpoint, organizationId: this.service.organizationsStore.currentOrganizationId! };
    const createdEndpoint = await this.service.apiClient.notifications.createEndpoint(request);
    this.setNotificationEndpoints([...this.notificationEndpoints, createdEndpoint]);
  }

  async updateNotificationEndpoint(
    id: string,
    update: Omit<NotificationsApi.UpdateNotificationEndpoint.Request.Body, 'organizationId'>
  ) {
    const updateRequest = { ...update, organizationId: this.service.organizationsStore.currentOrganizationId! };
    const updatedEndpoint = await this.service.apiClient.notifications.updateEndpoint(id, updateRequest);
    this.setNotificationEndpoints(
      this.notificationEndpoints.map(endpoint => (endpoint.id === id ? updatedEndpoint : endpoint))
    );
  }

  async loadProjectNotificationSettings(projectId: string) {
    const organizationId = this.service.organizationsStore.currentOrganizationId!;
    const [settings] = await Promise.all([
      this.service.apiClient.notifications.findNotificationSettingsByProjectId(projectId),
      this.loadOrganizationNotificationEndpoints(organizationId)
    ]);
    this.setProjectNotificationSettings(projectId, settings);
  }

  async updateNotificationSettings(projectId: string, endpointId: string, eventNames: EventNameType[]) {
    const updatedSetting = await this.service.apiClient.notifications.updateNotificationSettings(
      projectId,
      endpointId,
      { eventNames }
    );

    const updatedSettings = this.notificationSettingsByProjectId[projectId].map(setting =>
      setting.notificationEndpointId === updatedSetting.notificationEndpointId ? updatedSetting : setting
    );

    this.setProjectNotificationSettings(projectId, updatedSettings);
  }
}
