import React from 'react';
import { links } from 'constants/external-links';
import Link, { setLink } from 'components/common/link';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';

const prefix = 'settings.credentials.gcp';

const GcpCredentialInputs = ({ form }: SubFormProps) => {
  return (
    <>
      <Link
        messageId="settings.credentials.gcp.credentials.explanation"
        url={links.docs.ENABLING_COST_MONITORING.CREATE_GOOGLE_CREDENTIALS}
      />
      <ControlledTextInput
        label={{ id: `${prefix}.tableId` }}
        name="value.tableId"
        form={form}
        info={{
          id: 'settings.credentials.gcp.billing.explanation',
          values: setLink(links.docs.ENABLING_COST_MONITORING.EXPORT_GOOGLE_BILLING_TO_BIGQUERY)
        }}
      />
      <ControlledTextArea
        label={{ id: `${prefix}.serviceAccountKey` }}
        name="value.secret"
        form={form}
        autoSize={{ minRows: 6, maxRows: 12 }}
      />
    </>
  );
};

export default GcpCredentialInputs;
