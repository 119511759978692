import React, { useMemo } from 'react';
import type { SshKey } from 'types/api.types';
import * as yup from 'yup';
import type { CreateSshKeyInput } from 'types/api.types';
import SshKeyModal from 'components/organizations/keys/ssh-key-modal';
import { testDuplicateNames } from 'components/organizations/keys/common/key-validations';

type CreateSshKeyModalProps = {
  open: boolean;
  onClose: (createdKey?: any) => void;
  keys: SshKey[];
  createKey: (data: CreateSshKeyInput) => Promise<SshKey>;
  isLoading: boolean;
};

const getCreateSchema = (keys: SshKey[]) =>
  yup
    .object()
    .shape({
      name: yup.string().default('').required().test('name', '', testDuplicateNames(keys, 'SSH')),
      value: yup.string().default('').required()
    })
    .defined();

const CreateSshKeyModal: React.FC<CreateSshKeyModalProps> = ({ open, onClose, keys, createKey, isLoading }) => {
  const schema = useMemo(() => getCreateSchema(keys ? keys : []), [keys]);

  return <SshKeyModal open={open} schema={schema} onClose={onClose} onSubmit={createKey} isLoading={isLoading} />;
};

export default CreateSshKeyModal;
