import type { Auth0UserProfile } from 'auth0-js';
import { useCuratedProjects } from 'stores/rq/projects';
import useStores from 'hooks/use-stores.hooks';
import { useCallback, useEffect } from 'react';
import useSubscription from './use-subscription';

export const usePermissionChangesSubscriber = () => {
  const { userStore, authStore } = useStores();

  const { refetch } = useCuratedProjects({ enabled: authStore.isAuthenticated });

  const refetchProjects = useCallback(async () => {
    // need to refresh the token to get the new permissions
    await authStore.silentRenewSession();
    refetch?.();
  }, [authStore, refetch]);

  useSubscription({
    stream: 'permissionsChanged',
    key: userStore.profile?.sub,
    onEvent: refetchProjects,
    skipInitEvent: true
  });
};
