import { returnData, type HttpClient } from 'services/api-client/client';
import type { EnvironmentApi } from '@env0/environment-service/api';

export default (client: HttpClient) => ({
  getDriftStatus: async (deploymentLogId: string) =>
    client
      .get<EnvironmentApi.FindDeploymentDriftStatus.Response>(
        `environments/deployments/${deploymentLogId}/drift-status`
      )
      .then(returnData)
});
