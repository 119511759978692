import type { RolesApi } from '@env0/role-service/api';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import {
  useGetTeamRoleAssignments,
  useRemoveTeamRoleAssignment,
  useUpsertTeamRoleAssignment
} from 'components/common/role-assignments/use-role-assignments';
import type { TeamRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { ScopeId } from 'components/common/role-assignments/types';

export const useSaveTeamRoleAssignments = (scopeId: ScopeId) => {
  const intl = useIntl();
  const { refetch: refetchTeamRoleAssignments } = useGetTeamRoleAssignments(scopeId);
  const { mutateAsync: upsertTeamRoleAssignment } = useUpsertTeamRoleAssignment(scopeId);
  const { mutateAsync: removeTeamRoleAssignment } = useRemoveTeamRoleAssignment(scopeId);

  const onSave = async (
    rowsToAssign: TeamRoleAssignmentRow[],
    rowsToUpdate: TeamRoleAssignmentRow[],
    rowsToRemove: TeamRoleAssignmentRow[]
  ) => {
    try {
      const upsertAssignmentsPromises: Promise<RolesApi.TeamRoleAssignment>[] = [...rowsToAssign, ...rowsToUpdate].map(
        row => {
          return upsertTeamRoleAssignment({ teamId: row.id, role: row.role! });
        }
      );
      const removeAssignmentPromises: Promise<RolesApi.TeamRoleAssignment>[] = rowsToRemove.map(row => {
        return removeTeamRoleAssignment(row.id);
      });

      await Promise.all([...upsertAssignmentsPromises, ...removeAssignmentPromises]);
      await refetchTeamRoleAssignments();
    } catch (error) {
      const scopeName = Object.keys(scopeId)[0].replace('Id', '');
      notification.error({
        message: intl.formatMessage({ id: 'roles.upsert.teams.failed' }, { scope: scopeName })
      });
      console.error(error);
    }
  };
  return { onSave };
};
