import React, { useCallback } from 'react';

import SecretReferenceDropdown from 'components/common/secret-reference/secret-reference-dropdown';
import SecretReferenceInput from 'components/common/secret-reference/secret-reference-input';
import { isSecretReference } from '@env0/common-secret/secret-reference';
import TextArea from 'components/common/input-components/textarea';
import TextInput from 'components/common/input-components/text-input';
import styled from 'types/theme.types';
import useStores from 'hooks/use-stores.hooks';

export type SensitiveTextInputProps = {
  onChange: (value: string) => void;
  textArea?: boolean;
} & (React.ComponentProps<typeof TextArea> | React.ComponentProps<typeof TextInput>);

const SensitiveTextInput: React.FC<SensitiveTextInputProps> = ({ onChange, textArea = false, ...inputProps }) => {
  const [value, setValue] = React.useState('');

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  const {
    organizationsStore: {
      currentOrganization: { allowEnv0Secrets }
    }
  } = useStores();

  const onTextInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      updateValue(event.target.value);
    },
    [updateValue]
  );

  const onDropdownChange = useCallback(
    (stringValue: string, _shouldEncrypt: boolean) => {
      // When dropdown changed - we update value template
      updateValue(stringValue);
    },
    [updateValue]
  );

  const textProps = { value, onChange: onTextInputChange, 'data-e2e': 'sensitive-text-input' };
  return (
    <>
      <StyledSecretReferenceDropdown
        value={value}
        enablePlainText={false}
        isEnv0Secret={!isSecretReference(value) && allowEnv0Secrets}
        onChange={onDropdownChange}
        data-e2e={'sensitive-dropdown'}
      />
      {isSecretReference(value) ? (
        <SecretReferenceInput {...textProps} />
      ) : textArea ? (
        <TextArea
          autoSize={{ minRows: 12, maxRows: 12 }}
          {...(inputProps as React.ComponentProps<typeof TextArea>)}
          {...textProps}
        />
      ) : (
        <TextInput {...(inputProps as React.ComponentProps<typeof TextInput>)} {...textProps} />
      )}
    </>
  );
};

const StyledSecretReferenceDropdown = styled(SecretReferenceDropdown)`
  position: absolute;
  right: 0;

  // otherwise this will have 100% width due to being in the form
  width: unset !important;
`;

export default SensitiveTextInput;
