import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormButtonsContainer from 'components/common/buttons-container';
import type { CustomButtonProps } from 'components/common/button';
import Button from 'components/common/button';

type SaveDiscardButtonsProps = {
  discardButton: CustomButtonProps;
  saveButton: CustomButtonProps;
  isInline?: boolean;
};

const SaveDiscardButtons: React.FunctionComponent<SaveDiscardButtonsProps> = ({
  discardButton,
  saveButton,
  isInline
}) => {
  return (
    <FormButtonsContainer isInline={isInline} data-e2e={`save-discard-buttons`}>
      <Button data-e2e={`button-discard`} {...discardButton}>
        <FormattedMessage id="discard.changes" />
      </Button>
      <Button data-e2e={`button-save`} type="primary" {...saveButton}>
        <FormattedMessage id="save.changes" />
      </Button>
    </FormButtonsContainer>
  );
};

export default SaveDiscardButtons;
