// tslint:disable
// this is an auto generated file. This will be overwritten

export const onAnyEventPushed = /* GraphQL */ `
  subscription OnAnyEventPushed($stream: String!) {
    onAnyEventPushed(stream: $stream) {
      id
      stream
      key
      description
      projectId
      projectRole
      organizationId
      organizationRole
      createdAt
    }
  }
`;
