import { useMemo } from 'react';
import useStores from 'hooks/use-stores.hooks';
import type { WizardStepConfig } from 'components/common/use-wizard-navigator.hook';
import useWizardNavigator from 'components/common/use-wizard-navigator.hook';
import type {
  EnvironmentDetailsValues,
  TemplateSettingsStepCommonValues,
  TemplateWizardStep,
  VCSStepValues
} from 'components/templates/templates-wizard/common/template-wizard.types';
import { bindSshKeysField } from 'components/templates/templates-wizard/common/template-wizard-utils';
import type { RunEnvironmentArgs } from 'utils/environment.utils';
import type { Blueprint } from 'types/api.types';
import type { CredentialFormValues } from 'components/organizations/credentials/credential-modal-form.hook';
import type useCredentialModalForm from 'components/organizations/credentials/credential-modal-form.hook';
import type { EnvironmentDiscoveryMappingFormReturnType } from 'components/projects/settings/environment-discovery/use-environment-discovery-form';
import type useVcsStepForm from './step-forms/vcs-step-form.hook';
import type useSettingsStepForm from './step-forms/settings-step-form.hook';
import type useEnvironmentDetailsStepForm from './step-forms/environment-details-step-form.hook';
import type { WizardStepName } from 'components/common/steps';
import { stepToEventMapper } from 'components/templates/templates-wizard/common/steps-to-event-mapper';
import type { EventNames } from 'utils/analytics.utils';

export type EnvironmentDetailsPayload = EnvironmentDetailsValues &
  Pick<RunEnvironmentArgs, 'changedConfigurationProperties' | 'ttlRequest' | 'configurationSetChanges'>;

export type WizardSummary = {
  environmentDetails: EnvironmentDetailsPayload;
  templateFormValues: TemplateSettingsStepCommonValues & VCSStepValues & { name: string };
  credentialsValues?: CredentialFormValues;
  projectId: string;
};

export type UseTemplateWizardNavigationProps = {
  template?: Blueprint;
  isOnboarding?: boolean;
  onStepMoved?: (oldStep: TemplateWizardStep, newStep: TemplateWizardStep) => void;
  onSkip?: (step: TemplateWizardStep) => Promise<void>;
  onSubmit: (values: WizardSummary) => void | Promise<void>;
  steps: TemplateWizardStep[];
  mappingStepForm?: EnvironmentDiscoveryMappingFormReturnType;
  vcsStepForm: ReturnType<typeof useVcsStepForm>;
  settingsStepForm: ReturnType<typeof useSettingsStepForm<TemplateSettingsStepCommonValues>>;
  credentialsForm?: ReturnType<typeof useCredentialModalForm>;
  environmentDetailsForm?: ReturnType<typeof useEnvironmentDetailsStepForm>;
};

const useTemplateWizardNavigation = ({
  template,
  onSubmit,
  onStepMoved,
  onSkip,
  steps,
  mappingStepForm,
  vcsStepForm,
  settingsStepForm,
  credentialsForm,
  environmentDetailsForm
}: UseTemplateWizardNavigationProps) => {
  const { configurationPropertiesStore } = useStores();
  const stepToConfig: WizardStepConfig = useMemo(
    () => ({
      settings: settingsStepForm,
      vcs: vcsStepForm,
      credentials: credentialsForm,
      variables: configurationPropertiesStore,
      'environment-details': environmentDetailsForm,
      mapping: mappingStepForm
    }),
    [
      settingsStepForm,
      vcsStepForm,
      credentialsForm,
      configurationPropertiesStore,
      environmentDetailsForm,
      mappingStepForm
    ]
  );
  const isEditMode = !!template;
  const templateWizardNavigator = useWizardNavigator({
    isEditMode,
    stepToConfig,
    steps,
    onSubmit: onSubmit as () => void | Promise<void>,
    onSkip: onSkip as (step: WizardStepName) => void | Promise<void>,
    onStepMoved: onStepMoved as (oldStep: WizardStepName, newStep: WizardStepName) => void,
    eventTracking: {
      stepToEventMapper: stepToEventMapper as Record<WizardStepName, EventNames>,
      eventProperties: { isNewTemplate: !isEditMode }
    }
  });

  bindSshKeysField(settingsStepForm, vcsStepForm, templateWizardNavigator.currentStep as TemplateWizardStep);

  return templateWizardNavigator;
};

export type SingleUseTemplateWizardFormHook = ReturnType<typeof useTemplateWizardNavigation>;
export default useTemplateWizardNavigation;
