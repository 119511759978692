import type { TeamRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { TeamApi } from '@env0/team-service/api';
import type { RolesApi } from '@env0/role-service/api';

export const convertToTeamRoleAssignmentsRows = (
  teams: TeamApi.Team[],
  teamRoleAssignments: RolesApi.TeamRoleAssignment[]
): TeamRoleAssignmentRow[] =>
  teams.map(team => {
    const teamRoleAssignment = teamRoleAssignments.find(assignment => assignment.teamId === team.id);

    return {
      id: team.id,
      team,
      role: teamRoleAssignment?.role,
      assigned: !!teamRoleAssignment,
      initialData: {
        role: teamRoleAssignment?.role,
        assigned: !!teamRoleAssignment
      }
    };
  });
