import type { Project } from 'types/api.types';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

const HIERARCHY_DELIMITER = '|';
export const isParentProject = (project: Project) => !isEmpty(project?.children);
export const isSubProject = (project: Project) => !isUndefined(project?.parentProjectId);
export const getIdsOfProjectHierarchy = (project: Project) => project?.hierarchy.split(HIERARCHY_DELIMITER);
export const getRedirectUrlForProject = (project: Project) => {
  return `/p/${project.id}/environments`;
};

export const getProjectNameHierarchy = (projectId: string, projects: Record<string, Project>) => {
  const project = projects[projectId];
  if (!project) return null;
  const hierarchy = getIdsOfProjectHierarchy(project);
  return hierarchy.map(id => projects[id]?.name ?? id).join(' / ');
};
