import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from 'components/common/modal';
import Checkbox from 'components/common/input-components/checkbox';
import Button from 'components/common/button';
import { getEventDisplayText } from 'components/projects/settings/notifications/event-names-display-text';
import styled from 'types/theme.types';
import type { NotificationEventsModalState } from 'components/projects/settings/notifications/notification-events-modal-state.hook';
import { EventNameType } from '@env0/notification-service/api.enum';

type Props = { state: NotificationEventsModalState };

const allEventNames = Object.values(EventNameType);
const NotificationEventsModal: React.FunctionComponent<Props> = ({ state }) => {
  const intl = useIntl();
  const titleComponent = (
    <TitleContainer>
      <Title data-e2e="notification-events-modal-title">{state.endpoint?.name}</Title>
      <SubTitle data-e2e="notification-events-modal-subtitle">{state.endpoint?.type}</SubTitle>
    </TitleContainer>
  );
  const modalFooter = (
    <>
      <Button data-e2e="notification-events-cancel-button" onClick={state.closeModal} disabled={state.isSaving}>
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        data-e2e="notification-events-save-button"
        isLoading={state.isSaving}
        type="primary"
        onClick={state.save}
        disabled={state.noChanges}>
        <FormattedMessage id="save.changes" />
      </Button>
    </>
  );
  const eventNamesComponent = (
    <>
      <EventNamesTitleContainer>
        <FormattedMessage id="projects.settings.notifications.modal.events.title" />
      </EventNamesTitleContainer>
      <EventNamesContainer>
        {allEventNames.map(eventName => (
          <EventCheckbox
            key={eventName}
            checked={state.eventNames.includes(eventName)}
            onChange={e => state.changeEventNameChecked(eventName, e.target.checked)}
            data-e2e={`notification-event-${eventName}`}>
            {getEventDisplayText(intl, eventName)}
          </EventCheckbox>
        ))}
      </EventNamesContainer>
    </>
  );
  return (
    <Modal
      title={titleComponent}
      open={state.isOpen}
      onCancel={state.closeModal}
      footer={modalFooter}
      destroyOnClose
      maskClosable={false}
      centered>
      {eventNamesComponent}
    </Modal>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.primaryBlue};
  font-weight: 500;
  margin-top: 19px;
`;

const Title = styled.span`
  font-size: 24px;
`;

const SubTitle = styled.span`
  margin-top: 10px;
  font-size: 19px;
`;

const EventNamesTitleContainer = styled.div`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.primaryBlue};
  font-weight: 300;
  font-size: 15px;
`;

const EventCheckbox = styled(Checkbox)`
  margin-left: 0 !important;
`;

const EventNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default NotificationEventsModal;
