import { INHERIT_FROM_ORG } from 'constants/ttl.constants';
export type TtlPolicy = {
  maxTtl: string | null;
  defaultTtl: string | null;
};

export type TtlTimeUnit = 'M' | 'w' | 'd' | 'h';

export const timeUnitToName = {
  M: 'project.settings.policies.ttl.months',
  w: 'project.settings.policies.ttl.weeks',
  d: 'project.settings.policies.ttl.days',
  h: 'project.settings.policies.ttl.hours'
};

export const validTimeUnits = ['M', 'w', 'd', 'h'] as TtlTimeUnit[];

export const INIT_TTL_VALUE = '12-h';

export const getTtlNumber = (ttlValue: string | null): string | undefined =>
  ttlValue === INHERIT_FROM_ORG ? '12' : ttlValue?.split('-')[0];
export const getTtlTimeUnit = (ttlValue: string | null): string | undefined =>
  ttlValue === INHERIT_FROM_ORG ? 'h' : ttlValue?.split('-')[1];
