import React, { useMemo } from 'react';
import type { SchedulingApi } from '@env0/scheduling-service/api';
import styled from 'types/theme.types';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/common/tooltip';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import cronstrue from 'cronstrue';
import type { ScheduledAction } from 'components/environments/drift-detection/drift-detection-form.hook';
import Info from 'components/common/info';
import type { MenuProps } from 'antd';

export type ControlledScheduledActionFieldProps = {
  form: UseFormReturnType;
  cronPropertyName?: string;
  type: SchedulingApi.ActionType;
  isAuthorized: boolean;
  disabled?: boolean;
  tooltipTextId?: string;
  infoTooltip?: string | React.ReactNode;
};

const ControlledScheduledActionField: React.FunctionComponent<ControlledScheduledActionFieldProps> = ({
  form,
  cronPropertyName,
  type,
  isAuthorized,
  disabled,
  tooltipTextId,
  infoTooltip
}) => {
  const lowerType = type.toLowerCase();
  const { enabled, cron } = (cronPropertyName ? form.watch(cronPropertyName) : form.watch()) as ScheduledAction;
  const cronValueFormKey = cronPropertyName ? `${cronPropertyName}.cron` : 'cron';
  const cronEnabledFormKey = cronPropertyName ? `${cronPropertyName}.enabled` : 'enabled';
  const description = useMemo(() => {
    try {
      if (enabled) {
        return cronstrue.toString(cron);
      }
      return '';
    } catch (_) {
      return '';
    }
  }, [enabled, cron]);

  const cronMenu = useMemo<MenuProps>(() => {
    return {
      items: cronAutocompletes.map((item, index) => ({
        key: index,
        label: item.label,
        onClick: () => form.setValue(cronValueFormKey as never, item.cron)
      }))
    };
  }, [form, cronValueFormKey]);

  const inputs = (
    <AlignedInput>
      <CheckboxContainer>
        <ControlledBigCheckbox
          form={form}
          data-e2e={`scheduled-action-checkbox-${lowerType}`}
          disabled={!isAuthorized || disabled}
          name={cronEnabledFormKey}>
          <FormattedMessage id={`environment.scheduling.${lowerType}.checkbox`} />
        </ControlledBigCheckbox>
      </CheckboxContainer>
      <InputContainer>
        <ControlledTextInput
          data-e2e={`scheduled-action-input-${lowerType}`}
          disabled={!enabled || !isAuthorized || disabled}
          form={form}
          name={cronValueFormKey}
          hideError={false}
          errorSide="bottom"
          menu={cronMenu}
        />
      </InputContainer>
      {infoTooltip && (
        <InfoContainer>
          <Info tooltip={infoTooltip} />
        </InfoContainer>
      )}
      <DescriptionContainer>
        <div data-e2e="scheduled-action-schedule-text">{description}</div>
      </DescriptionContainer>
    </AlignedInput>
  );
  return (
    <Container>
      {tooltipTextId ? (
        <Tooltip titleId={tooltipTextId} placement="top" data-e2e={'scheduled-action-tooltip'}>
          <TooltipChildContainer>{inputs}</TooltipChildContainer>
        </Tooltip>
      ) : (
        inputs
      )}
    </Container>
  );
};

const cronAutocompletes: Array<{ label: string; cron: string }> = [
  {
    label: 'Once an hour',
    cron: '0 * * * *'
  },
  {
    label: 'Once a day',
    cron: '0 0 * * *'
  },
  {
    label: 'Once a month',
    cron: '0 0 1 * *'
  }
];

const TooltipChildContainer = styled.div`
  display: flex;
`;

const InfoContainer = styled.div`
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

export const AlignedInput = styled.div`
  display: flex;
  align-items: baseline;
`;

const DescriptionContainer = styled.div`
  font-size: 12px;
  margin-left: 0.5em;
`;

const CheckboxContainer = styled.div`
  margin-right: 1em;
`;

const InputContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
`;

export default ControlledScheduledActionField;
