import type { ReactNode } from 'react';
import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { useIntl } from 'react-intl';
import type { TooltipPlacement } from 'antd/lib/tooltip';
import type { O } from 'ts-toolbelt';

export type TooltipProps = O.Either<
  {
    title: string | ReactNode | (() => ReactNode);
    titleId: string;
    titleValues?: Record<string, any>;
    placement: TooltipPlacement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    children?: ReactNode;
  },
  'title' | 'titleId'
>;

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  title,
  titleId,
  children,
  placement,
  titleValues,
  open,
  onOpenChange
}) => {
  const intl = useIntl();

  const antdTitle = titleId ? intl.formatMessage({ id: titleId }, titleValues) : title ?? '';

  return (
    <AntdTooltip
      overlayStyle={{ whiteSpace: 'pre-line' }}
      title={antdTitle}
      placement={placement}
      open={open}
      onOpenChange={onOpenChange}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
