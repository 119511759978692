import isNil from 'lodash/fp/isNil';
import isBoolean from 'lodash/isBoolean';

interface PopupProps {
  url: string;
  onClosed?: () => void;
  onBlock?: () => void;
  features?: {
    [key: string]: string | boolean | number;
  };
}

export class Popup {
  private readonly url: string;
  private readonly onClosed: (() => void) | undefined;
  private readonly onBlock: (() => void) | undefined;
  private popup: Window | null = null;
  private windowCheckerInterval: number | null = null;
  private features: any;

  constructor({ url, onClosed, onBlock, features }: PopupProps) {
    this.url = url;
    this.onClosed = onClosed;
    this.onBlock = onBlock;
    this.features = features ?? {};

    if (window.top === null) {
      this.features.left = 0;
      this.features.top = 0;
    } else {
      this.features.left = window.top.outerWidth / 2 + window.top.screenX - ((features?.width as number) ?? 700) / 2;
      this.features.top = window.top.outerHeight / 2 + window.top.screenY - ((features?.height as number) ?? 700) / 2;
    }
  }

  static open(props: PopupProps) {
    return new Popup(props).open();
  }

  public open() {
    this.popup = window.open(this.url, 'popup', this.toWindowFeatures());

    if (isNil(this.popup)) {
      this.onBlock?.();
      return this;
    }

    this.windowCheckerInterval = window.setInterval(() => {
      if (this.popup?.closed) {
        this.release();
      }
    }, 100);

    return this;
  }

  public close() {
    this.popup?.close();
    this.release();
  }

  private toWindowFeatures() {
    return Object.keys(this.features)
      .reduce((features: string[], name) => {
        const value = this.features[name];
        if (isBoolean(value)) {
          features.push(`${name}=${value ? 'yes' : 'no'}`);
        } else {
          features.push(`${name}=${value}`);
        }

        return features;
      }, [])
      .join(',');
  }

  private release() {
    window.clearInterval(this.windowCheckerInterval as number);
    this.onClosed?.();
  }
}
