import React, { forwardRef } from 'react';
import type { ColProps as AntdColProps, RowProps as AntdRowProps } from 'antd';
import { Row as AntdRow, Col as AntdCol } from 'antd';

interface RowProps extends AntdRowProps {}

interface ColProps extends AntdColProps {}

export const Row = forwardRef<HTMLDivElement, RowProps>(({ children, ...rest }, ref) => {
  return (
    <AntdRow ref={ref} {...rest}>
      {children}
    </AntdRow>
  );
});

Row.displayName = 'Row';

export const Col = forwardRef<HTMLDivElement, ColProps>(({ children, ...rest }, ref) => {
  return (
    <AntdCol ref={ref} {...rest}>
      {children}
    </AntdCol>
  );
});

Col.displayName = 'Col';
