import React from 'react';
import useStores from 'hooks/use-stores.hooks';
import { observer } from 'mobx-react';
import TopBarView from 'components/layout/topbar/topbar-view';
import { useTopBarTitle } from 'components/layout/topbar/topbar-title.hook';
import type { BreadcrumbSelector } from 'components/breadcrumbs/use-breadcrumbs';

interface Props {
  title?: string;
  page?: string;
  breadcrumbs?: BreadcrumbSelector;
}

const TopBar: React.FC<Props> = ({ title, page, breadcrumbs }) => {
  const { userStore } = useStores();
  const fullTitle = useTopBarTitle(title, page);

  return <TopBarView profile={userStore.profile} title={fullTitle} breadcrumbs={breadcrumbs} />;
};

export default observer(TopBar);
