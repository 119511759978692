import type { ThemeConfig } from 'antd';
import theme from 'constants/themes.constants';

const themeConfig: ThemeConfig = {
  token: {
    borderRadius: 4,
    colorBgContainerDisabled: theme.primaryGray,
    colorBgMask: theme.modalMaskGray,
    colorError: theme.errorRed,
    colorPrimary: theme.primaryGreen,
    colorInfo: theme.primaryGreen,
    colorText: theme.textBaseGray,
    colorLink: theme.primaryBlue,
    colorLinkHover: theme.primaryBlue,
    colorLinkActive: theme.primaryBlue,
    fontFamily: "'ProximaNova', sans-serif",
    fontSize: 14
  },
  components: {
    Button: {
      colorText: theme.primaryBlue,
      colorBgContainer: theme.primaryWhite,
      colorBorder: theme.primaryBlue,
      lineHeight: 1 // 100% of font size
    },
    Card: {
      boxShadowTertiary: 'none'
    },
    Collapse: {
      colorBgContainer: theme.primaryWhite,
      colorFillAlter: theme.primaryWhite,
      paddingSM: 24,
      padding: 24,
      colorTextHeading: theme.textBaseGray
    },
    Dropdown: {
      fontSize: 12
    },
    Input: {
      controlHeight: 25
    },
    Menu: {
      itemSelectedBg: theme.menuItemBlue,
      itemMarginInline: 0,
      itemBorderRadius: 0
    },
    Radio: {
      buttonColor: theme.primaryBlue
    },
    Select: {
      colorBorder: 'black',
      controlHeight: 25,
      controlHeightSM: 25,
      fontSize: 12
    },
    InputNumber: {
      controlHeight: 25
    },
    Table: {
      colorFillAlter: theme.primaryWhite,
      colorTextHeading: theme.primaryBlue,
      fontWeightStrong: 500
    },
    Tabs: {
      cardBg: theme.backgroundGray,
      colorBorder: 'transparent'
    },
    Tooltip: {
      colorBgDefault: theme.primaryBlack
    }
  }
};

export default themeConfig;
