import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/common/button';
import { SelectOption } from 'components/common/input-components/select';
import AddNewTokenModal from 'components/organizations/credentials/tokens/add-new-token-modal';
import { useModal } from 'hooks/modal.hooks';

import map from 'lodash/fp/map';

import isEmpty from 'lodash/fp/isEmpty';
import has from 'lodash/fp/has';

import type { GitProviderTypes, Token, Tokens } from 'types/api.types';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { observer } from 'mobx-react';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import type { WithLabelProps } from 'components/common/form-controlled/with-label.hoc';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { isGitProviderUsingToken } from 'utils/vcs.utils';

interface Props extends WithLabelProps<SubFormProps<VCSForm>> {
  gitProvider: GitProviderTypes;
  tokens: Tokens;
}

const getTokensSelectOptions = (tokens: Tokens, selectedTokenId?: string) => {
  if (isEmpty(tokens)) {
    return (
      <SelectOption key="no-tokens" disabled value="">
        <FormattedMessage id="templates.add.new.field.token.empty" />
      </SelectOption>
    );
  }

  const tokensTemplate = map(
    (token: Token) => (
      <SelectOption key={`token-option-${token.id}`} value={token.id}>
        {token.name}
      </SelectOption>
    ),
    tokens
  );

  if (!selectedTokenId || has(selectedTokenId, tokens)) return tokensTemplate;

  return [...tokensTemplate];
};

const TokenSelectField: React.FunctionComponent<Props> = ({ form, gitProvider, tokens, ...labelProps }) => {
  const showAddModal = gitProvider === 'Other';

  const [tokenId, setTokenId] = form.useManualField<string | undefined>('tokenId');

  const { hideModal, showModal, isModalOpen } = useModal();

  const onTokenChanged = (id: string) => {
    setTokenId(id);
    if (isGitProviderUsingToken(gitProvider)) {
      form.setValue('repository', undefined, { shouldValidate: false });
    }
  };

  return (
    <>
      <ControlledSelect
        form={form}
        name="tokenId"
        data-e2e="template-token-select"
        placeholder={
          <FormattedMessage id={`templates.add.new.field.token.placeholder${showAddModal ? '.allow.add' : ''}`} />
        }
        value={tokenId && has(tokenId, tokens) ? tokenId : undefined}
        customActions={
          showAddModal && (
            <Button onClick={showModal} type="primary" size="small">
              <FormattedMessage id="settings.tokens.add.new.title" />
            </Button>
          )
        }
        {...labelProps}>
        {getTokensSelectOptions(tokens, tokenId)}
      </ControlledSelect>
      {showAddModal && (
        <AddNewTokenModal
          data-e2e="add-new-token-modal"
          open={isModalOpen}
          hideModal={hideModal}
          onTokenCreated={async id => {
            onTokenChanged(id);
          }}
        />
      )}
    </>
  );
};

export default observer(TokenSelectField);
