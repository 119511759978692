import { useQuery, useMutation } from '@tanstack/react-query';
import type { CreateSshKeyInput, SshKey, UpdateSshKeyInput } from 'types/api.types';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'ssh-keys'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all']
  } as const;

  return cacheKeys;
};

export const useGetSshKeys = () => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();

  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.configurations.getSshKeys(orgId)
  });
};

export const useCreateSshKey = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();
  const [orgId] = all;

  return useMutation({
    mutationFn: (sshKey: CreateSshKeyInput) => apiClient.configurations.createSshKey(sshKey, orgId),
    onSuccess: sshKey => {
      queryClient.setQueryData(all, (data?: SshKey[]) => data && [...data, sshKey]);
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useUpdateSshKey = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (request: { id: string; data: UpdateSshKeyInput }) =>
      apiClient.configurations.updateSshKey(request.id, request.data),
    onSuccess: updatedSshKey => {
      queryClient.setQueryData(all, (data?: SshKey[]) =>
        data?.map(sshKey => (sshKey.id === updatedSshKey.id ? updatedSshKey : sshKey))
      );
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useRemoveSshKey = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all } = useCacheKeys();

  return useMutation({
    mutationFn: (id: string) => apiClient.configurations.deleteSshKey(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(all, (data?: SshKey[]) => data?.filter(sshKey => sshKey.id !== id));
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};
