import React from 'react';
import { Alert, Row, Space } from 'antd';
import { useGitProvider } from 'components/templates/templates-wizard/vcs/use-git-provider.hook';
import Loader from 'components/common/loader';
import RadioButtons from 'components/common/radio-buttons';
import VcsFormFields from 'components/templates/templates-wizard/vcs/template-wizard-step-vcs.fields';
import type { GitProviderTypes } from 'types/api.types';
import VcsIntegrationWindow from 'components/templates/templates-wizard/vcs/vcs-integration-window';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import { BlockingBackground } from 'components/common/blocking-background';
import { getAllProviderButtons } from 'components/common/git-providers-radio-buttons';
import styled from 'types/theme.types';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { FaceRobot } from 'components/onboarding/face-robot';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { FormattedMessage } from 'react-intl';
import { BulbFilled } from '@ant-design/icons';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';

export type TemplateWizardStepVCSProps = {
  vcsForm: VCSForm;
  templateType: BlueprintApi.BlueprintType;
  isNewTemplate?: boolean;
  vcsRepositoryDisabled?: boolean;
  vcsRevisionDisabled?: boolean;
  showFriendlyRobot?: boolean;
  showWebhookSection?: boolean;
  CustomBlockingBackground?: typeof BlockingBackground;
  showLocationSection?: boolean;
  excludedProviders?: GitProviderTypes[];
};

const TemplateWizardStepVCS: React.FunctionComponent<TemplateWizardStepVCSProps> = ({
  vcsForm,
  templateType,
  isNewTemplate,
  vcsRepositoryDisabled,
  vcsRevisionDisabled,
  showFriendlyRobot,
  showWebhookSection = true,
  showLocationSection = true,
  CustomBlockingBackground = BlockingBackground,
  excludedProviders = []
}) => {
  const {
    gitProvider,
    isFormBlocked,
    isLoadingRepositories,
    providerBeingIntegrated,
    repositories,
    startVcsIntegration,
    onVcsIntegrationSuccess,
    onVcsIntegrationBlocked,
    onClickGitProvider
  } = useGitProvider(vcsForm);

  const { organizationsStore } = useStores();

  const providerButtons = getAllProviderButtons(templateType, excludedProviders).filter(
    ({ isForSelfHostedOrg }) => organizationsStore.isCurrentOrganizationSelfHosted || !isForSelfHostedOrg
  );

  const content = isFormBlocked ? (
    <Loader />
  ) : !gitProvider ? (
    <Space />
  ) : (
    <VcsFormFields
      data-e2e="vcs-step-fields"
      gitProvider={gitProvider}
      form={vcsForm}
      repositories={repositories}
      isLoadingRepositories={isLoadingRepositories}
      startVcsIntegration={startVcsIntegration}
      templateType={templateType}
      isNewTemplate={isNewTemplate}
      showWebhookSection={showWebhookSection}
      vcsRepositoryDisabled={vcsRepositoryDisabled}
      vcsRevisionDisabled={vcsRevisionDisabled}
      showLocationSection={showLocationSection}
    />
  );

  return (
    <div>
      {isFormBlocked && <CustomBlockingBackground />}
      <RobotInRightContainer>
        <Row align="middle" justify="center">
          <ProviderRadioButtons
            data-e2e="vcs-step-radio-buttons"
            onChange={v => onClickGitProvider(v as GitProviderTypes)}
            value={gitProvider}
            buttons={providerButtons}
            size="huge"
            disabled={vcsForm.readonly}
          />
        </Row>
        {showFriendlyRobot && <VcsFaceRobot wizardStep="vcs" vcs={gitProvider!} />}
      </RobotInRightContainer>
      {gitProvider === 'HelmRepository' && (
        <StyledAlert
          showIcon
          icon={<BulbFilled />}
          type="warning"
          message={
            <FormattedMessage
              id="templates.wizard.step.vcs.helmRepo.warning"
              values={setLink(links.docs.TEMPLATES.PROJECT_LEVEL_CUSTOM_FLOWS)}
            />
          }
        />
      )}
      {content}
      {providerBeingIntegrated && (
        <VcsIntegrationWindow
          data-e2e="vcs-integration-window"
          gitProvider={providerBeingIntegrated}
          onSuccess={onVcsIntegrationSuccess}
          onBlocked={onVcsIntegrationBlocked}
        />
      )}
    </div>
  );
};

const RobotInRightContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const VcsFaceRobot = styled(FaceRobot)`
  flex: none;
`;

const ProviderRadioButtons = styled(RadioButtons)`
  flex-basis: 1000px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default observer(TemplateWizardStepVCS);
