import React from 'react';
import Select, { SelectOption } from 'components/common/input-components/select';
import styled from 'types/theme.types';
import type { Role } from 'types/api.types';
import type { RolesApi } from '@env0/role-service/api';

interface Props {
  selectedRole?: RolesApi.RBACPermissionRole;
  isEditable: boolean;
  displayRole: boolean;
  onChangeRole: (role: string) => void;
  tab: 'users' | 'teams' | 'api-keys';
  defaultRoles: Partial<Role>[];
  customRoles: Partial<Role>[];
  dataE2ePrefix: string;
}

const DELIMITER: Partial<Role> = { id: '------------', name: '------------' };

const RoleSelector: React.FunctionComponent<Props> = ({
  displayRole,
  selectedRole,
  isEditable,
  onChangeRole,
  tab,
  defaultRoles,
  customRoles,
  dataE2ePrefix
}) => {
  if (!isEditable) {
    const selectedCustomRole = customRoles.find(role => role.id === selectedRole);
    const roleName = selectedCustomRole ? selectedCustomRole.name : selectedRole;

    return (
      <RoleSelectorContainer data-e2e={`${dataE2ePrefix}-${tab}-role-select-fixed`}>{roleName}</RoleSelectorContainer>
    );
  }

  if (!displayRole) {
    return <RoleSelectorContainer data-e2e={`${dataE2ePrefix}-${tab}-role-select-empty`} />;
  }

  const selectableRoles = defaultRoles.length
    ? defaultRoles.concat(customRoles.length ? [DELIMITER, ...customRoles] : [])
    : customRoles;

  return (
    <Select data-e2e={`${dataE2ePrefix}-${tab}-role-select`} value={selectedRole} onChange={onChangeRole}>
      {selectableRoles.map(role => (
        <SelectOption
          key={role.id}
          data-e2e={`${dataE2ePrefix}-${tab}-role-select-${role.name}`}
          value={role.id}
          disabled={role === DELIMITER}>
          {role.name}
        </SelectOption>
      ))}
    </Select>
  );
};
const RoleSelectorContainer = styled.div`
  height: 26px;
`;

export default RoleSelector;
