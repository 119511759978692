import styled from 'types/theme.types';

export const BlockingBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.1;
`;
