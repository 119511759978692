import { useCallback, useMemo, useState } from 'react';
import type { UserRoleAssignmentRow } from 'components/common/role-assignments/types';
import type { RolesApi } from '@env0/role-service/api';
import filter from 'lodash/filter';
import type { Role } from 'types/api.types';
import { useCuratedRoles } from 'stores/rq/roles';
import { ProjectRoles } from 'types/api.types';

const getInitialRole = (isOrganizationAdmin: boolean, defaultRoles: Role[], allCustomRoles: Role[]) => {
  if (isOrganizationAdmin) return ProjectRoles.Admin;
  if (defaultRoles.length) return defaultRoles[0].id!;
  if (allCustomRoles.length) return allCustomRoles[0].id!;

  return undefined;
};

export const useUserRoleAssignmentRows = (defaultRoles: Role[]) => {
  const { allCustomRoles } = useCuratedRoles();
  const [rows, setRows] = useState<UserRoleAssignmentRow[]>([]);

  const mergeRow = useCallback(
    (userId: string, newData: Partial<UserRoleAssignmentRow>) =>
      setRows(
        rows.map(row =>
          row.id === userId
            ? {
                ...row,
                ...newData
              }
            : row
        )
      ),
    [rows, setRows]
  );

  const onChangeIsAssigned = useCallback(
    (userId: string, assigned: boolean) => {
      const isOrganizationAdmin = rows.find(row => row.id === userId)?.isOrganizationAdmin ?? false;
      const role = assigned ? getInitialRole(isOrganizationAdmin, defaultRoles, allCustomRoles) : undefined;

      return mergeRow(userId, { assigned, role });
    },
    [rows, defaultRoles, allCustomRoles, mergeRow]
  );

  const onChangeAssignmentRole = useCallback(
    (userId: string, role: RolesApi.RBACPermissionRole) => mergeRow(userId, { role }),
    [mergeRow]
  );

  const rowsToRemove = useMemo(
    () =>
      filter(rows, {
        isOrganizationAdmin: false,
        assigned: false,
        initialData: {
          assigned: true
        }
      }),
    [rows]
  );

  const rowsToAssign = useMemo(
    () =>
      filter(rows, {
        isOrganizationAdmin: false,
        assigned: true,
        initialData: {
          assigned: false
        }
      }).filter(row => !!row.role),
    [rows]
  );

  const rowsToUpdate = useMemo(
    () =>
      filter(rows, {
        isOrganizationAdmin: false,
        assigned: true,
        initialData: {
          assigned: true
        }
      }).filter(row => row.initialData.role !== row.role),
    [rows]
  );

  const hasChanges = rowsToRemove.length + rowsToAssign.length + rowsToUpdate.length > 0;

  return {
    onChangeIsAssigned,
    onChangeAssignmentRole,
    rows,
    setRows,
    hasChanges,
    rowsToAssign,
    rowsToRemove,
    rowsToUpdate
  };
};
