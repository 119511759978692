import React, { useEffect } from 'react';
import styled from 'types/theme.types';
import { Menu } from 'antd';

import type { MenuProps as AntdMenuProps } from 'antd/lib/menu';

import { useNavigate, useLocation } from 'react-router-dom';

export interface MenuProps {
  setActiveMenu: React.Dispatch<string>;
  activeMenu: string;
  menus: readonly string[];
  children: React.ReactNode;
  renderMenuItem?: (key: string) => React.ReactNode;
  customActions?: React.ReactNode;
}

export const TAB_SEARCH_PARAM_NAME = 'tabname';

const TabsMenu: React.FunctionComponent<MenuProps> = ({
  activeMenu,
  setActiveMenu,
  menus,
  children,
  renderMenuItem,
  customActions
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has(TAB_SEARCH_PARAM_NAME)) {
      const tab = searchParams.get(TAB_SEARCH_PARAM_NAME);

      if (tab && menus.includes(tab)) {
        setActiveMenu(tab);
      }
    }
  }, [location, setActiveMenu, menus]);

  const setActiveMenuAndAddSearchParam: AntdMenuProps['onClick'] = event => {
    const key = event.key.toString();
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(TAB_SEARCH_PARAM_NAME, key);
    location.search = searchParams.toString();
    navigate(location);

    setActiveMenu(key);
  };

  const renderMenuItemFunc = renderMenuItem || ((menu: string) => <div>{menu}</div>);

  return (
    <Container>
      <MenuContainer>
        <StyledMenu
          onClick={setActiveMenuAndAddSearchParam}
          selectedKeys={[activeMenu]}
          mode="horizontal"
          items={menus.map(menuKey => ({
            key: menuKey,
            label: renderMenuItemFunc(menuKey),
            'data-e2e': `menu-${menuKey}`
          }))}
        />
        {customActions}
      </MenuContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.secondaryGray} !important;
  height: 47px;
`;

const ContentContainer = styled.div`
  padding: 18px 0;
`;

const StyledMenu = styled(Menu)`
  background: transparent !important;
  border-bottom-width: 0 !important;
  margin-bottom: -1px;
  flex: 1;

  .ant-menu-item {
    color: ${({ theme }) => theme.secondaryDisabledGray} !important;
    border-bottom-width: 4px !important;
    border-bottom-color: transparent;
    font-size: 15px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    z-index: 2;

    &-selected {
      color: ${({ theme }) => theme.secondaryBlue} !important;
      border-bottom-width: 4px !important;
      border-bottom-color: ${({ theme }) => theme.primaryGreen};
    }
  }
`;

export default TabsMenu;
