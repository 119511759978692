import ServiceContainer from 'services/service-container';
import { apiClient } from 'services/api-client';
import { axiosClient, postConfigAxiosClient } from 'services/api-client/client';
import omit from 'lodash/omit';

export const initializeStores = () => {
  const serviceContainer = new ServiceContainer(apiClient);
  postConfigAxiosClient(axiosClient, serviceContainer);

  return omit(serviceContainer, 'apiClient') as Omit<ServiceContainer, 'apiClient'>;
};
