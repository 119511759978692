import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'types/theme.types';
import type { ReactNode } from 'react';

export const Warning: React.FC<{ content: ReactNode }> = ({ content }) => {
  return (
    <Tooltip title={content}>
      <StyledExclamationCircleOutlined />
    </Tooltip>
  );
};

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.warningYellowDarker};
`;
