import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { page } from 'utils/analytics.utils';
import { TAB_SEARCH_PARAM_NAME } from 'components/common/menu';

const normalizePageName = (name: string) => name.toLowerCase().split(' ').join('-');

const usePageTracking = (title: string) => {
  const location = useLocation();
  const params = useParams<{ activeMenu?: string }>();

  const queryString = new URLSearchParams(location.search);
  let pageName = title;
  if (params.activeMenu) pageName += `-${params.activeMenu}`;
  if (queryString.has(TAB_SEARCH_PARAM_NAME)) pageName += `-${queryString.get(TAB_SEARCH_PARAM_NAME)}`;

  useEffect(() => {
    page(normalizePageName(pageName), params);
  }, [pageName, params]);
};

export default usePageTracking;
