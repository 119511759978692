import React from 'react';
import styled from 'types/theme.types';
import { INFINITE_VALUE, INHERIT_FROM_ORG } from 'constants/ttl.constants';
import { SelectOption } from 'components/common/input-components/select';
import { Select, InputNumber } from 'antd';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import type { TtlPolicy, TtlTimeUnit } from 'components/policies/common-ttl';
import { INIT_TTL_VALUE, getTtlNumber, getTtlTimeUnit, timeUnitToName } from 'components/policies/common-ttl';
import type { valueType } from 'antd/lib/statistic/utils';
import { FormattedMessage } from 'react-intl';
import { CaretDownOutlined } from '@ant-design/icons';

export interface TtlInputNumberProps {
  form: UseFormReturnType<TtlPolicy>;
  name: 'maxTtl' | 'defaultTtl';
  validTimeUnits: TtlTimeUnit[];
}

const createSelectOptions = (optionString: string, timeUnits: TtlTimeUnit[]) => {
  return timeUnits.map(timeUnit => (
    <SelectOption data-e2e={`option-${optionString}-unit-${timeUnit}`} key={timeUnit} value={timeUnit}>
      <FormattedMessage id={timeUnitToName[timeUnit]} />
    </SelectOption>
  ));
};

export const TtlInputNumber: React.FC<TtlInputNumberProps> = ({ form, name, validTimeUnits }) => {
  const ttlValue: string | null | 'inherit' = form.getValues(name);
  const ttlNumber = getTtlNumber(ttlValue);
  const ttlTimeUnit = getTtlTimeUnit(ttlValue);

  const selectAfter = (
    <StyledSelect
      onChange={(inputTimeUnitChangeEvent: valueType | null) => {
        form.setValue(name, `${ttlNumber}-${inputTimeUnitChangeEvent?.toString()}`);
      }}
      className={'input-number-after-select-addon'}
      disabled={ttlValue === INFINITE_VALUE || ttlValue === INHERIT_FROM_ORG}
      value={
        ttlValue === INHERIT_FROM_ORG || ttlValue === INFINITE_VALUE ? getTtlTimeUnit(INIT_TTL_VALUE) : ttlTimeUnit
      }
      suffixIcon={<Caret disabled={ttlValue === INFINITE_VALUE || ttlValue === INHERIT_FROM_ORG} />}
      data-e2e={`${name}-time-unit-select`}>
      {createSelectOptions(name, validTimeUnits)}
    </StyledSelect>
  );

  const onChangeInputNumber = (inputNumberChangeEvent: valueType | null) =>
    form.setValue(name, `${inputNumberChangeEvent?.toString()}-${ttlTimeUnit}`);

  return (
    <StyledInputNumber
      onChange={onChangeInputNumber}
      min={1}
      addonAfter={selectAfter}
      data-e2e={`${name}-number-input`}
      disabled={ttlValue === INFINITE_VALUE || ttlValue === INHERIT_FROM_ORG}
      value={ttlValue === INHERIT_FROM_ORG || ttlValue === INFINITE_VALUE ? getTtlNumber(INIT_TTL_VALUE) : ttlNumber}
    />
  );
};

const StyledInputNumber = styled(InputNumber)`
  width: 30%;
  font-size: 0.82em;
`;

const StyledSelect = styled(Select)<any>`
  font-size: 12px !important;
  font-weight: 500 !important;
  width: 7em;
`;

const Caret = styled(CaretDownOutlined)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.nonEditableBorderGray : '#000')};
  pointer-events: none !important;
`;
