import React from 'react';
import { observer } from 'mobx-react';
import Card, { CardHeader } from 'components/common/card';

import ErrorContainer from 'components/common/error-container';
import type { ColumnProps } from 'components/common/table';
import Table from 'components/common/table';
import EditIcon from 'components/common/edit-icon';
import NotificationEventNames from 'components/projects/settings/notifications/notification-event-names';
import NotificationEventsModal from 'components/projects/settings/notifications/notification-events-modal';
import { links } from 'constants/external-links';

import useNotificationEventsModalState from 'components/projects/settings/notifications/notification-events-modal-state.hook';
import { useAsyncPromise } from 'hooks/use-async-promise';
import useStores from 'hooks/use-stores.hooks';
import type { EnrichedProjectNotificationSetting, Project } from 'types/api.types';
import { useModal } from 'hooks/modal.hooks';
import { FormattedMessage } from 'react-intl';
import NotificationEndpointModal from 'components/organizations/notifications/notification-endpoint-modal';
import Button from 'components/common/button';
import Stack from 'components/common/stack';
import { useHasPermission } from 'hooks/use-has-permission';
import SubProjectWontBeAffectedAlert from 'components/projects/sub-projects-not-affected-alert';

type Props = {
  project: Project;
};

const intlPrefix = 'projects.settings.notifications';

const staticColumns: ColumnProps[] = [
  {
    title: 'name',
    dataIndex: ['endpoint', 'name']
  },
  {
    title: 'type',
    dataIndex: ['endpoint', 'type']
  },
  {
    width: 500,
    title: `${intlPrefix}.column.events`,
    dataIndex: 'eventNames',
    render: (eventNames: EnrichedProjectNotificationSetting['eventNames']) => (
      <NotificationEventNames eventNames={eventNames} />
    )
  }
];

const ProjectSettingsNotifications: React.FC<Props> = ({ project }) => {
  const { notificationSettingsStore } = useStores();
  const { id: projectId } = project;
  const notificationSettings = notificationSettingsStore.notificationSettingsByProjectId[projectId];
  const state = useNotificationEventsModalState(projectId);
  const { hideModal, showModal, isModalOpen } = useModal();
  const { isAuthorized: canEditProjectSettings } = useHasPermission('EDIT_PROJECT_SETTINGS');

  const [error, , loading] = useAsyncPromise(
    () => notificationSettingsStore.loadProjectNotificationSettings(projectId),
    [notificationSettingsStore, projectId]
  );

  const columns = [
    ...staticColumns,
    {
      render: ({ endpoint, eventNames }: EnrichedProjectNotificationSetting) => (
        <EditIcon onClick={() => state.openModal(endpoint, eventNames)} />
      )
    }
  ];

  const refreshProjectNotifications = async () => {
    await notificationSettingsStore.loadProjectNotificationSettings(projectId);
    hideModal();
  };

  if (error) {
    return <ErrorContainer errorToReport={error} />;
  }

  const cardTitle = (
    <CardHeader
      titleId={`${intlPrefix}.title`}
      descriptionId={`${intlPrefix}.description`}
      link={links.docs.NOTIFICATIONS.SLACK}
    />
  );

  const footer = () => {
    return (
      canEditProjectSettings && (
        <Button addPlus data-e2e="add-notification-endpoint" type="primary" onClick={showModal}>
          <FormattedMessage id="addNotificationTarget" />
        </Button>
      )
    );
  };

  return (
    <Stack>
      <SubProjectWontBeAffectedAlert pageType={'configuration'} />
      <Card title={cardTitle}>
        <Table rowKey="id" columns={columns} dataSource={notificationSettings} loading={loading} footer={footer} />
        <NotificationEventsModal state={state} />
        {isModalOpen && <NotificationEndpointModal onSubmitOrCancel={refreshProjectNotifications} />}
      </Card>
    </Stack>
  );
};

export default observer(ProjectSettingsNotifications);
