import type { ArgsProps } from 'antd/es/notification/interface';

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
  open = 'open'
}

export interface NotificationArgs extends ArgsProps {
  notificationType: NotificationTypes;
  messageVariables?: Record<string, any>;
  message: string;
}
