import React from 'react';
import { observer } from 'mobx-react';
import { ErrorMessage, Label } from 'components/common/form-components';
import styled from 'types/theme.types';
import type { SshKey, UpdateSshKeyInput } from 'types/api.types';
import useForm from 'hooks/use-form.hook';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import type { CreateSshKeyInput } from 'types/api.types';
import type { SchemaOf } from 'yup';
import type { UseFormMethods } from 'react-hook-form/dist/types';
import { onKeySetFailure } from 'components/organizations/keys/common/key-validations';
import ControlledCredentialTextInput from 'components/common/form-controlled/controlled-credential-text-input';

const messagePrefix = 'organization.settings.keys';
export const SshValueTextAreaPlaceholder: string = '-----BEGIN RSA PRIVATE KEY-----';

type SshKeyInput = CreateSshKeyInput & UpdateSshKeyInput;

type SshKeyModalProps = {
  open: boolean;
  onClose: (createdKey?: any) => void;
  onSubmit: (data: SshKeyInput) => Promise<SshKey>;
  schema: SchemaOf<SshKeyInput>;
  keyName?: string;
  isLoading?: boolean;
};

const SshKeyModal: React.FC<SshKeyModalProps> = ({ open, onClose, onSubmit, schema, keyName, isLoading }) => {
  const modalType = keyName ? 'update' : 'add';

  const form = useForm({
    schema,
    onSubmit: (data: SshKeyInput, form: UseFormMethods<SshKeyInput>) => {
      onSubmit(data)
        .then((createdKey: SshKey) => closeAndClearForm(createdKey))
        .catch(onKeySetFailure(form, 'SSH'));
    },
    initialValues: {
      name: keyName ?? '',
      value: ''
    }
  });

  const closeAndClearForm = (createdKey?: SshKey) => {
    onClose(createdKey);
    form.clear();
  };

  return (
    <ControlledModal
      data-e2e={`${modalType}-ssh-key-modal`}
      saveButtonDataE2E={`${modalType}-ssh-key-button`}
      open={open}
      form={form}
      onCancel={onClose}
      titleId={`organization.settings.keys.${modalType}`}
      titleValues={{ keyType: 'SSH' }}
      saveMessageId="save.changes"
      isLoading={isLoading}>
      <>
        <Label id={`${messagePrefix}.name`} values={{ keyType: 'SSH' }} />
        <ControlledTextInput
          form={form}
          disabled={!!keyName}
          id={`${modalType}-ssh-key-name`}
          data-e2e={`${modalType}-ssh-key-name`}
          name="name"
          errorSide="bottom"
        />
        <ControlledCredentialTextInput
          isSensitive={true}
          textArea={true}
          label={{ id: `${messagePrefix}.value`, values: { keyType: 'SSH' } }}
          form={form}
          name="value"
          data-e2e={`${modalType}-ssh-key-value-textarea`}
          placeholder={SshValueTextAreaPlaceholder}
          autoSize={{ minRows: 12, maxRows: 12 }}
        />
      </>
    </ControlledModal>
  );
};

styled(ErrorMessage)`
  display: flex;
`;

export default observer(SshKeyModal);
