import React from 'react';
import NotificationManager from 'components/common/notifications-manager';
import Localization from 'components/localization';
import AntdWrapper from 'components/antd-wrapper';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { Provider } from 'mobx-react';
import createCache from '@emotion/cache';
import theme from 'constants/themes.constants';
import history from 'services/history-service';
import useStores from 'hooks/use-stores.hooks';
import useApiClient from 'hooks/use-api-client';
import Router from 'components/routes/router';
import { QueryClientProvider } from 'stores/rq/common/query-client-provider';
import { QueryClientProvider as QueryClientProviderNext } from 'stores/rq/common/query-client-provider';
import { KBarProvider } from 'kbar';
import GlobalSubscriptions from 'components/common/global-subscriptions';
import { AppSwitch } from 'components/app-route-switch';
import ErrorBoundary from 'components/common/error-boundary';
import PayGate from 'components/common/pay-gate';
import SentryEventProcessors from 'components/sentry/sentry-event-processors';

const emotionCache = createCache({
  key: 'css'
});

emotionCache.compat = true;

const App: React.FunctionComponent = () => {
  const stores = useStores();
  const apiClient = useApiClient();

  return (
    <CacheProvider value={emotionCache}>
      <ErrorBoundary renderErrorMessageOnError={false}>
        <Provider {...{ ...stores, apiClient }}>
          <ThemeProvider theme={theme}>
            <Localization>
              <AntdWrapper>
                <Router history={history}>
                  <QueryClientProviderNext>
                    <QueryClientProvider>
                      <GlobalSubscriptions>
                        <KBarProvider options={{ enableHistory: true }}>
                          <NotificationManager />
                          <PayGate />
                          <AppSwitch />
                          <SentryEventProcessors />
                        </KBarProvider>
                      </GlobalSubscriptions>
                    </QueryClientProvider>
                  </QueryClientProviderNext>
                </Router>
              </AntdWrapper>
            </Localization>
          </ThemeProvider>
        </Provider>
      </ErrorBoundary>
    </CacheProvider>
  );
};

export default App;
