import type { BlueprintApi } from '@env0/blueprint-service/api';
import {
  opentofuVersions,
  pulumiVersions,
  terraformVersions,
  terragruntVersions,
  ansibleVersions
} from 'constants/config';
import semver from 'semver';

export const BACKWARD_COMPATIBLE_TF_VERSION_API_VALUE: BlueprintApi.BackwardCompatibleTFVersionType = null; // fallback to tf version 0.12.24
export const BACKWARD_COMPATIBLE_TF_VERSION_FALLBACK: BlueprintApi.TerraformVersion = '0.12.24';
export const DEFAULT_TF_VERSION: BlueprintApi.TerraformVersion = '1.5.7';
export const DEFAULT_OPENTOFU_VERSION: BlueprintApi.OpentofuVersion = 'latest';
export const DEFAULT_TG_VERSION = '0.52.0';
export const DEFAULT_PULUMI_VERSION: BlueprintApi.PulumiVersionType = 'latest';
export const DEFAULT_ANSIBLE_VERSION: BlueprintApi.AnsibleVersionType = 'latest';

// Minimal Terraform version is 0.9
const TF_MINIMAL_VERSION = '0.9.0';
const TF_MAXIMAL_VERSION = '1.6.0';
// Minimal Terragrunt version is 0.27.0
const TG_MINIMAL_VERSION = '0.27.0';
// Minimal Ansible version is 3.0.0
const ANSIBLE_MINIMAL_VERSION = '3.0.0';

// Ansible's versions should be 3.0.0 and above, and we wish to support RC, alpha, beta
// versions as well, and the semver coercion will return the version without the pre-release
const isValidAnsibleVersion = (version: string): boolean => {
  const versionParts = version.split('.');
  const majorVersion = +versionParts[0];

  if (majorVersion > 3) return true;
  if (majorVersion < 3) return false;

  const minorVersion = +versionParts[1];
  if (minorVersion > 0) return true;

  const patchVersion = versionParts[2];

  // if patch number is a number or not, using regex
  return /^\d+$/.test(patchVersion);
};

const isValidVersionAndInVersionRange = (version: string, minVersion: string, maxVersion?: string): boolean => {
  const isValidVersion = semver.valid(semver.coerce(version)) === version;
  if (!isValidVersion) {
    return false;
  }
  const greaterOrEqualThanMinimalVersion = semver.gte(version, minVersion);
  const lessThanMaximalVersion = !maxVersion || semver.lt(version, maxVersion);
  return greaterOrEqualThanMinimalVersion && lessThanMaximalVersion;
};

const splitAndTrim = (list: string): string[] =>
  Array.from(new Set<string>(list?.split('\n').map(s => s.trim().replace(/^"|"$/g, ''))));

const terraformVersionsArray = splitAndTrim(terraformVersions)?.filter(version =>
  isValidVersionAndInVersionRange(version, TF_MINIMAL_VERSION, TF_MAXIMAL_VERSION)
) ?? [DEFAULT_TF_VERSION, BACKWARD_COMPATIBLE_TF_VERSION_FALLBACK];

const openTofuVersionsArray = splitAndTrim(opentofuVersions).map(v => v.substring(1)) ?? [DEFAULT_OPENTOFU_VERSION];

const terragruntVersionsArray = splitAndTrim(terragruntVersions)?.filter(version =>
  isValidVersionAndInVersionRange(version, TG_MINIMAL_VERSION)
) ?? [DEFAULT_TG_VERSION];

const ansibleVersionsArray = splitAndTrim(ansibleVersions)?.filter(version =>
  isValidVersionAndInVersionRange(version, ANSIBLE_MINIMAL_VERSION)
) ?? [DEFAULT_ANSIBLE_VERSION];

const terraformVersionList = terraformVersionsArray as ReadonlyArray<string>;
const terragruntVersionList = terragruntVersionsArray as ReadonlyArray<string>;
const opentofuVersionList = openTofuVersionsArray as ReadonlyArray<string>;
const pulumiVersionList = ['latest', ...JSON.parse(pulumiVersions)] as ReadonlyArray<string>;
const ansibleVersionList = ['latest', ...ansibleVersionsArray] as ReadonlyArray<string>;

export const iacVersionList: Record<BlueprintApi.IacType, ReadonlyArray<string>> = {
  opentofu: opentofuVersionList,
  terraform: terraformVersionList,
  terragrunt: terragruntVersionList,
  pulumi: pulumiVersionList,
  k8s: [],
  cloudformation: [],
  helm: [],
  ansible: ansibleVersionList
};
