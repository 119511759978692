import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Section } from 'components/common/section';
import { MaxTTLSection } from 'components/policies/max-ttl-section';
import { DefaultTtlSection } from 'components/policies/default-ttl-section';
import type { TtlPolicy } from 'components/policies/common-ttl';

export const TtlPolicySection: React.FunctionComponent<{
  form: UseFormReturnType<TtlPolicy>;
  canInherit?: boolean;
}> = ({ form, canInherit = false }) => {
  form.useManualField<string | null>('maxTtl');
  form.useManualField<string | null>('defaultTtl');

  return (
    <Section titleId={'organization.settings.policy.ttl.title'}>
      <MaxTTLSection canInherit={canInherit} form={form} />
      <DefaultTtlSection form={form} />
    </Section>
  );
};
