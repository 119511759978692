import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'types/theme.types';
import { Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInputState } from 'hooks/utils.hooks';
import Button from 'components/common/button';
import type { ProjectFormErrors } from 'components/projects/create-project-form';
import CreateProjectForm from 'components/projects/create-project-form';
import useStores from 'hooks/use-stores.hooks';
import { observer } from 'mobx-react';
import { useCreateProject } from 'stores/rq/projects';

export interface CreateProjectModalProps {
  parentProjectId?: string;
  hideModal: () => void;
  open: boolean;
}

const CreateProjectModal: React.FunctionComponent<CreateProjectModalProps> = ({ parentProjectId, open, hideModal }) => {
  const intl = useIntl();
  const [name, setName] = useInputState();
  const [description, setDescription] = useInputState();
  const [errors, setErrors] = useState<ProjectFormErrors>({});
  const [isLoading, setLoading] = useState(false);
  const {
    organizationsStore: { currentOrganizationId }
  } = useStores();
  const { mutateAsync: createProject } = useCreateProject(parentProjectId);
  const navigate = useNavigate();

  const mergeErrors = useCallback(
    (newErrors: Partial<ProjectFormErrors>) =>
      setErrors({
        ...errors,
        ...newErrors
      }),
    [errors, setErrors]
  );

  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      mergeErrors({
        common: null,
        name: null
      });

      setName(event);
    },
    [mergeErrors, setName]
  );

  const onChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      mergeErrors({ common: null });
      setDescription(event);
    },
    [setDescription, mergeErrors]
  );

  const onCreateClick = async () => {
    if (!name) {
      mergeErrors({
        name: intl.formatMessage({ id: 'projects.create.modal.empty.name' })
      });
      return;
    }

    setLoading(true);
    try {
      const createdProject = await createProject({
        name,
        description,
        organizationId: currentOrganizationId as string
      });
      setName('');
      setDescription('');
      navigate(`/p/${createdProject.id}/environments`);
    } catch (error) {
      mergeErrors({
        common: (error as Error).message
      });
    } finally {
      setLoading(false);
      hideModal();
    }
  };

  const title = (
    <Header>
      <Title>
        <FormattedMessage id="projects.add.new" tagName="h1" />
      </Title>
    </Header>
  );

  const footer = (
    <Footer>
      <FooterButton onClick={hideModal} disabled={isLoading} data-e2e="create-project-modal-cancel">
        <FormattedMessage id="cancel" />
      </FooterButton>
      <FooterButton
        id="projects-create-submit"
        type="primary"
        onClick={onCreateClick}
        disabled={isLoading}
        isLoading={isLoading}
        data-e2e="create-project-modal-button">
        <FormattedMessage id="projects.button.create" />
      </FooterButton>
    </Footer>
  );

  return (
    <Modal open={open} onCancel={hideModal} width="525px" title={title} footer={footer} destroyOnClose centered>
      <CreateProjectForm
        name={name}
        description={description}
        onChangeName={onChangeName}
        onChangeDescription={onChangeDescription}
        errors={errors}
      />
    </Modal>
  );
};

const Header = styled.div`
  padding: 16px 8px 8px 16px;
`;

const Title = styled.div`
  h1 {
    color: ${({ theme }) => theme.primaryBlue};
    font-size: 24px;
    font-weight: 600;
  }
`;

const Footer = styled.div`
  padding: 10px 9px;
`;

const FooterButton = styled(Button)`
  width: 140px !important;
`;

export default observer(CreateProjectModal);
