import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Text } from 'components/common/form-components';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';

const RemoteStateSettings: React.FC<{ form: UseFormReturnType }> = ({ form }) => {
  return (
    <Section titleId="before.run.settings.remote-state-section">
      <ControlledBigCheckbox name="forceRemoteBackend" form={form}>
        <Text
          id={'remote-state.force.title'}
          info={{
            id: 'remote-state.force.description',
            values: setLink(links.docs.REMOTE_BACKEND.ROOT)
          }}
        />
      </ControlledBigCheckbox>
    </Section>
  );
};

export default RemoteStateSettings;
