// This file is meant to be exporting enums only
// Please refrain from importing anything to it and from introducing anything here other than enums
// Doing so will make downstream services include runtime code from this service
export enum AutoDestroyStatus {
  NONE,
  MARKED_FOR_DELETION,
  ALERTED_BEFORE_DELETION_2H,
  ALERTED_BEFORE_DELETION_2D
}

export enum DeploymentType {
  deploy = 'deploy',
  destroy = 'destroy',
  prPlan = 'prPlan',
  driftDetection = 'driftDetection',
  task = 'task',
  remotePlan = 'remotePlan'
}

// order matters, the first status is the most severe
export enum DriftStatus {
  ERROR,
  DRIFTED,
  OK,
  NEVER_RUN,
  DISABLED
}
