import React from 'react';
import noop from 'lodash/noop';
import TextInput from 'components/common/input-components/text-input';
import TextArea from 'components/common/input-components/textarea';
import { Container, Field, Label, CommonErrorMessage } from 'components/common/form-components';

export interface ProjectFormErrors {
  name?: null | string;
  common?: null | string;
}

export interface CreateProjectFormProps {
  name: string;
  description: string;
  onChangeName?: (newValue: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDescription: (newValue: React.ChangeEvent<HTMLTextAreaElement>) => void;
  errors: ProjectFormErrors;
}

const CreateProjectForm: React.FunctionComponent<CreateProjectFormProps> = ({
  name,
  description,
  onChangeName,
  onChangeDescription,
  errors
}) => {
  return (
    <Container>
      <Field>
        <Label id="name" />
        <TextInput
          id="projects-create-name"
          data-e2e={'projects-create-name'}
          value={name}
          onChange={onChangeName || noop}
          disabled={!onChangeName}
          error={errors.name}
          autoFocus
        />
      </Field>
      <Field>
        <Label id="description" optional={true} />
        <TextArea id="projects-create-description" value={description} onChange={onChangeDescription} />
      </Field>
      <CommonErrorMessage>{errors.common}</CommonErrorMessage>
    </Container>
  );
};

export default CreateProjectForm;
