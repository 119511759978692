import React, { useState } from 'react';
import type { NotificationsApi } from '@env0/notification-service/api';
import { useNotificationEndpointForm } from 'components/organizations/notifications/notification-endpoint-form.hook';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { NotificationEndpointType } from '@env0/notification-service/api.enum';
import { SelectOption } from 'components/common/input-components/select';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import RadioGroup from 'components/common/radio-group';
import withLabel from 'components/common/form-controlled/with-label.hoc';
import { FormattedMessage } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import { IdField, intlPrefix } from 'components/organizations/notifications/modal-utils';
import { EmailList } from 'components/organizations/notifications/email-list';
import { Row } from 'components/common/grid';

type Props = {
  endpoint?: NotificationsApi.NotificationEndpoint;
  onSubmitOrCancel: () => void;
};

const NotificationEndpointModal: React.FunctionComponent<Props> = ({ endpoint, onSubmitOrCancel }) => {
  const form = useNotificationEndpointForm(onSubmitOrCancel, endpoint);
  const notificationTypes = Object.values(NotificationEndpointType);
  const selectedNotificationType = form.watch('type');
  const valueLabel = selectedNotificationType === NotificationEndpointType.EMAIL ? 'emailAddress' : 'value';
  const isNewEndpoint = isUndefined(endpoint);

  return (
    <ControlledModal
      data-e2e="notification-endpoint-modal"
      form={form}
      onCancel={onSubmitOrCancel}
      titleId={`${endpoint ? 'organization.settings.notifications.edit' : 'addNotificationTarget'}`}
      open>
      {!isNewEndpoint && <IdField id={endpoint!.id} />}

      <Row>
        <ControlledTextInput
          label={{ id: `${intlPrefix}.name` }}
          name="name"
          form={form}
          data-e2e={'notification-target-name-input'}
        />
      </Row>

      <Row>
        <ControlledSelect data-e2e="notification-options" label={{ id: `${intlPrefix}.type` }} name="type" form={form}>
          {notificationTypes.map(value => (
            <SelectOption key={value} value={value} data-e2e={`notification-target-options-${value}`}>
              <FormattedMessage id={`${intlPrefix}.type.${value.toLowerCase()}`} />
            </SelectOption>
          ))}
        </ControlledSelect>
      </Row>

      {selectedNotificationType === NotificationEndpointType.EMAIL ? (
        <EmailList form={form} />
      ) : (
        <Row>
          <ControlledTextInput
            label={{ id: `${intlPrefix}.${valueLabel}` }}
            name="value"
            form={form}
            data-e2e={'notification-target-value-input'}
          />
        </Row>
      )}

      {selectedNotificationType === NotificationEndpointType.WEBHOOK && (
        <WebhookSecretField form={form} label={{ id: `${intlPrefix}.webhook-secret` }} isNewEndpoint={isNewEndpoint} />
      )}
    </ControlledModal>
  );
};

const WebhookSecretField = withLabel(
  (props: { form: ReturnType<typeof useNotificationEndpointForm>; isNewEndpoint: boolean }) => {
    const { form, isNewEndpoint } = props;
    type SecretOperation = 'no-change' | 'remove' | 'edit' | 'no-secret' | 'define';
    const defaultSecretOperation: SecretOperation = isNewEndpoint ? 'no-secret' : 'no-change';
    const [operation, setOperation] = useState<SecretOperation>(defaultSecretOperation);

    const secretOperationsToOptions = (operations: SecretOperation[]) => {
      return operations.map(operation => {
        return { content: <FormattedMessage id={`${intlPrefix}.webhook-secret-op.${operation}`} />, value: operation };
      });
    };

    const createSecretOptions = secretOperationsToOptions(['no-secret', 'define']);
    const editSecretOptions = secretOperationsToOptions(['no-change', 'remove', 'edit']);
    const secretOptions = isNewEndpoint ? createSecretOptions : editSecretOptions;

    const onOperationChange = (value: SecretOperation) => {
      const secretOperationToInitialValue: Record<SecretOperation, string | null | undefined> = {
        'no-change': undefined,
        remove: null,
        edit: '',
        'no-secret': undefined,
        define: ''
      };
      const initialValue = secretOperationToInitialValue[value];

      setOperation(value);
      form.setValue('webhookSecret', initialValue);
    };

    return (
      <>
        <RadioGroup items={secretOptions} value={operation} onChange={onOperationChange} />
        <ControlledTextInput
          name="webhookSecret"
          form={form}
          fullWidth={true}
          disabled={!['edit', 'define'].includes(operation)}
          errorSide="bottom"
          data-e2e="notification-webhook-secret-input"
        />
      </>
    );
  }
);

export default NotificationEndpointModal;
