import styled from '@emotion/styled';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import type { KeyToObjectLiteral } from 'types/common.types';
import Button from './button';
import PopupEntity from './popup-entity';

export interface DeletePopupProps {
  onDelete: () => void;
  messagePrefix: string;
  disabled?: boolean;
  'data-e2e'?: string;
  popupTitleVariables?: KeyToObjectLiteral<string | React.ReactElement>;
}

const DeletePopup: FC<DeletePopupProps> = ({
  onDelete,
  messagePrefix,
  disabled,
  'data-e2e': dataE2e,
  popupTitleVariables
}) => {
  const deleteButton = (
    <Button data-e2e="delete-button" disabled={disabled} type="danger">
      <FormattedMessage id={`${messagePrefix}.button.text`} />
    </Button>
  );

  const popupContent = (
    <div data-e2e={dataE2e || 'delete-popup-modal'}>
      <PopupConfirmationStyle>
        <FormattedMessage id={`${messagePrefix}.content.confirmation`} />
      </PopupConfirmationStyle>
      <PopupWarningStyle>
        <FormattedMessage id={`${messagePrefix}.content.warning`} />
      </PopupWarningStyle>
      <PopupDetailsStyle>
        <FormattedMessage id={`${messagePrefix}.content.details`} />
      </PopupDetailsStyle>
    </div>
  );

  return (
    <PopupEntity
      iconComponent={deleteButton}
      onApprove={onDelete}
      popupTitleId={`${messagePrefix}.title`}
      popupContent={popupContent}
      popupTitleVariables={popupTitleVariables}
      popupOkTextId={`${messagePrefix}.button.text`}
      popupCancelTextId="cancel"
      disabled={disabled}
    />
  );
};

const PopupConfirmationStyle = styled.div`
  margin-bottom: 5px;
`;
const PopupWarningStyle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;
const PopupDetailsStyle = styled.div`
  white-space: pre-line;
`;

export default DeletePopup;
