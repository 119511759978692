import type { ColumnsType, ColumnType } from 'antd/es/table';
import { AntDPrebuiltColumns } from 'components/common/table';
import groupByLodash from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import { sortGroups } from 'components/settings/variables-group-by-settings';

export type GroupedRowType<RowType> = Partial<RowType> & { children: RowType[]; id: string };
export type GroupedRowOrRow<RowType> = GroupedRowType<RowType> | RowType;
export function isGroupRow<RowType>(row: GroupedRowOrRow<RowType>): row is GroupedRowType<RowType> {
  return (row as GroupedRowType<RowType>).children !== undefined;
}

export type GroupBySettings<T> = {
  groupBy: (row: T) => string;
  createParentRow: (group: string, rows: T[]) => Partial<T> & { id: string };
  getParentRowRenderer: (record: GroupedRowType<T>) => React.ReactNode;
};

export const tableGroupBy = <RowType>(
  columns: ColumnsType<any>,
  items: RowType[],
  groupBySettings: GroupBySettings<RowType>
): GroupedRowOrRow<RowType>[] => {
  const { getParentRowRenderer, groupBy, createParentRow } = groupBySettings;

  updateColumnsToSupportGroupRows(columns, getParentRowRenderer);

  const groupedItems: GroupedRowOrRow<RowType>[] = groupByItems(items, groupBy, createParentRow);

  return sortItemsWithoutChildrenAtTop(groupedItems);
};

const groupByItems = <RowType>(
  items: RowType[],
  groupBy: (row: RowType) => string,
  createParentRow: (group: string, rows: RowType[]) => Partial<RowType> & { id: string }
): GroupedRowOrRow<RowType>[] => {
  return toPairs(groupByLodash(items, groupBy)).flatMap(([group, rows]) => {
    if (!group) {
      return rows as GroupedRowOrRow<RowType>[];
    }
    return [{ ...createParentRow(group, rows), children: rows }];
  });
};

const sortItemsWithoutChildrenAtTop = <RowType>(
  groupedItems: GroupedRowOrRow<RowType>[]
): GroupedRowOrRow<RowType>[] => {
  return groupedItems.sort((a, b) => {
    if (isGroupRow(a) && !isGroupRow(b)) {
      return 1;
    }

    if (!isGroupRow(a) && isGroupRow(b)) {
      return -1;
    }

    if (isGroupRow(a) && isGroupRow(b)) {
      return sortGroups(a, b);
    }

    return 0;
  });
};

const updateColumnsToSupportGroupRows = <RowType>(
  columns: ColumnsType<any>,
  getParentRowRenderer: (record: GroupedRowType<RowType>) => React.ReactNode
): void => {
  wrapRootColumnToSupportGroupParentRender(columns, getParentRowRenderer);
  wrapOnCellToOnlyShowGroupParentCell(columns);
  wrapOnFilterToFilterChildrenOnly(columns);
};

/*
 * We want to replace the first column aka the root column's render function to render the group row if it's a group row
 * This will ensure we don't modify the original columns array and cause any side effects
 */
const wrapRootColumnToSupportGroupParentRender = <RowType>(
  columns: ColumnsType<any>,
  getParentRowRenderer: (record: GroupedRowType<RowType>) => React.ReactNode
) => {
  const rootColumnIndex = getRootNonPrebuiltColumnIndex(columns);

  const rootColumn = columns[rootColumnIndex];
  const renderWrapper = (
    render?: (value: any, record: GroupedRowOrRow<RowType>, index: number) => React.ReactNode
  ): ((value: any, record: GroupedRowOrRow<RowType>, index: number) => React.ReactNode) | undefined => {
    return (row, record, index) => {
      if (isGroupRow(row)) {
        return getParentRowRenderer(row);
      }

      return render ? render(row, record, index) : undefined;
    };
  };

  rootColumn.render = renderWrapper(
    rootColumn.render as (value: any, record: GroupedRowOrRow<RowType>, index: number) => React.ReactNode
  );
};

/*
 * We want the content of the group row to be a single cell that spans all columns and hide the rest of the cells
 */
const wrapOnCellToOnlyShowGroupParentCell = <RowType>(columns: ColumnsType<any>) => {
  const rootColumnIndex = getRootNonPrebuiltColumnIndex(columns);

  columns.forEach((column, columnIndex) => {
    const onCellWrapper = (
      onCell?: (row: RowType, index: number | undefined) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>
    ): ((row: RowType, index: number | undefined) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>) => {
      return (row: RowType) => {
        if (!isGroupRow(row)) {
          return onCell ? onCell(row, columnIndex) : {};
        }

        if (columnIndex === rootColumnIndex) {
          return {
            colSpan: columns.length
          };
        }

        return {
          colSpan: 0,
          rowSpan: 0
        };
      };
    };

    column.onCell = onCellWrapper(column.onCell);
  });
};

/*
 * When column filtering is used, we want to allow filtering for children only.
 * Meaning that group (parent) row will not be included in the filtering options.
 */
const wrapOnFilterToFilterChildrenOnly = <RowType>(columns: ColumnsType<any>) => {
  columns.forEach((column, columnIndex) => {
    const onFilterWrapper = (
      onFilter?: (value: string | number | boolean, row: RowType) => boolean
    ): ((value: string | number | boolean, row: RowType) => boolean) => {
      return (value: string | number | boolean, row: RowType) => {
        if (!isGroupRow(row)) {
          return onFilter ? onFilter(value, row) : true;
        }

        const filteredChildren = row.children.filter(child => onFilter?.(value, child) ?? true);
        if (filteredChildren.length > 0) {
          row.children = filteredChildren;
          return true;
        }

        return false;
      };
    };

    column.onFilter = onFilterWrapper(column.onFilter);
  });
};

const getRootNonPrebuiltColumnIndex = (columns: ColumnType<any>[]): number => {
  return columns.findIndex(column => !AntDPrebuiltColumns.has(column));
};
