import React from 'react';
import type { TrackingData } from 'utils/analytics.utils';
import { track } from 'utils/analytics.utils';
import type { LinkProps } from 'components/common/link';
import Link from 'components/common/link';

export interface LinkTrackingProps extends LinkProps {
  trackingData: TrackingData;
}

const TrackableLink: React.FC<LinkTrackingProps> = ({ trackingData, onClick, children, ...props }) => {
  const onClickHandler = (e: any) => {
    track(trackingData.eventName, trackingData.eventProperties || {});
    onClick && onClick(e);
  };

  return (
    <Link onClick={onClickHandler} {...props}>
      {children}
    </Link>
  );
};

export default TrackableLink;
