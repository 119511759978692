import React from 'react';
import * as MongoDB from 'assets/images/logos/mongodb.svg';
import * as Paypal from 'assets/images/logos/paypal.svg';
import * as Varonis from 'assets/images/logos/varonis.svg';
import * as Virgin from 'assets/images/logos/virgin.svg';
import * as MgmResorts from 'assets/images/logos/mgm-resorts.svg';
import * as VMware from 'assets/images/logos/vmware.svg';
import * as AutomationAnywhere from 'assets/images/logos/automation-anywhere.svg';
import * as WesternUnion from 'assets/images/logos/western-union.svg';
import theme from 'constants/themes.constants';
import styled from 'types/theme.types';

type SvgComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type CustomerIcon = {
  svgName: string;
  Icon: SvgComponent;
};

// order matters
const icons = [Paypal, MongoDB, Virgin, WesternUnion, Varonis, MgmResorts, VMware, AutomationAnywhere].map(
  namespace => ({ svgName: namespace.default, Icon: namespace.ReactComponent } as CustomerIcon)
);

interface CustomersLogosProps {
  logosColor?: string;
  logos?: CustomerIcon[];
}

const CustomersLogos: React.FunctionComponent<CustomersLogosProps> = ({
  logosColor = theme.primaryWhite,
  logos = icons
}) => {
  return (
    <>
      {logos.map(({ svgName, Icon }) => (
        <IconContainer key={svgName}>
          <Icon fill={logosColor} />
        </IconContainer>
      ))}
    </>
  );
};

const IconContainer = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 25%;
    transform: scale(0.7);
  }
`;

export default CustomersLogos;
