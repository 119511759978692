import ErrorContainer from 'components/common/error-container';
import Menu from 'components/common/menu2';
import ProjectSettingsCredentials from 'components/projects/settings/credentials/project-credentials';
import ProjectSettingsGeneral from 'components/projects/settings/general/project-settings-general';
import ProjectSettingsUsers from 'components/projects/settings/users/project-settings-users';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import find from 'lodash/find';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ProjectTeamRoleAssignmentsCard from 'components/projects/settings/teams/project-team-role-assignments-card';
import ProjectSettingsPolicies from 'components/projects/settings/policies/project-settings-policies';
import ProjectSettingsNotifications from 'components/projects/settings/notifications/project-settings-notifications';
import type { Project } from 'types/api.types';
import { useCuratedProjects } from 'stores/rq/projects';
import useIsMobile from 'hooks/use-is-mobile';
import ProjectSettingsEnvironmentDiscoveryContainer from 'components/projects/settings/environment-discovery/project-settings-environmnet-discovery-container';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import Loader from 'components/common/loader';

export const PROJECT_SETTINGS_MENU_KEYS = [
  'general',
  'users',
  'teams',
  'credentials',
  'policies',
  'notifications',
  'environment-discovery'
] as const;

type MenuKey = (typeof PROJECT_SETTINGS_MENU_KEYS)[number];

const MOBILE_PROJECT_SETTINGS_MENU_KEYS: MenuKey[] = ['general', 'policies'];

const allMenus = PROJECT_SETTINGS_MENU_KEYS.map(key => ({
  key,
  value: <FormattedMessage id={`projects.settings.menu.${key}`} />
}));

const menuComponents: Record<MenuKey, React.FunctionComponent<{ project: Project }>> = {
  general: ProjectSettingsGeneral,
  users: ProjectSettingsUsers,
  teams: ProjectTeamRoleAssignmentsCard,
  credentials: ProjectSettingsCredentials,
  policies: ProjectSettingsPolicies,
  notifications: ProjectSettingsNotifications,
  'environment-discovery': ProjectSettingsEnvironmentDiscoveryContainer
};

const ProjectSettings: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { activeMenu } = useParams<{ activeMenu: string }>();
  const projectId = useCurrentProjectId<string>();
  const { projects, isLoading } = useCuratedProjects();
  const { isDesktop } = useIsMobile();
  const organizationId = useCurrentOrganizationId();

  const project = projects[projectId];
  const menu = find(allMenus, { key: activeMenu as MenuKey });

  const menusToShow = useMemo(() => {
    if (isDesktop) return allMenus;
    else return allMenus.filter(({ key }) => MOBILE_PROJECT_SETTINGS_MENU_KEYS.includes(key));
  }, [isDesktop]);

  if (isLoading) {
    return <Loader />;
  }

  if (!project) {
    return <ErrorContainer message="projects.settings.not.found" errorToReport="project not found" />;
  }

  if (!menu) {
    return <Navigate replace to={`/p/${projectId}/settings/general`} />;
  }

  const setActiveMenu = (menu: string) => navigate(`/p/${projectId}/settings/${menu}`);
  const Component = menuComponents[menu.key];
  return (
    <Menu activeMenu={menu.key} menus={menusToShow} setActiveMenu={setActiveMenu}>
      <Component project={project} />
    </Menu>
  );
};

export default observer(ProjectSettings);
