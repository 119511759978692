import React from 'react';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import { links } from 'constants/external-links';
import Link from 'components/common/link';

const EKSCredentialInputs = ({ form }: CredentialsInputProps) => {
  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.K8S_EKS_AUTH;

  return (
    <>
      <Link messageId="settings.credentials.k8s.credentials.explanation" url={docsLink} />
      <ControlledTextInput key={'clusterName'} label={{ id: 'cluster.name' }} name={'value.clusterName'} form={form} />
      <ControlledTextInput
        key={'clusterRegion'}
        label={{ id: 'settings.credentials.eks.clusterRegion' }}
        name={'value.clusterRegion'}
        form={form}
      />
    </>
  );
};

export default EKSCredentialInputs;
