import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import type { BlueprintSshKey, SshKey } from 'types/api.types';
import type { LabeledValue } from 'components/common/input-components/select';
import { SelectOption } from 'components/common/input-components/select';
import { useModal } from 'hooks/modal.hooks';
import Button from 'components/common/button';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import type { WithLabelProps } from 'components/common/form-controlled/with-label.hoc';
import { useGetSshKeys, useCreateSshKey } from 'stores/rq/ssh-keys';
import type { SettingsForm, VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import CreateSshKeyModal from 'components/organizations/keys/create-ssh-key-modal';

const SSH_KEYS_FIELD_NAME = 'sshKeys' as const;

const getSshKeysSelectOptions = (sshKeys: BlueprintSshKey[]) => {
  return isEmpty(sshKeys) ? (
    <SelectOption key="no-ssh-keys" disabled value="">
      <FormattedMessage id="templates.add.new.field.ssh-keys.empty" />
    </SelectOption>
  ) : (
    sshKeys.map(({ id, name }) => (
      <SelectOption key={id} value={id}>
        {name}
      </SelectOption>
    ))
  );
};

const transformSshKeysToLabelValues = (sshKeys: BlueprintSshKey[]): LabeledValue | null => {
  if (isEmpty(sshKeys)) return null;
  const { id, name } = sshKeys[0];
  return { key: id, label: name, value: id };
};

type Props = WithLabelProps<SubFormProps<SettingsForm | VCSForm>>;

const SshKeysField: React.FC<Props> = ({ form, ...labelProps }) => {
  const { hideModal, showModal, isModalOpen } = useModal();

  const { data: availableSshKeys = [], isLoading: loading } = useGetSshKeys();
  const { mutateAsync: createSshKey, isPending: isCreatePending } = useCreateSshKey();

  const [sshKeys, setSshKeys] = (form as SettingsForm & VCSForm).useManualField<BlueprintSshKey[]>(SSH_KEYS_FIELD_NAME);

  const onSshKeysSelectChange = (value?: LabeledValue) => {
    const newSshKeys = isNil(value) ? [] : [{ id: value.key, name: value.label } as BlueprintSshKey];
    setSshKeys(newSshKeys);
  };

  const onModalClose = useCallback(
    (createdKey?: SshKey) => {
      if (createdKey) setSshKeys([createdKey]);
      hideModal();
    },
    [setSshKeys, hideModal]
  );

  return (
    <>
      <ControlledSelect
        label={{ id: 'sshKey' }}
        info={{ id: 'templates.add.new.field.ssh-keys.info' }}
        data-e2e="ssh-keys-field"
        name={SSH_KEYS_FIELD_NAME}
        form={form}
        loading={loading}
        labelInValue
        allowClear={true}
        onChange={onSshKeysSelectChange}
        value={transformSshKeysToLabelValues(sshKeys)}
        showArrow
        menuItemSelectedIcon={<div />}
        placeholder={<FormattedMessage id="templates.add.new.field.ssh-keys.placeholder" />}
        customActions={
          <Button onClick={showModal} type="primary" size="small">
            <FormattedMessage id="settings.ssh-keys.add.new.title" />
          </Button>
        }
        {...labelProps}>
        {getSshKeysSelectOptions(availableSshKeys)}
      </ControlledSelect>
      <CreateSshKeyModal
        open={isModalOpen}
        onClose={onModalClose}
        keys={availableSshKeys}
        createKey={createSshKey}
        isLoading={isCreatePending}
      />
    </>
  );
};

export default SshKeysField;
