import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledCredentialTextInput from 'components/common/form-controlled/controlled-credential-text-input';

const AzureCredentialInputs = ({ form, usageType }: CredentialsInputProps) => {
  const docsLink =
    usageType === 'COSTS'
      ? links.docs.ENABLING_COST_MONITORING.CREATE_AZURE_CREDENTIALS
      : links.docs.DEPLOYMENT_CREDENTIALS.AZURE_SERVICE_PRINCIPAL_DOCS;

  return (
    <>
      <Link messageId="settings.credentials.azure.credentials.explanation" url={docsLink} />
      {[
        { key: 'clientId' },
        { key: 'clientSecret', isSensitive: true },
        { key: 'subscriptionId' },
        { key: 'tenantId' }
      ].map(({ key, isSensitive }) => (
        <ControlledCredentialTextInput
          isSensitive={isSensitive && usageType === 'DEPLOYMENT'}
          key={key}
          label={{ id: `settings.credentials.azure.${key}` }}
          name={`value.${key}`}
          form={form}
        />
      ))}
    </>
  );
};

export default AzureCredentialInputs;
