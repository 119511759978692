import { useMemo, useCallback } from 'react';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { VCSStepValues } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { Blueprint } from 'types/api.types';
import useForm from 'hooks/use-form.hook';
import noop from 'lodash/noop';
import { getVcsStepSchema } from 'components/templates/templates-wizard/common/template-wizard-schemas';
import type { AnyObjectSchema } from 'yup';
import { getFormInitialValues } from 'components/templates/templates-wizard/common/template-wizard-utils';

type UseSettingsStepFormProps = {
  iacType: BlueprintApi.BlueprintType;
  readonly: boolean;
  template?: Blueprint;
};

export type VcsStepForm = ReturnType<typeof useVcsStepForm>;

const useVcsStepForm = ({ iacType, readonly, template }: UseSettingsStepFormProps) => {
  const getInitialValues = useCallback((schema: AnyObjectSchema) => getFormInitialValues(schema, template), [template]);

  const vcsStepSchema = useMemo(() => getVcsStepSchema(iacType), [iacType]);

  return useForm<VCSStepValues>({
    initialValues: getInitialValues(vcsStepSchema),
    schema: vcsStepSchema,
    shouldUnregister: false,
    onSubmit: noop,
    readonly
  });
};

export default useVcsStepForm;
