import { observable, action } from 'mobx';
import type { SchedulingApi } from '@env0/scheduling-service/api';
import BaseService from 'services/base-service';

const combineActions = (
  deployAction: SchedulingApi.SetEnvironmentSchedule.Request.ScheduledAction,
  destroyAction: SchedulingApi.SetEnvironmentSchedule.Request.ScheduledAction
): SchedulingApi.SetEnvironmentSchedule.Request.Body => {
  const result: SchedulingApi.SetEnvironmentSchedule.Request.Body = {};

  if (deployAction.enabled) result.deploy = deployAction;
  if (destroyAction.enabled) result.destroy = destroyAction;

  return result;
};

export class SchedulingStore extends BaseService {
  @observable scheduledActionsByEnvironment = new Map<string, SchedulingApi.SetEnvironmentSchedule.Response>();

  @action private updateScheduledActions(
    environmentId: string,
    scheduleActions: SchedulingApi.SetEnvironmentSchedule.Response
  ) {
    this.scheduledActionsByEnvironment.set(environmentId, scheduleActions);
  }

  async getScheduledActions(environmentId: string) {
    const scheduleActions = await this.service.apiClient.scheduling.getScheduledActions(environmentId);

    this.updateScheduledActions(environmentId, scheduleActions);
  }

  async setScheduledActions(
    environmentId: string,
    deployAction: SchedulingApi.SetEnvironmentSchedule.Request.ScheduledAction,
    destroyAction: SchedulingApi.SetEnvironmentSchedule.Request.ScheduledAction
  ) {
    const scheduledActions = await this.service.apiClient.scheduling.setScheduledActions(
      environmentId,
      combineActions(deployAction, destroyAction)
    );

    this.updateScheduledActions(environmentId, scheduledActions);
    return scheduledActions;
  }
}
