import React from 'react';
import styled from 'types/theme.types';

const ColorText: React.FC<{ text: string; colorText: string }> = ({ text, colorText }) => {
  const index = text?.toLowerCase().indexOf(colorText.toLowerCase());
  const beforeStr = text.substring(0, index);
  const middleStr = text.substring(index, index + colorText.length);
  const afterStr = text.slice(index + colorText.length);
  return (
    <>
      {beforeStr}
      <StyledText>{middleStr}</StyledText>
      {afterStr}
    </>
  );
};

const StyledText = styled.span`
  color: red;
`;

export default ColorText;
