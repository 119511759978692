import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type {
  CreateGpgKeyPayload,
  CreateProviderPayload,
  GpgKey,
  Provider,
  ProviderWithVersions,
  UpdateProviderPayload
} from 'types/api.types';

export default (client: HttpClient) => ({
  createProvider: (provider: CreateProviderPayload) => {
    return client.post('providers', provider).then(returnData);
  },
  getProviders: (organizationId: string): Promise<Provider[]> => {
    return client.get('providers', { params: { organizationId } }).then(returnData);
  },
  getProvider: (id: string): Promise<ProviderWithVersions> => client.get(`providers/${id}`).then(returnData),
  updateProvider: (providerId: string, payload: UpdateProviderPayload) => {
    return client.put(`providers/${providerId}`, payload).then(returnData);
  },
  deleteProvider: (id: string) => {
    return client.delete(`providers/${id}`);
  },
  createGPGKey: async (gpgKey: CreateGpgKeyPayload): Promise<GpgKey> =>
    await client.post('gpg-keys', { ...gpgKey }).then(returnData),

  readGPGKeys: (organizationId: string) => client.get('gpg-keys', { params: { organizationId } }).then(returnData),

  deleteGPGKey: async (id: string) => {
    await client.delete(`gpg-keys/${id}`);
  },

  deleteVersion: (providerId: string, version: string, platform: string) => {
    throw new Error(
      `delete version not yet supported. providerId: ${providerId}, version: ${version}, platform: ${platform}`
    );
  }
});
