import { action, observable } from 'mobx';
import BaseService from 'services/base-service';

export class NavBarStore extends BaseService {
  @observable open?: boolean = true;

  @action setOpen(isOpen: boolean) {
    this.open = isOpen;
  }

  @action toggleOpen() {
    this.open = !this.open;
  }

  get isOpen() {
    return this.open;
  }
}
